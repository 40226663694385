<ion-content [color]="Color.Alvin" [fullscreen]="true">
    <section class="encourage-trial-modal">
        <img class="encourage-trial-modal__logo" [src]="'assets/images/zigzag/zigzag-logo.png'" alt="zigzag logo" />
        <div class="encourage-trial-modal__image-container">
            <img
                class="encourage-trial-modal__image"
                [src]="'assets/images/bailey/bailey-flower-bucket.svg'"
                alt="illustration of a dog standing on a toppled over plant pot"
            />
        </div>

        <div class="encourage-trial-modal__title-container ignore-global-color">
            <p class="encourage-trial-modal__text">Your free access has expired</p>
            <h1>Keep going with your 7 day FREE trial!</h1>
        </div>
        <ion-footer class="encourage-trial-modal-footer page-padding-sides">
            <ion-button
                appTrackClick
                identifier="encourage-trial-modal-footer-continue-journey-btn"
                expand="full"
                shape="round"
                data-test="encourage-trial-modal-continue-journey-btn"
                [color]="Color.Max"
                class="encourage-trial-modal-footer__button"
                (click)="continueMyJourney()"
            >
                Continue my journey
            </ion-button>
        </ion-footer>
    </section>
</ion-content>
