import { platformFeature } from '@frontend/data-access/platform';
import { selectCountryCode } from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';

export const selectLandingPageVm = createSelector(
    platformFeature.selectPlatformIsIos,
    selectCountryCode,
    (isIos, countryCode) => {
        return {
            isIos,
            countryCode,
        };
    },
);
