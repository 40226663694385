@if (vm$ | async; as vm) {
<ion-content class="page-safe-margin ion-padding-bottom page-ion-content page-padding-top" [fullscreen]="true">
    <div class="page-padding-top page-padding-sides">
        <h1 class="page-title">Pup support hub <br /></h1>
        <p class="s">Ask Ziggy AI & our training coaches anything, anytime of day or night, 7 days a week! 🐶</p>
        <section class="tile-container">
            @for (tile of vm.tiles; track tile.title) {
            <app-generic-card-slim
                [attr.data-test]="tile.isContentUnlocked ? 'support-page-' + tile.cardName + '-card' : 'support-page-' + tile.cardName + '-card-locked'"
                [background]="tile.backgroundColor"
                [title]="tile.title"
                [imageUrl]="tile.imageUrl"
                [unlocked]="tile.isContentUnlocked"
                [isBasicPlan]="vm.isBasicPlan"
                [disabled]="!!vm.isStreamChatBusyCleaningUp && tile.cardName !== 'call'"
                (click)="cardClicked(tile.isContentUnlocked, tile.cardName, !!(vm.isStreamChatBusyCleaningUp && tile.cardName !== 'call'))"
                appTrackClick
                [identifier]="'support-' + tile.cardName + '-btn'"
            >
            </app-generic-card-slim>
            }
        </section>
        <app-trainer-info [isUsUser]="vm.isUsUser"></app-trainer-info>
    </div>
</ion-content>
}
