import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectQuestionFirstDayAtHomeLegacyPageVm } from './question-first-day-at-home-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { onboardingQuestionSubmittedFirstDayAtHome } from '../../../data-access/onboarding.actions';
import { subDays } from 'date-fns';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-onboarding-legacy-question-first-day-at-home',
    templateUrl: 'question-first-day-at-home-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss'],
    standalone: true,
    imports: [
        OnboardingLegacyQuestionTemplateComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonSpinner,
    ],
})
export class OnboardingQuestionFirstDayAtHomeLegacyPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectQuestionFirstDayAtHomeLegacyPageVm);

    public hasArrivedTodayAnswered(arrivedToday: boolean): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedFirstDayAtHome({
                dateOfArrival: (arrivedToday ? new Date() : subDays(new Date(), 2)).toISOString(),
            }),
        );
    }
}
