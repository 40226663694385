import { NgIf } from '@angular/common';
import { Component, input } from '@angular/core';
import { IonChip } from '@ionic/angular/standalone';

@Component({
    selector: 'zz-author-chip',
    templateUrl: './zz-author-chip.component.html',
    styleUrl: './zz-author-chip.component.scss',
    standalone: true,
    imports: [NgIf, IonChip],
})
export class ZzAuthorChipComponent {
    public author = input.required<string>();
    public authorImageUrl = input.required<string>();
}
