import { Component, computed, effect, EventEmitter, inject, Input, Output } from '@angular/core';
import { trackLibrarySearch } from '@frontend/data-access/analytics';
import { SearchBarComponent } from '@frontend/ui';
import { IonList } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { removeDuplicates } from '@shared/utils/typescript';
import MiniSearch from 'minisearch';
import { Observable } from 'rxjs';
import { StepCardSlimModel } from '../../step/step-card-slim/models/step-card-slim.model';
import { StepCardSlimComponent } from '../../step/step-card-slim/step-card-slim.component';
import { selectLibrarySearchBarVm } from '../../store/library/library.selector';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { routeToStep } from '../../store/router/router.actions';

@Component({
    selector: 'app-library-search-bar',
    templateUrl: './library-search-bar.component.html',
    styleUrls: ['./library-search-bar.component.scss'],
    standalone: true,
    imports: [SearchBarComponent, StepCardSlimComponent, IonList],
})
export class LibrarySearchBarComponent {
    private readonly store = inject(Store);

    @Input() onLibraryPage: Observable<boolean> | undefined;
    @Output() searching = new EventEmitter<boolean>();

    private readonly vm = this.store.selectSignal(selectLibrarySearchBarVm);

    unlocked = computed(() => this.vm().unlocked); //todo delete this as it is unused
    allCardItems = computed(() => this.vm().cardItems);
    itemsToSearchOn = computed(() => this.vm().searchItems);
    isBasicPlan = computed(() => this.vm().isBasicPlan);

    foundItems: StepCardSlimModel[] = [];

    private miniSearch = new MiniSearch({
        fields: ['title', 'topic', 'content'],
        searchOptions: {
            fuzzy: 0.2,
            prefix: true,
            boost: { title: 11 },
        },
    });

    // TODO: NBSon - write tests
    constructor() {
        effect(() => {
            if (this.itemsToSearchOn().length > 0) {
                this.miniSearch.removeAll();
                this.miniSearch.addAll(removeDuplicates(this.itemsToSearchOn(), (a, b) => a.id === b.id));
            }
        });
    }

    public searchItems(searchTerm: string): void {
        if (searchTerm && searchTerm.trim() !== '') {
            this.foundItems = this.search(searchTerm).slice(0, 10);
        }
    }

    public goToStep(stepId: string): void {
        this.store.dispatch(
            routeToStep({
                baseUrl: ['main', 'library'],
                stepId,
                from: 'library',
            }),
        );
    }

    // TODO: NBSon - private method made public for testing purposes
    public search(searchTerm: string): StepCardSlimModel[] {
        const searchResults = this.miniSearch.search(searchTerm);

        this.store.dispatch(trackLibrarySearch({ searchTerm, resultCount: searchResults.length }));

        return searchResults
            .map((result) => this.allCardItems().find((item) => item.id === result.id))
            .filter((cardItem) => cardItem !== undefined)
            .map((cardItem) => cardItem as StepCardSlimModel);
    }

    public focusChange(isSearching: boolean) {
        return this.isBasicPlan()
            ? this.store.dispatch(showPaymentModal({ trigger: 'library search bar' }))
            : this.searching.emit(isSearching);
    }
}
