import { Component, inject, ViewChild } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { launchRatingPrompt } from '@frontend/data-access/app-rating';

@Component({
    standalone: true,
    selector: 'app-chat-review-modal',
    templateUrl: './chat-review-modal.component.html',
    styleUrls: ['./chat-review-modal.component.scss'],
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        IonItem,
        IonInput,
        IonButton,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class ChatReviewModalComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @ViewChild('commentInput') commentReviewInput: IonInput | undefined;
    modalPage = 1;

    page1ReviewButton(response: 'positive' | 'negative') {
        if (response === 'positive') {
            this.submitChatReview(response);
            this.store.dispatch(launchRatingPrompt());
            this.closeModal();
        } else {
            this.modalPage = 2;
        }
    }

    page2ReviewButton(skipped: boolean) {
        this.submitChatReview('negative');
        if (skipped) {
            this.closeModal();
        } else {
            this.modalPage = 3;
        }
    }

    submitChatReview(response: 'positive' | 'negative') {
        this.store.dispatch(
            trackEvent({
                eventName: 'chat-review',
                eventProperties: {
                    response: response,
                    comment: this.commentReviewInput?.value,
                },
            }),
        );
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
