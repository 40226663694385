import { Injectable, inject } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { FoodProduct } from '../../data-access/food-calculator.model';
import { FoodProductSelectorModalComponent } from './food-product-selector-modal/food-product-selector-modal.component';

@Injectable({
    providedIn: 'root',
})
export class FoodProductModalService {
    private readonly modalController = inject(ModalController);

    public async showSelectorModal(foodProducts: FoodProduct[]): Promise<FoodProduct | undefined> {
        const modal = await this.modalController.create({
            component: FoodProductSelectorModalComponent,
            componentProps: {
                products: foodProducts,
            },
        });

        return await modal
            .present()
            .then(() => modal.onDidDismiss<FoodProduct>())
            .then(({ data }) => data);
    }

    public dismiss(data?: unknown): void {
        void this.modalController.dismiss(data);
    }
}
