import { createSelector } from '@ngrx/store';
import { isEmpty } from '@shared/utils/typescript';
import { subscriptionFeature } from './subscription.reducer';

export const selectIsNonNativeSubscriptionInfoLoading = createSelector(
    subscriptionFeature.selectNonNativeSubscriptionInfo,
    (nonNativeSubscriptionInfo) => nonNativeSubscriptionInfo?.loading || false,
);

export const selectNonNativeSubscriptionInfoData = createSelector(
    subscriptionFeature.selectNonNativeSubscriptionInfo,
    (nonNativeSubscriptionInfo) => nonNativeSubscriptionInfo?.data?.value,
);

export const selectIsNonNativeSubscriptionActive = createSelector(
    selectNonNativeSubscriptionInfoData,
    (nonNativeSubscriptionInfoData) => nonNativeSubscriptionInfoData?.isActive || false,
);

export const selectIsSubscriptionLoading = createSelector(
    subscriptionFeature.selectAreEntitlementsLoading,
    subscriptionFeature.selectEntitlements,
    selectIsNonNativeSubscriptionInfoLoading,
    (areEntitlementsLoading, entitlements, isNonNativeSubscriptionInfoLoading) =>
        areEntitlementsLoading || (isEmpty(entitlements) && isNonNativeSubscriptionInfoLoading),
);
