import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { LocalStorageKeys } from './models/local-storage-keys.model';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    // todo remove me after June 24th
    async syncLegacyData(): Promise<void> {
        const appState = await this.getLocalStorage<
            { dismissibleInfo: unknown; foodCalculator: unknown; profileImage: unknown } | undefined
        >(LocalStorageKeys.appState);

        if (!(await this.getLocalStorage('food-calculator')) && appState?.foodCalculator) {
            void this.setLocalStorage('food-calculator', appState.foodCalculator);
        }

        if (!(await this.getLocalStorage('profile-image')) && appState?.profileImage) {
            void this.setLocalStorage('profile-image', appState.profileImage);
        }

        if (!(await this.getLocalStorage('dismissible-info')) && appState?.dismissibleInfo) {
            void this.setLocalStorage('dismissible-info', appState.dismissibleInfo);
        }
    }

    async setLocalStorage(key: string, value: unknown): Promise<void> {
        await Preferences.set({ key, value: JSON.stringify(value) });
    }

    async getLocalStorage<TValue = unknown>(key: string): Promise<TValue | undefined> {
        const ret = await Preferences.get({ key });
        return ret && ret.value !== null ? JSON.parse(ret.value) : undefined;
    }

    async deleteLocalStorage(key: string): Promise<void> {
        await Preferences.remove({ key });
    }

    async clearLocalStorage(): Promise<void> {
        await Preferences.clear();
    }
}
