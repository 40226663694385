import { createSelector } from '@ngrx/store';
import { selectIsSelectedCourseActive, selectSelectedCourseSplits } from '../store/course-path.selectors';

export const selectCoursePathSplitComponentVm = createSelector(
    selectSelectedCourseSplits,
    selectIsSelectedCourseActive,
    (splits, isSelectedCourseActive) => ({
        splits: splits ?? [],
        isSelectedCourseActive,
    }),
);
