import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { PushListenersService } from '../services/push-listeners.service';
import { addCapacitorPushNotificationListeners } from './push-listeners.actions';

@Injectable()
export class PushListenersEffects {
    private readonly actions$ = inject(Actions);
    private readonly capacitorAppService = inject(PushListenersService);
    addPushNotificationListeners$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addCapacitorPushNotificationListeners),
            tap(async () => {
                await this.capacitorAppService.addPushNotificationListeners();
            }),
            filter(() => false),
        );
    });
}
