<ion-text class="ion-text-center">
    <h2>Why do you want to<br />skip this lesson?</h2>
</ion-text>

<div class="buttons">
    @for (skipReason of skipStepOptions.reasons; track skipReason.id) {
        <ion-button
            class="skip-reason-button"
            appTrackClick
            [identifier]="'step-skip-reason-' + skipReason.id"
            [id]="skipReason.id"
            [attr.data-test]="'lesson-skip-notification-modal-skip-reason-' + skipReason.id + '-btn'"
            (click)="onSkipClick(skipReason)"
        >
            {{ skipReason.text | textReplacement }}
        </ion-button>
    }
    <ion-button
        class="lesson-button"
        appTrackClick
        identifier="step-skip-cancel-button"
        color="max"
        data-test="lesson-skip-notification-modal-cancel-btn"
        (click)="onCancelClick()"
        >Cancel
    </ion-button>
</div>
