<div class="error-screen">
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-header
                [leftButton]="{ analyticsIdentifier: 'error-screen-back-btn', background: 'none' }"
                [rightButton]="false"
                (leftButtonPressed)="closeModal()"
            />
        </ion-toolbar>
    </ion-header>

    <ion-content [fullscreen]="true" class="page-ion-content error-screen__no-background ion-text-center">
        <div class="page-content page-content-padding-top">
            <div class="error-screen__digging-dog-container">
                <img
                    alt="digging-dog-image"
                    src="assets/images/buddy/buddy-digs-into-ground.svg"
                    class="error-screen__digging-dog-image"
                />
            </div>
            <div>
                <h1>Oops!</h1>
                <p class="error-screen__text">
                    {{ errorMessage }}
                </p>
            </div>
        </div>
    </ion-content>
    <ion-footer class="ion-text-center page-footer auth-footer">
        <ion-button
            (click)="buttonClick()"
            data-test="error-screen-try-again-btn"
            color="max"
            expand="block"
            appTrackClick
            identifier="error-screen-try-again-btn"
            >{{ buttonText }}
        </ion-button>
    </ion-footer>
</div>
