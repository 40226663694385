import { map, Observable, of } from 'rxjs';
import { breedGroupFeature, ContentService, getBreedGroupsSuccess } from '@frontend/data-access/contentful';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContentfulLocale } from '@shared/constants';
import { InternalBreedGroupDto } from '@shared/content-api-interface';

export const breedResolver = (): Observable<boolean> => {
    const store = inject(Store);
    const breedService = inject(ContentService);

    const breedGroups = store.selectSignal(breedGroupFeature.selectBreedGroups);
    if (breedGroups().length > 0) {
        return of(true);
    }

    return breedService.getBreedGroups({ locale: ContentfulLocale.EN_GB }).pipe(
        map((breedGroups: InternalBreedGroupDto[]) => {
            store.dispatch(getBreedGroupsSuccess({ breedGroups }));
            return true;
        }),
    );
};
