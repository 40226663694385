import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../../../store/payment/store/payment.selectors';
import { selectIsSelectedCourseActive, selectSelectedCourseColor } from '../store/course-path.selectors';

export const selectCoursePathStepComponentVm = createSelector(
    selectContentUnlocked,
    selectSelectedCourseColor,
    selectIsSelectedCourseActive,
    (unlocked, selectedCourseColor, isCourseActive) => ({
        unlocked,
        selectedCourseColor,
        isCourseActive,
    }),
);
