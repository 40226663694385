import { Action, createFeature } from '@ngrx/store';
import { EditProfileForm } from './edit-profile/edit-profile.form.model';
import { editProfileFormReducer, initialEditProfileFormState } from './edit-profile/edit-profile.form.reducer';

export interface FormsState {
    editProfile: EditProfileForm;
}

export const initialState: FormsState = {
    editProfile: initialEditProfileFormState,
};

export const editProfileFeature = createFeature({
    name: 'editProfile',
    reducer: (state: FormsState = initialState, action: Action): FormsState => ({
        editProfile: editProfileFormReducer(state.editProfile, action),
    }),
});
