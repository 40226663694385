import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';
import { HapticFeedbackService } from '../haptic-feedback.service';
import { fireHapticFeedback } from './haptic-feedback.actions';

@Injectable()
export class HapticFeedbackEffects {
    private readonly actions$ = inject(Actions);
    private readonly hapticFeedbackService = inject(HapticFeedbackService);
    fire$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(fireHapticFeedback),
                tap(({ style }) => void this.hapticFeedbackService.fire(style)),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
