@if (swiper) {
    <ion-header class="chat-intro-modal">
        <div
            class="chat-intro-modal__header page-content-padding-top page-padding-sides font-bailey"
            [ngClass]="{
                'chat-intro-modal__header--justify-end': swiper.swiper.activeIndex === 0
            }"
        >
            @if (swiper.swiper.activeIndex !== 0) {
                <button
                    (click)="previousSlide()"
                    class="zz-button xxxl as-div"
                    data-test="ai-chat-intro-modal-previous-slide-btn"
                >
                    <i class="font-bailey fa-thin fa-circle-arrow-left"></i>
                </button>
            }
            <button
                (click)="closeModalAndRouteToPrevious()"
                class="zz-button xxxl as-div"
                data-test="ai-chat-intro-modal-close-btn"
            >
                <i class="font-bailey fa-thin fa-circle-xmark"></i>
            </button>
        </div>
    </ion-header>
}

<ion-content [fullscreen]="true" class="chat-intro-modal">
    @if (swiper) {
        <img
            class="chat-intro-modal__background"
            [ngStyle]="{ transform: backgroundTransform[swiper.swiper.activeIndex ?? 0] }"
            src="/assets/images/squiggles/chat-intro-squiggle.svg"
            alt="background image of squiggle"
        />
    }
    <!--swiper event exists but it is a web component so angular doesn't recognise event-->
    <swiper-container
        class="chat-intro-modal__swiper"
        appSwiper
        #swiper
        [config]="config"
        (swiperslidechange)="onSlideChange()"
    >
        @for (slide of slides; track slide.title) {
            <swiper-slide>
                <div class="page-padding-sides center">
                    <div class="chat-intro-modal__main-image-wrapper">
                        <img [src]="slide.imageSrc" [alt]="slide.imageAlt" class="chat-intro-modal__main-image" />
                    </div>
                    <h1 class="text-snowy" [innerHTML]="slide.title | textReplacement"></h1>
                    <p class="text-snowy" [innerHTML]="slide.description | textReplacement"></p>
                </div>
            </swiper-slide>
        }
    </swiper-container>
</ion-content>

@if (swiper) {
    <ion-footer class="chat-intro-modal page-footer">
        <div class="chat-intro-modal__pagination">
            <div
                class="chat-intro-modal__pagination-circle-container"
                data-test="ai-chat-intro-modal-pagination-container"
                [ngStyle]="{ width: paginationWidth(slides.length) }"
            >
                @for (slide of slides; track slide.title; let index = $index) {
                    @if (index === 0) {
                        <div
                            class="chat-intro-modal__pagination-circle chat-intro-modal__pagination-circle--active"
                            data-test="ai-chat-intro-modal-active-pagination"
                            [ngStyle]="{ left: positionActivePaginationCircle() }"
                        ></div>
                    } @else {
                        <div
                            class="chat-intro-modal__pagination-circle"
                            [ngStyle]="{ left: positionPaginationCircle(index - 1) }"
                            [attr.data-test]="'ai-chat-intro-modal-pagination-' + (index - 1)"
                            (click)="paginationCircleClick(index - 1)"
                        ></div>
                    }
                }
            </div>
        </div>
        <ion-button
            (click)="nextSlide()"
            [color]="swiper.swiper.isEnd ? Color.Max : ''"
            expand="block"
            class="chat-intro-modal__footer-button"
            data-test="ai-chat-intro-modal-footer-btn"
        >
            {{ slides[swiper.swiper.activeIndex ?? 0]?.buttonText }}
        </ion-button>
    </ion-footer>
}
