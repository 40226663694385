<app-onboarding-legacy-question-template
    backButtonId="question-rescue-back-btn"
    title="Has {{ vm().dogName }} come from a shelter or rescue?"
    subtitle="This will help to tailor the program"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <div
            class="rescue-image"
            style="background-image: url('assets/images/charly/charly-asleep-in-dog-bed.svg')"
            alt="dog in bed"
        ></div>
    </ng-container>
    <ng-container footer>
        <div class="onboarding-radio-button-container">
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-from-rescue-true-btn"
                fill="outline"
                data-test="question-from-rescue-true-btn"
                [disabled]="vm().isLoading"
                (click)="continue(true)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { Yes }
            </ion-button>
            <ion-button
                class="light-button-activated-jack"
                appTrackClick
                identifier="question-from-rescue-false-btn"
                fill="outline"
                data-test="question-from-rescue-false-btn"
                [disabled]="vm().isLoading"
                (click)="continue(false)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { No }
            </ion-button>
        </div>
    </ng-container>
</app-onboarding-legacy-question-template>
