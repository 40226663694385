import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { getSVGColour, isColorContrastLight, setCourseBackgroundColor } from '@shared/utils/typescript';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { Course } from '@frontend/data-access/contentful';
import { Store } from '@ngrx/store';
import { goToCourseFromTile } from '../../today.page.actions';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-course-tile',
    templateUrl: './course-tile.component.html',
    styleUrls: ['./course-tile.component.scss'],
    standalone: true,
    imports: [IonCard, IonButton, NgStyle, AnalyticsTrackClickDirective, NgCircleProgressModule, AsyncPipe],
})
export class CourseTileComponent {
    store = inject(Store);

    @Input() backgroundColor = 'harley';
    @Input({ required: true }) course!: Course;

    getSVGColour = getSVGColour;

    setCourseBackgroundColor = setCourseBackgroundColor;

    goToCourse(courseId: string) {
        this.store.dispatch(goToCourseFromTile({ courseId }));
    }

    setBackground() {
        return {
            ...setCourseBackgroundColor(this.course.color),
            'background-position': 'center',
            'background-image': isColorContrastLight(this.course.color)
                ? 'url(/assets/images/squiggles/doodle.png)'
                : 'url(/assets/images/squiggles/doodle-dark.png)',
        };
    }
}
