import { createAction, props } from '@ngrx/store';
import { RestoredListenerEvent } from '@capacitor/app';

export const addCapacitorAppListeners = createAction('[Capacitor App] Add Capacitor App Listeners');

export const capacitorAppStateChange = createAction('[Capacitor App] App State Change', props<{ isActive: boolean }>());

export const capacitorAppRestored = createAction(
    '[Capacitor App] App Restored',
    props<{ data: RestoredListenerEvent }>(),
);

export const capacitorAppResume = createAction('[Capacitor App] App Resume');
