import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalEndpoints, InternalPinnedTopicDto, InternalPostPinnedTopicCommand } from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PinnedTopicsService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getPinnedTopics(): Observable<InternalPinnedTopicDto[]> {
        return this.http.get<InternalPinnedTopicDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.pinnedTopicsCollection}`,
            httpOptions,
        );
    }
    public addPinnedTopic(command: InternalPostPinnedTopicCommand): Observable<InternalPinnedTopicDto> {
        return this.http.post<InternalPinnedTopicDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.pinnedTopicsCollection}`,
            command,
            httpOptions,
        );
    }

    public removePinnedTopic(id: string): Observable<void> {
        return this.http.delete<void>(
            `${this.environment.internalApiUrl}${internalEndpoints.pinnedTopicsCollection}/${id}`,
            httpOptions,
        );
    }
}
