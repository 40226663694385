<ion-card class="input-block page-ion-content">
    <form [formGroup]="form">
        <section class="weight-block">
            <h2 class="weight-title">{{ "[NAME_C]'s weight" | textReplacement }}</h2>
            <span data-test="last-calculated">
                @if (foodPortionLastCalculated) {
                    <span>Last updated: {{ foodPortionLastCalculated | date: 'mediumDate' }}</span>
                } @else {
                    <span>&nbsp;</span>
                }
            </span>
            <app-weight-selector [formControlName]="'currentWeight'" />
        </section>

        <section class="food-block page-padding-sides">
            <ion-card>
                <app-food-product-selector [formControlName]="'foodProductId'" />
                <ion-item lines="full" detail="false">
                    <ion-label position="stacked">
                        <div
                            class="expected-weight-block info-button"
                            appTrackClick
                            identifier="expected-weight-info-button"
                            data-test="expected-weight-info-button"
                            (click)="showWeightInfoModal.next()"
                        >
                            Expected adult weight
                            <ion-icon name="information-circle-outline" />
                        </div>
                    </ion-label>
                    <ion-input
                        aria-label="Expected adult weight"
                        class="zz-input expected-weight"
                        inputmode="numeric"
                        type="tel"
                        maxlength="3"
                        data-test="expected-weight-input"
                        placeholder="..."
                        labelPlacement="stacked"
                        [formControlName]="'expectedWeight'"
                        [min]="minExpectedWeight"
                        [tabindex]="1"
                    >
                    </ion-input>
                    @if (form.controls.expectedWeight.errors?.['min']) {
                        <app-invalid-input-error-message
                            [errorMessage]="'Expected weight should be minimum ' + minExpectedWeight + ' kilogram'"
                        />
                    }
                </ion-item>

                <ng-container>
                    <ion-item lines="none" detail="false" data-test="age-item">
                        <ion-label position="stacked">Age in weeks</ion-label>
                        <ion-input
                            class="zz-input"
                            aria-label="Age in weeks"
                            inputmode="numeric"
                            type="tel"
                            maxlength="2"
                            data-test="age-in-weeks-input"
                            placeholder="..."
                            [formControlName]="'ageInWeeks'"
                            [min]="minAgeInWeeks"
                            [tabindex]="2"
                        />
                        @if (form.controls.ageInWeeks.errors?.['min']) {
                            <app-invalid-input-error-message
                                data-test="invalid-input-age-in-weeks"
                                [errorMessage]="
                                    'You can use the calculator when [NAME] is at least ' +
                                        minAgeInWeeks +
                                        ' weeks old.' | textReplacement
                                "
                            />
                        }
                    </ion-item>
                </ng-container>
            </ion-card>
        </section>
    </form>
</ion-card>
