import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { openUrlWindow } from '@frontend/data-access/router';
import {
    GET_INITIAL_OFFERINGS_CORRELATION_ID,
    getEntitlementsSuccess,
    getOfferings,
    getOfferingsSuccess,
    purchaseProductSuccess,
    subscriptionFeature,
} from '@frontend/data-access/subscription';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { PaymentModalLimitedOfferComponent } from '../../../payment-modal/payment-modal-limited-offer/payment-modal-limited-offer.component';
import { PaymentModalParentComponent } from '../../../payment-modal/payment-modal-parent/payment-modal-parent.component';
import { PaymentModalTrialReminderComponent } from '../../../payment-modal/payment-modal-trial-reminder/payment-modal-trial-reminder.component';
import { PaymentModalComponent } from '../../../payment-modal/payment-modal.component';
import {
    PAYMENT_MODAL_ID,
    PAYMENT_MODAL_LIMITED_OFFER_ID,
    PAYMENT_MODAL_PLAN_COMPARISON_ID,
} from '../../../payment-modal/payment-modal.constant';
import { selectBasicOfferingProductCards } from '../../../payment-modal/payment-modal.selectors';
import { SHOW_PAYMENT_MODAL_TRIGGERS } from '../constants/payment.constants';
import {
    closePaymentModalClicked,
    openIosDiscountProducts,
    showIosDiscountPaymentModal,
    showPaymentModal,
    showPaymentModalLimitedOffer,
} from './payment.actions';
import {
    selectIsBasicPlan,
    selectIsEligibleForDiscountPaymentModal,
    selectIsEligibleForLimitedOfferPaymentModal,
    selectLimitedOfferProductCards,
} from './payment.selectors';

@Injectable()
export class PaymentEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    triggerDiscountPaywallAfterInitialOffering$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getOfferingsSuccess),
            filter(({ correlationId }) => correlationId === GET_INITIAL_OFFERINGS_CORRELATION_ID),
            map(() => {
                return showIosDiscountPaymentModal({ trigger: SHOW_PAYMENT_MODAL_TRIGGERS.STARTUP });
            }),
        );
    });

    showDiscountPaymentModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showIosDiscountPaymentModal),
            concatLatestFrom(() => this.store.select(selectIsEligibleForDiscountPaymentModal)),
            filter(([, isEligible]) => isEligible),
            map(([{ trigger }]) => {
                return showPaymentModal({
                    trigger,
                    showDiscountProducts: true,
                });
            }),
        );
    });

    showPaymentModalParent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showPaymentModal),
            concatLatestFrom(() => [
                this.store.select(subscriptionFeature.selectHasHistoricalPurchase),
                this.store.select(selectIsBasicPlan),
            ]),
            filter(
                ([{ showDiscountProducts }, hasHistoricalPurchase, isBasicPlan]) =>
                    !!showDiscountProducts || !!hasHistoricalPurchase || !!isBasicPlan,
            ),
            tap(([{ trigger, showDiscountProducts }]) => {
                void this.modalService.showModal({
                    id: PAYMENT_MODAL_ID,
                    component: PaymentModalComponent,
                    componentProps: {
                        pageComponent: PaymentModalParentComponent,
                        showDiscountProducts,
                        trigger,
                    },
                    cssClass: ['modal', 'modal-fullscreen'],
                    showBackdrop: false,
                });
            }),
            switchMap(([{ trigger, showDiscountProducts }, hasHistoricalPurchase]) => [
                getOfferings({}),
                trackEvent({
                    eventName: 'payment-modal-initiated',
                    eventProperties: {
                        trigger,
                        showDiscountProducts: showDiscountProducts ?? false,
                        hasHistoricalPurchase,
                    },
                }),
            ]),
        );
    });

    showPaymentModalTrialReminder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showPaymentModal),
            concatLatestFrom(() => [
                this.store.select(subscriptionFeature.selectHasHistoricalPurchase),
                this.store.select(selectIsBasicPlan),
            ]),
            filter(
                ([{ showDiscountProducts }, hasHistoricalPurchase, isBasicPlan]) =>
                    !showDiscountProducts && !hasHistoricalPurchase && !isBasicPlan,
            ),
            tap(([{ trigger, showDiscountProducts }]) => {
                void this.modalService.showModal({
                    id: PAYMENT_MODAL_ID,
                    component: PaymentModalComponent,
                    componentProps: {
                        pageComponent: PaymentModalTrialReminderComponent,
                        trigger,
                        showDiscountProducts,
                    },
                    cssClass: ['modal', 'modal-fullscreen'],
                    showBackdrop: false,
                    /**
                     * NBSon - this is used in combination with the `ionViewWillEnter` and `ionViewWillLeave` lifecycle
                     * to prevent the user from being able to dismiss the modal through the back gesture/Android back button
                     * see {@link PaymentModalComponent.ionViewWillEnter}
                     */
                    backdropDismiss: false,
                });
            }),
            switchMap(([{ trigger, showDiscountProducts }, hasHistoricalPurchase]) => [
                getOfferings({}),
                trackEvent({
                    eventName: 'payment-modal-initiated',
                    eventProperties: {
                        trigger,
                        showDiscountProducts: showDiscountProducts ?? false,
                        hasHistoricalPurchase,
                    },
                }),
            ]),
        );
    });

    closePaymentModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(purchaseProductSuccess, getEntitlementsSuccess, closePaymentModalClicked),
                tap(() => {
                    void this.modalService.dismissById(PAYMENT_MODAL_ID, {
                        data: { dismissed: true },
                    });
                    void this.modalService.dismissById(PAYMENT_MODAL_LIMITED_OFFER_ID, {
                        data: { dismissed: true },
                    });
                    void this.modalService.dismissById(PAYMENT_MODAL_PLAN_COMPARISON_ID, {
                        data: { dismissed: true },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showLimitedOfferModalWhenClosingPaymentModalParent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(closePaymentModalClicked),
            concatLatestFrom(() => this.store.select(selectIsEligibleForLimitedOfferPaymentModal)),
            filter(([_, isEligible]) => isEligible),
            map(() => {
                return showPaymentModalLimitedOffer();
            }),
        );
    });

    showPaymentModalLimitedOffer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showPaymentModalLimitedOffer),
            concatLatestFrom(() => {
                return [
                    this.store.select(selectLimitedOfferProductCards),
                    this.store.select(selectBasicOfferingProductCards),
                ];
            }),
            filter(([_, limitedOfferProducts]) => limitedOfferProducts.length > 0),
            map(([_, limitedOfferProducts, basicProducts]) => {
                const shownProducts = limitedOfferProducts.filter((product) => product.offerEndDate);
                return {
                    shownProducts,
                    limitedOfferProducts: limitedOfferProducts,
                    basicProducts: basicProducts,
                };
            }),
            tap((props) => {
                void this.modalService.showModal({
                    id: PAYMENT_MODAL_LIMITED_OFFER_ID,
                    component: PaymentModalLimitedOfferComponent,
                    componentProps: props,
                    cssClass: ['modal', 'modal-fullscreen'],
                    showBackdrop: false,
                });
            }),
            switchMap((props) => {
                return [
                    getOfferings({}),
                    trackEvent({
                        eventName: '[Payment] Payment Modal Limited Offer Initiated',
                        eventProperties: props,
                    }),
                ];
            }),
        );
    });

    openIosDiscountProducts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(openIosDiscountProducts),
            switchMap(() => {
                return [
                    trackEvent({
                        eventName: 'Promo Offer Opened',
                    }),
                    openUrlWindow({
                        url: 'https://apps.apple.com/redeem?ctx=offercodes&id=1550121165&code=ZIGZAG50OFFER',
                        openInNewWindow: false,
                    }),
                ];
            }),
        );
    });
}
