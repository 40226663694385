import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { IonContent, IonIcon, IonProgressBar, IonSpinner } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { checkmarkOutline, ellipseOutline } from 'ionicons/icons';
import { finalisePageFinishedWaiting } from '../../../data-access/onboarding.actions';
import { accountFeature } from '@frontend/data-access/user/account';

@Component({
    selector: 'app-onboarding-legacy-finalize',
    templateUrl: 'finalize-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss', 'finalize-legacy.page.scss'],
    standalone: true,
    imports: [AsyncPipe, IonContent, IonSpinner, IonIcon, IonProgressBar],
})
export class OnboardingLegacyFinalizePage implements OnInit {
    private readonly store = inject(Store);
    stage = 0;
    progressBarPercentage = 0;
    dogName$ = this.store.select(accountFeature.selectDogName);
    public buttonLocked = true;

    constructor() {
        addIcons({ checkmarkOutline, ellipseOutline });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.stage++;
            this.progressBarPercentage = this.stage < 3 ? this.stage * 33 : 100;
        }, 2000);
        setTimeout(() => {
            this.stage++;
            this.progressBarPercentage = this.stage < 3 ? this.stage * 33 : 100;
        }, 5000);
        setTimeout(() => {
            this.stage++;
            this.progressBarPercentage = this.stage < 3 ? this.stage * 33 : 100;
        }, 6500);
        setTimeout(() => {
            this.store.dispatch(finalisePageFinishedWaiting());
            this.buttonLocked = false;
        }, 7000);
    }
}
