<div class="audio-player" (click)="seekAudio($event)">
    <audio #audioPlayer [src]="audio.url"></audio>
    <div class="audio-player__progress-bar" [style.width.%]="audioProgress"></div>
    <button
        class="zz-button audio-player__play-button"
        (click)="toggleAudio(); onPlayButtonClick($event)"
        appTrackClick
        identifier="audio-player-toggle-audio-button"
        data-test="audio-player-toggle-audio-button"
    >
        <img
            [src]="
                isAudioPlaying
                    ? 'assets/images/audio-player/pause-button.svg'
                    : 'assets/images/audio-player/play-button.svg'
            "
            alt="Play/Pause Icon"
        />
    </button>
    <p class="audio-player__title" [style.color]="isAudioPlaying ? 'var(--ion-color-max)' : 'black'">
        {{ audio.title }}
    </p>
    <p class="audio-player__remaining-time xs">{{ audioRemainingTime }}</p>
</div>
