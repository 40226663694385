import { Component, inject } from '@angular/core';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { subDays } from 'date-fns';
import {
    onboardingNavigateBack,
    onboardingQuestionSubmittedFirstDayAtHome,
} from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-first-day-at-home',
    templateUrl: 'question-first-day-at-home.page.html',
    styleUrls: ['question-first-day-at-home.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionFirstDayAtHomePage {
    private readonly store = inject(Store);

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onFirstDayAtHomeClick(hasArrivedToday: boolean): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedFirstDayAtHome({
                dateOfArrival: (hasArrivedToday ? new Date() : subDays(new Date(), 2)).toISOString(),
            }),
        );
    }
}
