import { notEmpty } from '@shared/utils/typescript';
import { PRE_PUP_COURSE_ID } from '@shared/constants';
import { StepType } from '@shared/content-api-interface';
import { StepStatus } from '../../../store/step/step.model';
import { CourseWithSteps } from '../../models/courses.models';
import { CourseCoordinates, SplitCoordinates } from '../store/course-path.reducer';
import {
    Coordinates,
    CoursePageStep,
    CoursePathSettings,
    CourseSplitWithCoordinates,
} from '../store/models/course-path.model';

export const courseSettings: CoursePathSettings = {
    iterationDistance: 25,
    pathMinSeparation: 200,
    stepMinSeparation: { x: 100, y: 130 },
    splitMinSeparation: { y: 130 },
    svgWidth: 250,
};

export const hasMetSeparationConditions = (
    potentialCoordinates: Coordinates,
    previousCoordinates: Coordinates,
    minimumSeparation: { x?: number; y?: number },
): boolean => {
    // y direction is signed as we only want to place steps below the previous step
    if (minimumSeparation.y && potentialCoordinates.y - previousCoordinates.y > minimumSeparation.y) {
        return true;
    }
    if (minimumSeparation.x && Math.abs(potentialCoordinates.x - previousCoordinates.x) > minimumSeparation.x) {
        return true;
    }
    return false;
};

export const addCoordinatesToItem = <T>(
    item: T,
    coordinatesToAdd: Coordinates,
    pathLength: number,
    distance: number,
): T & { coordinates: Coordinates; distance: number } => {
    let coordinates: Coordinates;

    if (distance <= pathLength) {
        coordinates = coordinatesToAdd;
    } else {
        coordinates = { x: 0, y: 0 };
    }
    return {
        ...item,
        coordinates: {
            x: coordinates.x,
            y: coordinates.y,
        },
        distance,
    };
};

export const getStableCoordinates = <T>(
    previousCoordinates: Coordinates,
    minimumSeparation: { x?: number; y?: number },
    item: T,
    pathElement: SVGPathElement,
    pathLength: number,
    startDistance: number,
    iterationDistance: number,
): T & { coordinates: Coordinates; distance: number } => {
    let potentialPosition: T & { coordinates: Coordinates; distance: number };
    let distance = startDistance;
    let numberOfTries = 0;

    if (startDistance === 0) {
        const calculatedCoordinates = addCoordinatesToItem<T>(
            item,
            pathElement.getPointAtLength(distance),
            pathLength,
            distance,
        );
        calculatedCoordinates.coordinates.y = 0;
        return calculatedCoordinates;
    }

    do {
        potentialPosition = addCoordinatesToItem<T>(item, pathElement.getPointAtLength(distance), pathLength, distance);
        distance += iterationDistance;
        numberOfTries++; // prevent potential endless loop
    } while (
        !hasMetSeparationConditions(potentialPosition.coordinates, previousCoordinates, minimumSeparation) &&
        numberOfTries < 10
    );
    return potentialPosition;
};

export const positionStepsAndSplits = (
    pathElement: SVGPathElement,
    course: CourseWithSteps,
    courseSettings: CoursePathSettings,
): CourseCoordinates => {
    const pathLength = pathElement.getTotalLength();
    let splitIndex = 0;
    let previousDistance = 0;
    let previousCoordinates: Coordinates = { x: 0, y: 0 };
    const splitCoordinates: SplitCoordinates[] = [];

    const stepCoordinates = course.steps.map((step, index) => {
        const hasSplit = index === course.splits[splitIndex]?.splitIndex;
        if (hasSplit) {
            const { distance, ...splitPositioned } = getStableCoordinates<{ splitNumber: number }>(
                previousCoordinates,
                courseSettings.splitMinSeparation,
                { splitNumber: course.splits[splitIndex]?.splitNumber },
                pathElement,
                pathLength,
                previousDistance,
                courseSettings.iterationDistance,
            );
            splitCoordinates.push(splitPositioned);
            splitIndex++;

            previousCoordinates = splitPositioned.coordinates;
            previousDistance = distance + courseSettings.pathMinSeparation;
        }

        const { distance, ...stepPositioned } = getStableCoordinates<{ id: number; isPractice: boolean }>(
            previousCoordinates,
            hasSplit ? courseSettings.splitMinSeparation : courseSettings.stepMinSeparation,
            { id: step.content.id, isPractice: step.progress?.isPractice ?? false },
            pathElement,
            pathLength,
            previousDistance,
            courseSettings.iterationDistance,
        );
        previousCoordinates = stepPositioned.coordinates;
        previousDistance = distance + courseSettings.pathMinSeparation;

        return stepPositioned;
    });
    return { id: course.id, stepCoordinates, splitCoordinates };
};
export const pickSelectedCourseId = (
    hasArrived: boolean,
    uncompletedFoundationCourses: string[],
    uncompletedCourses: string[],
): string => {
    if (!hasArrived && uncompletedFoundationCourses.find((id) => id === PRE_PUP_COURSE_ID)) {
        return PRE_PUP_COURSE_ID;
    }

    if (uncompletedFoundationCourses.length !== 0) {
        return uncompletedFoundationCourses[0];
    }

    if (uncompletedCourses.length !== 0) {
        return uncompletedCourses[0];
    }
    return PRE_PUP_COURSE_ID;
};

export const mapCourseAndCoordinatesToStepsAndSplitsWithCoordinates = (
    courseCoordinates: CourseCoordinates,
    courseWithStepsAndSplits: CourseWithSteps,
): { steps: CoursePageStep[]; splits: CourseSplitWithCoordinates[] } => {
    const firstInactiveStep = courseWithStepsAndSplits.steps.find((step) => {
        return step.progress.state === StepStatus.INACTIVE || step.progress.state === StepStatus.READ;
    });

    const steps: CoursePageStep[] = courseWithStepsAndSplits.steps
        .map((step): CoursePageStep | undefined => {
            const coordinates = courseCoordinates.stepCoordinates.find(
                (stepCoordinates) =>
                    stepCoordinates.id === step.content.id && step.progress.isPractice === stepCoordinates.isPractice,
            );

            if (coordinates !== undefined) {
                return {
                    ...step,
                    coordinates: coordinates.coordinates,
                    isFirstInactiveStep:
                        firstInactiveStep?.id === step.id &&
                        step.progress.isPractice === firstInactiveStep.progress.isPractice,
                    thumbnailUrl: `${step.imageUrl}${
                        step.content.contentType === StepType.ARTICLE ? '?w=160&h=120' : '?w=152&h=112'
                    }`,
                };
            }
            return undefined;
        })
        .filter(notEmpty);

    const splits: CourseSplitWithCoordinates[] = courseWithStepsAndSplits.splits
        .map((split) => {
            const splitCoordinates = courseCoordinates.splitCoordinates.find(
                (splitCoordinates) => splitCoordinates.splitNumber === split.splitNumber,
            );
            if (splitCoordinates) {
                return {
                    ...split,
                    coordinates: splitCoordinates.coordinates,
                };
            }
            return undefined;
        })
        .filter(notEmpty);

    return { steps, splits };
};
