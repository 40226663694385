import { Component, inject } from '@angular/core';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { onboardingNavigateBack, onboardingQuestionSubmittedIsRescue } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-rescue',
    templateUrl: 'question-rescue.page.html',
    styleUrls: ['question-rescue.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionRescuePage {
    protected readonly store = inject(Store);

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public name = this.store.selectSignal(accountFeature.selectDogName);

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onIsRescueClick(isRescue: boolean) {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedIsRescue({ isRescuePup: isRescue }));
    }
}
