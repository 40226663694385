import { inject, Injectable } from '@angular/core';
import { CapacitorCalendar } from '@ebarooni/capacitor-calendar';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    private platform = inject(Platform);

    public async checkAllPermissions() {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await CapacitorCalendar.checkAllPermissions();
    }

    public async requestFullCalendarAccess() {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        return await CapacitorCalendar.requestFullCalendarAccess();
    }

    public async createEventWithPrompt(title: string, startDate: number, endDate: number, notes: string) {
        if (!this.platform.is('capacitor')) {
            throw new Error('method_not_available_on_web');
        }

        const options = {
            title,
            startDate,
            endDate,
            notes,
        };

        return await CapacitorCalendar.createEventWithPrompt(options);
    }
}
