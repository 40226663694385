import { selectIsUsUser } from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { selectOnboardingProgress } from '../../../data-access/onboarding.selectors';
import { specialNeedOptions, usSpecialNeedOptions } from '../../constants/question-special-needs.constants';

export const selectQuestionSpecialNeedsLegacyPageVm = createSelector(
    selectOnboardingProgress,
    selectIsUsUser,
    (progress, isUsUser) => ({
        progress,
        specialNeeds: isUsUser ? usSpecialNeedOptions : specialNeedOptions,
    }),
);
