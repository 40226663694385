import { AsyncPipe, DatePipe, NgClass, NgStyle } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { openSettingsAndroid, openSettingsIos, socialShare } from '@frontend/data-access/capacitor';
import { selectBreeds } from '@frontend/data-access/contentful';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { platformFeature } from '@frontend/data-access/platform';
import { openPrivacy, openTerms, openUrlWindow, routeToPrevious } from '@frontend/data-access/router';
import { accountFeature, Profile, selectCountryCode, updateAccount } from '@frontend/data-access/user/account';
import { authenticationFeature, logout } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Gender } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { FormGroupState, NgrxFormsModule } from 'ngrx-forms';
import { genderOptionValues } from '../../../constants/profile-form.constants';
import { EditProfileFormValues } from '../../../store/forms/edit-profile/edit-profile.form.model';
import { editProfileFeature } from '../../../store/forms/forms.reducer';
import { openInfoDialog } from '../../../store/info-dialog/info-dialog.actions';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from '../../../store/profile/account/account.constants';
import { openToast } from '../../../store/toast/toast.actions';
import { DeleteAccountComponent } from '../../delete-account/delete-account.component';
import { emailIsReadonlyDialogProps } from './profile-edit-dialog-props';
import { resetProfileEditForm } from './store/profile-edit.page.actions';

@Component({
    selector: 'app-profile-edit',
    templateUrl: 'profile-edit.page.html',
    styleUrl: './profile-edit.page.scss',
    standalone: true,
    imports: [
        AsyncPipe,
        DatePipe,
        NgClass,
        NgStyle,
        IonButton,
        IonCard,
        IonContent,
        IonFooter,
        IonHeader,
        IonInput,
        IonItem,
        IonLabel,
        IonList,
        IonSelect,
        IonSelectOption,
        IonToolbar,
        NgrxFormsModule,
        AnalyticsTrackClickDirective,
        HeaderComponent,
        InvalidInputErrorMessageComponent,
        ProfileImageComponent,
    ],
})
export class ProfileEditPage implements OnInit {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    public form = this.store.selectSignal(editProfileFeature.selectEditProfile);
    public platformIsIos = this.store.selectSignal(platformFeature.selectPlatformIsIos);
    public platformIsAndroid = this.store.selectSignal(platformFeature.selectPlatformIsAndroid);
    public platformIsCapacitor = this.store.selectSignal(platformFeature.selectPlatformIsCapacitor);
    public profile = this.store.selectSignal(accountFeature.selectProfile);
    public breeds = this.store.selectSignal(selectBreeds);
    public countryCode = this.store.selectSignal(selectCountryCode);
    public isInputFocusedOnAndroid = this.store.selectSignal(selectIsInputOpenOnAndroid);
    public signInProvider = this.store.selectSignal(authenticationFeature.selectSignInProvider);

    // TODO: NBSon - test this, might be better to select from state
    public currentDate = new Date();
    public subscriptionLink = '';
    public signInProviderLogoString = '';

    protected readonly ProfileImageType = ProfileImageType;
    protected readonly genderOptions = genderOptionValues;

    public ngOnInit(): void {
        this.sortSubscriptionLink();
        this.sortSignInProvider();
    }

    private sortSubscriptionLink(): void {
        if (this.platformIsIos()) {
            this.subscriptionLink = 'https://apps.apple.com/account/subscriptions';
        } else if (this.platformIsAndroid()) {
            this.subscriptionLink = 'https://play.google.com/store/account/subscriptions';
        }
    }

    private sortSignInProvider(): void {
        if (this.signInProvider() === 'apple.com') {
            this.signInProviderLogoString = 'apple';
        } else if (this.signInProvider() === 'google.com') {
            this.signInProviderLogoString = 'google';
        }
    }

    public back(profile: Profile | undefined): void {
        if (profile) {
            this.store.dispatch(resetProfileEditForm({ profile }));
        }

        this.store.dispatch(routeToPrevious({}));
    }

    public showIsReadonlyModal(): void {
        // TODO: NBSon - don't do this, dispatch many is no no no good, no good yess, no good
        void this.store.dispatch(trackEvent({ eventName: 'readonly email clicked, modal shown' }));
        void this.store.dispatch(openInfoDialog(emailIsReadonlyDialogProps));
    }

    public share(): void {
        void this.store.dispatch(
            socialShare({
                message:
                    'Puppyhood made easy! The #1 app 100% dedicated to puppyhood training. Join me on https://zigzag.dog',
            }),
        );
    }

    public saveProfile(form: FormGroupState<EditProfileFormValues>): void {
        if (form.isValid) {
            const breedIdChanged = form.controls.breedId?.isDirty;
            const genderChanged = form.controls.gender?.isDirty;
            const mailChanged = form.controls.mail?.isDirty;
            const ownerNameChanged = form.controls.ownerName?.isDirty;
            const dateOfBirthChanged = form.controls.dateOfBirth?.isDirty;
            const nameChanged = form.controls.name?.isDirty;
            const isApproximateDateOfBirth = dateOfBirthChanged ? false : undefined;

            if (
                breedIdChanged ||
                genderChanged ||
                mailChanged ||
                ownerNameChanged ||
                nameChanged ||
                dateOfBirthChanged
            ) {
                this.store.dispatch(
                    updateAccount({
                        command: {
                            ...(isApproximateDateOfBirth !== undefined && {
                                isApproximateDateOfBirth: isApproximateDateOfBirth,
                            }),
                            ...(breedIdChanged && { breedId: form.controls.breedId.value }),
                            ...(genderChanged && { gender: form.controls.gender.value as Gender }),
                            ...(mailChanged && { mail: form.controls.mail.value }),
                            ...(ownerNameChanged && { ownerName: form.controls.ownerName.value }),
                            ...(nameChanged && { name: form.controls.name.value }),
                            ...(dateOfBirthChanged && { dateOfBirth: form.controls.dateOfBirth.value }),
                        },
                        correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                    }),
                );
            }
            this.store.dispatch(routeToPrevious({}));
        } else {
            void this.store.dispatch(
                openToast({
                    message: 'Please fill in all fields correctly',
                    color: Color.Jack,
                }),
            );
        }
    }

    // TODO: NBSon - can we make this an effect?
    public openApplicationDetails(platform: { ios: boolean; android: boolean; capacitor: boolean }) {
        if (platform.android) {
            this.store.dispatch(openSettingsAndroid({ option: AndroidSettings.ApplicationDetails }));
        }

        if (platform.capacitor && platform.ios) {
            this.store.dispatch(openSettingsIos({ option: IOSSettings.App }));
        }
    }

    public openSubscriptions(link: string): void {
        this.store.dispatch(openUrlWindow({ url: link, openInNewWindow: true }));
    }

    public showTerms(countryCode: string): void {
        this.store.dispatch(openTerms({ countryCode }));
    }

    public showPrivacy(countryCode: string): void {
        this.store.dispatch(openPrivacy({ countryCode }));
    }

    public logout(): void {
        this.store.dispatch(logout());
    }

    public showDeleteAccount(): void {
        void this.modalService.showModal({
            component: DeleteAccountComponent,
            cssClass: ['modal', 'modal-info-notification'],
        });
    }
}
