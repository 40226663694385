import { isEmpty, notEmpty } from '@shared/utils/typescript';
import { StepProgressPayload } from '@frontend/data-access/user/progress';
import { LessonProgressType } from '@shared/user-domain';
import { Step, StepStatus } from '../../step/step.model';
import { CreateStepProgress } from './step-progress.model';

export const mapCreateStepProgressToStepProgressPayload = (
    progress: CreateStepProgress[],
    steps: Step[],
): StepProgressPayload[] => {
    if (isEmpty(progress) || isEmpty(steps)) {
        return [];
    }

    return progress
        .map((stepProgress) => mapSingleCreateStepProgressToStepProgressPayload(stepProgress, steps))
        .filter(notEmpty);
};

export const mapSingleCreateStepProgressToStepProgressPayload = (
    progress: CreateStepProgress,
    steps: Step[],
): StepProgressPayload | undefined => {
    const foundStep = steps.find((step) => step.id === progress.stepId);

    if (!foundStep) {
        return undefined;
    }

    switch (progress.progressType) {
        case LessonProgressType.COMPLETED: {
            return {
                stepId: progress.stepId,
                contentId: foundStep.content.id,
                progressType: progress.progressType,
                rating: progress.rating,
                isPractice: progress.isPractice ?? false,
            };
        }
        case LessonProgressType.SKIPPED:
        case LessonProgressType.READ: {
            return {
                stepId: progress.stepId,
                contentId: foundStep.content.id,
                progressType: progress.progressType,
                isPractice: false,
            };
        }
        default: {
            return undefined;
        }
    }
};

export const getInactiveOrReadStepIdsBeforeIndex = (steps: Step[], index: number): string[] => {
    return steps
        .slice(0, index)
        .filter((step) => step.progress.state === StepStatus.INACTIVE || step.progress.state === StepStatus.READ)
        .map((step) => step.id);
};
