import { Gender } from '@shared/user-domain';

export interface Partner {
    id: string;
    name: string;
    freeAccessLengthInDays: number;
}

export interface Profile {
    name: string;
    dateOfBirth: Date | undefined;
    breedId: string | undefined;
    gender: Gender | undefined;
    ownerName: string;
    mail: string;
    notifications: boolean;
    dateOfOnboarding: Date | undefined;
    privacyPolicyAccepted: boolean;
    dateOfArrival: Date | undefined;
    hasArrived: boolean | undefined;
    userToken: string | undefined;
    partners: Partner[];
    countryCode: string;
    firstDayAtHomeWhenOnboarding: boolean | undefined;
    isRescuePup: boolean | undefined;
    purinaMarketingAccepted: boolean | undefined;
    isApproximateDateOfBirth: boolean | undefined;
}
