import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, concatMap, map, of, take } from 'rxjs';
import { AccountService } from '../../services/account.service';
import {
    createEmptyUser,
    createEmptyUserFailure,
    createEmptyUserSuccess,
    getAccountSuccess,
} from '../get/get-account.actions';
import { setAnalyticsProfile } from '@frontend/data-access/analytics';
import { getConfigCatFeatureFlags } from '@frontend/data-access/user/config-cat';
import { APP_VERSION_NUMBER } from '@shared/constants';
import { selectCountryCode } from '../account.selectors';
import { Store } from '@ngrx/store';
import { initAdjust } from '@frontend/data-access/adjust';
import { initializeSubscriptionService } from '@frontend/data-access/subscription';

@Injectable()
export class EmptyUserEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly accountService = inject(AccountService);
    /**
     * This effect was created to ensure all users opening/landing on the app without a valid auth session still had an id.
     * That id is then used for services like ConfigCat, Mixpanel etc.
     * This allows for functionality like Mixpanel tracking from install --> onboard --> app.
     * It also ensures the users gets the same feature flags during auth/onboarding when the close and re-open the app
     * */
    createEmptyUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEmptyUser),
            concatMap(() =>
                this.accountService.createEmptyUser().pipe(
                    map((userDto) => createEmptyUserSuccess({ profileId: userDto.id })),
                    catchError((error: Error) => of(createEmptyUserFailure({ error }))),
                ),
            ),
        );
    });

    loginToThirdPartyServicesWithUserId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEmptyUserSuccess, getAccountSuccess),
            take(1),
            concatLatestFrom(() => [this.store.select(selectCountryCode)]),
            concatMap(([{ profileId }, countryCode]) => [
                setAnalyticsProfile({ id: profileId, profile: {} }),
                initAdjust(),
                initializeSubscriptionService({ id: profileId }),
                getConfigCatFeatureFlags({
                    userObject: {
                        identifier: profileId,
                        country: countryCode,
                        custom: { appVersionNumber: APP_VERSION_NUMBER },
                    },
                }),
            ]),
        );
    });
}
