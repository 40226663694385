@for (card of cards(); track card.title.text; let cardIndex = $index) {
    <div class="creating-plan-card-container">
        @if (cardSteps[cardIndex].showIconAndHeading) {
            <i class="creating-plan-card-container__location-icon fa-solid fa-location-dot"></i>

            <div class="creating-plan-card">
                <div class="creating-plan-card__heading">
                    <label class="creating-plan-card__title zz-label">{{ card.title.text }}</label>
                    @if (cardSteps[cardIndex].isLoading) {
                        <ion-spinner class="creating-plan-card__spinner" name="crescent" />
                    }
                </div>

                @if (card?.firstRow && cardSteps[cardIndex]?.showFirstRow) {
                    <div class="creating-plan-card__main">
                        <div class="creating-plan-card__row">
                            @for (item of card.firstRow!.items; track item.text; let itemIndex = $index) {
                                <div class="creating-plan-card__item">
                                    <i
                                        [class]="
                                            'creating-plan-card__icon fa-regular ' +
                                            card.firstRow!.items[itemIndex].icon
                                        "
                                    ></i>
                                    <p>{{ card.firstRow!.items[itemIndex].text }}</p>
                                </div>
                            }
                        </div>
                    </div>
                }

                @if (card?.secondRow && cardSteps[cardIndex]?.showSecondRow) {
                    <div class="creating-plan-card__main">
                        <div class="creating-plan-card__row">
                            @for (item of card.secondRow!.items; track item.text; let itemIndex = $index) {
                                <div class="creating-plan-card__item">
                                    <i
                                        [class]="
                                            'creating-plan-card__icon fa-regular ' +
                                            card.secondRow!.items[itemIndex].icon
                                        "
                                    ></i>
                                    <p>{{ card.secondRow!.items[itemIndex].text }}</p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}
