<div class="weight-selector">
    <ion-button
        color="max"
        class="l weight-button decrease"
        shape="round"
        data-test="weight-decrease-btn"
        (click)="change(-0.5)"
        >&minus;
    </ion-button>
    <div>
        <h1 class="current-weight xl" data-test="current-weight">{{ currentWeight }}</h1>
        <p class="s">Kilogram</p>
    </div>
    <ion-button
        color="max"
        class="l weight-button increase"
        shape="round"
        data-test="weight-increase-btn"
        (click)="change(0.5)"
        >+
    </ion-button>
</div>
