import { Course } from '@frontend/data-access/contentful';
import { PRE_PUP_COURSE_ID } from '@shared/constants';
import { CourseType } from '@shared/content-api-interface';
import { ContentFetchingConditions } from './content-effects.constants';

export const getCourseToSetInProgressAfterOnboardingForNonHomeRecommendedContentUsers = (
    courses: Course[],
    hasArrived: boolean,
): string => {
    const arrivalFoundationalCourse = courses.find((course) => course.type === CourseType.FOUNDATIONAL);

    if (hasArrived && arrivalFoundationalCourse) {
        return arrivalFoundationalCourse.id;
    }

    return PRE_PUP_COURSE_ID;
};

export const containsValueForAtLeastOneCondition = (
    profileFields: Partial<{ [key in keyof typeof ContentFetchingConditions]: unknown }>,
    conditions: ContentFetchingConditions[],
): boolean => {
    return conditions.some((condition) => {
        return profileFields[condition] !== undefined && profileFields[condition] !== null;
    });
};

export const containsValueForAllConditions = (
    profileFields: Partial<{ [key in keyof typeof ContentFetchingConditions]: unknown }>,
    conditions: ContentFetchingConditions[],
): boolean => {
    return conditions.every((condition) => {
        return profileFields[condition] !== null && profileFields[condition] !== undefined;
    });
};
