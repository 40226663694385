<ion-header class="page-header ion-no-border">
    <!-- TODO: NBSon - follow up with Marija about the back button for quitting onboarding -->
    <!-- TODO: NBSon - check the animation, as it seems to double up the progress bars on iOS -->
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackButtonClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content question-name-main">
        <section>
            <h1>What is the name of your pup?</h1>
            <p>Let's keep it personal - we'd love to call them by their first name</p>
        </section>

        <form class="onboarding-form onboarding-form--alvin" id="nameForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <ion-item class="onboarding-form__input-container">
                <i slot="start" class="fa-regular fa-dog onboarding-form__input-icon"></i>
                <ion-input
                    class="zz-input"
                    appTrackClick
                    identifier="question-name-dogname-input"
                    formControlName="name"
                    autocapitalize="words"
                    placeholder="e.g. Alvin"
                    type="text"
                    data-test="dogname-input"
                    [maxlength]="NAME_MAX_LENGTH"
                />
            </ion-item>
        </form>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-button-color-max"
            appTrackClick
            identifier="question-name-next-btn"
            expand="block"
            type="submit"
            form="nameForm"
            data-test="question-name-next-btn"
            [disabled]="isLoading() || form.invalid"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Next }
        </ion-button>
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--alvin"
            appTrackClick
            identifier="question-name-unknown-next-btn"
            expand="block"
            data-test="question-name-unknown-next-btn"
            [disabled]="isLoading()"
            (click)="onUnknownNameClick()"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { I don't know yet }
        </ion-button>
    </div>
</ion-footer>
