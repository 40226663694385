<ion-toolbar>
    @if (leftButton) {
        <ion-buttons slot="start">
            <ion-button
                class="app-header-button l"
                appTrackClick
                [style.--button-background]="leftButton.background"
                [appTrackClickEnabled]="leftButton.analyticsIdentifier !== ''"
                [identifier]="leftButton.analyticsIdentifier"
                [disabled]="isDisabled()"
                [attr.data-test]="leftButton.analyticsIdentifier"
                (click)="leftButtonAction()"
            >
                <app-icon sprite="regular" iconName="arrow-left" [color]="leftButton.iconColour" />
            </ion-button>
        </ion-buttons>
    }

    @if (progressRatio) {
        <div class="app-header-progress-bar center">
            <ion-progress-bar color="sophie" [value]="progressRatio" />
            <ion-title class="app-header-progress-bar__title">{{ title }}</ion-title>
        </div>
    } @else {
        <ion-title class="center">
            <h2 data-test="app-header-title" [style.color]="titleColour()">
                {{ title }}
            </h2>
        </ion-title>
    }

    @if (rightButton) {
        <ion-buttons class="app-header-end" slot="end">
            <ion-button
                class="app-header-button l"
                appTrackClick
                [style.--button-background]="rightButton.background"
                [appTrackClickEnabled]="rightButton.analyticsIdentifier !== ''"
                [identifier]="rightButton.analyticsIdentifier"
                [disabled]="isDisabled() || rightButton.isDisabled"
                [attr.data-test]="rightButton.analyticsIdentifier"
                (click)="rightButtonAction()"
            >
                <app-icon
                    *appElementRerender="rightButton.iconName"
                    [sprite]="rightButton.sprite"
                    [iconName]="rightButton.iconName"
                    [color]="rightButton.iconColour"
                />
            </ion-button>
        </ion-buttons>
    } @else if (rightImage) {
        <ion-buttons class="app-header-end" slot="end">
            <div class="app-header-end__button app-header-end__button--image">
                <img [src]="rightImage.content" alt="rightbutton-image" />
            </div>
        </ion-buttons>
    }
</ion-toolbar>
