import { Component, inject } from '@angular/core';
import { createUserAppAccessGranted } from '@frontend/data-access/user/user-app-access-granted';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalService } from '@frontend/utility/modal';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { UserAppAccessGrantedType } from '@shared/user-api-interface';
import { Color } from '@shared/utils/typescript';

@Component({
    standalone: true,
    selector: 'app-free-access-modal',
    templateUrl: './free-access-modal.component.html',
    styleUrls: ['./free-access-modal.component.scss'],
    imports: [AnalyticsTrackClickDirective, IonicModule], // TODO: NBSon - replace with standalone imports
})
export class FreeAccessModalComponent {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService); // TODO: NBSon - remove modal service and use effects

    protected readonly Color = Color;

    public getFreeAccess(numberOfDaysAccess: number): void {
        this.store.dispatch(
            createUserAppAccessGranted({
                postUserAppAccessGrantedCommand: {
                    accessTypeId: UserAppAccessGrantedType.REVERSE_PAYWALL,
                    accessGrantedTimestamp: new Date().getTime(),
                    expiryTimeStamp: new Date(
                        new Date().getTime() + numberOfDaysAccess * 24 * 60 * 60 * 1000,
                    ).getTime(),
                    openedAppAfterExpiry: false,
                    numberOfDaysAccessGranted: 3,
                },
            }),
        );

        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
