import { selectIsRescuePupValuePropAb } from '@frontend/data-access/user/config-cat';
import { Gender } from '@shared/user-domain';
import { createSelector } from '@ngrx/store';
import {
    accountFeature,
    selectAgeInWeeks,
    selectBreedName,
    selectHasArrived,
    selectIsUsUser,
    selectPartner,
} from '@frontend/data-access/user/account';
import { createCoursePreviewContent, createNextCoursesContent } from './course-preview.utils';

export const selectCoursePreviewAbTestContent = createSelector(
    selectIsRescuePupValuePropAb,
    accountFeature.selectIsRescuePup,
    (isRescuePupValuePropAb, isRescue) => {
        return {
            isRescuePupValuePropAb,
            isRescue,
        };
    },
);

export const selectCoursePreviewPageVm = createSelector(
    selectPartner,
    accountFeature.selectGender,
    selectBreedName,
    selectAgeInWeeks,
    selectHasArrived,
    selectIsUsUser,
    accountFeature.selectDateOfBirth,
    selectCoursePreviewAbTestContent,
    (
        partner,
        gender,
        breedName,
        ageInWeeks,
        hasArrived,
        isUSUser,
        dateOfBirth,
        { isRescuePupValuePropAb, isRescue },
    ) => {
        return {
            partner,
            hasPartner: !!partner,
            hasArrived,
            isUSUser,
            profileInfo: {
                gender: gender === Gender.MALE ? 'Boy' : 'Girl',
                breedName,
                dateOfBirth,
            },
            courseInfo: createCoursePreviewContent(
                hasArrived,
                ageInWeeks,
                breedName,
                isUSUser,
                isRescuePupValuePropAb,
                isRescue,
            ),
            nextCourses: createNextCoursesContent(hasArrived),
            ageInWeeks,
        };
    },
);
