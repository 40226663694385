import { createAction, props } from '@ngrx/store';
import { StepProgress, StepProgressPayload } from '../models/step-progress.model';

export const getStepProgress = createAction('[Step Progress] Get Step Progress');

export const getStepProgressSuccess = createAction(
    '[Step Progress] Get Step Progress Success',
    props<{
        progress: StepProgress[];
    }>(),
);
export const getStepProgressFailure = createAction(
    '[Step Progress] Get Step Progress Failure',
    props<{
        error: Error;
    }>(),
);

export const createStepProgress = createAction(
    '[Step Progress] Create Step Progress',
    props<{
        progress: StepProgressPayload[];
    }>(),
);
export const createStepProgressSuccess = createAction(
    '[Step Progress] Create Step Progress Success',
    props<{
        progress: StepProgress[];
    }>(),
);
export const createStepProgressFailure = createAction(
    '[Step Progress] Create Step Progress Failure',
    props<{
        error: Error;
    }>(),
);
