import { inject, Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, UrlTree } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '@frontend/data-access/user/authentication';
import { accountFeature } from '@frontend/data-access/user/account';

@Injectable({
    providedIn: 'root',
})
export class LoggedInGuard {
    private readonly authenticationService = inject(AuthenticationService);
    private readonly router = inject(Router);
    private readonly store = inject(Store);

    canActivate(): Observable<boolean | UrlTree> {
        return this.authenticationService.isLoggedIn().pipe(
            take(1),
            concatLatestFrom(() => [this.store.select(accountFeature.selectAccountFailure)]),
            map(([isLoggedIn, accountFailure]) => {
                if (!isLoggedIn) return true;

                return accountFailure ? true : this.router.createUrlTree(['main', 'today']);
            }),
        );
    }
}
