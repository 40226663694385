import { CardItemModel } from '@frontend/ui';
import { createSelector } from '@ngrx/store';
import { selectSeenDailyBites } from '../../../store/daily-bites/daily-bites.selectors';

export const selectLibraryDailyBiteTileVm = createSelector(
    selectSeenDailyBites,
    (seenBites): { biteInfo: CardItemModel } => {
        return {
            biteInfo: {
                imageUrl: 'assets/icon/book.svg',
                title: 'Daily Bites',
                subtitle: `${seenBites.length} daily bite${seenBites.length === 1 ? '' : 's'}`,
                hideRightArrow: true,
            },
        };
    },
);
