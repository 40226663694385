import { AsyncPipe, DatePipe, NgClass, NgStyle } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import {
    AgePipe,
    InvalidInputErrorMessageComponent,
    RadioWithLogicComponent,
    RadioWithLogicItem,
    RadioWithLogicResult,
} from '@frontend/ui';
import { IonButton, IonDatetime, IonDatetimeButton, IonModal, IonSpinner } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import {
    AGE_RADIO_OPTIONS,
    OVER_TWO_YEARS_RADIO_OPTION,
    ZERO_TO_FOUR_MONTHS_RADIO_OPTION,
} from '../../../constants/question-age.constants';
import { onboardingQuestionSubmittedDateOfBirth } from '../../../data-access/onboarding.actions';
import { getInitialDateOfBirth, getMaxValue, getMinValue } from '../../../onboarding-utils/question-age.utils';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { selectQuestionAgeLegacyPageVm } from './question-age-legacy.page.selectors';

@Component({
    selector: 'app-onboarding-legacy-question-age',
    templateUrl: 'question-age-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss', 'question-age-legacy.page.scss'],
    standalone: true,
    imports: [
        AgePipe,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        DatePipe,
        InvalidInputErrorMessageComponent,
        IonButton,
        IonDatetime,
        IonDatetimeButton,
        IonModal,
        NgClass,
        NgStyle,
        OnboardingLegacyQuestionTemplateComponent,
        RadioWithLogicComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingLegacyQuestionAgeLegacyPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectQuestionAgeLegacyPageVm);

    public form = new FormGroup({
        dateOfBirth: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
        isApproximateDateOfBirth: new FormControl(false, { nonNullable: true }),
    });
    public radioResult: RadioWithLogicResult | undefined;

    protected readonly Color = Color;
    protected readonly AGE_RADIO_OPTIONS: RadioWithLogicItem[] = AGE_RADIO_OPTIONS;
    protected readonly OVER_TWO_YEARS_RADIO_OPTION = OVER_TWO_YEARS_RADIO_OPTION;
    protected readonly ZERO_TO_FOUR_MONTHS_RADIO_OPTION = ZERO_TO_FOUR_MONTHS_RADIO_OPTION;
    protected readonly getMinValue = getMinValue;
    protected readonly getMaxValue = getMaxValue;

    public setAnswer(radioResult: RadioWithLogicResult): void {
        if (this.vm().isLoading) {
            return;
        }

        this.radioResult = radioResult;

        if (!this.radioResult.complete) {
            this.form.reset();
        } else {
            this.form.controls.dateOfBirth.setValue(getInitialDateOfBirth(this.radioResult));
            this.form.controls.isApproximateDateOfBirth.setValue(
                radioResult.complete && radioResult.selected !== ZERO_TO_FOUR_MONTHS_RADIO_OPTION,
            );
            this.form.markAsDirty();
        }
    }

    public onSubmit(): void {
        if (this.form.invalid || this.vm().isLoading) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedDateOfBirth({
                dateOfBirth: new Date(this.form.controls.dateOfBirth.value).toISOString(),
                isApproximateDateOfBirth: this.form.controls.isApproximateDateOfBirth.value,
            }),
        );
    }
}
