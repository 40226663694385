import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { HeaderComponent } from '@frontend/feature/header';

@Component({
    selector: 'app-onboarding-legacy-question-template',
    templateUrl: './onboarding-legacy-question-template.component.html',
    styleUrls: ['../onboarding-legacy.scss', 'onboarding-legacy-question-template.component.scss'],
    standalone: true,
    imports: [IonContent, IonFooter, HeaderComponent, IonHeader, IonToolbar],
})
export class OnboardingLegacyQuestionTemplateComponent {
    @Input()
    public title: string | undefined;
    @Input()
    public subtitle: string | undefined;
    @Input()
    public progress = 0;
    @Input()
    public backButtonId = 'onboarding-back-btn';

    public isLoading = input(false);

    @Output() public backButtonPressed = new EventEmitter();

    public backButtonAction(): void {
        this.backButtonPressed.emit();
    }
}
