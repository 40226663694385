import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../../data-access/onboarding.selectors';
import { accountFeature } from '@frontend/data-access/user/account';

export const selectQuestionAgeLegacyPageVm = createSelector(
    selectOnboardingProgress,
    accountFeature.selectDogName,
    onboardingFeature.selectIsLoading,
    (progress, dogName, isLoading) => ({
        progress,
        dogName,
        currentDate: new Date(),
        isLoading,
    }),
);
