import { createAction, props } from '@ngrx/store';
import { CourseEndProgressReason } from '../../courses/end-course-modal/end-course.model';

export const triggerAddNewCourse = createAction('[Course] Trigger Add New Course');

export const setCourseProgressEnd = createAction(
    '[Course] Set Course Progress End',
    props<{
        courseEnd: CourseEndProgressReason;
    }>(),
);
