export const foodCalculatorExpectedWeightInfoProps = {
    dialogProps: {
        title: 'Expected adult weight',
        content:
            'Please enter the expected weight of [NAME] when [HE/SHE] reaches adulthood. Ask our training coaches if you don’t know it yourself.',
        buttonText: 'Got it',
    },
    supportProps: {
        buttonText: 'Ask for help',
        supportRequestText: 'I’m not sure about the expected weight for [NAME].',
    },
};
