<div class="ai-chat-header">
    <section class="ai-chat-header__top-container">
        <ion-buttons class="ai-chat-header__button-container">
            <ion-button
                class="ai-chat-header__back-button"
                appTrackClick
                identifier="ai-chat-back-button"
                data-test="ai-chat-header-back-button"
                fill="clear"
                shape="round"
                (click)="onBackClicked()"
            >
                <ion-icon color="dark" name="arrow-back-outline" />
            </ion-button>
        </ion-buttons>
        <section class="ai-chat-header__title-container">
            <img
                class="ai-chat-header__ziggy"
                src="assets/images/ziggy/ziggy-sitting.svg"
                alt="Ziggy sitting patiently"
            />
            <h2>Ziggy AI</h2>
        </section>
    </section>
    <p class="ai-chat-header__zigzag-approved s"><i class="fa-solid fa-badge-check"> </i> Zigzag approved</p>
</div>
