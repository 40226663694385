import { Component, Input } from '@angular/core';
import { DailyBite } from '@frontend/data-access/contentful';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/angular/standalone';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-daily-bite-card',
    templateUrl: './daily-bite-card.component.html',
    styleUrls: ['./daily-bite-card.component.scss'],
    standalone: true,
    imports: [IonCard, IonCardHeader, IonCardTitle, IonCardContent, TextReplacementPipe],
})
export class DailyBiteCardComponent {
    @Input({ required: true }) public content!: DailyBite;
}
