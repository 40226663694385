<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackButtonClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="breed-page page-content ion-justify-content-start">
        <section>
            <h1>What breed is {{ dogName() }}?</h1>
            <p class="onboarding-text">We tailor lessons based on your pup’s breed</p>
        </section>

        <form id="breedForm" [formGroup]="breedForm" (ngSubmit)="onSubmit()">
            <app-breed-selector
                class="breed-selector-margin-top"
                formControlName="breedId"
                data-test="onboarding-breed-selector"
                [breedOptions]="breeds()"
                [isDisabled]="isLoading()"
                [isNewOnboarding]="true"
            />
        </form>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="question-breed-next-btn"
        expand="block"
        type="submit"
        form="breedForm"
        data-test="question-breed-next-btn"
        [disabled]="breedForm.invalid || isLoading()"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { Next }
    </ion-button>
</ion-footer>
