import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { accountFeature } from './store/account.reducer';
import { EmptyUserEffects } from './store/create/empty-user.effects';
import { DeleteAccountEffects } from './store/delete/delete-account.effects';
import { GetAccountEffects } from './store/get/get-account.effects';
import { UpdateAccountEffects } from './store/update/update-account.effects';
import { CreateAccountEffects } from './store/create/create-account.effects';

@NgModule({
    providers: [
        provideState(accountFeature),
        provideEffects([
            DeleteAccountEffects,
            EmptyUserEffects,
            CreateAccountEffects,
            GetAccountEffects,
            UpdateAccountEffects,
        ]),
    ],
})
export class ZigzagFrontendDataAccessUserAccountModule {}
