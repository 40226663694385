import { AsyncPipe } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { MultiSelectComponent, MultiSelectOption } from '@frontend/ui';
import { IonButton } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { onboardingQuestionSubmittedNeeds } from '../../../data-access/onboarding.actions';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { selectQuestionSpecialNeedsLegacyPageVm } from './question-special-needs-legacy.page.selectors';

@Component({
    selector: 'app-onboarding-legacy-question-special-needs',
    templateUrl: './question-special-needs-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss', 'question-special-needs-legacy.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        MultiSelectComponent,
        OnboardingLegacyQuestionTemplateComponent,
    ],
})
export class OnboardingQuestionSpecialNeedsLegacyPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectQuestionSpecialNeedsLegacyPageVm);

    public options = signal(this.vm().specialNeeds);

    public needs = computed(() => {
        const needs: Record<string, boolean> = {};

        this.options().forEach((need) => {
            needs[need.value] = need.selected;
        });

        return needs;
    });
    public nextButtonText = computed(() => {
        const noTilesSelected = !this.options().some((option) => option.selected);

        return noTilesSelected ? 'Skip' : 'Next';
    });

    protected readonly Color = Color;

    public onValueChanged(options: MultiSelectOption<string>[]): void {
        this.options.set([...options]);
    }

    public onClickNext(): void {
        this.store.dispatch(onboardingQuestionSubmittedNeeds({ needs: this.needs() }));
    }
}
