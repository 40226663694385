import { inject, Injectable } from '@angular/core';
import {
    ChatApiService,
    chatFeature,
    createChannel,
    createChannelSuccess,
    getChatToken,
    getChatTokenSuccess,
    initChatUser,
    initChatUserSuccess,
    watchChannel,
    watchChannelSuccess,
} from '@frontend/data-access/chat';
import { createAccountSuccess, getAccountSuccess } from '@frontend/data-access/user/account';
import { ChatChannelType } from '@shared/constants';
import { generateUUID } from '@ionic/cli/lib/utils/uuid';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, mergeMap, of } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { chatPageLoaded, userLeftMessageFeedback, userRatedMessage, userRatedMessageFailure } from './chat.actions';
import { selectChannelParams, selectInitChatParams } from './chat.selectors';

@Injectable()
export class ChatEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly chatApiService = inject(ChatApiService);

    getChatTokenOncePageLoadsOnAccountCreate$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(chatPageLoaded)),
            this.actions$.pipe(ofType(createAccountSuccess), take(1)),
        ]).pipe(map(([{ chatType }]) => getChatToken({ chatType })));
    });

    getChatTokenOncePageLoadsOnAccountGet$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(chatPageLoaded)),
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
        ]).pipe(map(([{ chatType }]) => getChatToken({ chatType })));
    });

    connectUserWithToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChatTokenSuccess),
            concatLatestFrom(() => this.store.select(selectInitChatParams)),
            filter(([, { profileId }]) => {
                return !!profileId;
            }),
            map(([{ token, chatType }, { profileId, ownerName }]) => {
                return initChatUser({ token, profileId: profileId!, ownerName, chatType });
            }),
        );
    });

    createOrWatchChannelAfterInitChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(initChatUserSuccess),
            concatLatestFrom(() => this.store.select(selectChannelParams)),
            filter(([{ chatType, channels }, { agentChannelParams }]) => {
                return chatType === ChatChannelType.AGENT && channels.length === 0 ? !!agentChannelParams.id : true;
            }),
            map(([{ chatType, channels }, { agentChannelParams, aiChannelParams }]) => {
                const params =
                    chatType === ChatChannelType.AGENT
                        ? { ...agentChannelParams, id: agentChannelParams.id! }
                        : { ...aiChannelParams, id: generateUUID() };
                return channels.length === 0
                    ? createChannel({ params, chatType })
                    : watchChannel({ channelId: channels[0].id, chatType });
            }),
        );
    });

    watchChannelWhenCreateChannelSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createChannelSuccess),
            map(({ channelId, chatType }) => {
                return watchChannel({ channelId, chatType });
            }),
        );
    });

    trackUserOpenedChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(watchChannelSuccess),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            filter(([, activeChannel]) => !!activeChannel),
            map(([, activeChannel]) => {
                return trackEvent({
                    eventName: '[Chat] User Opened Chat',
                    eventProperties: {
                        channelId: activeChannel!.id,
                        channelType: activeChannel?.type,
                    },
                });
            }),
        );
    });

    userRatedMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userRatedMessage),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            filter(([, activeChannel]) => !!activeChannel),
            mergeMap(([{ rating, streamChatMessageId, streamChatUserId }, activeChannel]) => {
                return this.chatApiService
                    .updateMessage(streamChatMessageId, streamChatUserId, { set: { zigzagRating: rating } })
                    .pipe(
                        mergeMap(() => [
                            trackEvent({
                                eventName: '[Chat] User Rated Message',
                                eventProperties: {
                                    rating,
                                    streamChatMessageId,
                                    channelId: activeChannel!.id,
                                    channelType: activeChannel?.type,
                                },
                            }),
                        ]),
                        catchError((error) => of(userRatedMessageFailure({ error }))),
                    );
            }),
        );
    });

    userLeftMessageFeedback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userLeftMessageFeedback),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            filter(([, activeChannel]) => !!activeChannel),
            map(([{ reason, comment, streamChatMessageId }, activeChannel]) =>
                trackEvent({
                    eventName: '[Chat] User Left Message Feedback',
                    eventProperties: {
                        reason,
                        comment,
                        streamChatMessageId,
                        channelId: activeChannel!.id,
                        channelType: activeChannel?.type,
                    },
                }),
            ),
        );
    });
}
