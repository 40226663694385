import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { Entitlement } from './models/subscription.model';
import {
    getEntitlements,
    getEntitlementsSuccess,
    getOfferingsSuccess,
    logInToPurchasesSuccess,
    purchaseProductCancelled,
    purchaseProductSuccess,
} from './subscription.actions';

@Injectable()
export class SubscriptionAnalyticsEffects {
    private readonly actions$ = inject(Actions);

    purchaseProductCancelled$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(purchaseProductCancelled),
            map(() => {
                return trackEvent({
                    eventName: '[Purchases] Purchase Cancelled',
                });
            }),
        );
    });

    // Move when trackEvent is in separate module to subscription module
    trackGetOfferingsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getOfferingsSuccess),
            map(({ data: { value: offerings } }) => {
                return trackEvent({
                    eventName: '[RevenueCat] Get Offerings Success',
                    eventProperties: {
                        offerings,
                    },
                });
            }),
        );
    });

    // Move when trackEvent is in seperate module to subscription module
    trackGetEntitlementsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEntitlementsSuccess),
            map(({ data: { value } }) => {
                return trackEvent({
                    eventName: 'get-entitlements-success',
                    eventProperties: {
                        entitlementIds: value.map((entitlement: Entitlement) => entitlement.identifier),
                    },
                });
            }),
        );
    });

    trackLoginToPurchasesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logInToPurchasesSuccess),
            map(({ data }) => {
                return trackEvent({
                    eventName: '[RevenueCat] Purchases Login',
                    eventProperties: {
                        purchaserInfo: data.value,
                    },
                });
            }),
        );
    });

    trackPurchaseProductSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(purchaseProductSuccess),
            switchMap(({ data }) => [
                trackEvent({
                    eventName: 'Product bought',
                    eventProperties: { productKey: data.identifier, price: data.priceWithUnit },
                }),
                getEntitlements(), // added to explicitly refresh entitlements after purchases success (when using mock)
            ]),
        );
    });
}
