import { Routes } from '@angular/router';
import { TabsPage } from './tabs.page';

export const tabRoutes: Routes = [
    {
        path: '',
        component: TabsPage,
        children: [
            {
                path: '',
                redirectTo: 'today',
                pathMatch: 'full',
            },
            {
                path: 'today',
                loadChildren: () => import('../today/today.routes'),
            },
            {
                path: 'library',
                loadChildren: () => import('../library/library.routes'),
            },
            {
                path: 'courses',
                loadChildren: () => import('../courses/course.routes'),
            },
            {
                path: 'food-calculator',
                loadChildren: () => import('../food-calculator/feature-food-calculator/food-calculator.routes'),
            },
            {
                path: 'support',
                loadChildren: () => import('../support/support.routes'),
            },
        ],
    },
];
