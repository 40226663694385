import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { SubscriptionInternalApiService } from './services/subscription-internal-api.service';
import { SubscriptionEffects } from './store/subscription.effects';
import { subscriptionFeature } from './store/subscription.reducer';
import { subscriptionServiceProvider } from './subscription-service.provider';
import { SubscriptionAnalyticsEffects } from './store/subscription-analytics.effects';

@NgModule({
    providers: [
        provideState(subscriptionFeature),
        provideEffects([SubscriptionEffects, SubscriptionAnalyticsEffects]),
        subscriptionServiceProvider,
        SubscriptionInternalApiService,
    ],
})
export class ZigzagFrontendDataAccessSubscriptionModule {}
