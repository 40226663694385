import { createFeature, createReducer, on } from '@ngrx/store';
import { getLocalStorageSuccess, removeAllLocalStorageSuccess } from './local-storage.actions';

export interface LocalStorageKeysRestoredState {
    restoredKeys: string[];
}

export const initialState: LocalStorageKeysRestoredState = {
    restoredKeys: [],
};

export const localStorageKeysRestoredFeature = createFeature({
    name: 'local-storage-keys-restored',
    reducer: createReducer(
        initialState,
        on(
            removeAllLocalStorageSuccess,
            (state): LocalStorageKeysRestoredState => ({
                ...state,
                restoredKeys: [],
            }),
        ),
        on(getLocalStorageSuccess, (state, { key }): LocalStorageKeysRestoredState => {
            const index = state.restoredKeys.indexOf(key);

            if (index !== -1) {
                return state;
            }

            return {
                ...state,
                restoredKeys: [...state.restoredKeys, key],
            };
        }),
    ),
});
