import { createFeature, createReducer, on } from '@ngrx/store';
import { changeAppReadyStatus } from './app-component.actions';
import { AppReadyStatus } from './app-ready-status.model';

export interface AppComponentState {
    appStatus: AppReadyStatus;
}

export const initialState: AppComponentState = {
    appStatus: AppReadyStatus.initial,
};

export const appStatusFeature = createFeature({
    name: 'appStatus',
    reducer: createReducer(
        initialState,
        on(
            changeAppReadyStatus,
            (state, payload): AppComponentState => ({
                ...state,
                appStatus: payload.status,
            }),
        ),
    ),
});
