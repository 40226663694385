import { createFeature, createReducer, on } from '@ngrx/store';
import { LoadingState } from '@frontend/data-access/shared-models';
import { Household } from '../models/household.model';
import {
    createHouseholdFailure,
    createHouseholdSuccess,
    getHouseholdFailure,
    getHouseholdSuccess,
} from './household.actions';

export interface HouseholdState {
    household: Household | undefined;
    loadingState: LoadingState;
}

export const initialState: HouseholdState = {
    household: undefined,
    loadingState: LoadingState.INIT,
};

export const householdFeature = createFeature({
    name: 'household',
    reducer: createReducer(
        initialState,
        on(
            getHouseholdSuccess,
            createHouseholdSuccess,
            (state, action): HouseholdState => ({
                ...state,
                household: action.household,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            getHouseholdFailure,
            createHouseholdFailure,
            (state): HouseholdState => ({
                ...state,
                household: undefined,
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
});
