import { inject, Injectable } from '@angular/core';
import { initMobileAppSentry } from '@frontend/data-access/sentry';
import {
    addCapacitorAppListeners,
    addCapacitorPushNotificationListeners,
    syncLegacyLocalStorageData,
} from '@frontend/data-access/capacitor';
import { initializeAuthenticationService } from '@frontend/data-access/user/authentication';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { changeAppReadyStatus } from '../app-component/app-component.actions';
import { AppReadyStatus } from './app-ready-status.model';
import { Platform } from '@ionic/angular/standalone';
import { platformReady, setPlatforms } from '@frontend/data-access/platform';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class AppComponentEffects {
    private readonly actions$ = inject(Actions);
    private readonly platform = inject(Platform);
    private minimumSplashScreenTime = 3000 as const;
    private badgePlayTime = 2500 as const;
    dispatchStartupActionsWhenPlatformReady$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(platformReady),
            concatMap(() => [
                initMobileAppSentry(),
                initializeAuthenticationService(),
                setPlatforms(),
                syncLegacyLocalStorageData(),
                addCapacitorAppListeners(),
                addCapacitorPushNotificationListeners(),
                trackEvent({ eventName: '[App] Initialised' }),
            ]),
        );
    });

    setDeviceStatusBarStyles$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(platformReady),
                filter(() => {
                    return this.platform.is('capacitor');
                }),
                tap(() => {
                    void StatusBar.setStyle({ style: Style.Light });
                    if (this.platform.is('android')) {
                        // Android only settings
                        void StatusBar.setOverlaysWebView({ overlay: false });
                        void StatusBar.setBackgroundColor({ color: '#fcfbfa' });
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    dispatchSplashScreenStartedEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(platformReady),
            map(() => trackEvent({ eventName: '[Splash Screen] Started' })),
        );
    });

    delayWhileSplashScreenPlays$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(platformReady),
            delay(this.minimumSplashScreenTime + this.badgePlayTime),
            concatMap(() => [
                changeAppReadyStatus({ status: AppReadyStatus.ready }),
                trackEvent({ eventName: '[Splash Screen] Ended' }),
            ]),
        );
    });
}
