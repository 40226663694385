<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="FAQ"
            background="var(--ion-color-app-background)"
            [rightButton]="false"
            [leftButton]="{ analyticsIdentifier: 'faq-back-to-support-btn', background: 'none' }"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

@if (vm$| async; as faqs) {
<ion-content class="page-ion-content">
    <div class="faq-page">
        <div class="faq-page__image-container image-box lola">
            <img class="faq-page__image image-self" src="assets/images/oliver/oliver-looks-confused.png" />
        </div>

        <div class="text-block">
            <h3 class="l">How can we help you?</h3>
        </div>

        @for (faq of faqs; track faq.id) {
        <app-accordion
            [title]="faq.title | textReplacement"
            [content]="faq.content | richContent | textReplacement"
            [analytics]="{ identifier: '[FAQ] Opened' , properties: { faqTitle: faq.mixpanelId } }"
        />
        }
    </div>
</ion-content>
}
