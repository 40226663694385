import { Component, inject } from '@angular/core';
import { PurchaseProduct, purchaseProduct } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonChip, IonContent, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { closeModal } from '../../../store/modal/modal.actions';
import { PaymentModalPlanFeaturesListComponent } from '../../payment-modal-plan-features-list/payment-modal-plan-features-list.component';
import { PaymentModalProductComponent } from '../../payment-modal-product/payment-modal-product.component';
import { PaymentModalTermsComponent } from '../../payment-modal-terms/payment-modal-terms.component';
import { selectPaymentModalVm } from '../../payment-modal.selectors';
import { TieredPricingComparisonToggleComponent } from '../tiered-pricing-comparison-toggle/tiered-pricing-comparison-toggle.component';
import { TieredPricingPlanComparisonComponent } from '../tiered-pricing-plan-comparison/tiered-pricing-plan-comparison.component';

@Component({
    standalone: true,
    selector: 'app-tiered-pricing-comparison-modal',
    templateUrl: './tiered-pricing-comparison-modal.component.html',
    styleUrl: './tiered-pricing-comparison-modal.component.scss',
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        PaymentModalProductComponent,
        PaymentModalTermsComponent,
        PaymentModalPlanFeaturesListComponent,
        IonChip,
        TieredPricingComparisonToggleComponent,
        TextReplacementPipe,
        TieredPricingPlanComparisonComponent,
        IonContent,
        TieredPricingComparisonToggleComponent,
    ],
})
export class TieredPricingComparisonModalComponent {
    private readonly store = inject(Store);
    protected readonly Color = Color;

    public vm = this.store.selectSignal(selectPaymentModalVm);

    public onPurchaseClick(product: PurchaseProduct): void {
        this.store.dispatch(purchaseProduct({ data: product }));
    }

    closeModalClicked(): void {
        this.store.dispatch(closeModal());
    }
}
