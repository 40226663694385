import { Component } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
    selector: 'app-logout-page',
    templateUrl: './logout.page.html',
    styleUrls: ['./logout.page.scss'],
    standalone: true,
    imports: [IonSpinner],
})
export class LogoutPage {}
