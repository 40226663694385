import { Injectable } from '@angular/core';
import {
    CustomerInfo,
    PACKAGE_TYPE,
    PurchasesOffering,
    PurchasesOfferings,
    PurchasesStoreProductDiscount,
} from '@awesome-cordova-plugins/purchases/ngx';
import { EMPTY, Observable, of } from 'rxjs';
import { SubscriptionOffering } from './store/models/subscription.model';
import { SubscriptionService } from './subscription.service';
import { mapPurchasesOfferingToBaseSubscriptionOffering } from './subscription.utils';

export const createMockPackage = (
    identifier: string,
    price: number,
    period: string,
    packageType: PACKAGE_TYPE,
    freeDays: number,
    discounts?: Partial<PurchasesStoreProductDiscount>[],
) => {
    return {
        identifier,
        packageType,
        product: {
            currencySymbol: '£',
            identifier: `mock-product-${identifier}`,
            title: period,
            price,
            currencyCode: 'GBP',
            description: `First ${freeDays} days free `,
            priceString: `£${price}`,
            introPrice: {
                periodNumberOfUnits: freeDays,
                periodUnit: 'DAY',
            },
            discounts,
        },
    };
};

export const createMockOffering = (identifier: string, lowPrice: number, highPrice: number) => {
    return {
        identifier,
        availablePackages: [
            createMockPackage('Annual', highPrice, 'Pup Training: Annual Plan', PACKAGE_TYPE.ANNUAL, 7, [
                {
                    identifier: 'promo_trial_expiry_discount',
                    price: 14.99,
                    priceString: '£14.99',
                },
            ]),
            createMockPackage('Three Month', lowPrice, 'Pup Training: Quarterly Plan', PACKAGE_TYPE.THREE_MONTH, 3, []),
        ],
    } as unknown as PurchasesOffering;
};

@Injectable()
export class SubscriptionServiceMock extends SubscriptionService {
    private mockOffering: PurchasesOfferings = {
        all: {
            ['Subscription-Q20-Y40']: createMockOffering('Subscription-Q20-Y40', 14.99, 29.99),
            ['Subscription-Q30-Y50']: createMockOffering('Subscription-Q30-Y50', 29.99, 49.99),
        },
        current: createMockOffering('Subscription-Q20-Y40', 19.99, 29.99),
    };

    private contentUnlocked = false;

    private getEntitlementsState = () => {
        return {
            entitlements: {
                all: {
                    content: {
                        identifier: 'content',
                        isActive: this.contentUnlocked,
                    },
                },
            },
            allPurchasedProductIdentifiers: [],
        } as unknown as CustomerInfo;
    };

    initialize(): void {
        console.log('[Mock Subscription Service] Initialize');
    }

    getEntitlements(): Observable<CustomerInfo> {
        return of(this.getEntitlementsState());
    }

    logInToPurchases(
        id: string,
        email: string,
    ): Observable<{
        customerInfo: CustomerInfo;
    }> {
        console.log('[Mock Subscription Service] Logging In To Purchases', id, email);

        if (email === 'revenue-cat-user@e2e.zigzag.dog') {
            this.contentUnlocked = true;
        }

        return of({
            customerInfo: this.getEntitlementsState(),
        });
    }

    getOfferings(): Observable<SubscriptionOffering[]> {
        console.log('[Mock Subscription Service] Get Offerings');

        return of([
            {
                ...mapPurchasesOfferingToBaseSubscriptionOffering(this.mockOffering.current as PurchasesOffering),
                isDefault: true,
            },
        ]);
    }

    purchaseProduct(id: string): Observable<{
        productIdentifier: string;
        customerInfo: CustomerInfo;
    }> {
        console.log('[Mock Subscription Service] Purchase Product', { id });

        if (id === 'mock-product-Annual' || id === 'mock-product-Three Month') {
            this.contentUnlocked = true;
            return of({ productIdentifier: id, customerInfo: this.getEntitlementsState() });
        }

        return EMPTY;
    }

    collectDeviceIdentifiers(adjustId: string): void {
        console.log('[Mock Subscription Service] Collect Device Identifiers - Adjust ID', adjustId);
    }

    restoreTransactions(): void {
        console.log('[Mock Subscription Service] Restore Transactions');

        this.contentUnlocked = false;
    }

    syncPurchases() {
        console.log('[Mock Subscription Service] Sync Purchases');
    }

    presentPromoCodePrompt(): void {
        console.log('[Mock Subscription Service] Present Promo Code Prompt');
    }
}
