import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { RescheduledStepService } from './services/rescheduled-step.service';
import { RescheduledStepEffects } from './store/rescheduled-step.effects';
import { rescheduledStepFeature } from './store/rescheduled-step.reducer';

@NgModule({
    providers: [
        provideState(rescheduledStepFeature),
        provideEffects([RescheduledStepEffects]),
        {
            provide: RescheduledStepService,
        },
    ],
})
export class ZigzagFrontendDataAccessUserRescheduledStepModule {}
