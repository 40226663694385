<ion-content class="landing-page-ion-content ion-text-center" [fullscreen]="true">
    <main class="landing-page-ion-content__content">
        <header class="landing-page-ion-content__header-section">
            <img src="assets/images/zigzag/zigzag-logo-white.png" class="logo" alt="The Zigzag logo" />
        </header>
        <section class="landing-page-ion-content__title-section">
            <h1 class="landing-page-ion-content__title">Welcome! We'd give you a cuddle if we could!</h1>
        </section>
        <section class="landing-page-ion-content__img-section">
            <img
                class="landing-page-ion-content__img"
                src="assets/images/charly/charly-on-squiggle.svg"
                alt="Charly lays back playfully"
            />
        </section>
    </main>
</ion-content>

<ion-footer class="landing-page-ion-footer ion-text-center page-padding-sides">
    @if (vm().isIos) {
    <ion-button
        class="authentication-button"
        appTrackClick
        identifier="auth-apple-btn"
        expand="block"
        data-test="auth-apple-btn"
        [color]="Color.Snowy"
        (click)="onSignInWithAppleClick()"
    >
        <h3 class="authentication-button__content">
            <i class="authentication-button__icon fa-brands fa-apple"></i>
            <span class="authentication-button__text">Continue with Apple</span>
        </h3>
    </ion-button>
    }

    <ion-button
        class="authentication-button"
        appTrackClick
        identifier="auth-google-btn"
        expand="block"
        data-test="auth-google-btn"
        [color]="Color.Snowy"
        (click)="onSignInWithGoogleClick()"
    >
        <h3 class="authentication-button__content">
            <img src="assets/images/third-party/google-logo-color.svg" alt="Continue with Google" />
            <span class="authentication-button__text">Continue with Google</span>
        </h3>
    </ion-button>

    <ion-button
        class="authentication-button"
        appTrackClick
        identifier="auth-sign-up-btn"
        expand="block"
        data-test="auth-sign-up-btn"
        [color]="Color.Snowy"
        (click)="onSignUpWithEmailClick()"
    >
        <ion-text class="authentication-button__content" [color]="Color.Jack">
            <i class="authentication-button__icon fa-solid fa-envelope"></i>
            <span class="authentication-button__text">Sign Up with Email</span>
        </ion-text>
    </ion-button>

    <section class="landing-page-ion-footer__sign-in-wrapper">
        <p class="landing-page-ion-footer__already-a-member no-margin">Already a member?</p>
        <button
            class="zz-button landing-page-ion-footer__sign-in-button"
            appTrackClick
            identifier="auth-sign-in-btn"
            data-test="auth-login-btn"
            (click)="onSignInClick()"
        >
            Sign in
        </button>
    </section>

    <span class="landing-page-ion-footer__disclaimer" data-test="landing-page-disclaimer">
        By signing up to Zigzag, you agree to our
        <br /><strong data-test="landing-page-terms-link" (click)="onTermsClick()">Terms</strong> &
        <strong data-test="landing-page-privacy-link" (click)="openPrivacyClick()">Privacy statement</strong>
    </span>
</ion-footer>
