import { NgClass } from '@angular/common';
import { Component, forwardRef, inject, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BreedInfo } from '@frontend/data-access/contentful';
import { ModalService } from '@frontend/utility/modal';
import { IonInput } from '@ionic/angular/standalone';
import { BreedSelectorModalComponent } from './breed-selector-modal/breed-selector-modal.component';

// TODO: NBSon - need to look at preventing the modal from being opened multiple time e.g. when spam clicking, see if we can hook into the modal lifecycle events?
@Component({
    standalone: true,
    selector: 'app-breed-selector',
    templateUrl: './breed-selector.component.html',
    styleUrl: './breed-selector.component.scss',
    imports: [IonInput, NgClass],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BreedSelectorComponent),
            multi: true,
        },
    ],
})
export class BreedSelectorComponent implements ControlValueAccessor {
    private readonly modalService = inject(ModalService);

    public breedOptions = input<BreedInfo[]>([]);
    public isDisabled = input<boolean>(false);
    public isNewOnboarding = input<boolean>(false);

    public value = '';
    public displayValue: string | undefined;

    public onTouched: () => void = () => {
        /* Do nothing */
    };
    public onChange: (value: string) => void = () => {
        /* Do nothing */
    };

    public writeValue(value: string): void {
        this.value = value;
        this.displayValue = this.breedOptions().find((breedInfo) => breedInfo.id === value)?.name;
    }

    public registerOnChange(onChange: (value: string) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public async openModal(): Promise<void> {
        if (this.isDisabled()) {
            return;
        }

        const selectedBreedInfo = await this.modalService.showModalWithCallbackAsync<BreedInfo>({
            component: BreedSelectorModalComponent,
            componentProps: { breedOptions: this.breedOptions(), isNewOnboarding: this.isNewOnboarding() },
        });

        // The response can be undefined if the user dismisses the modal
        if (selectedBreedInfo) {
            this.value = selectedBreedInfo.id;
            this.displayValue = selectedBreedInfo.name;

            this.onChange(this.value);
        }

        this.onTouched();
    }
}
