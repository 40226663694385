import { Platform } from '@ionic/angular/standalone';
import { RevenueCatSubscriptionService } from './revenue-cat/revenue-cat-subscription.service';
import { SUBSCRIPTION_CONFIG, SubscriptionConfig } from './subscription-config.token';
import { SubscriptionService } from './subscription.service';
import { SubscriptionServiceMock } from './subscription.service.mock';

export const subscriptionServiceProvider = {
    provide: SubscriptionService,
    useFactory: (config: SubscriptionConfig, platform: Platform) => {
        return !config.useSubscriptionMock || platform.is('capacitor')
            ? new RevenueCatSubscriptionService()
            : new SubscriptionServiceMock();
    },
    deps: [SUBSCRIPTION_CONFIG, Platform],
};
