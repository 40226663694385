<ion-content
    [fullscreen]="true"
    [scrollEvents]="true"
    [scrollY]="canScroll(vm().selectedCourse!)"
    (ionScroll)="setScrollHeight($any($event))"
    class="page-safe-margin"
>
    <div class="course-header page-padding-sides page-padding-top">
        <h1 class="page-title">My Journey</h1>
        @if (vm().selectedCourse && vm().selectedCourse!.status !== courseStatus.COMPLETED &&
        !vm().isRecommendedContentEnabled) {
        <div
            class="ellipsis-button"
            data-test="ellipsis-btn"
            (click)="openMenu(vm().selectedCourse!, vm().contentUnlocked, vm().isBasicPlan)"
            appTrackClick
            identifier="courses-menu-btn"
        >
            <i class="fa-light fa-ellipsis-vertical fa-xl"></i>
        </div>
        }
    </div>
    @if (vm().selectedCourse) {
    <div>
        <app-course-slider [courses]="vm().courses" data-test="course-swipe"></app-course-slider>
        <div class="no-access-section">
            @if (displayNoAccess(vm().selectedCourse!)) {
            <app-no-access
                title="Unlock additional courses with premium"
                subTitle="You're on the Basic plan now."
                buttonText="Upgrade to Premium"
                (openPaymentModal)="openPaymentModal()"
            />
            }
            <div class="course-path page-padding-sides">
                <app-course-path></app-course-path>
            </div>
            @if (vm().selectedCourse!.status !== CourseStatus.COMPLETED) {
            <div
                *appElementRerender="vm().selectedCourse!.color"
                class="scroll-down-container"
                data-test="scroll-down-icon"
                [ngClass]="{'point-up': (vm().heightOfFirstInactiveStep ?? 0) < scrollHeight}"
                (click)="scrollToFirstInactiveStep(vm().heightOfFirstInactiveStep)"
            >
                <i
                    class="fa-solid fa-circle-chevron-down fa-2xl"
                    [style.color]="'var(--ion-color-' + vm().selectedCourse!.color + ')'"
                ></i>
            </div>
            }
            <div class="page-padding-sides next-up-container">
                @if (vm().nextCourse) {
                <app-generic-card-large
                    [title]="vm().nextCourse.title"
                    [description]="vm().nextCourse.introduction"
                    [backgroundColor]="vm().nextCourse.color"
                    [imageUrl]="vm().nextCourse.imageUrl"
                    label="Next Up"
                    callToActionText="View course"
                    [backgroundUrl]="getCourseCardDoodle(vm().nextCourse.color)"
                    imageAltText="An image of a dog excited about the next cup course"
                    (callToActionClicked)="openCourseInfoModal(vm().nextCourse)"
                    data-test="next-up-tile"
                >
                </app-generic-card-large>
                }
            </div>
        </div>
    </div>
    }
</ion-content>
