@if (vm$ | async; as vm) {
    <div class="text-block">
        <h2 class="library-topics-overview__title" data-test="library-topic-overview-title">Topics</h2>
        <p class="s library-topics-overview__subtitle">
            All lessons {{ vm.isUsUser ? 'categorized' : 'categorised' }} by skill
        </p>
        <div class="library-topics-overview__topics">
            @for (topic of vm.topics; track topic.entityId) {
                <app-topic-card-component
                    class="library-topics-overview__topic-card"
                    clickEventIdentifier="library-to-topic-{{ topic.title }}-btn"
                    [isLoading]="vm.arePinnedTopicsLoading"
                    [routerLinkPath]="['topic', topic.entityId]"
                    [showPinButton]="true"
                    [topic]="topic"
                    [hasPinnedTopics]="vm.hasPinnedTopics"
                ></app-topic-card-component>
            }
        </div>
    </div>
}
