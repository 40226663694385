import { AsyncPipe, Location } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { routeTo } from '@frontend/data-access/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';

@Component({
    selector: 'app-reset-password-check-email',
    templateUrl: './reset-password-check-email.page.html',
    styleUrls: ['../authentication.scss', './reset-password-check-email.page.scss'],
    standalone: true,
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        AsyncPipe,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class ResetPasswordCheckEmailPage {
    private readonly location = inject(Location);
    private readonly store = inject(Store);
    @Input({ required: true }) email!: string;

    routeToPrevious(): void {
        void this.location.back();
    }

    routeToLogin(): void {
        void this.store.dispatch(routeTo({ commands: ['auth', 'login'] }));
    }
}
