import { inject, Injectable } from '@angular/core';
import { adjustRequestTrackingConsent } from '@frontend/data-access/adjust';
import { routeTo } from '@frontend/data-access/router';
import { selectIsNewOnboardingAb } from '@frontend/data-access/user/config-cat';
import { finalisePageFinishedWaiting } from '@frontend/feature/onboarding';
import { AnimationController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, switchMap } from 'rxjs/operators';

@Injectable()
export class OnboardingEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly animationController = inject(AnimationController);

    finalizeOnboardingLegacy$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(finalisePageFinishedWaiting),
            concatLatestFrom(() => this.store.select(selectIsNewOnboardingAb)),
            filter(([, isNewOnboardingAb]) => !isNewOnboardingAb),
            switchMap(() => [
                routeTo({
                    commands: ['course-preview'],
                }),
                adjustRequestTrackingConsent(),
            ]),
        );
    });

    finalizeOnboarding$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(finalisePageFinishedWaiting),
            concatLatestFrom(() => this.store.select(selectIsNewOnboardingAb)),
            filter(([, isNewOnboardingAb]) => !!isNewOnboardingAb),
            switchMap(() => [
                routeTo({
                    commands: ['course-preview'],
                    extras: {
                        animation: (baseEl: HTMLElement) => {
                            const enterAnimation = this.animationController
                                .create('enter-animation')
                                .addElement(baseEl)
                                .fromTo('opacity', '0', '1')
                                .beforeStyles({ 'pointer-events': 'none' }) // Prevent user from interacting with the page while animating
                                .afterStyles({ 'pointer-events': 'auto' })
                                .afterAddWrite(() => {
                                    void this.animationController
                                        .create('course-preview-animation')
                                        .addElement(baseEl.querySelector('[data-test="course-preview-title"]')!)
                                        .addElement(baseEl.querySelector('[data-test="course-preview-wrapper"]')!)
                                        .addElement(baseEl.querySelector('[data-test="course-preview-endorsements"]')!)
                                        .addElement(baseEl.querySelector('[data-test="course-preview-footer"]')!)
                                        .fromTo('opacity', '0', '1')
                                        .duration(500)
                                        .play();
                                });

                            const leaveAnimation = this.animationController
                                .create('leave-animation')
                                .addElement(baseEl)
                                .fromTo('opacity', '0', '1');

                            return this.animationController.create().addAnimation([enterAnimation, leaveAnimation]);
                        },
                    },
                    isAnimated: true,
                }),
                adjustRequestTrackingConsent(),
            ]),
        );
    });
}
