import { Component, inject } from '@angular/core';
import { openUrlWindow } from '@frontend/data-access/router';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { onboardingNavigateBack, onboardingQuestionSubmittedPurinaOptIn } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-purina-opt-in',
    templateUrl: 'question-purina-opt-in.page.html',
    styleUrls: ['question-purina-opt-in.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionPurinaOptInPage {
    private readonly store = inject(Store);

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onExternalLinkClick(url: string): void {
        this.store.dispatch(openUrlWindow({ url, openInNewWindow: false }));
    }

    public onOptInClick(optedIn: boolean) {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedPurinaOptIn({ purinaMarketingAccepted: optedIn }));
    }
}
