import { AfterViewInit, Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { DismissibleModalService } from '@frontend/feature/dismissible-modal';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-food-calculator-instruction-modal-content',
    templateUrl: './food-calculator-instruction-modal-content.component.html',
    styleUrls: ['./food-calculator-instruction-modal-content.component.scss'],
    standalone: true,
    imports: [TextReplacementPipe],
})
export class FoodCalculatorInstructionModalContentComponent implements AfterViewInit {
    private readonly dismissibleModalService = inject(DismissibleModalService);
    @ViewChild('modalContent') private modalContent: TemplateRef<unknown> | undefined;

    //todo JL - make showModal and remove showDismissableModal code
    ngAfterViewInit(): void {
        if (this.modalContent) {
            void this.dismissibleModalService.showDismissibleModal(this.modalContent, {
                modalId: 'food-calculator-modal',
                backgroundColor: 'app-background',
            });
        }
    }
}
