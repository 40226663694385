import { NgClass } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { pinTopic, unpinTopic } from '@frontend/data-access/user/account';
import { IonCard, IonProgressBar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { TopicWithProgress } from '../../store/topic/models/topic-with-progress.model';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-topic-card-component',
    templateUrl: 'topic-card.component.html',
    styleUrls: ['topic-card.component.scss'],
    standalone: true,
    imports: [IonCard, AnalyticsTrackClickDirective, NgClass, RouterLink, IonProgressBar],
})
export class TopicCardComponent {
    private readonly store = inject(Store);
    @Input({ required: true }) topic!: TopicWithProgress;
    @Input({ required: true }) routerLinkPath: string[] = [];
    @Input({ required: true }) clickEventIdentifier!: string;
    @Input({ required: true }) isLoading!: boolean;

    @Input() showPinButton = false;
    @Input() showProgressBar = false;
    @Input() hasPinnedTopics = false;

    pinTopic(topicEntityId: string, pinned: boolean) {
        if (!this.isLoading) {
            const action = pinned ? unpinTopic({ topicEntityId }) : pinTopic({ topicEntityId });
            this.store.dispatch(action);
        }
    }
}
