import { RescheduledStepType } from '@shared/user-domain';
import { createSelector } from '@ngrx/store';
import { rescheduledStepFeature } from './rescheduled-step.reducer';

export const selectRescheduledStepsPractice = createSelector(
    rescheduledStepFeature.selectRescheduledSteps,
    (rescheduledSteps) =>
        rescheduledSteps.filter((rescheduledStep) => rescheduledStep.type === RescheduledStepType.PRACTICED),
);

export const selectRescheduledStepsPracticeIdsWithCount = createSelector(
    selectRescheduledStepsPractice,
    (rescheduledSteps) => {
        let result: { stepId: string; count: number }[] = [];

        rescheduledSteps.forEach((rescheduledStep) => {
            if (!result.find((item) => item.stepId === rescheduledStep.stepId)) {
                const count = rescheduledSteps.filter((item) => item.stepId === rescheduledStep.stepId).length;
                result = [...result, { stepId: rescheduledStep.stepId, count }];
            }
        });

        return result;
    },
);
