import { stepProgressFeature } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { selectCoursesWithStepsAndSplits } from '../../courses/course-steps-and-splits.selectors';
import { getStreak } from './progress-streak.utils';

export const selectProgressStreakInfo = createSelector(
    selectCoursesWithStepsAndSplits,
    stepProgressFeature.selectStepProgress,
    (courses, stepProgress) =>
        courses
            .map((course) => {
                const streakInfo = getStreak(course, stepProgress);
                return {
                    id: course.id,
                    color: course.color,
                    title: course.title,
                    length: streakInfo.length,
                    progressPercent: course.progress,
                    dateStarted: streakInfo.startDate,
                };
            })
            .filter((course) => course.length > 0),
);
