import { Directive, HostListener, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';

@Directive({
    selector: '[appTrackClick]',
    standalone: true,
})
export class AnalyticsTrackClickDirective {
    private readonly router = inject(Router);
    private readonly store = inject(Store);
    @Input() identifier = '';
    @Input() positionId = '';
    @Input() appTrackClickEnabled = true;

    @HostListener('click', ['$event']) onClick(): void {
        if (this.appTrackClickEnabled) {
            this.store.dispatch(
                trackEvent({
                    eventName: 'click',
                    eventProperties: {
                        identifier: this.identifier,
                        page: this.router.url,
                        positionId: this.positionId,
                    },
                }),
            );
        }
    }
}
