import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-training-coach-chat-intro',
    templateUrl: './training-coach-chat-intro.component.html',
    styleUrls: ['./training-coach-chat-intro.component.scss'],
    standalone: true,
})
export class TrainingCoachChatIntroComponent {
    @Input() dogName = 'your pup';
}
