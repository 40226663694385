import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import { profileImageFeature } from './store/profile-image.reducer';
import { provideEffects } from '@ngrx/effects';
import { ProfileImageEffects } from './store/profile-image.effects';

@NgModule({
    providers: [provideState(profileImageFeature), provideEffects(ProfileImageEffects)],
})
export class ZigzagFrontendFeatureProfileImageModule {}
