import { LocalNotificationSchema, ScheduleResult } from '@capacitor/local-notifications';
import { createAction, props } from '@ngrx/store';

export const scheduleLocalNotification = createAction(
    '[Local Notification] Schedule Local Notification',
    props<{ notifications: LocalNotificationSchema[] }>(),
);
export const scheduleLocalNotificationSuccess = createAction(
    '[Local Notification] Schedule Local Notification Success',
    props<{ result: ScheduleResult }>(),
);
export const scheduleLocalNotificationFailure = createAction(
    '[Local Notification] Schedule Local Notification Failure',
    props<{ error: Error }>(),
);
