import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { CourseProgressEffects } from './store/course-progress.effects';
import { courseProgressFeature } from './store/course-progress.reducer';

@NgModule({
    providers: [provideState(courseProgressFeature), provideEffects([CourseProgressEffects])],
})
export class ZigzagFrontendDataAccessUserCourseProgressModule {}
