import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { routeTo } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { CourseProgressType } from '@shared/user-domain';
import { concatMap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { selectFirstInactiveCourseId } from '../../courses/course-path/store/course-path.selectors';
import { setCourseSlider } from '../../courses/course-slider/course-slider.actions';
import { triggerCreateCourseProgress } from '../progress/course-progress/course-progress.actions';
import { setCourseProgressEnd, triggerAddNewCourse } from './course.actions';

@Injectable()
export class CourseEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    addNewCourseTodayPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerAddNewCourse),
            concatLatestFrom(() => this.store.select(selectFirstInactiveCourseId)),
            switchMap(([, courseId]) => [setCourseSlider({ courseId }), routeTo({ commands: ['main', 'courses'] })]),
        );
    });

    endCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setCourseProgressEnd),
            concatMap((course) => {
                return [
                    triggerCreateCourseProgress({
                        courseProgress: {
                            id: course.courseEnd.id,
                            progressType: CourseProgressType.ENDED,
                        },
                    }),
                    trackEvent({
                        eventName: '[Course] End Course',
                        eventProperties: {
                            courseId: course.courseEnd.id,
                            courseTitle: course.courseEnd.title,
                            selectedReason: course.courseEnd.reason,
                            explanation: course.courseEnd?.explanation,
                        },
                    }),
                ];
            }),
        );
    });
}
