export enum OnboardingRoutes {
    FINALIZE = 'finalize',
    AGE = 'age',
    BREED = 'breed',
    GENDER = 'gender',
    HAS_ARRIVED = 'has-arrived',
    NAME = 'name',
    RESCUE = 'rescue',
    NOTIFICATIONS = 'notifications',
    NEEDS = 'needs',
    OWNER_NAME = 'owner-name',
    ARRIVAL = 'arrival',
    FIRST_DAY_AT_HOME = 'first-day-at-home',
    PURINA_OPT_IN = 'purina-opt-in',
    CREATING_PLAN = 'creating-plan',
}
