@if (step) {
<ion-content class="article-page page-ion-content" [ngClass]="{ 'no-scroll': !unlocked() }" [fullscreen]="true">
    @if (!unlocked()) {
    <div class="article-page__lock-overlay"></div>
    }

    <article class="article-page__container">
        <ion-header class="article-page__header page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="{analyticsIdentifier:'article-back-btn', background: 'white'}"
                    [rightButton]="false"
                    (leftButtonPressed)="routeToPreviousPage()"
                />
            </ion-toolbar>
        </ion-header>

        <img class="article-page__header-image image-self" src="{{ step.content.imageUrl }}" alt="header-image" />

        <div class="article-page__content-container text-block">
            <h1>{{ step.content.title | textReplacement }}</h1>

            <!-- TODO: NBSon - evaluate this conditional -->
            @if (step.content.articleAuthor) {
            <zz-author-chip
                [author]="step.content.articleAuthor.authorName"
                [authorImageUrl]="step.content.articleAuthor.authorImageUrl"
            />
            } @else if (step.content.author) {
            <zz-author-chip [author]="step.content.author" [authorImageUrl]="step.content.authorImageUrl" />
            } @if (step.content.content) {
            <div
                class="article-page__content contentful-rich-text"
                data-test="legacy-content"
                [innerHTML]="step.content.content | textReplacement"
            ></div>
            } @if (step.content.richContent) {
            <div
                class="article-page__content contentful-rich-text"
                data-test="rich-content"
                [innerHTML]="step.content.richContent | richContent | textReplacement"
            ></div>
            }
            <app-feedback
                #feedbackComponent
                [contentfulId]="stepId()"
                [existingRating]="userFeedback"
                (feedbackSubmitted)="onFeedbackSubmitted($event)"
            ></app-feedback>
        </div>
    </article>

    <ion-footer class="ion-text-center gradient-footer">
        <ion-button
            appTrackClick
            identifier="article-read-btn"
            color="max"
            expand="block"
            data-test="article-read"
            [disabled]="userFeedbackLoading() === loadingState.LOADING"
            (click)="unlocked() ? articleCompleted(step) : openPaymentModal()"
        >
            @if (unlocked()) { Read it! } @else {
            <i class="fa-regular fa-lock-keyhole ion-margin-end icon-large article-page__padlock-icon"></i>
            @if (!hasHistoricalPurchase()) { Start my free trial } @else { See plans } }
        </ion-button>
    </ion-footer>
</ion-content>
}
