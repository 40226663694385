import { NgClass } from '@angular/common';
import { Component, effect } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { timeFeature } from '@frontend/data-access/time';
import { accountFeature } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import {
    AgePipe,
    ProgressHeaderComponent,
    RadioWithLogicComponent,
    RadioWithLogicItem,
    RadioWithLogicResult,
} from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonFooter,
    IonHeader,
    IonItem,
    IonModal,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { format } from 'date-fns';
import {
    AGE_RADIO_OPTIONS,
    OVER_TWO_YEARS_RADIO_OPTION,
    ZERO_TO_FOUR_MONTHS_RADIO_OPTION,
} from '../../constants/question-age.constants';
import { onboardingQuestionSubmittedDateOfBirth } from '../../data-access/onboarding.actions';
import { getInitialDateOfBirth, getMaxValue, getMinValue } from '../../onboarding-utils/question-age.utils';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-age',
    templateUrl: 'question-age.page.html',
    styleUrls: ['question-age.page.scss', '../onboarding.scss'],
    imports: [
        NgClass,
        ReactiveFormsModule,
        IonButton,
        IonContent,
        IonDatetime,
        IonDatetimeButton,
        IonFooter,
        IonHeader,
        IonItem,
        IonModal,
        IonSpinner,
        IonToolbar,
        AgePipe,
        AnalyticsTrackClickDirective,
        ProgressHeaderComponent,
        RadioWithLogicComponent,
    ],
})
export class OnboardingQuestionAgePage extends OnboardingQuestionBasePage {
    protected readonly AGE_RADIO_OPTIONS: RadioWithLogicItem[] = AGE_RADIO_OPTIONS;
    protected readonly OVER_TWO_YEARS_RADIO_OPTION = OVER_TWO_YEARS_RADIO_OPTION;
    protected readonly ZERO_TO_FOUR_MONTHS_RADIO_OPTION = ZERO_TO_FOUR_MONTHS_RADIO_OPTION;
    protected readonly getMinValue = getMinValue;
    protected readonly getMaxValue = getMaxValue;

    public currentDate = this.store.selectSignal<Date>(timeFeature.selectToday);
    public dateOfBirth = this.store.selectSignal(accountFeature.selectDateOfBirth);
    public isApproximateDateOfBirth = this.store.selectSignal(accountFeature.selectIsApproximateDateOfBirth);

    public radioResult: RadioWithLogicResult | undefined;
    public ageForm = new FormGroup({
        dateOfBirth: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
        isApproximateDateOfBirth: new FormControl(false, { nonNullable: true }),
    });

    constructor() {
        super();

        // TODO: LB - this keeps the age selected when navigating back, but not when refreshing the page - still need to work that out
        effect(() => {
            if (this.dateOfBirth() !== undefined && this.isApproximateDateOfBirth() !== undefined) {
                this.ageForm.patchValue({
                    dateOfBirth: format(this.dateOfBirth()!, 'yyyy-MM-dd'),
                    isApproximateDateOfBirth: this.isApproximateDateOfBirth()!,
                });
            }
        });
    }

    public setAnswer(radioResult: RadioWithLogicResult): void {
        if (this.isLoading()) {
            return;
        }

        this.radioResult = radioResult;

        if (!this.radioResult.complete) {
            this.ageForm.reset();
        } else {
            this.ageForm.controls.dateOfBirth.setValue(getInitialDateOfBirth(this.radioResult));
            this.ageForm.controls.isApproximateDateOfBirth.setValue(
                radioResult.complete && radioResult.selected !== ZERO_TO_FOUR_MONTHS_RADIO_OPTION,
            );
            this.ageForm.markAsDirty();
        }
    }

    public onSubmit(): void {
        if (this.ageForm.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedDateOfBirth({
                dateOfBirth: new Date(this.ageForm.controls.dateOfBirth.value).toISOString(),
                isApproximateDateOfBirth: this.ageForm.controls.isApproximateDateOfBirth.value,
            }),
        );
    }
}
