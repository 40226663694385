import { DismissibleInfoItem, shouldDisplayModal, ShowModalFrequency } from '@frontend/data-access/dismissible-info';
import { StepProgress } from '@frontend/data-access/user/progress';
import { ArticleProgressType, LessonProgressType } from '@shared/user-domain';
import { addDays, isSameDay } from 'date-fns';
import { CourseWithSteps } from '../../courses/models/courses.models';
import { ProgressStreakModelInfo } from './progress-streak.model';

export const getStreak = (course: CourseWithSteps, stepProgress: StepProgress[]) => {
    let currentDate = new Date();
    let stepsCompletedOnDay = 0;

    let streakStartDate = new Date();
    let streakLength = 0;

    // only completed steps matter for streaks
    const completedStepProgress = stepProgress.filter((step) => {
        return step.progress === LessonProgressType.COMPLETED || step.progress === ArticleProgressType.COMPLETED;
    });

    do {
        // get completed step ids for the day we are checking
        const completedStepProgressIds = completedStepProgress
            .filter((stepProgress) => {
                return isSameDay(new Date(stepProgress.timestamp), currentDate);
            })
            .map((stepProgress) => stepProgress.stepId);

        // get step ids in course
        const courseStepIds = course.steps.map((courseStep) => courseStep.id);

        // see how often step ids of this day occur in the course step ids
        stepsCompletedOnDay = countUniqueOccurrencesOfProgressInCourse(courseStepIds, completedStepProgressIds);

        // add to streak, and check previous day
        if (stepsCompletedOnDay >= 3) {
            streakLength++;
            streakStartDate = currentDate;
        }
        currentDate = addDays(currentDate, -1);
    } while (stepsCompletedOnDay >= 3);

    return {
        length: streakLength,
        startDate: streakStartDate,
    };
};

export function didStepCompletionCauseStreakUserHasNotSeen(
    completedStepId: string,
    courses: CourseWithSteps[],
    streaks: ProgressStreakModelInfo[],
    dismissedItems: DismissibleInfoItem[],
    today: Date,
) {
    const courseWhereStepWasCompleted = courses.find((course) =>
        course.steps.find((step) => step.id === completedStepId),
    );

    if (!courseWhereStepWasCompleted) {
        return undefined;
    }

    const courseStreak = streaks.find((streak) => streak.id === courseWhereStepWasCompleted.id);

    if (!courseStreak) {
        return undefined;
    }

    const shouldDisplayStreakModal = shouldDisplayModal(
        ShowModalFrequency.DAILY,
        `progress-streak-modal-${courseStreak.id}`,
        dismissedItems,
        today,
    );

    if (!shouldDisplayStreakModal) {
        return undefined;
    }
    return courseStreak;
}

export function countUniqueOccurrencesOfProgressInCourse(courseStepIds: string[], stepProgressIds: string[]) {
    const foundIds: string[] = [];

    stepProgressIds.forEach((stepProgressId) => {
        const stepIdOccursInCourse = courseStepIds.find((courseStepId) => courseStepId === stepProgressId);

        if (!!stepIdOccursInCourse && !foundIds.find((foundId) => foundId === stepProgressId)) {
            foundIds.push(stepProgressId);
        }
    });

    return foundIds.length;
}
