import { createAction, props } from '@ngrx/store';

export const suggestedAiChatQuestionClicked = createAction(
    '[Today] Suggested Ai Chat Question Clicked',
    props<{ optionId: string; card: string; option: string }>(),
);

export const showAiChatView = createAction('[Today] Show AI Chat View');

export const hideAiChatView = createAction('[Today] Hide AI Chat View');

export const aiChatInputClicked = createAction('[Today] AI Chat Input Clicked');

export const aiChatInputSubmitted = createAction('[Today] AI Chat Input Submitted', props<{ message: string }>());

export const aiChatBackButtonClicked = createAction('[Today] AI Chat Back Button Clicked');

export const goToCourseFromTile = createAction('[Today] Go to Course from Tile', props<{ courseId: string }>());
