import { Routes } from '@angular/router';
import { ArticlePage } from '../article/article.page';
import { LessonIntroPage } from '../lesson/pages/lesson-intro/lesson-intro-page.component';
import { LessonInstructionPage } from '../lesson/pages/lesson-instruction/lesson-instruction-page.component';
import { LessonRatingPage } from '../lesson/pages/lesson-rating/lesson-rating-page.component';
import { LessonCompletionPage } from '../lesson/pages/lesson-completion/lesson-completion-page.component';
import { StepGuard } from './guards/step.guard';
import { TodayPage } from '../today/today.page';

export const stepRoutes: Routes = [
    {
        path: ':stepId',
        children: [
            {
                path: '',
                pathMatch: 'full',
                canActivate: [StepGuard],
                component: TodayPage, // had to put something here otherwise it complains
            },
            {
                path: 'article',
                component: ArticlePage,
            },
            {
                path: 'lesson',
                children: [
                    {
                        path: '',
                        redirectTo: 'intro',
                        pathMatch: 'full',
                    },
                    {
                        path: 'intro',
                        component: LessonIntroPage,
                    },
                    {
                        path: 'instruction',
                        component: LessonInstructionPage,
                    },
                    {
                        path: 'rating',
                        component: LessonRatingPage,
                    },
                    {
                        path: 'completion',
                        component: LessonCompletionPage,
                    },
                ],
            },
        ],
    },
];
