@if (vm$ | async; as vm) {
    <div class="today-courses page-safe-margin">
        <h2 class="title">My Journey</h2>
        <app-slider>
            @if (vm.courseProgressLoading) {
                <app-course-card-today-loading></app-course-card-today-loading>
            } @else {
                @for (course of vm.courses; track course.id) {
                    <app-course-card-today-component [course]="course"></app-course-card-today-component>
                }
            }
            @if (vm.showAddButton) {
                <div class="today-courses__add-course-container">
                    <ion-card
                        class="today-courses__add-course-tile"
                        button="true"
                        appTrackClick
                        identifier="add-new-course-from-today-page"
                        data-test="today-courses-add-course-tile"
                        (click)="addNewCourse()"
                    >
                        <div class="today-courses__add-course-inner">
                            <i class="fa-regular fa-plus today-courses__plus-sign"></i>
                            <p class="s today-courses__add-course-text">Add Course</p>
                        </div>
                    </ion-card>
                </div>
            }
        </app-slider>
    </div>
}
