import { NgModule } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { Store, provideState } from '@ngrx/store';
import { keyboardDidHide, keyboardDidShow } from './store/keyboard.actions';
import { keyboardFeature } from './store/keyboard.reducer';

@NgModule({
    providers: [provideState(keyboardFeature)],
})
export class ZigzagFrontendDataAccessKeyboardModule {
    constructor(
        private readonly store: Store,
        private readonly platform: Platform,
    ) {
        this.platform.keyboardDidShow.subscribe(() => {
            this.store.dispatch(keyboardDidShow());
        });

        this.platform.keyboardDidHide.subscribe(() => {
            this.store.dispatch(keyboardDidHide());
        });
    }
}
