import { Routes } from '@angular/router';
import { FoodCalculatorPage } from './food-calculator.page';

const foodCalculatorRoutes: Routes = [
    {
        path: '',
        component: FoodCalculatorPage,
        pathMatch: 'full',
    },
    {
        path: 'chat',
        loadChildren: () => import('../../chat/chat.routes'),
    },
];

export default foodCalculatorRoutes;
