import { createAction, props } from '@ngrx/store';
import { InternalApiError } from '../../models/internal-api-error.model';
import { Profile } from '../../models/profile.model';

export const createAccount = createAction(
    '[Account] Create Account',
    props<{ id?: string; email: string; accountId: string; countryCode: string }>(),
);

export const createAccountSuccess = createAction(
    '[Account] Create Account Success',
    props<{ profile: Profile; profileId: string }>(),
);

export const createAccountFailure = createAction(
    '[Account] Create Account Failure',
    props<{ error: InternalApiError }>(),
);

export const resetAccountErrorState = createAction('[Account] Reset Account Error State');
