import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { TodayCourseProgressState } from '../models/today-course-progress.model';
import {
    createTodayCourseProgress,
    createTodayCourseProgressFailure,
    createTodayCourseProgressSuccess,
    getTodayCourseProgress,
    getTodayCourseProgressFailure,
    getTodayCourseProgressSuccess,
    updateTodayCourseProgress,
    updateTodayCourseProgressFailure,
    updateTodayCourseProgressSuccess,
} from './today-course-progress.actions';

export const todayCourseProgressInitialState: TodayCourseProgressState = {
    todayCourseProgress: [],
    loadingState: LoadingState.INIT,
};

export const todayCourseProgressFeature = createFeature({
    name: 'todayCourseProgress',
    reducer: createReducer(
        todayCourseProgressInitialState,
        on(
            getTodayCourseProgress,
            createTodayCourseProgress,
            updateTodayCourseProgress,
            (state): TodayCourseProgressState => ({
                ...state,
                loadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getTodayCourseProgressSuccess,
            createTodayCourseProgressSuccess,
            updateTodayCourseProgressSuccess,
            (state, action): TodayCourseProgressState => ({
                ...state,
                todayCourseProgress: action.todayProgress,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            getTodayCourseProgressFailure,
            createTodayCourseProgressFailure,
            updateTodayCourseProgressFailure,
            (state): TodayCourseProgressState => ({
                ...state,
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
});
