import { Injectable, inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    private readonly appInsights = inject(ApplicationInsights);

    logPageView(name?: string, url?: string): void {
        this.appInsights.trackPageView({
            name: name,
            uri: url,
        });
    }

    logEvent(name: string, properties?: Record<string, unknown>): void {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: Record<string, unknown>): void {
        this.appInsights.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number): void {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: Record<string, unknown>): void {
        this.appInsights.trackTrace({ message: message }, properties);
    }
}
