<ng-template #modalContent>
    <div class="food-calculator-modal">
        <div class="text-block">
            <h1 class="ion-text-center">How the calculator works</h1>
        </div>
        <div class="instructions-container">
            <div class="step">
                <div class="step-enum">
                    <h3>1</h3>
                    <div class="line"></div>
                </div>
                <div class="step-text">
                    <h3>Enter weight & food brand</h3>
                    <p class="xs">
                        {{ 'Put [NAME] on the scale to be sure! Search your food product by name.' | textReplacement }}
                    </p>
                </div>
            </div>
            <div class="step">
                <div class="step-enum">
                    <h3>2</h3>
                    <div class="line"></div>
                </div>
                <div class="step-text">
                    <h3>Hit calculate</h3>
                    <p class="xs">We checked the packaging and did the sums, so you don’t have to.</p>
                </div>
            </div>
            <div class="step">
                <div class="step-enum">
                    <h3>3</h3>
                </div>
                <div class="step-text">
                    <h3>Bone appetit!</h3>
                    <p class="xs">
                        {{
                            "Don’t forget to recalculate every week, to account for [NAME]'s growth!" | textReplacement
                        }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
