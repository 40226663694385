import { Color } from '@shared/utils/typescript';
import { createAction, props } from '@ngrx/store';

export const openToast = createAction(
    '[Toast] Open Toast',
    props<{
        message: string;
        color: Color;
    }>(),
);
