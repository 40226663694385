import { platformFeature } from '@frontend/data-access/platform';
import { LoadingState } from '@frontend/data-access/shared-models';
import { accountFeature, mapFirebaseError } from '@frontend/data-access/user/account';
import { authenticationFeature, mapAuthenticationError } from '@frontend/data-access/user/authentication';
import { createSelector } from '@ngrx/store';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { selectCurrentIpCountryCode } from '@frontend/data-access/geo-location';

export const selectCreateAccountPageVm = createSelector(
    accountFeature.selectAccountState,
    authenticationFeature.selectAuthenticationState,
    selectCurrentIpCountryCode,
    selectIsInputOpenOnAndroid,
    platformFeature.selectPlatformIsIos,
    (accountState, authenticationState, countryCode, isInputFocusedOnAndroid, isIos) => {
        // signUpError means the email already exists

        return {
            error:
                mapFirebaseError(accountState.error) || mapAuthenticationError(authenticationState.signUpError?.code),
            isLoading: accountState.loading === LoadingState.LOADING || authenticationState.signUpLoading,
            countryCode: countryCode ?? '',
            isInputFocusedOnAndroid,
            isIos,
        };
    },
);
