import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonCard } from '@ionic/angular/standalone';

@Component({
    selector: 'app-chat-error-message',
    templateUrl: 'chat-error-message.component.html',
    styleUrls: ['chat-error-message.component.scss'],
    standalone: true,
    imports: [NgStyle, IonCard],
})
export class ChatErrorMessageComponent {
    @Input({ required: true }) public backgroundColor!: Color;
    @Input({ required: true }) public textColor!: Color;
}
