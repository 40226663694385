import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { authenticationFeature } from '@frontend/data-access/user/authentication';
import { AuthenticationEffects } from './data-access/store/authentication.effects';
import { DuplicateProfileManagementEffects } from './data-access/store/duplicate-profile-management.effects';
import { LandingPageEffects } from './feature-authentication/landing-page/landing.page.effects';

@NgModule({
    providers: [
        provideState(authenticationFeature),
        provideEffects([AuthenticationEffects, DuplicateProfileManagementEffects, LandingPageEffects]),
    ],
})
export class ZigzagFeatureAuthenticationModule {}
