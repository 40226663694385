@if (remainingTime) {
    <p class="payment-modal-limited-offer-product-chip s">Ends in {{ remainingTime }}</p>
}

<ion-card
    class="payment-modal-limited-offer-product"
    [class.payment-modal-limited-offer-product--selected]="isSelected()"
>
    <h3 class="payment-modal-limited-offer-product__title">{{ product()?.title }}</h3>

    <h3 class="payment-modal-limited-offer-product__price">
        <span class="payment-modal-limited-offer-product__discount">{{ product()?.discount?.originalPrice }}</span
        >&nbsp;{{ product()?.priceWithUnit }}
    </h3>

    <p class="payment-modal-limited-offer-product__description s" [innerHTML]="product()?.description"></p>
</ion-card>
