import { ConfigCatFlag } from '@frontend/data-access/user/config-cat';

export function mapConfigCatFlagsToKeyValuePairs(configCatFlags: ConfigCatFlag[]): Record<string, boolean> {
    const flags: Record<string, boolean> = {};

    configCatFlags.forEach((flag) => {
        flags[flag.name] = flag.flagValue;
    });
    return flags;
}
