import { createSelector } from '@ngrx/store';
import { selectCoursePathSvgSize, selectSelectedCourseWithCoordinates } from './store/course-path.selectors';

export const selectCoursePathComponentVm = createSelector(
    selectSelectedCourseWithCoordinates,
    selectCoursePathSvgSize,
    (course, svgSize) => {
        return {
            course,
            svgSize,
        };
    },
);
