import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FrontendCourseProgressEntry } from '../models/course-progress.model';
import { CourseProgressService } from '../services/course-progress.service';
import { mapInternalCourseProgressDtoToFrontendCourseProgressEntry } from '../utils/course-progress.utils';
import {
    createCourseProgress,
    createCourseProgressFailure,
    createCourseProgressSuccess,
    createManyCourseProgress,
    createManyCourseProgressSuccess,
    getCourseProgress,
    getCourseProgressFailure,
    getCourseProgressSuccess,
} from './course-progress.actions';

@Injectable()
export class CourseProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly courseProgressService = inject(CourseProgressService);

    getCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCourseProgress),
            exhaustMap(() => {
                return this.courseProgressService.getCourseProgress().pipe(
                    map((response) => {
                        const progress: FrontendCourseProgressEntry[] = response.map((entry) => {
                            const { userId, ...rest } = entry;

                            return rest;
                        });
                        return getCourseProgressSuccess({ progress });
                    }),
                    catchError((error: Error) => of(getCourseProgressFailure({ error }))),
                );
            }),
        );
    });

    createCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCourseProgress),
            concatMap(({ courseProgress, correlationId }) => {
                return this.courseProgressService.createCourseProgress(courseProgress).pipe(
                    map((response) => {
                        const { userId, ...entry } = response;

                        return createCourseProgressSuccess({
                            entry,
                            correlationId,
                        });
                    }),
                    catchError((error: Error) => of(createCourseProgressFailure({ error }))),
                );
            }),
        );
    });

    createManyCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createManyCourseProgress),
            concatMap(({ courseProgress, correlationId }) => {
                return this.courseProgressService.createManyCourseProgress({ courseProgress }).pipe(
                    map((response) => {
                        const entries = response.map(mapInternalCourseProgressDtoToFrontendCourseProgressEntry);

                        return createManyCourseProgressSuccess({
                            entries,
                            correlationId,
                        });
                    }),
                    catchError((error: Error) => of(createCourseProgressFailure({ error }))),
                );
            }),
        );
    });
}
