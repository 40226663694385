import { AdjustConfig, AdjustEnvironment, AdjustLogLevel } from '@awesome-cordova-plugins/adjust/ngx';
import { ADJUST_CONFIG } from '@frontend/data-access/adjust';
import { Environment } from '../environment/environment.model';
import { ENVIRONMENT } from '../injection-tokens';
import { ProjectEnvironment } from '@frontend/configuration';

export const adjustConfigProvider = {
    provide: ADJUST_CONFIG,
    useFactory: (env: Environment) => {
        const adjustEnvironment =
            env.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
                ? AdjustEnvironment.Production
                : AdjustEnvironment.Sandbox;
        const adjustLogLevel =
            env.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
                ? AdjustLogLevel.Error
                : AdjustLogLevel.Verbose;

        const adjustConfig: AdjustConfig = new AdjustConfig(env.adjustAppToken, adjustEnvironment);
        adjustConfig.setLogLevel(adjustLogLevel);
        return adjustConfig;
    },
    deps: [ENVIRONMENT],
};
