<ion-content [color]="Color.Luna">
    <section class="pup-happiness-modal">
        <button
            class="zz-button xxxl pup-happiness-modal__close"
            appTrackClick
            identifier="pup-happiness-modal-close-btn"
            data-test="pup-happiness-modal-close-btn"
            (click)="closeModal()"
        >
            <ion-icon name="close-outline" [color]="Color.Max" />
        </button>

        <section class="pup-happiness-modal__content-container">
            <img
                class="pup-happiness-modal__dog-listening"
                [src]="'/assets/images/bailey/bailey-holds-one-ear-up-listening-cropped.svg'"
                [alt]="'A puppy awaiting your response as to how ' + dogName + ' is feeling today.'"
            />
            <h2 class="pup-happiness-modal__title" data-test="pup-happiness-modal-title">
                How is {{ dogName }} feeling today?
            </h2>
            <p class="pup-happiness-modal__subtitle" data-test="pup-happiness-modal-subtitle">
                Tracking your dog's mood state can help you both build a better relationship.
            </p>
        </section>

        <section class="pup-happiness-modal__feedback-container">
            @for (button of buttons; track button.id) {
                <button
                    class="zz-button pup-happiness-modal__feedback-button"
                    [attr.data-test]="'pup-happiness-modal-' + button.id + '-btn'"
                    (click)="closeModal(button.id)"
                >
                    <p class="xl no-margin">
                        <i
                            class="pup-happiness-modal__feedback-button-image pup-happiness-modal__feedback-button-image--sad fa-light"
                            [ngClass]="['pup-happiness-modal__feedback-button-image--' + button.id, button.icon]"
                        ></i>
                    </p>
                    <span class="pup-happiness-modal__feedback-button-text">{{ button.text }}</span>
                </button>
            }
        </section>

        <button
            class="zz-button"
            appTrackClick
            identifier="pup-happiness-modal-dont-show-again-btn"
            data-test="pup-happiness-modal-dont-show-again-btn"
            (click)="dontShowAgain()"
        >
            Don't show this again
        </button>
    </section>
</ion-content>
