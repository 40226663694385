import { inject, Injectable } from '@angular/core';
import {
    logoutAnalytics,
    logoutAnalyticsFailure,
    logoutAnalyticsSuccess,
    trackEvent,
} from '@frontend/data-access/analytics';
import {
    removeAllLocalStorage,
    removeAllLocalStorageFailure,
    removeAllLocalStorageSuccess,
} from '@frontend/data-access/capacitor';
import { getGeoLocation } from '@frontend/data-access/geo-location';
import {
    logoutPushNotifications,
    logoutPushNotificationsFailure,
    logoutPushNotificationsSuccess,
} from '@frontend/data-access/push-notification';
import { forceAppRefresh, routeTo } from '@frontend/data-access/router';
import {
    foundValidUserSession,
    loginWithEmailAndPasswordFailure,
    logoutSuccess,
    noValidUserSession,
    requestPasswordResetSuccess,
} from '@frontend/data-access/user/authentication';
import { fireHapticFeedback } from '@frontend/utility/native-plugins';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { exhaustMap, mergeMap, zip } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { accountFeature, getAccount } from '@frontend/data-access/user/account';
import { LoadingState } from '@frontend/data-access/shared-models';

@Injectable()
export class AuthenticationEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    loginWithEmailAndPasswordFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginWithEmailAndPasswordFailure),
            mergeMap(({ error }) => [
                trackEvent({ eventName: 'Login failed', eventProperties: { error } }),
                fireHapticFeedback({ style: 'warning' }),
            ]),
        );
    });

    logoutSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutSuccess),
            exhaustMap(() => [logoutAnalytics(), removeAllLocalStorage(), logoutPushNotifications()]),
        );
    });

    redirectToRoot$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(removeAllLocalStorageSuccess, removeAllLocalStorageFailure)),
            this.actions$.pipe(ofType(logoutAnalyticsSuccess, logoutAnalyticsFailure)),
            this.actions$.pipe(ofType(logoutPushNotificationsSuccess, logoutPushNotificationsFailure)),
        ]).pipe(map(() => forceAppRefresh()));
    });

    requestPasswordResetSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestPasswordResetSuccess),
            map(({ email }) =>
                routeTo({
                    commands: ['auth', 'reset-password-check-email'],
                    isAnimated: true,
                    extras: {
                        queryParams: {
                            email,
                        },
                    },
                }),
            ),
        );
    });

    getProfileIfUserHasValidSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foundValidUserSession),
            concatLatestFrom(() => this.store.select(accountFeature.selectLoading)),
            filter(([, loadingState]) => loadingState === LoadingState.INIT), // this means we have done login or signup and should not trigger getAccount
            map(() => getAccount({})),
        );
    });

    getGeoLocationIfNoInitialUserSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(noValidUserSession),
            take(1),
            map(() => {
                return getGeoLocation();
            }),
        );
    });
}
