import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonButtons, IonContent, IonIcon, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';
import { selectFoodCalculatorDisclaimerModalVm } from './food-calculator-disclaimer-modal.selectors';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-food-calculator-disclaimer-modal',
    templateUrl: './food-calculator-disclaimer-modal.component.html',
    styleUrls: ['./food-calculator-disclaimer-modal.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, AsyncPipe, IonContent, IonToolbar, IonButtons, IonButton, IonIcon],
})
export class FoodCalculatorDisclaimerModalComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    public vm$ = this.store.select(selectFoodCalculatorDisclaimerModalVm);

    constructor() {
        addIcons({ close });
    }

    closeModal() {
        void this.modalService.dismiss();
    }
}
