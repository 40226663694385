@if (vm$ | async; as vm) {
<ion-header class="authentication-ion-header page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Sign up"
            titleColour="white"
            [rightButton]="false"
            [leftButton]="{
                    analyticsIdentifier:'question-create-account-back-btn',
                    background: 'none',
                    iconColour: 'white'
                }"
            [isDisabled]="vm.isLoading"
            (leftButtonPressed)="onBackButtonPressed()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="sign-up-ion-content authentication-ion-content" [fullscreen]="true">
    <main class="sign-up page-content ion-justify-content-start">
        <section class="authentication-title-container">
            <h1>Let's sign up!</h1>
            <p data-test="create-account-page-subtitle">
                We'll save your progress and send you updates to help with your training journey!
            </p>
        </section>

        <app-create-account-form class="authentication-form" (signUpFormChange)="onSignUpFormChange($event)" />

        @if (vm.isIos) {
        <ion-button
            class="authentication-button"
            appTrackClick
            identifier="auth-apple-btn"
            expand="block"
            color="snowy"
            data-test="auth-apple-btn"
            (click)="onSignUpWithAppleClick()"
        >
            <h3 class="authentication-button__content">
                <i class="authentication-button__icon fa-brands fa-apple"></i>
                <span class="authentication-button__text">Continue with Apple</span>
            </h3>
        </ion-button>
        }

        <ion-button
            class="authentication-button"
            appTrackClick
            identifier="auth-google-btn"
            expand="block"
            color="snowy"
            data-test="auth-google-btn"
            (click)="onSignUpWithGoogleClick()"
        >
            <h3 class="authentication-button__content">
                <img src="assets/images/third-party/google-logo-color.svg" alt="Continue with Google" />
                <span class="authentication-button__text">Continue with Google</span>
            </h3>
        </ion-button>
    </main>
</ion-content>

@if (!vm.isInputFocusedOnAndroid) {
<ion-footer class="authentication-ion-footer ion-text-center page-footer">
    <div class="sign-up-footer">
        <div class="hide-on-keyboard-small-screen">
            @if (!vm.isInputFocusedOnAndroid) {
            <p data-test="create-account-disclaimer">
                By signing up to Zigzag, you agree to our <br /><strong
                    data-test="show-terms"
                    (click)="onOpenTermsClick(vm.countryCode)"
                    >Terms</strong
                >
                &
                <strong data-test="show-privacy" (click)="onPrivacyClick(vm.countryCode)">Privacy statement</strong>
            </p>
            }
            <ion-button
                class="ion-button-color-app-background-max"
                appTrackClick
                identifier="question-create-account-sign-up-btn"
                expand="block"
                data-test="question-create-account-sign-up-btn"
                [disabled]="form && (!form.controls.email.valid || !form.controls.password.valid || vm.isLoading)"
                (click)="onSignUpClick(form.value.email, form.value.password)"
            >
                @if (vm.isLoading) {
                <ion-spinner name="crescent" />
                } @else { Sign up }
            </ion-button>
        </div>
    </div>
</ion-footer>
} }
