import { Component, inject } from '@angular/core';
import { DailyBite } from '@frontend/data-access/contentful';
import { ModalService } from '@frontend/utility/modal';
import { Store } from '@ngrx/store';
import { DailyBiteCardComponent } from '../../daily-bites/daily-bite-card/daily-bite-card.component';
import { DailyBiteModalComponent } from '../../daily-bites/daily-bite-modal/daily-bite-modal.component';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { selectTodayDailyBitesVm } from './today-daily-bites.selectors';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-today-daily-bites',
    templateUrl: './today-daily-bites.component.html',
    styleUrls: ['./today-daily-bites.component.scss'],
    standalone: true,
    imports: [AnalyticsTrackClickDirective, DailyBiteCardComponent, TextReplacementPipe],
})
export class TodayDailyBitesComponent {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    public vm = this.store.selectSignal(selectTodayDailyBitesVm);

    public openDailyBiteModal(dailyBite: DailyBite): void {
        void this.modalService.showModal({
            component: DailyBiteModalComponent,
            componentProps: { dailyBite },
        });
    }
}
