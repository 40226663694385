import {
    Component,
    computed,
    effect,
    ElementRef,
    EventEmitter,
    Input,
    model,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { OptionSelectorSquareComponent, SliderComponent } from '@frontend/ui';
import { ChatTileContent, ChatTileTopic } from '@frontend/data-access/contentful';

export interface SuggestedQuestionsPromptClicked {
    card: string;
    option: string | null;
    optionId: string | undefined;
    message: string | null;
}

@Component({
    selector: 'app-suggested-ai-chat-questions',
    templateUrl: './suggested-ai-chat-questions.component.html',
    standalone: true,
    imports: [SliderComponent, OptionSelectorSquareComponent],
})
export class SuggestedAiChatQuestionsComponent {
    @Input({ required: true }) suggestedQuestionsCards!: ChatTileTopic[];

    selectedOption = model<ChatTileContent | undefined>();
    selectedCard = model<ChatTileTopic | undefined>();

    @Output() promptClicked = new EventEmitter<SuggestedQuestionsPromptClicked>();

    @ViewChildren('questionCards') questionCards!: QueryList<OptionSelectorSquareComponent>;
    @ViewChildren('questionCards', { read: ElementRef }) public questionsCardEl!: QueryList<ElementRef<any>>;

    indexOfSelectedCard = computed(() =>
        this.questionCards.toArray().findIndex((card) => {
            return card.title === this.selectedCard()?.title;
        }),
    );

    constructor() {
        effect(() => {
            setTimeout(() => {
                (this.questionsCardEl.get(this.indexOfSelectedCard())?.nativeElement as HTMLElement)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                });
            }, 0);
        });
    }

    public selectedOptionChanged(clickedCard: ChatTileTopic, option: ChatTileContent | undefined): void {
        if (!this.selectedCard()) {
            this.selectedCard.set(clickedCard);
        }

        // The user has clicked a new card - we should clear selection on the old card
        if (this.selectedCard()?.id !== clickedCard.id) {
            this.questionCards
                .find((questionCard) => questionCard.title === this.selectedCard()?.title)
                ?.clearSelection();
            this.selectedCard.set(clickedCard);
        }

        this.selectedOption.set(option);

        this.promptClicked.emit({
            card: clickedCard.id,
            optionId: option?.id,
            option: option === undefined ? null : option.shortQuestion,
            message: option === undefined ? null : option.longQuestion,
        });
    }

    public clearSelection() {
        this.questionCards.find((questionCard) => questionCard.title === this.selectedCard()?.title)?.clearSelection();
        this.selectedCard.set(undefined);
        this.selectedOption.set(undefined);
    }
}
