import { AsyncPipe, Location, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { FeedbackComponent, FeedbackSubmittedEvent, RichContentPipe } from '@frontend/ui';
import { ZzAuthorChipComponent } from '@frontend/zigzag-main/zz-author-chip';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { setStepCompleted, userLeftArticleFeedback } from '../store/progress/step-progress/step-progress.actions';
import { Step } from '../store/step/step.model';
import { selectArticlePageVm } from './article.page.selectors';

@Component({
    selector: 'app-article',
    templateUrl: './article.page.html',
    styleUrls: ['./article.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        NgClass,
        RichContentPipe,
        TextReplacementPipe,
        ZzAuthorChipComponent,
        FeedbackComponent,
    ],
})
export class ArticlePage {
    private readonly store = inject(Store);
    private readonly location = inject(Location);

    public vm$ = this.store.select(selectArticlePageVm);

    public routeToPreviousPage(): void {
        this.location.back();
    }

    public articleCompleted(step: Step): void {
        this.store.dispatch(
            setStepCompleted({
                properties: {
                    title: step.content.title,
                    stepId: step.id,
                    contentType: step.content.contentType,
                    contentId: step.content.id,
                    topicId: step.topic.title,
                },
            }),
        );
        this.routeToPreviousPage();
    }

    public openPaymentModal(): void {
        void this.store.dispatch(showPaymentModal({ trigger: 'article-page' }));
    }

    onFeedbackSubmitted(feedback: FeedbackSubmittedEvent) {
        const { option, comment, thumbsDown } = feedback;
        this.store.dispatch(
            userLeftArticleFeedback({
                reason: option?.value,
                comment,
                thumbsDown,
            }),
        );
    }
}
