import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { courseSliderChanged, setCourseSlider, setSelectedPathCourseId } from './course-slider.actions';

@Injectable()
export class CourseSliderEffects {
    private readonly actions$ = inject(Actions);
    setSelectedPathSliderCourseId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setCourseSlider, courseSliderChanged),
            map(({ courseId }) => setSelectedPathCourseId({ courseId })),
        );
    });

    trackCourseSliderChanged$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(courseSliderChanged),
            map(({ courseId, courseTitle }) => {
                return trackEvent({
                    eventName: '[Course] Course Slider Swiped',
                    eventProperties: { courseId, courseTitle },
                });
            }),
        );
    });
}
