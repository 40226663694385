import { NgModule } from '@angular/core';
import { ZigzagFrontendDataAccessTimeModule } from '@frontend/data-access/time';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { DismissibleInfoEffects } from './store/dismissible-info.effects';
import { dismissibleInfoFeature } from './store/dismissible-info.reducer';

@NgModule({
    imports: [ZigzagFrontendDataAccessTimeModule],
    providers: [provideState(dismissibleInfoFeature), provideEffects([DismissibleInfoEffects])],
})
export class ZigzagFrontendFeatureDismissibleInfoModule {}
