@if (vm$ | async; as vm) {
    @if (vm.showTile) {
        <ion-card
            class="background-zig arrival-tile tile"
            color="charly"
            (click)="continueOnboarding(vm.daysUntilArrival)"
            data-test="arrival-tile-btn"
        >
            @if (vm.daysUntilArrival > 0) {
                <div class="arrival-tile__card-text">
                    <ion-card-title class="arrival-tile__card-title">
                        <h1 class="arrival-tile__countdown xxl" data-test="arrival-tile-countdown">
                            {{ vm.daysUntilArrival }}
                        </h1>
                        <div data-test="arrival-tile-message">
                            @if (vm.daysUntilArrival === 1) {
                                <h3>
                                    Day until
                                    {{ '[NAME]' | textReplacement }} comes home!
                                </h3>
                            } @else {
                                <h3>
                                    Days until
                                    {{ '[NAME]' | textReplacement }} comes home!
                                </h3>
                            }
                        </div>
                    </ion-card-title>
                    <ion-card-content class="arrival-tile__content">
                        <ion-button class="arrival-tile__has-arrived-btn plain-text s" color="max" expand="block"
                            >{{ '[NAME_C]' | textReplacement }} has arrived!
                        </ion-button>
                    </ion-card-content>
                </div>
                <img
                    src="/assets/images/charly/charly-wants-belly-rubs.png"
                    class="arrival-tile__image"
                    alt="young pup"
                />
            } @else {
                <div class="arrival-tile__card-text">
                    <ion-card-title>
                        <h3 data-test="arrival-tile-message">
                            {{ 'Is [NAME] still not home?' | textReplacement }}
                        </h3>
                    </ion-card-title>
                    <ion-card-content class="arrival-tile__content">
                        <ion-button class="arrival-tile__has-arrived-btn plain-text s" color="max" expand="block"
                            >{{ '[NAME_C]' | textReplacement }} has arrived!
                        </ion-button>
                    </ion-card-content>
                </div>
                <img
                    src="/assets/images/buddy/buddy-runs-towards-you.png"
                    class="arrival-tile__image"
                    alt="enthusiastic pup"
                />
            }
        </ion-card>
    }
}
