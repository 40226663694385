import { Injectable, inject } from '@angular/core';
import { deleteAccountSuccess } from '@frontend/data-access/user/account';
import { logout } from '@frontend/data-access/user/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { changeAppReadyStatus } from '../../app-component/app-component.actions';
import { AppReadyStatus } from '../../app-component/app-ready-status.model';
import { routeTo } from '@frontend/data-access/router';

@Injectable()
export class DeleteAccountEffects {
    private readonly actions$ = inject(Actions);
    deleteAccountSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteAccountSuccess),
            switchMap(() => [
                logout(),
                trackEvent({ eventName: 'delete account success' }),
                changeAppReadyStatus({ status: AppReadyStatus.initial }),
                routeTo({ commands: ['logout'] }),
            ]),
        );
    });
}
