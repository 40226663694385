import { Routes } from '@angular/router';
import { OnboardingRoutes } from './onboarding-routing.model';
import { OnboardingLegacyFinalizePage } from './legacy/pages/finalize/finalize-legacy.page';
import { OnboardingLegacyQuestionAgeLegacyPage } from './legacy/pages/question-age/question-age-legacy.page';
import { OnboardingLegacyQuestionArrivalPage } from './legacy/pages/question-arrival/question-arrival-legacy.page';
import { OnboardingQuestionBreedLegacyPage } from './legacy/pages/question-breed/question-breed-legacy.page';
import { OnboardingQuestionFirstDayAtHomeLegacyPage } from './legacy/pages/question-first-day-at-home/question-first-day-at-home-legacy.page';
import { OnboardingQuestionGenderLegacyPage } from './legacy/pages/question-gender/question-gender-legacy.page';
import { OnboardingQuestionHasArrivedLegacyPage } from './legacy/pages/question-has-arrived/question-has-arrived-legacy.page';
import { OnboardingQuestionNameLegacyPage } from './legacy/pages/question-name/question-name-legacy.page';
import { OnboardingQuestionNotificationsLegacyPage } from './legacy/pages/question-notifications/question-notifications-legacy.page';
import { OnboardingQuestionOwnerNameLegacyPage } from './legacy/pages/question-owner-name/question-owner-name-legacy.page';
import { OnboardingQuestionPurinaOptInLegacyPage } from './legacy/pages/question-purina-opt-in/question-purina-opt-in-legacy.page';
import { OnboardingQuestionRescueLegacyPage } from './legacy/pages/question-rescue/question-rescue-legacy.page';
import { OnboardingQuestionSpecialNeedsLegacyPage } from './legacy/pages/question-special-needs/question-special-needs-legacy.page';
import { breedResolver } from './resolvers/breed.resolver';
import { OnboardingQuestionNamePage } from './pages/question-name/question-name.page';
import { OnboardingQuestionAgePage } from './pages/question-age/question-age.page';
import { OnboardingQuestionArrivalPage } from './pages/question-arrival/question-arrival.page';
import { OnboardingQuestionBreedPage } from './pages/question-breed/question-breed.page';
import { OnboardingQuestionRescuePage } from './pages/question-rescue/question-rescue.page';
import { OnboardingQuestionHasArrivedPage } from './pages/question-has-arrived/question-has-arrived.page';
import { OnboardingQuestionFirstDayAtHomePage } from './pages/question-first-day-at-home/question-first-day-at-home.page';
import { OnboardingQuestionGenderPage } from './pages/question-gender/question-gender.page';
import { OnboardingQuestionOwnerNamePage } from './pages/question-owner-name/question-owner-name.page';
import { OnboardingQuestionNeedsPage } from './pages/question-needs/question-needs.page';
import { OnboardingQuestionNotificationsPage } from './pages/question-notifications/question-notifications.page';
import { OnboardingQuestionPurinaOptInPage } from './pages/question-purina-opt-in/question-purina-opt-in.page';

export const onboardingRoutes: Routes = [
    {
        path: '',
        redirectTo: OnboardingRoutes.NAME,
        pathMatch: 'full',
    },
    {
        path: OnboardingRoutes.AGE,
        component: OnboardingQuestionAgePage,
    },
    {
        path: OnboardingRoutes.ARRIVAL,
        component: OnboardingQuestionArrivalPage,
    },
    {
        path: OnboardingRoutes.FIRST_DAY_AT_HOME,
        component: OnboardingQuestionFirstDayAtHomePage,
    },
    {
        path: OnboardingRoutes.GENDER,
        component: OnboardingQuestionGenderPage,
    },
    {
        path: OnboardingRoutes.HAS_ARRIVED,
        component: OnboardingQuestionHasArrivedPage,
    },
    {
        path: OnboardingRoutes.BREED,
        component: OnboardingQuestionBreedPage,
    },
    {
        path: OnboardingRoutes.NAME,
        component: OnboardingQuestionNamePage,
    },
    {
        path: OnboardingRoutes.NEEDS,
        component: OnboardingQuestionNeedsPage,
    },
    {
        path: OnboardingRoutes.NOTIFICATIONS,
        component: OnboardingQuestionNotificationsPage,
    },
    {
        path: OnboardingRoutes.OWNER_NAME,
        component: OnboardingQuestionOwnerNamePage,
    },
    {
        path: OnboardingRoutes.PURINA_OPT_IN,
        component: OnboardingQuestionPurinaOptInPage,
    },
    {
        path: OnboardingRoutes.RESCUE,
        component: OnboardingQuestionRescuePage,
    },
    {
        path: 'legacy',
        children: [
            {
                path: '',
                redirectTo: OnboardingRoutes.NAME,
                pathMatch: 'full',
            },
            {
                path: OnboardingRoutes.NAME,
                component: OnboardingQuestionNameLegacyPage,
            },
            {
                path: OnboardingRoutes.RESCUE,
                component: OnboardingQuestionRescueLegacyPage,
            },
            {
                path: OnboardingRoutes.AGE,
                component: OnboardingLegacyQuestionAgeLegacyPage,
            },
            {
                path: OnboardingRoutes.BREED,
                component: OnboardingQuestionBreedLegacyPage,
                resolve: { data: breedResolver },
            },
            {
                path: OnboardingRoutes.GENDER,
                component: OnboardingQuestionGenderLegacyPage,
            },
            {
                path: OnboardingRoutes.HAS_ARRIVED,
                component: OnboardingQuestionHasArrivedLegacyPage,
            },
            {
                path: OnboardingRoutes.NAME,
                component: OnboardingQuestionNameLegacyPage,
            },
            {
                path: OnboardingRoutes.NOTIFICATIONS,
                component: OnboardingQuestionNotificationsLegacyPage,
            },
            {
                path: OnboardingRoutes.NEEDS,
                component: OnboardingQuestionSpecialNeedsLegacyPage,
            },
            {
                path: OnboardingRoutes.OWNER_NAME,
                component: OnboardingQuestionOwnerNameLegacyPage,
            },
            {
                path: OnboardingRoutes.ARRIVAL,
                component: OnboardingLegacyQuestionArrivalPage,
            },
            {
                path: OnboardingRoutes.FIRST_DAY_AT_HOME,
                component: OnboardingQuestionFirstDayAtHomeLegacyPage,
            },
            {
                path: OnboardingRoutes.PURINA_OPT_IN,
                component: OnboardingQuestionPurinaOptInLegacyPage,
            },
            {
                path: OnboardingRoutes.FINALIZE,
                component: OnboardingLegacyFinalizePage,
            },
        ],
    },
];
