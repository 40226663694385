import { createFeature, createReducer, on } from '@ngrx/store';
import { setSelectedPathCourseId } from '../../course-slider/course-slider.actions';
import { calculateCoursePathCoordinatesSuccess } from './course-path.actions';
import { Coordinates } from './models/course-path.model';

export interface StepCoordinates {
    id: number;
    isPractice: boolean;
    coordinates: Coordinates;
}

export interface SplitCoordinates {
    splitNumber: number;
    coordinates: Coordinates;
}

export interface CourseCoordinates {
    id: string;
    stepCoordinates: StepCoordinates[];
    splitCoordinates: SplitCoordinates[];
}

export interface CoursePathState {
    selectedCourseId: string | undefined;
    coursesCoordinates: CourseCoordinates[];
}

export const initialState: CoursePathState = {
    selectedCourseId: undefined,
    coursesCoordinates: [],
};

export const coursePathFeature = createFeature({
    name: 'coursePath',
    reducer: createReducer(
        initialState,
        on(
            setSelectedPathCourseId,
            (state, action): CoursePathState => ({
                ...state,
                selectedCourseId: action.courseId,
            }),
        ),
        on(
            calculateCoursePathCoordinatesSuccess,
            (state, action): CoursePathState => ({
                ...state,
                coursesCoordinates: action.courses,
            }),
        ),
    ),
});
