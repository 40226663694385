import { AsyncPipe, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs, Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectTabsVm } from './tabs.selectors';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-tabs',
    templateUrl: 'tabs.page.html',
    styleUrls: ['tabs.page.scss'],
    standalone: true,
    imports: [IonTabs, AnalyticsTrackClickDirective, AsyncPipe, IonTabBar, IonTabButton, IonIcon, IonLabel],
})
export class TabsPage {
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);
    private readonly router = inject(Router);
    private readonly location = inject(Location);
    public vm$ = this.store.select(selectTabsVm);

    constructor() {
        this.platform.backButton.subscribeWithPriority(10, () => {
            if (!this.router.url.startsWith('/onboarding') && !(this.router.url === '/main/today')) {
                this.location.back();
            }
        });
    }
}
