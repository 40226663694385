import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, first, map } from 'rxjs/operators';

export const debouncedValidator =
    (validator: ValidatorFn, delay = 800): ((ctr: AbstractControl) => Observable<ValidationErrors | null>) =>
    (ctr: AbstractControl): Observable<ValidationErrors | null> =>
        ctr.valueChanges.pipe(
            debounceTime(delay),
            map(() => validator(ctr)),
            first(),
        );
