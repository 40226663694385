import { inject, Injectable } from '@angular/core';
import { getPlatform } from '@frontend/utility/native-plugins';
import { selectCurrentIp } from '@frontend/data-access/geo-location';
import {
    accountFeature,
    createAccountSuccess,
    getAccountSuccess,
    selectProfileBreedInfo,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { configCatFeature, getConfigCatFeatureFlagsSuccess } from '@frontend/data-access/user/config-cat';
import { createStepProgressSuccess, selectCompletedStepProgress } from '@frontend/data-access/user/progress';
import { Platform } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, merge, zip } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import {
    mapFeatureFlagsForIterable,
    mapTrackEventToInternalIterablePostEventCommand,
    mapUserToInternalIterableUpsertUserCommand,
    PushNotificationService,
    setPushNotifications,
    trackIterableEvent,
    upsertIterableUser,
    upsertIterableUserSuccess,
} from '@frontend/data-access/push-notification';

@Injectable()
export class PushNotificationsEffects {
    private readonly actions$ = inject(Actions);
    private readonly pushNotificationService = inject(PushNotificationService);
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);
    checkIfAppropriateUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setPushNotifications),
            filter(() => !this.pushNotificationService.pushNotificationsEnabled()),
            map(() => setPushNotifications()),
        );
    });

    displayPushNotificationPrompt$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setPushNotifications),
                tap(() => this.pushNotificationService.registerIterablePushNotifications()),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trackIterableEvent$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                ofType(trackEvent), // trigger
                filter(({ eventName }) => eventName !== 'App error'),
            ),
            merge(
                // conditions
                zip([
                    // user finished onboarding and exists in iterable
                    this.actions$.pipe(ofType(createAccountSuccess)),
                    this.actions$.pipe(ofType(upsertIterableUserSuccess)),
                ]),
                this.actions$.pipe(ofType(getAccountSuccess)), // user logged in
            ).pipe(take(1)),
        ]).pipe(
            concatLatestFrom(() => {
                return this.store.select(accountFeature.selectId);
            }),
            filter(([, profileId]) => {
                return !!profileId;
            }),
            map(([[{ eventName, eventProperties }], profileId]) => {
                return trackIterableEvent({
                    command: mapTrackEventToInternalIterablePostEventCommand(profileId!, eventName, eventProperties),
                });
            }),
        );
    });

    upsertIterableUser$ = createEffect(() => {
        return merge(
            this.actions$.pipe(
                ofType(
                    createAccountSuccess,
                    updateAccountSuccess,
                    createStepProgressSuccess,
                    getConfigCatFeatureFlagsSuccess,
                ),
            ), // step progress is there specifically to upsert the complete progress field in Iterable for Ed CRM
            combineLatest([
                this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess)),
                this.actions$.pipe(ofType(getAccountSuccess), take(1)),
            ]),
        ).pipe(
            concatLatestFrom(() => [
                this.store.select(accountFeature.selectProfile),
                this.store.select(accountFeature.selectId),
                this.store.select(selectProfileBreedInfo),
                this.store.select(selectCurrentIp),
                this.store.select(selectCompletedStepProgress),
                this.store.select(configCatFeature.selectConfigCatFlags),
            ]),
            filter(([_, profile, profileId]) => !!profile && !!profileId),
            map(([_, profile, profileId, breedInfo, ipAddress, completedSteps, featureFlags]) => {
                return upsertIterableUser({
                    user: mapUserToInternalIterableUpsertUserCommand(
                        profile!,
                        profileId!,
                        getPlatform(this.platform),
                        mapFeatureFlagsForIterable(featureFlags),
                        ipAddress,
                        breedInfo,
                        completedSteps.length,
                    ),
                });
            }),
        );
    });
}
