import { inject, Injectable } from '@angular/core';
import { capacitorPushNotificationOpened } from '@frontend/data-access/capacitor';
import {
    coursesFeature,
    dailyBitesFeature,
    getDailyBitesSuccess,
    getDevelopmentalWindowsSuccess,
    getStepsSuccess,
} from '@frontend/data-access/contentful';
import { routeTo } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, zip } from 'rxjs';
import { concatMap, filter, map, take } from 'rxjs/operators';
import { showDailyBiteModal } from '../../daily-bites/daily-bite-modal/daily-bite-modal.actions';
import { SHOW_PAYMENT_MODAL_TRIGGERS } from '../payment/constants/payment.constants';
import { showIosDiscountPaymentModal, showPaymentModal } from '../payment/store/payment.actions';
import { routeToStep, routeToStepUsingContentId } from '../router/router.actions';
import { routeToNextStep } from './deeplink.actions';
import { selectNextStepForDeepLink } from './deeplink.selectors';
import { setSelectedPathCourseId } from '../../courses/course-slider/course-slider.actions';
import { getConfigCatFeatureFlagsSuccess } from '@frontend/data-access/user/config-cat';

@Injectable()
export class DeeplinkEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    handleDeeplink$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(capacitorPushNotificationOpened)),
            this.actions$.pipe(ofType(getDevelopmentalWindowsSuccess), take(1)),
            this.actions$.pipe(ofType(getStepsSuccess), take(1)),
            this.actions$.pipe(ofType(getDailyBitesSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
        ]).pipe(
            concatLatestFrom(() => [
                this.store.select(dailyBitesFeature.selectDailyBitesState),
                this.store.select(coursesFeature.selectCoursesState),
            ]),
            concatMap(([[notificationAction], dailyBiteState, courseState]) => {
                const splitLink = notificationAction.urlToOpen.split('://')[1].split('/');

                if (splitLink.length === 1) {
                    if (splitLink[0] === 'nextStep') {
                        return [routeToNextStep()];
                    } else if (splitLink[0] === 'paywall') {
                        return [
                            routeTo({ commands: ['main', 'today'] }),
                            showPaymentModal({ trigger: SHOW_PAYMENT_MODAL_TRIGGERS.PAYWALL_DEEPLINK }),
                        ];
                    } else if (splitLink[0] === 'iosDiscountPaywall') {
                        return [
                            routeTo({ commands: ['main', 'today'] }),
                            showIosDiscountPaymentModal({
                                trigger: SHOW_PAYMENT_MODAL_TRIGGERS.PAYWALL_DEEPLINK_IOS_DISCOUNT,
                            }),
                        ];
                    } else if (splitLink[0] === 'limitedOfferPaywall') {
                        return [
                            routeTo({ commands: ['main', 'today'] }),
                            showIosDiscountPaymentModal({
                                trigger: SHOW_PAYMENT_MODAL_TRIGGERS.LIMITED_OFFER_PAYWALL_DEEPLINK,
                            }),
                        ];
                    } else {
                        return [routeTo({ commands: ['main', 'today'] })];
                    }
                } else if (splitLink.length === 2) {
                    if (splitLink[0] === 'lesson' || splitLink[0] === 'article') {
                        // TODO - deprecated (remove me after Ed has switched everyone over)
                        return [
                            routeToStepUsingContentId({
                                baseUrl: ['main', 'courses'],
                                contentId: +splitLink[1],
                                from: 'deeplink',
                            }),
                        ];
                    } else if (splitLink[0] === 'step') {
                        return [
                            routeToStep({
                                baseUrl: ['main', 'courses'],
                                stepId: splitLink[1],
                                from: 'deeplink',
                            }),
                        ];
                    } else if (splitLink[0] === 'dailyBite') {
                        const dailyBiteToOpen = dailyBiteState.dailyBites.find(
                            (dailyBite) => dailyBite.id === parseInt(splitLink[1]),
                        );

                        if (dailyBiteToOpen) {
                            return [
                                routeTo({ commands: ['main', 'today'] }),
                                showDailyBiteModal({ dailyBite: dailyBiteToOpen }),
                            ];
                        }
                    } else if (splitLink[0] === 'course') {
                        const courseToOpen = courseState.courses.find((course) => course.id === splitLink[1]);

                        if (courseToOpen) {
                            return [
                                routeTo({ commands: ['main', 'courses'] }),
                                setSelectedPathCourseId({ courseId: courseToOpen.id }),
                            ];
                        }

                        return [routeTo({ commands: ['main', 'courses'] })];
                    } else {
                        return [routeTo({ commands: splitLink })];
                    }
                }
                return [routeTo({ commands: splitLink })];
            }),
        );
    });

    routeToNextStep$ = createEffect(() => {
        return zip([this.actions$.pipe(ofType(routeToNextStep))]).pipe(
            concatLatestFrom(() => {
                return this.store.select(selectNextStepForDeepLink);
            }),
            filter(([_, nextStep]) => !!nextStep?.id),
            map(([_, nextStep]) => {
                return routeToStep({
                    baseUrl: ['main', 'courses'],
                    stepId: nextStep!.id,
                    from: 'courses',
                });
            }),
        );
    });
}
