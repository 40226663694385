@if (topic) {
    @if (showPinButton) {
        <button
            class="{{ topic.color }} as-div topic-card-pin-button"
            appTrackClick
            [attr.data-test]="
                topic.pinned
                    ? 'unpin-topic-' + topic.entityId + '-from-library-page'
                    : 'pin-topic-' + topic.entityId + '-from-library-page'
            "
            [identifier]="
                topic.pinned
                    ? 'unpin-topic-' + topic.entityId + '-from-library-page'
                    : 'pin-topic-' + topic.entityId + '-from-library-page'
            "
            (click)="pinTopic(topic.entityId, topic.pinned)"
        >
            @if (isLoading) {
                <span>
                    <i
                        class="fa-thin fa-circle-plus fa-2xl topic-card-pin-button__icon disabled"
                        [attr.data-test]="'topic-card-' + topic.entityId + '-unpinned-icon'"
                    ></i>
                </span>
            } @else {
                @if (topic.pinned) {
                    <span>
                        <i
                            class="fa-solid fa-circle-check topic-card-pin-button__icon fa-2xl"
                            [attr.data-test]="'topic-card-' + topic.entityId + '-pinned-icon'"
                        ></i>
                    </span>
                } @else {
                    <span>
                        <i
                            [ngClass]="{ 'animated-icon': !hasPinnedTopics }"
                            class="fa-thin fa-circle-plus topic-card-pin-button__icon fa-2xl"
                            [attr.data-test]="'topic-card-' + topic.entityId + '-unpinned-icon'"
                        ></i>
                    </span>
                }
            }
        </button>
    }

    <ion-card
        class="topic-card {{ topic.color }}"
        appTrackClick
        identifier="{{ clickEventIdentifier }}"
        [attr.data-test]="'topic-card-' + topic.entityId"
        [routerLink]="routerLinkPath"
    >
        <div class="topic-card__square-tile">
            <div class="topic-card__square-tile-text">
                <h3>{{ topic.title }}</h3>
            </div>
            <div class="topic-card__topic-image">
                <img src="{{ topic.imageUrl }}" alt="{{ topic.title }}" />
            </div>
            @if (showProgressBar) {
                <div class="topic-card__progress-bar-box">
                    <ion-progress-bar
                        class="progress-bar {{ topic.color }}"
                        [value]="topic.progress / 100"
                    ></ion-progress-bar>
                    <span class="topic-card__progress-bar-text">{{ topic.progress }}%</span>
                </div>
            }
        </div>
    </ion-card>
}
