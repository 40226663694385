import { Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { onboardingNavigateBack, onboardingQuestionSubmittedOwnerName } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-owner-name',
    templateUrl: 'question-owner-name.page.html',
    styleUrls: ['../onboarding.scss', 'question-owner-name.page.scss'],
    imports: [
        IonButton,
        IonSpinner,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        IonInput,
        IonItem,
        FormsModule,
        ReactiveFormsModule,
        ProgressHeaderComponent,
        AnalyticsTrackClickDirective,
    ],
})
export class OnboardingQuestionOwnerNamePage {
    private readonly store = inject(Store);

    protected readonly Color = Color;
    protected readonly OWNER_NAME_MAX_LENGTH = 32 as const;

    public isLoading = this.store.selectSignal(selectIsLoading);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public ownerName = this.store.selectSignal(accountFeature.selectOwnerName);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);

    public ownerNameForm = new FormGroup({
        ownerName: new FormControl('', {
            validators: [Validators.required, Validators.maxLength(this.OWNER_NAME_MAX_LENGTH)],
            nonNullable: true,
        }),
    });

    constructor() {
        effect(() => {
            if (this.ownerName()) {
                this.ownerNameForm.setValue({
                    ownerName: this.ownerName()!,
                });
            }
        });
    }

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onSubmit(): void {
        if (this.ownerNameForm.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedOwnerName({
                ownerName: this.ownerNameForm.controls.ownerName.value,
            }),
        );
    }
}
