import { SkipStepOptions } from '../../store/progress/step-progress/step-progress.model';

export const LESSON_SKIP_NOTIFICATION_MODAL_ID = 'lesson-skip-notification-modal';

export const SKIP_STEP_OPTIONS: SkipStepOptions = {
    reasons: [
        { id: 'can-already-do-this', text: '[NAME] can already do this' },
        { id: 'too-difficult', text: "It's too difficult" },
        { id: 'no-time', text: 'I want to do this lesson later' },
        { id: 'not-relevant', text: 'This lesson is not relevant' },
    ],
};
