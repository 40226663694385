import { NgModule } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { OnboardingEffects } from './data-access/onboarding.effects';
import { onboardingFeature } from './data-access/onboarding.reducer';

@NgModule({
    providers: [provideState(onboardingFeature), provideEffects([OnboardingEffects])],
})
export class ZigzagFeatureOnboardingModule {}
