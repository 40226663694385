import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { basicToggleSelected, premiumToggleSelected } from '../../../store/payment/store/payment.actions';
import { tieredPricingSelectionFeature } from '../../../store/payment/store/tiered-pricing-selection.reducer';

@Component({
    standalone: true,
    selector: 'app-tiered-pricing-comparison-toggle',
    templateUrl: './tiered-pricing-comparison-toggle.component.html',
    styleUrl: './tiered-pricing-comparison-toggle.component.scss',
    imports: [IonicModule, NgClass],
})
export class TieredPricingComparisonToggleComponent {
    public readonly store = inject(Store);

    isPremiumSelected = this.store.selectSignal(tieredPricingSelectionFeature.selectIsPremiumToggleSelected);

    basicClicked() {
        this.store.dispatch(basicToggleSelected());
    }

    premiumClicked() {
        this.store.dispatch(premiumToggleSelected());
    }
}
