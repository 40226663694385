import { Article, Lesson } from '@frontend/data-access/contentful';
import { StepType } from '@shared/content-api-interface';
import { Step } from '../step/step.model';

export const mapStepToSearchInput = (step: Step) => {
    let content: string;

    if (step.content.contentType === StepType.LESSON) {
        const lesson = step.content as Lesson;
        content = [lesson.introduction, ...lesson.beforeYouStart, ...lesson.steps, ...lesson.tips].join(' ').trim();
    } else {
        const article = step.content as Article;
        content = article.content ?? '';
    }

    return {
        id: step.id,
        title: step.content.title,
        topic: step.topic.title,
        content: content,
    };
};
