import { Location, NgClass, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    ElementRef,
    ViewChild,
    inject,
} from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { SwiperDirective } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonHeader } from '@ionic/angular/standalone';
import { SwiperContainer } from 'swiper/swiper-element';
import {
    aiChatBackgroundTransform,
    chatIntroModalSliderConfig,
    chatIntroModalSlides,
} from './ai-chat-intro-modal.constants';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-ai-chat-intro-modal',
    templateUrl: './ai-chat-intro-modal.component.html',
    styleUrls: ['./ai-chat-intro-modal.component.scss'],
    standalone: true,
    imports: [NgClass, NgStyle, SwiperDirective, TextReplacementPipe, IonHeader, IonContent, IonFooter, IonButton],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AiChatIntroModalComponent implements AfterViewInit {
    private readonly modalService = inject(ModalService);
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    private readonly location = inject(Location);
    @ViewChild('swiper') swiper: ElementRef<SwiperContainer> | undefined;

    protected readonly Color = Color;

    config = chatIntroModalSliderConfig;
    slides = chatIntroModalSlides;
    backgroundTransform = aiChatBackgroundTransform;

    /**
     * This is a workaround to fix the issue with the swiper not being initialized properly
     *
     * This prevents the error: Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError being thrown
     */
    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    nextSlide() {
        if (this.swiper?.nativeElement?.swiper.isEnd) {
            this.closeModal();
            return;
        }

        this.swiper?.nativeElement?.swiper.slideNext();
    }

    previousSlide() {
        this.swiper?.nativeElement?.swiper.slidePrev();
    }

    positionActivePaginationCircle(): string {
        const activeIndex = this.swiper?.nativeElement?.swiper.activeIndex ?? 0;
        return `${activeIndex * 20}px`;
    }

    positionPaginationCircle(index: number): string {
        const activeIndex = this.swiper?.nativeElement?.swiper.activeIndex ?? 0;

        const basePosition = index * 20;
        const position = activeIndex > index ? basePosition : basePosition + 34;
        return `${position}px`;
    }

    paginationCircleClick(index: number) {
        const activeIndex = this.swiper?.nativeElement?.swiper?.activeIndex ?? 0;
        const slideClicked = activeIndex > index ? index : index + 1;
        this.swiper?.nativeElement?.swiper.slideTo(slideClicked);
    }

    paginationWidth(slides: number): string {
        return `${(slides * 2 - 1) * 10 + 14}px`;
    }

    onSlideChange() {
        this.changeDetectorRef.detectChanges();
    }

    closeModal() {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    closeModalAndRouteToPrevious() {
        void this.modalService.dismiss({
            dismissed: true,
        });
        void this.location.back();
    }
}
