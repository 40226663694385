import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { HouseholdEffects } from './store/household.effects';
import { householdFeature } from './store/household.reducer';

@NgModule({
    providers: [provideEffects(HouseholdEffects), provideState(householdFeature)],
})
export class ZigzagFrontendDataAccessHouseholdModule {}
