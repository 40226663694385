import { PACKAGE_TYPE } from '@awesome-cordova-plugins/purchases/ngx';

export interface Entitlement {
    identifier: string;
    isActive: boolean;
    periodType: SubscriptionPeriodType;
    expirationDate?: Date;
}

export interface SubscriptionProduct {
    identifier: string;
    description: string;
    title: string;
    price: number;
    priceWithUnit: string;
    pricePerMonth: number;
    currencySymbol: string;
    introPricePeriod?: string;
    discount?: ProductDiscount;
}

export interface PurchaseProduct {
    identifier: string;
    priceWithUnit: string;
}

export interface ProductDiscount {
    identifier: string;
    price: number;
    priceWithUnit: string;
    pricePerMonth: number | undefined;
    discountPercentage: number;
}

export interface SubscriptionPackage {
    identifier: string;
    packageType: PACKAGE_TYPE;
    product: SubscriptionProduct;
    description: string;
}

export interface BaseSubscriptionOffering {
    identifier: string;
    availablePackages: SubscriptionPackage[];
}

export interface SubscriptionOffering extends BaseSubscriptionOffering {
    isDefault: boolean;
}

export interface PromotionalEntitlement {
    userId: string | undefined;
    entitlementId: string;
    freeAccessLengthInDays?: number;
}

export enum SubscriptionPeriodType {
    NORMAL = 'NORMAL',
    INTRO = 'INTRO',
    TRIAL = 'TRIAL',
}

export const CONTENT = 'content';
