import { AgeAlternativeValues } from '@shared/user-domain';
import { Partner, Profile } from '../models/profile.model';
import { InternalPartnerDto, InternalUserDto } from '@shared/user-api-interface';

export const mapUserDtoToProfile = (userDto: InternalUserDto): Profile => ({
    name: userDto.name ?? '',
    dateOfBirth: mapAgeAlternativeToDateOfBirth(userDto.dateOfBirth, userDto.dateOfOnboarding),
    breedId: userDto.breedId,
    gender: userDto.gender,
    ownerName: userDto.ownerName ?? '',
    mail: userDto.mail ?? '',
    notifications: false, // TODO: NBSon - remove from backend, since this is not used
    dateOfOnboarding: userDto.dateOfOnboarding ? new Date(userDto.dateOfOnboarding) : undefined,
    privacyPolicyAccepted: true,
    dateOfArrival: userDto.dateOfArrival ? new Date(userDto.dateOfArrival) : undefined,
    hasArrived: userDto.hasArrived,
    userToken: userDto.userToken ?? '',
    partners: userDto.partners && userDto.partners.length > 0 ? mapPartnerDtoToPartner(userDto.partners) : [],
    countryCode: userDto.countryCode ?? '',
    firstDayAtHomeWhenOnboarding: userDto.firstDayAtHomeWhenOnboarding,
    isRescuePup: userDto.isRescuePup,
    purinaMarketingAccepted: userDto.purinaMarketingAccepted,
    isApproximateDateOfBirth: userDto.isApproximateDateOfBirth,
});

export function mapPartnerDtoToPartner(partners: InternalPartnerDto[]): Partner[] {
    return partners.map((partner) => {
        return {
            id: partner.id,
            name: partner.name ?? '',
            freeAccessLengthInDays: partner.freeAccessLengthInDays ?? 365,
        };
    });
}

export const mapAgeAlternativeToDateOfBirth = (
    dateOfBirth: string | AgeAlternativeValues | undefined,
    referenceDate: Date | string | undefined,
): Date | undefined => {
    let reference = new Date();

    if (typeof referenceDate === 'string') {
        reference = new Date(referenceDate);
    } else if (typeof referenceDate === 'object') {
        reference = referenceDate;
    }
    if (dateOfBirth === AgeAlternativeValues.OLDER) {
        return new Date(reference.getTime() - 1000 * 60 * 60 * 24 * 7 * 17); // older than 15 weeks but in the past this has been hard coded as 17 so leaving functionality as is
    } else if (dateOfBirth === AgeAlternativeValues.NOT_BORN) {
        return undefined;
    }

    return dateOfBirth ? new Date(dateOfBirth) : undefined;
};
