import { SubscriptionOffering } from '../subscription.model';
import { SubscriptionPackageMock } from './subscription-package.mock';

export class SubscriptionOfferingMock {
    private defaultValue: SubscriptionOffering = {
        identifier: '',
        availablePackages: [new SubscriptionPackageMock().value],
        isDefault: false,
    };

    constructor(overrides?: Partial<SubscriptionOffering>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SubscriptionOffering {
        return this.defaultValue;
    }
}
