import { inject, Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { openActionSheet } from './action-sheet.actions';

@Injectable()
export class ActionSheetEffects {
    private readonly actions$ = inject(Actions);
    private readonly actionSheetController = inject(ActionSheetController);

    displayActionSheet$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openActionSheet),
                concatMap(({ buttons }) =>
                    from(
                        this.actionSheetController.create({
                            cssClass: 'action-sheet',
                            buttons,
                        }),
                    ).pipe(
                        switchMap((actionSheet) => {
                            return actionSheet.present();
                        }),
                    ),
                ),
            );
        },
        { dispatch: false },
    );
}
