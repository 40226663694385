import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../../../store/payment/store/payment.selectors';
import { selectSelectedCourseColor } from '../store/course-path.selectors';

export const selectCoursePathStepComponentVm = createSelector(
    selectContentUnlocked,
    selectSelectedCourseColor,
    (unlocked, selectedCourseColor) => ({
        unlocked,
        selectedCourseColor,
    }),
);
