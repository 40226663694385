@if (foodPortion) {
    <div class="page-padding-sides">
        <div class="title-block">
            <h2>{{ 'We recommend feeding [NAME]...' | textReplacement }}</h2>
        </div>
        <div class="result-block">
            <div class="top-image">
                <img
                    class="food-image"
                    alt="food-bowl"
                    [src]="'assets/images/illustrations/food-calculator/food-bowl.png'"
                />
                <div class="portion" data-test="food-portion-amount">
                    @if (loading) {
                        <img
                            [src]="'assets/images/illustrations/food-calculator/bone.png'"
                            class="spinner-bone rotate"
                            alt="spinning bone"
                        />
                    } @else {
                        <h1 class="food-calculator-result">{{ foodPortion.amount }}g</h1>
                    }
                </div>
            </div>
            <div class="units-info">
                <h2>...across 3 meals a day</h2>
                <a class="text-link" data-test="how-is-this-calculated" (click)="howIsThisCalculated.next()">
                    Why can this be different from my packaging?
                </a>
            </div>
        </div>
    </div>
} @else {
    <div class="no-result-block page-padding-sides" data-test="no-result-block">
        <img
            [src]="'assets/images/buddy/buddy-looks-longingly-at-steak.png'"
            class="no-result-image"
            alt="pup with treat"
        />
        <h1>
            {{ "Fill in [NAME_C]'s weight & select the food you give [HIM/HER]" | textReplacement }}
        </h1>
    </div>
}
