import { createSelector } from '@ngrx/store';
import { selectIsUsUser } from '@frontend/data-access/user/account';
import { selectTopicsWithProgress } from '../../store/topic/topic-with-progress.selectors';
import { LoadingState } from '@frontend/data-access/shared-models';
import { pinnedTopicFeature } from '@frontend/data-access/user/pinned-topics';

export const selectLibraryTopicOverviewVm = createSelector(
    selectTopicsWithProgress,
    selectIsUsUser,
    pinnedTopicFeature.selectLoadingState,
    (topics, isUsUser, loading) => {
        return {
            topics,
            isUsUser,
            arePinnedTopicsLoading: loading === LoadingState.LOADING,
            hasPinnedTopics: topics.some((t) => t.pinned),
        };
    },
);
