import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { RichContentPipe } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { isColorContrastLight } from '@shared/utils/typescript';
import { closeModal } from '../../store/modal/modal.actions';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { setCourseSlider } from '../course-slider/course-slider.actions';
import { CourseWithSteps } from '../models/courses.models';
import { selectCourseInformationModalVm } from './course-information-modal.selectors';

@Component({
    selector: 'app-course-information-modal',
    templateUrl: './course-information-modal.component.html',
    styleUrl: './course-information-modal.component.scss',
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        NgStyle,
        RichContentPipe,
        TextReplacementPipe,
    ],
})
export class CourseInformationModalComponent {
    private readonly store = inject(Store);

    protected readonly CourseType = CourseType;

    public course = input.required<CourseWithSteps>();

    public vm = this.store.selectSignal(selectCourseInformationModalVm);

    public readonly squiggleDarkSvg =
        'url(/assets/images/squiggles/course-information-squiggle-dark.svg) no-repeat top -50px right 0px';
    public readonly squiggleLightSvg =
        'url(/assets/images/squiggles/course-information-squiggle-light.svg) no-repeat top -50px right 0px';

    public dynamicallyAppliedStyles = computed(() => {
        return {
            background: isColorContrastLight(this.course().color) ? this.squiggleLightSvg : this.squiggleDarkSvg,
        };
    });

    public goToCourse(): void {
        this.store.dispatch(setCourseSlider({ courseId: this.course().id }));
        this.closeModal();
    }

    public closeModal(): void {
        this.store.dispatch(closeModal());
    }

    public openPaymentModal(): void {
        this.store.dispatch(showPaymentModal({ trigger: 'course-information-modal' }));
    }

    public onGoToCourseClicked(): void {
        return this.vm().isBasicPlan && this.course().type === CourseType.OPTIONAL
            ? this.openPaymentModal()
            : this.goToCourse();
    }
}
