import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { addCapacitorAppListeners } from './app-listeners.actions';
import { AppListenersService } from '../services/app-listeners.service';

@Injectable()
export class AppListenersEffects {
    private readonly actions$ = inject(Actions);
    private readonly capacitorAppService = inject(AppListenersService);
    addCapacitorAppListeners$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(addCapacitorAppListeners),
                tap(() => {
                    this.capacitorAppService.addAppListeners();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
