import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonTextarea } from '@ionic/angular/standalone';

@Component({
    selector: 'app-today-ai-chat-input',
    templateUrl: './today-ai-chat-input.component.html',
    styleUrls: ['./today-ai-chat-input.component.scss'],
    standalone: true,
    imports: [IonTextarea, NgClass],
})
export class TodayAiChatInputComponent {
    @ViewChild('input')
    inputRef!: IonTextarea;

    @Input()
    showSendButton = false;

    @Output()
    inputClicked = new EventEmitter<void>();
    @Output()
    submitted = new EventEmitter<string>();

    onInputClicked() {
        this.inputClicked.emit();
        void this.inputRef.setFocus();
    }

    onSubmitted() {
        if (this.inputRef.value) {
            this.submitted.emit(this.inputRef.value);
            this.clear();
        }
    }

    clear() {
        this.inputRef.value = '';
    }
}
