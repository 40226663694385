import { createSelector } from '@ngrx/store';
import { selectContentUnlocked, selectIsBasicPlan } from '../../store/payment/store/payment.selectors';
import { selectSelectedPathCourseId } from '../course-path/store/course-path.selectors';

export const selectCourseInformationModalVm = createSelector(
    selectContentUnlocked,
    selectSelectedPathCourseId,
    selectIsBasicPlan,
    (unlocked, selectedPathCourseId, isBasicPlan) => ({
        unlocked: unlocked && !isBasicPlan,
        selectedPathCourseId,
        isBasicPlan,
    }),
);
