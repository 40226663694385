<ion-input
    class="zz-input breed-selector"
    [ngClass]="{ 'breed-selector__new-onboarding': isNewOnboarding() }"
    [readonly]="true"
    [tabindex]="1"
    [placeholder]="'Search breed'"
    [value]="displayValue"
    (click)="openModal()"
>
    <i
        class="fa-regular fa-dog breed-selector__dog-icon"
        [ngClass]="{ 'breed-selector__dog-icon-white': isNewOnboarding() }"
    ></i>
</ion-input>
