import { createAction, props } from '@ngrx/store';
import { CourseSplit } from '../../../courses/models/courses.models';
import {
    CreateStepProgress,
    StepCompletedProperties,
    StepSkippedProperties,
    StepUpdateBaseProperties,
    StepUpdateEventName,
    StepUpdateEventProperties,
} from './step-progress.model';

export const triggerCreateStepProgress = createAction(
    '[Step Progress] Trigger Create Step Progress',
    props<{
        progress: CreateStepProgress[];
        correlationId?: string;
    }>(),
);

export const setStepCompleted = createAction(
    '[Step Progress] Set Step Completed',
    props<{ properties: StepCompletedProperties }>(),
);
export const setStepRead = createAction(
    '[Step Progress] Set Step Read',
    props<{ properties: StepUpdateBaseProperties }>(),
);
export const setStepSkipped = createAction(
    '[Step Progress] Set Step Skipped',
    props<{ properties: StepSkippedProperties }>(),
);
export const trackStepUpdate = createAction(
    '[Step Progress] Track Step Update',
    props<{
        eventName: StepUpdateEventName;
        eventProperties: StepUpdateEventProperties;
    }>(),
);
export const updateJumpToProgressForSelectedCourse = createAction(
    '[Step Progress] Update Jump to Progress For Selected Course',
    props<{ split: CourseSplit }>(),
);

export const userLeftArticleFeedback = createAction(
    '[Step Progress] User Article Feedback',
    props<{ thumbsDown: boolean; reason?: string; comment?: string }>(),
);
