import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { TodayCourseProgressEffects } from './store/today-course-progress.effects';
import { todayCourseProgressFeature } from './store/today-course-progress.reducer';

@NgModule({
    providers: [provideState(todayCourseProgressFeature), provideEffects([TodayCourseProgressEffects])],
})
export class ZigzagFrontendDataAccessUserTodayCourseProgressModule {}
