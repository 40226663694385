import { selectShowFoodCalculator } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { selectIsRouteChildRoute } from '../store/router/router.selectors';
import { todayPageFeature } from '../today/today.page.reducer';
import { tabButtons, TabRoute_FOOD_CALCULATOR } from './tabs.constants';
import { selectUrl } from '@frontend/data-access/router';

export const selectShouldHideTabsOnAndroid = createSelector(
    selectIsInputOpenOnAndroid,
    selectUrl,
    (isInputFocusedOnAndroid, currentUrl) => {
        const routesToHideTabOn = ['/main/food-calculator', '/main/library'];
        return isInputFocusedOnAndroid && routesToHideTabOn.includes(currentUrl);
    },
);

export const selectTabsVm = createSelector(
    selectIsRouteChildRoute,
    selectShowFoodCalculator,
    selectShouldHideTabsOnAndroid,
    todayPageFeature.selectShowAiChatView,
    (isChildRoute, showFoodCalculator, shouldHideTabsOnAndroid, showAiChatView) => {
        const mappedTabButtons = tabButtons.map((tabButton) => {
            if (tabButton.tab === TabRoute_FOOD_CALCULATOR) {
                return {
                    ...tabButton,
                    isHidden: !showFoodCalculator,
                };
            }

            return tabButton;
        });

        return {
            hideTabs: isChildRoute || shouldHideTabsOnAndroid || showAiChatView,
            // TODO: The tabs below do not correctly show as highlighted when navigating directly via URL, possibly due to how the class ('tab-selected') is applied before being rendered, we should look at storing currentTab in state
            tabButtons: mappedTabButtons,
        };
    },
);
