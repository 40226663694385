import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    InternalCourseProgressDto,
    internalEndpoints,
    InternalPostCourseProgressCommand,
    InternalPostManyCourseProgressCommand,
} from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CourseProgressService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getCourseProgress(): Observable<InternalCourseProgressDto[]> {
        return this.http.get<InternalCourseProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.courseProgressCollection}?takeLatest=true`,
            httpOptions,
        );
    }

    public createCourseProgress(command: InternalPostCourseProgressCommand): Observable<InternalCourseProgressDto> {
        return this.http.post<InternalCourseProgressDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.courseProgressCollection}`,
            command,
            httpOptions,
        );
    }

    public createManyCourseProgress(
        command: InternalPostManyCourseProgressCommand,
    ): Observable<InternalCourseProgressDto[]> {
        return this.http.post<InternalCourseProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.courseProgressCollection}/many`,
            command,
            httpOptions,
        );
    }
}
