import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { userAppAccessGrantedFeature } from './store/user-app-access-granted.reducer';
import { UserAppAccessGrantedEffects } from './store/user-app-access-granted.effects';
import { UserAppAccessGrantedTrackingEffects } from './store/user-app-access-granted-tracking.effects';

@NgModule({
    providers: [
        provideEffects(UserAppAccessGrantedEffects, UserAppAccessGrantedTrackingEffects),
        provideState(userAppAccessGrantedFeature),
    ],
})
export class ZigzagFrontendDataAccessAppAccessGrantedModule {}
