import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { accountFeature } from '@frontend/data-access/user/account';
import { addApiKeyAndJwtToRequest, AuthenticationService } from '@frontend/data-access/user/authentication';
import { Store } from '@ngrx/store';
import { internalEndpoints } from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private readonly store = inject(Store);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    private userToken$ = this.store.select(accountFeature.selectUserToken);

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            request.url === `${this.environment.internalApiUrl}${internalEndpoints.accountsCollection}` &&
            request.method === 'GET'
        ) {
            return addApiKeyAndJwtToRequest(
                this.authenticationService.token$,
                this.environment.applicationToInternalHashedApiKey,
                request,
                next,
            );
        }

        if (request.url.startsWith(this.environment.internalApiUrl)) {
            return addApiKeyAndJwtToRequest(
                this.userToken$,
                this.environment.applicationToInternalHashedApiKey,
                request,
                next,
            );
        }

        return next.handle(request);
    }
}
