import { Component, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Color } from '@shared/utils/typescript';
import { Store } from '@ngrx/store';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { SHOW_PAYMENT_MODAL_TRIGGERS } from '../../store/payment/constants/payment.constants';
import { ModalService } from '@frontend/utility/modal';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    standalone: true,
    selector: 'app-encourage-trial-modal',
    templateUrl: './encourage-trial-modal.component.html',
    styleUrls: ['./encourage-trial-modal.component.scss'],
    imports: [AnalyticsTrackClickDirective, IonicModule],
})
export class EncourageTrialModalComponent {
    private store = inject(Store);
    private modalService = inject(ModalService);
    protected readonly Color = Color;

    public continueMyJourney() {
        this.store.dispatch(showPaymentModal({ trigger: SHOW_PAYMENT_MODAL_TRIGGERS.TRIAL_REMINDER_PAYWALL }));
        this.closeModal();
    }

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
