import { ShowModalFrequency, selectShouldDisplayInfoItem } from '@frontend/data-access/dismissible-info';
import { createSelector } from '@ngrx/store';
import { RATING_QUALIFIER_MODAL_ID } from '../rating-qualifier-modal.constants';

export const selectShouldLaunchRatingReviewModal = createSelector(
    selectShouldDisplayInfoItem(RATING_QUALIFIER_MODAL_ID, ShowModalFrequency.MONTHLY),
    (shouldDisplayInfoItem) => {
        return shouldDisplayInfoItem;
    },
);
