import { createAction, props } from '@ngrx/store';
import { CourseCoordinates } from './course-path.reducer';

export const calculateCoursePathCoordinates = createAction('[CoursePath] Calculate Course Path Coordinates');
export const calculateCoursePathCoordinatesSuccess = createAction(
    '[CoursePath] Calculate Course Path Coordinates Success',
    props<{
        courses: CourseCoordinates[];
    }>(),
);
