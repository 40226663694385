import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { basicToggleSelected, premiumToggleSelected, purchaseProductSelected } from './payment.actions';
import { TieredPricingSelectionState } from './tiered-pricing-selection.model';

const initialState: TieredPricingSelectionState = {
    selectedProduct: { identifier: '', priceWithUnit: '' },
    isPremiumToggleSelected: true,
};

export const tieredPricingSelectionFeature = createFeature({
    name: 'tieredPricingSelection',
    reducer: createReducer(
        initialState,
        on(
            purchaseProductSelected,
            (state, { product }): TieredPricingSelectionState => ({
                ...state,
                selectedProduct: product,
            }),
        ),
        on(
            premiumToggleSelected,
            (state): TieredPricingSelectionState => ({
                ...state,
                isPremiumToggleSelected: true,
            }),
        ),
        on(
            basicToggleSelected,
            (state): TieredPricingSelectionState => ({
                ...state,
                isPremiumToggleSelected: false,
            }),
        ),
    ),
    extraSelectors: ({ selectTieredPricingSelectionState }) => ({
        selectIsPremiumToggleSelected: createSelector(
            selectTieredPricingSelectionState,
            (tieredPricingSelectionState) => tieredPricingSelectionState.isPremiumToggleSelected,
        ),
        selectSelectedProduct: createSelector(
            selectTieredPricingSelectionState,
            (tieredPricingSelectionState) => tieredPricingSelectionState.selectedProduct,
        ),
    }),
});
