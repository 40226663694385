import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoadingState } from '@frontend/data-access/shared-models';
import { accountFeature } from '@frontend/data-access/user/account';
import { configCatFeature, selectIsNewOnboardingAb } from '@frontend/data-access/user/config-cat';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, map, skipWhile } from 'rxjs';
import { selectOnboardingGuardPage } from '../data-access/onboarding.selectors';

@Injectable({
    providedIn: 'root',
})
export class OnboardingFeatureFlagGuard {
    private readonly store = inject(Store);
    private readonly router = inject(Router);

    public canActivate(route: ActivatedRouteSnapshot) {
        return combineLatest([
            this.store.select(accountFeature.selectAtLeastOneProfileCalledHasFinished),
            this.store.select(configCatFeature.selectLoading),
        ]).pipe(
            skipWhile(
                ([ready, loading]) => !ready || loading === LoadingState.INIT || loading === LoadingState.LOADING,
            ),
            concatLatestFrom(() => [
                this.store.select(selectOnboardingGuardPage),
                this.store.select(selectIsNewOnboardingAb),
            ]),
            map(([_, onboardingRoute, isNewOnboarding]) => {
                const requiresNewOnboarding = route.data['requiresNewOnboarding'];

                // Return true if the user is accessing the correct onboarding e.g. legacy user accessing legacy route OR new user accessing new route
                if (isNewOnboarding === requiresNewOnboarding) {
                    return true;
                }

                // Return URL tree for legacy OR new onboarding based on user's feature flags
                const command = isNewOnboarding
                    ? ['onboarding', onboardingRoute]
                    : ['onboarding', 'legacy', onboardingRoute];

                return this.router.createUrlTree(command);
            }),
        );
    }
}
