import { Routes } from '@angular/router';
import { ProfileEditPage } from './pages/profile-edit/profile-edit.page';

const profileRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: ProfileEditPage,
    },
];

export default profileRoutes;
