import { subscriptionFeature, SubscriptionOffering, SubscriptionPackage } from '@frontend/data-access/subscription';
import { timeFeature } from '@frontend/data-access/time';
import { accountFeature } from '@frontend/data-access/user/account';
import { selectIsFollowUpDiscountOfferAb } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { addDays } from 'date-fns';
import { REVENUE_CAT_OFFERINGS } from '../../store/payment/constants/payment.constants';
import { selectContentUnlocked } from '../../store/payment/store/payment.selectors';
import { mapOfferingToLimitedOfferPaymentProductCardArray } from '../payment-modal.utils';

const selectLimitedOfferOffering = createSelector(
    subscriptionFeature.selectOfferings,
    (offerings: SubscriptionOffering[]) => {
        return offerings.find((offering) => offering.identifier === REVENUE_CAT_OFFERINGS.DISCOUNT_PAYWALL);
    },
);

export const selectLimitedOfferOfferingAvailablePackages = createSelector(
    selectLimitedOfferOffering,
    (offering: SubscriptionOffering | undefined) => {
        return offering?.availablePackages ?? [];
    },
);

const selectPaymentModalLimitedOfferOfferingAvailablePackagesSortedByPrice = createSelector(
    selectLimitedOfferOfferingAvailablePackages,
    (availablePackages) =>
        availablePackages ? [...availablePackages].sort((a, b) => b.product.price - a.product.price) : [],
);

export const selectPaymentModalLimitedOfferProductCards = createSelector(
    accountFeature.selectDateOfOnboarding,
    selectPaymentModalLimitedOfferOfferingAvailablePackagesSortedByPrice,
    (dateOfOnboarding, packages: SubscriptionPackage[]) => {
        const offerEndDate = dateOfOnboarding ? addDays(dateOfOnboarding, 1) : undefined;

        return mapOfferingToLimitedOfferPaymentProductCardArray(packages, offerEndDate);
    },
);

export const selectIsEligibleForLimitedOfferPaymentModal = createSelector(
    selectIsFollowUpDiscountOfferAb,
    selectContentUnlocked,
    timeFeature.selectToday,
    selectPaymentModalLimitedOfferProductCards,
    subscriptionFeature.selectHasHistoricalPurchase,
    accountFeature.selectCountryCode,
    (isFollowUpDiscountOfferAb, isContentUnlocked, today, productCards, hasHistoricalPurchase, countryCode) => {
        return (
            (countryCode === 'GB' || countryCode === 'US') &&
            isFollowUpDiscountOfferAb &&
            !hasHistoricalPurchase &&
            !isContentUnlocked &&
            productCards.filter((productCard) => productCard.offerEndDate && productCard.offerEndDate >= today).length >
                0
        );
    },
);
