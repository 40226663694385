import { Injectable, inject } from '@angular/core';
import { createEmptyUser, createEmptyUserSuccess, getAccountSuccess } from '@frontend/data-access/user/account';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import {
    getLocalStorage,
    getLocalStorageSuccess,
    LocalStorageKeys,
    removeLocalStorage,
    safeSetLocalStorage,
} from '@frontend/data-access/capacitor';
import { getUserIdForFeatureFlags } from '../../feature-authentication/landing-page/landing.page.actions';

@Injectable()
export class DuplicateProfileManagementEffects {
    private readonly actions$ = inject(Actions);
    getUserIdFromLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserIdForFeatureFlags),
            map(() => getLocalStorage({ key: LocalStorageKeys.userId })),
        );
    });

    createEmptyUserUsingExistingUserIdIfItsExists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLocalStorageSuccess),
            filter(({ key }) => key === LocalStorageKeys.userId),
            map(({ data }) => {
                return data ? createEmptyUserSuccess({ profileId: data as string }) : createEmptyUser();
            }),
        );
    });

    setUserIdInLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEmptyUserSuccess),
            map(({ profileId }) => safeSetLocalStorage({ key: LocalStorageKeys.userId, data: profileId })),
        );
    });

    clearUserIdFromLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            map(() => removeLocalStorage({ key: LocalStorageKeys.userId })),
        );
    });
}
