<section class="payment-modal-discount-products-title">
    <h1>
        {{ vm().ownerName }}, here is an additional
        <span class="payment-modal-discount-products-title__highlight">{{
            vm().discountProducts[0]?.discount?.discountPercentage
        }}</span>
        off for you
    </h1>
    <p class="s">
        {{ 'Limited time only! Continue [NAME]s journey to pup happiness.' | textReplacement }}
    </p>
</section>

<section class="payment-modal-discount-products">
    @for (product of vm().discountProducts; track product.identifier) {
        <app-payment-modal-product
            [isSelected]="product.identifier === selectedProduct().identifier"
            [product]="product"
            (productClicked)="onProductClick($event)"
        />
    }

    <ion-footer class="payment-modal-discount-products__footer">
        <div class="payment-modal-discount-products__button-group">
            <ion-button
                appTrackClick
                [identifier]="'discount-paywall-payment-purchase-' + selectedProduct().identifier"
                color="max"
                expand="full"
                shape="round"
                [disabled]="!selectedProduct()"
                (click)="onPurchaseClick()"
            >
                Get offer
            </ion-button>
        </div>
        <app-payment-modal-terms />
    </ion-footer>
</section>
