export const getId = <T>(entry: { id: T }): T => entry.id;

export const randomWithSeed = (a: number): number => {
    let t = a + 0x6d2b79f5;
    // eslint-disable-next-line no-bitwise
    t = Math.imul(t ^ (t >>> 15), t | 1);
    // eslint-disable-next-line no-bitwise
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    // eslint-disable-next-line no-bitwise
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
};
