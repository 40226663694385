import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    DomainPostTodayProgressCommand,
    internalEndpoints,
    InternalPatchTodayCourseProgressCommand,
    InternalProgressDto,
} from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TodayCourseProgressService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getTodayProgress(date: Date): Observable<InternalProgressDto[]> {
        return this.http.get<InternalProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.progressCollection}/${date}`,
            httpOptions,
        );
    }

    public createTodayProgress(command: DomainPostTodayProgressCommand): Observable<InternalProgressDto[]> {
        return this.http.post<InternalProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.progressCollection}`,
            command,
            httpOptions,
        );
    }

    public updateTodayProgress(
        id: string,
        command: InternalPatchTodayCourseProgressCommand,
    ): Observable<InternalProgressDto[]> {
        return this.http.patch<InternalProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.progressCollection}/${id}`,
            command,
            httpOptions,
        );
    }
}
