@if (vm()) {
    <ion-app>
        @if (vm().appLoading) {
            <div class="splash">
                <img [src]="'/assets/images/zigzag/zigzag-splashscreen.gif'" alt="splash-image" />
            </div>
            <div class="badge">
                @if (vm().showAppOfTheDay) {
                    <img [src]="'/assets/images/third-party/app-of-the-day.svg'" alt="app of the day" />
                }
                @if (vm().showPurina) {
                    <img
                        class="purina-logo"
                        [src]="'/assets/images/third-party/powered-by-purina.svg'"
                        alt="powered by purina"
                    />
                }
            </div>
        }
        @if (vm().appReset) {
            <ion-spinner name="crescent"></ion-spinner>
        }
        <ion-router-outlet [hidden]="!vm().appReady" [swipeGesture]="false"></ion-router-outlet>
    </ion-app>
}
