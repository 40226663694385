import { Component } from '@angular/core';
import { IonCard, IonCardHeader, IonSkeletonText } from '@ionic/angular/standalone';
import { StepCardSlimSkeletonComponent } from '../../../../step/step-card-slim/skeleton/step-card-slim-skeleton.component';

@Component({
    selector: 'app-course-card-today-loading',
    templateUrl: './course-card-loading.component.html',
    styleUrls: ['./course-card-loading.component.scss', '../course-card.component.scss'],
    standalone: true,
    imports: [IonCard, StepCardSlimSkeletonComponent, IonCardHeader, IonSkeletonText],
})
export class CourseCardLoadingComponent {}
