import { createFeature, createReducer, on } from '@ngrx/store';
import { PinnedTopic } from '../models/pinned-topic';
import {
    addPinnedTopic,
    addPinnedTopicSuccess,
    getPinnedTopicsSuccess,
    removePinnedTopic,
    removePinnedTopicSuccess,
} from './pinned-topics.actions';
import { LoadingState } from '@frontend/data-access/shared-models';

export interface PinnedTopicsState {
    pinnedTopics: PinnedTopic[];
    loadingState: LoadingState;
}

export const initialState: PinnedTopicsState = {
    pinnedTopics: [],
    loadingState: LoadingState.INIT,
};

export const pinnedTopicFeature = createFeature({
    name: 'pinnedTopicState',
    reducer: createReducer(
        initialState,
        on(
            addPinnedTopic,
            removePinnedTopic,
            (state): PinnedTopicsState => ({
                ...state,
                loadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getPinnedTopicsSuccess,
            (state, action): PinnedTopicsState => ({
                ...state,
                pinnedTopics: action.pinnedTopics,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            addPinnedTopicSuccess,
            (state, action): PinnedTopicsState => ({
                ...state,
                pinnedTopics: state.pinnedTopics.concat(action.pinnedTopic),
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            removePinnedTopicSuccess,
            (state, action): PinnedTopicsState => ({
                ...state,
                pinnedTopics: state.pinnedTopics.filter((pinnedTopic) => pinnedTopic.id !== action.pinnedTopic.id),
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
});
