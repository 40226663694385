import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { AccountState } from '../models/account.model';
import {
    createAccount,
    createAccountFailure,
    createAccountSuccess,
    resetAccountErrorState,
} from './create/create-account.actions';
import { createEmptyUserSuccess, getAccount, getAccountFailure, getAccountSuccess } from './get/get-account.actions';
import { updateAccount, updateAccountFailure, updateAccountSuccess } from './update/update-account.actions';

export const initialState: AccountState = {
    profile: undefined,
    id: undefined,
    loading: LoadingState.INIT,
    error: undefined,
    hasCreateAccountFired: false,
    atLeastOneProfileCalledHasFinished: false,
    accountFailure: false,
};

export const accountFeature = createFeature({
    name: 'account',
    reducer: createReducer(
        initialState,
        on(
            createAccount,
            (state): AccountState => ({
                ...state,
                loading: LoadingState.LOADING,
                error: undefined,
                hasCreateAccountFired: true,
            }),
        ),
        on(
            getAccount,
            updateAccount,
            (state): AccountState => ({
                ...state,
                loading: LoadingState.LOADING,
                error: undefined,
            }),
        ),
        on(
            createAccountSuccess,
            (state, { profile, profileId }): AccountState => ({
                ...state,
                profile,
                id: profileId,
                loading: LoadingState.LOADED,
                error: undefined,
                atLeastOneProfileCalledHasFinished: true,
            }),
        ),
        on(
            getAccountSuccess,
            updateAccountSuccess,
            (state, { profile, profileId }): AccountState => ({
                ...state,
                profile,
                id: profileId,
                loading: LoadingState.LOADED,
                error: undefined,
            }),
        ),
        on(
            createAccountFailure,
            getAccountFailure,
            updateAccountFailure,
            (state, { error }): AccountState => ({
                ...state,
                loading: LoadingState.LOADED,
                error,
            }),
        ),
        on(
            createEmptyUserSuccess,
            (state, payload): AccountState => ({
                ...state,
                id: payload.profileId,
            }),
        ),
        on(
            createAccountFailure,
            getAccountSuccess,
            getAccountFailure,
            (state): AccountState => ({
                ...state,
                atLeastOneProfileCalledHasFinished: true,
            }),
        ),
        on(
            resetAccountErrorState,
            (state): AccountState => ({
                ...state,
                error: undefined,
            }),
        ),
        on(
            getAccountFailure,
            createAccountFailure,
            (state): AccountState => ({
                ...state,
                accountFailure: true,
            }),
        ),
    ),
    extraSelectors: ({ selectProfile }) => ({
        selectPartners: createSelector(selectProfile, (profile) => profile?.partners),
        selectUserToken: createSelector(selectProfile, (profile) => profile?.userToken),
        selectGender: createSelector(selectProfile, (profile) => profile?.gender),
        selectIsRescuePup: createSelector(selectProfile, (profile) => profile?.isRescuePup),
        selectDateOfArrival: createSelector(selectProfile, (profile) => profile?.dateOfArrival),
        selectEmail: createSelector(selectProfile, (profile) => profile?.mail),
        selectDateOfBirth: createSelector(selectProfile, (profile) => profile?.dateOfBirth),
        selectIsApproximateDateOfBirth: createSelector(selectProfile, (profile) => profile?.isApproximateDateOfBirth),
        selectBreedId: createSelector(selectProfile, (profile) => profile?.breedId),
        selectCountryCode: createSelector(selectProfile, (profile) => profile?.countryCode),
        selectHasArrivedOrUndefined: createSelector(selectProfile, (profile) => profile?.hasArrived),
        selectDogName: createSelector(selectProfile, (profile) => profile?.name ?? ''),
        selectOwnerName: createSelector(selectProfile, (profile) => profile?.ownerName ?? ''),
        selectDateOfOnboarding: createSelector(selectProfile, (profile) => profile?.dateOfOnboarding),
        selectDateOfOnboardingOrToday: createSelector(
            selectProfile,
            (profile) => profile?.dateOfOnboarding ?? new Date(),
        ),
    }),
});
