import { chatFeature } from '@frontend/data-access/chat';
import { createSelector } from '@ngrx/store';
import { selectContentUnlocked, selectIsBasicPlan } from '../store/payment/store/payment.selectors';

export const selectLibraryPageVm = createSelector(
    selectContentUnlocked,
    chatFeature.selectIsDestroying,
    selectIsBasicPlan,
    (unlocked, isStreamChatBusyCleaningUp, isBasicPlan) => ({
        unlocked,
        isStreamChatBusyCleaningUp,
        isBasicPlan,
    }),
);
