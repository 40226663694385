@if (course) {
    <ion-card
        class="course-tile tile"
        color="harley"
        [ngStyle]="setBackground()"
        [attr.data-test]="'course-tile-' + course.id"
    >
        <div class="course-tile__left-section">
            <div>
                <!-- This div is needed to position the top and middle sections into the same flexbox -->
                <div class="course-tile__top-section">
                    <h2>
                        {{ course.title }}
                    </h2>
                </div>
                <div class="course-tile__middle-section">
                    <div class="course-tile__intro">
                        <p class="s">{{ course.introduction }}</p>
                    </div>
                </div>
            </div>
            <div class="course-tile__bottom-section">
                <ion-button
                    (click)="goToCourse(course.id)"
                    [ngStyle]="{
                        '--color': course.color + '-contrast',
                        '--border-color': course.color + '-contrast'
                    }"
                    class="course-tile__go-to-btn s"
                    data-test="course-tile-go-to-btn"
                    identifier="course-tile-go-to-btn"
                    appTrackClick
                    size="small"
                    fill="outline"
                    >Go to course
                </ion-button>
            </div>
        </div>
    </ion-card>
}
