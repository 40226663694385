@if (vm$ | async; as vm) {
    <div class="course-card-steps-container">
        @if (vm.isLoading) {
            <app-step-card-slim-skeleton></app-step-card-slim-skeleton>
        } @else {
            @if (course) {
                @for (step of course.cardItems; track step.contentId; let i = $index) {
                    <app-step-card-slim
                        [content]="step"
                        [attr.data-test]="'app-step-card-slim-' + i"
                        (click)="goToStep(step)"
                    >
                    </app-step-card-slim>
                }
                @if (course.status === CourseStatus.INACTIVE) {
                    <div class="course-card-start-overlay">
                        <h2>Ready to start {{ '[NAME]' | textReplacement }}'s journey?</h2>
                        <ion-button
                            class="course-card-start-button"
                            [color]="course?.color"
                            expand="full"
                            shape="round"
                            appTrackClick
                            identifier="start-course-today-btn"
                            data-test="start-course-today-btn"
                            (click)="vm.unlocked ? clickStartCourse(course.id) : openPaymentModal()"
                            >Start course
                        </ion-button>
                    </div>
                }
            }
        }
    </div>
}
