import { DatePipe } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { timeFeature } from '@frontend/data-access/time';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonFooter,
    IonHeader,
    IonModal,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { onboardingNavigateBack, onboardingQuestionSubmittedArrivalDate } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-arrival',
    templateUrl: 'question-arrival.page.html',
    styleUrls: ['question-arrival.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        DatePipe,
        IonButton,
        IonContent,
        IonDatetime,
        IonDatetimeButton,
        IonFooter,
        IonHeader,
        IonModal,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
        ReactiveFormsModule,
    ],
})
export class OnboardingQuestionArrivalPage {
    protected readonly store = inject(Store);

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);
    public currentDate = this.store.selectSignal<Date>(timeFeature.selectToday);

    private dateOfArrival = this.store.selectSignal(accountFeature.selectDateOfArrival);

    public arrivalForm = new FormGroup({
        dateOfArrival: new FormControl(formatISO(new Date(), { representation: 'date' }), {
            validators: [Validators.required],
            nonNullable: true,
        }),
    });

    constructor() {
        effect(() => {
            if (this.dateOfArrival()) {
                this.arrivalForm.setValue({
                    dateOfArrival: formatISO(this.dateOfArrival()!, { representation: 'date' }),
                });
            }
        });
    }

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onSubmit(): void {
        if (this.arrivalForm.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedArrivalDate({
                dateOfArrival: new Date(this.arrivalForm.controls.dateOfArrival.value).toISOString(),
            }),
        );
    }
}
