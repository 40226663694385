import { CallState, LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { FrontendRescheduledStep } from '../models/rescheduled-step.model';
import {
    createRescheduledSteps,
    createRescheduledStepsFailure,
    createRescheduledStepsSuccess,
    getRescheduledSteps,
    getRescheduledStepsFailure,
    getRescheduledStepsSuccess,
    setRescheduledStepsAsInactive,
    setRescheduledStepsAsInactiveFailure,
    setRescheduledStepsAsInactiveSuccess,
} from './rescheduled-step.actions';

export interface RescheduledStepState {
    rescheduledSteps: FrontendRescheduledStep[];
    loading: CallState;
}

export const initialState: RescheduledStepState = {
    rescheduledSteps: [],
    loading: LoadingState.INIT,
};

export const rescheduledStepFeature = createFeature({
    name: 'rescheduledStepState',
    reducer: createReducer(
        initialState,
        on(
            getRescheduledSteps,
            (state): RescheduledStepState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            getRescheduledStepsSuccess,
            (state, action): RescheduledStepState => ({
                ...state,
                rescheduledSteps: action.rescheduledSteps,
                loading: LoadingState.LOADED,
            }),
        ),
        on(
            getRescheduledStepsFailure,
            (state, error): RescheduledStepState => ({
                ...state,
                loading: { errorMsg: error.error.message },
            }),
        ),
        on(
            createRescheduledSteps,
            (state): RescheduledStepState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            createRescheduledStepsSuccess,
            (state, action): RescheduledStepState => ({
                ...state,
                rescheduledSteps: [...state.rescheduledSteps, ...action.rescheduledSteps],
                loading: LoadingState.LOADED,
            }),
        ),
        on(
            createRescheduledStepsFailure,
            (state, error): RescheduledStepState => ({
                ...state,
                loading: { errorMsg: error.error.message },
            }),
        ),
        on(
            setRescheduledStepsAsInactive,
            (state): RescheduledStepState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            setRescheduledStepsAsInactiveSuccess,
            (state, action): RescheduledStepState => ({
                ...state,
                rescheduledSteps: state.rescheduledSteps.filter(
                    (step) => !action.rescheduledSteps.some((removedStep) => removedStep.id === step.id),
                ),
                loading: LoadingState.LOADED,
            }),
        ),
        on(
            setRescheduledStepsAsInactiveFailure,
            (state, error): RescheduledStepState => ({
                ...state,
                loading: { errorMsg: error.error.message },
            }),
        ),
    ),
});
