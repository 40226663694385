import { createSelector } from '@ngrx/store';
import { accountFeature } from '@frontend/data-access/user/account';
import { mapOfferingToDiscountPaymentProductCardArray } from '../payment-modal.utils';
import { selectDefaultOfferingAvailablePackagesSortedByPrice } from '../../store/payment/store/payment.selectors';

export const selectPaymentModalDiscountProductsVm = createSelector(
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    accountFeature.selectOwnerName,
    (defaultPackages, ownerName) => {
        return {
            discountProducts: mapOfferingToDiscountPaymentProductCardArray(defaultPackages),
            ownerName,
        };
    },
);
