@if (vm$ | async; as vm) {
    <div class="text-block">
        <ion-card data-test="library-daily-bite-tile">
            <app-card-item
                appTrackClick
                identifier="to-step-from-today"
                [content]="vm.biteInfo"
                [routerLink]="['/main/library/daily-bites']"
            />
        </ion-card>
    </div>
}
