import { InternalUserDto } from '@shared/user-api-interface';
import { User } from '../models/user.model';

export function mapInternalUserDtoToUser(internalUserDto: InternalUserDto): User {
    return {
        id: internalUserDto.id,
        name: internalUserDto.ownerName,
        mail: internalUserDto.mail,
    };
}
