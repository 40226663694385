import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CourseProgressType } from '@shared/user-domain';
import { map, switchMap, tap } from 'rxjs/operators';
import { triggerCreateCourseProgress } from '../../store/progress/course-progress/course-progress.actions';
import { setCourseSlider } from '../course-slider/course-slider.actions';
import { showCourseInformationModal, startCourseClicked } from './course-information-modal.actions';
import { CourseInformationModalComponent } from './course-information-modal.component';

@Injectable()
export class CourseInformationModalEffects {
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    showCourseInformationModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showCourseInformationModal),
            tap(({ course }) => {
                void this.modalService.showModal({
                    component: CourseInformationModalComponent,
                    cssClass: ['modal', 'modal-fullscreen'],
                    componentProps: {
                        course,
                    },
                });
            }),
            map(({ course }) => {
                return trackEvent({
                    eventName: '[Course] Course Information Modal Initiated',
                    eventProperties: {
                        courseId: course.id,
                    },
                });
            }),
        );
    });

    startCourseClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(startCourseClicked),
            switchMap(({ courseId }) => [
                triggerCreateCourseProgress({
                    courseProgress: {
                        id: courseId,
                        progressType: CourseProgressType.IN_PROGRESS,
                    },
                }),
                setCourseSlider({ courseId }),
            ]),
        );
    });
}
