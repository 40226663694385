import { Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { DEFAULT_PUPPY_NAME } from '@shared/constants';
import { onboardingNavigateBack, onboardingQuestionSubmittedName } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-name',
    templateUrl: 'question-name.page.html',
    styleUrls: ['question-name.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonInput,
        IonItem,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
        ReactiveFormsModule,
    ],
})
export class OnboardingQuestionNamePage {
    private readonly store = inject(Store);

    protected readonly NAME_MAX_LENGTH = 16;

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public name = this.store.selectSignal(accountFeature.selectDogName);

    public form = new FormGroup({
        name: new FormControl<string>('', {
            validators: [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)],
            nonNullable: true,
        }),
    });

    constructor() {
        // NBSon - we only want to set the form value if the name is NOT the default puppy name
        effect(() => {
            this.form.setValue({
                name: this.name() === DEFAULT_PUPPY_NAME ? '' : this.name(),
            });
        });
    }

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onSubmit(): void {
        if (this.form.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedName({ name: this.form.controls.name.value }));
    }

    public onUnknownNameClick(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedName({ name: DEFAULT_PUPPY_NAME }));
        this.form.reset();
    }
}
