import {
    getAccount,
    getAccountFailure,
    getAccountSuccess,
    updateAccount,
    updateAccountFailure,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { createFeature, createReducer, on } from '@ngrx/store';
import { onboardingQuestionSubmittedNeeds } from './onboarding.actions';

export interface OnboardingState {
    isLoading: boolean;
    selectedNeedIds: string[];
}

export const initialState: OnboardingState = {
    isLoading: false,
    selectedNeedIds: [],
};

export const onboardingFeature = createFeature({
    name: 'onboarding',
    reducer: createReducer(
        initialState,
        on(getAccount, updateAccount, (state): OnboardingState => {
            return {
                ...state,
                isLoading: true,
            };
        }),
        on(
            getAccountSuccess,
            getAccountFailure,
            updateAccountSuccess,
            updateAccountFailure,
            (state): OnboardingState => {
                return {
                    ...state,
                    isLoading: false,
                };
            },
        ),
        on(onboardingQuestionSubmittedNeeds, (state, { needs }): OnboardingState => {
            // Convert the Record<string, boolean> to an array of selected need ids
            const selectedNeedIds = Object.keys(needs).filter((needId) => needs[needId]);

            return {
                ...state,
                selectedNeedIds,
            };
        }),
    ),
});
