import { InternalCourseProgressDto } from '@shared/user-api-interface';
import { FrontendCourseProgressEntry } from '../models/course-progress.model';

export const mapInternalCourseProgressDtoToFrontendCourseProgressEntry = (
    dto: InternalCourseProgressDto,
): FrontendCourseProgressEntry => {
    return {
        courseId: dto.courseId,
        progressType: dto.progressType,
        timestamp: dto.timestamp,
        dogId: dto.dogId,
    };
};
