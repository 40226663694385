import { inject, Injectable } from '@angular/core';
import { ModalOptions } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DismissByIdOptions } from './models/modal.model';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private modalController = inject(ModalController);

    public showModalWithCallback<TData = unknown>(options: ModalOptions): Observable<TData | undefined> {
        return from(
            this.modalController.create(options).then((modal) => {
                return modal.present().then(() => modal.onDidDismiss<TData>());
            }),
        ).pipe(map(({ data }) => data));
    }

    public async showModalWithCallbackAsync<TData = unknown>(options: ModalOptions): Promise<TData | undefined> {
        const modal = await this.modalController.create(options);

        await modal.present();

        const { data } = await modal.onDidDismiss<TData>();

        return data;
    }

    public async showModal(options: ModalOptions): Promise<void> {
        const modal = await this.modalController.create(options);
        await modal.present();
    }

    /**
     * Dismiss the currently open modal.
     *
     * NOTE: although you can use this, it is recommended to use dismissById instead to prevent dismissing the wrong modal.
     */
    public dismiss<TData = unknown>(data?: TData) {
        void this.modalController.dismiss(data);
    }

    /**
     * Dismiss the modal with the provided id.
     */
    public dismissById(id: string, options?: DismissByIdOptions) {
        const { data, role } = options ?? {};

        void this.modalController.dismiss(data, role, id);
    }
}
