<!-- Any changes made here will need to be copied to rich-content.pipe.ts -->
<div class="dynamic-content-block">
    <div class="dynamic-content-block__sidebar"></div>
    <h3 class="dynamic-content-block__title" data-test="dynamic-content-block-title">{{ title }}</h3>
    <p
        class="dynamic-content-block__content"
        data-test="dynamic-content-block-content"
        [innerHTML]="content | richContent | textReplacement"
    ></p>
</div>
