import { notEmpty } from '@shared/utils/typescript';
import { selectRescheduledStepsPracticeIdsWithCount } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { selectCoursesWithProgress } from '../store/course/course-with-progress.selectors';
import { selectSteps } from '../store/step/step.selectors';
import { CourseWithSteps } from './models/courses.models';
import { placeRescheduledStepsInCourseSteps } from './utils/course-steps-and-splits.utils';
import { getSplitsForCourse } from './utils/courses.utils';

export const selectCoursesWithStepsAndSplits = createSelector(
    selectCoursesWithProgress,
    selectSteps,
    selectRescheduledStepsPracticeIdsWithCount,
    (courses, steps, stepIdPracticeCount): CourseWithSteps[] =>
        courses.map((course) => {
            // Ordered correctly already
            const courseSteps = course.stepIds
                .map((stepId) => steps.find((step) => step.id === stepId))
                .filter(notEmpty);

            const stepsWithPracticePlacedIn = placeRescheduledStepsInCourseSteps(courseSteps, stepIdPracticeCount);

            return {
                ...course,
                steps: stepsWithPracticePlacedIn,
                splits: getSplitsForCourse(course, stepsWithPracticePlacedIn),
            };
        }),
);
