export const firebaseConfigProd = {
    apiKey: 'AIzaSyCqYb5wccDh4GT-zRrPTOhawyHLke5M-tk',
    authDomain: 'puppito-873cf.firebaseapp.com',
    databaseURL: 'https://puppito-873cf.firebaseio.com',
    projectId: 'puppito-873cf',
    storageBucket: 'puppito-873cf.appspot.com',
    messagingSenderId: '743112033000',
    appId: '1:743112033000:web:10fc51a1f0cb95c3bc3f5d',
    useFirebaseAuthMock: false,
};

export const firebaseConfigCi = {
    ...firebaseConfigProd,
    useFirebaseAuthMock: true,
};

export const firebaseConfigDev = {
    apiKey: 'AIzaSyDm5o2h6-TgnmQ_h3rh-hvEMVM6SOWb6rE',
    authDomain: 'zigzag-dev-79eeb.firebaseapp.com',
    projectId: 'zigzag-dev-79eeb',
    storageBucket: 'zigzag-dev-79eeb.appspot.com',
    messagingSenderId: '1024111136829',
    appId: '1:1024111136829:web:fc72232caa785d6afa1a54',
    useFirebaseAuthMock: false,
};
