import { APPLICATION_INSIGHTS_CONFIG } from '@frontend/data-access/application-insights';
import { Environment } from '../environment/environment.model';
import { ENVIRONMENT } from '../injection-tokens';

export const applicationInsightsConfigProvider = {
    provide: APPLICATION_INSIGHTS_CONFIG,
    useFactory: (env: Environment) => {
        return {
            instrumentationKey: env.applicationInsights.instrumentationKey,
        };
    },
    deps: [ENVIRONMENT],
};
