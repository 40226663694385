import { inject, Injectable } from '@angular/core';
import { CustomerInfo, Purchases, PurchasesOfferings } from '@awesome-cordova-plugins/purchases/ngx';
import { trackEvent } from '@frontend/data-access/analytics';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SubscriptionOffering } from '../store/models/subscription.model';
import { SUBSCRIPTION_CONFIG, SubscriptionConfig } from '../subscription-config.token';
import { SubscriptionService } from '../subscription.service';
import { mapPurchasesOfferingToBaseSubscriptionOffering } from '../subscription.utils';

@Injectable({
    providedIn: 'root',
})
export class RevenueCatSubscriptionService extends SubscriptionService {
    public readonly store = inject(Store);

    private readonly config = inject<SubscriptionConfig>(SUBSCRIPTION_CONFIG);
    private readonly purchases = inject(Purchases);

    constructor() {
        super();
    }

    public initialize(id?: string): void {
        this.purchases.configureWith({
            apiKey: this.config.revenueCatKey,
            appUserID: id,
        });
    }

    public logInToPurchases(
        id: string,
        email: string,
    ): Observable<{
        customerInfo: CustomerInfo;
    }> {
        return from(this.purchases.logIn(id)).pipe(
            tap(() => {
                this.purchases.setEmail(email);
            }),
            map((loginResult) => ({
                customerInfo: loginResult.customerInfo,
            })),
        );
    }

    public getEntitlements(): Observable<CustomerInfo> {
        return this.purchases.onCustomerInfoUpdated();
    }

    public getOfferings(): Observable<SubscriptionOffering[]> {
        return from(this.purchases.getOfferings()).pipe(
            map((purchasesOfferings: PurchasesOfferings) => {
                const { all, current } = purchasesOfferings;

                if (!current) {
                    throw new Error('[RevenueCatService]: No current offering received from RevenueCat');
                }

                return Object.values(all).map((purchasesOffering) => {
                    const isDefault = current.identifier === purchasesOffering.identifier;

                    return {
                        ...mapPurchasesOfferingToBaseSubscriptionOffering(purchasesOffering),
                        isDefault,
                    };
                });
            }),
        );
    }

    public purchaseProduct(id: string): Observable<{
        productIdentifier: string;
        customerInfo: CustomerInfo;
    }> {
        return from(this.purchases.purchaseProduct(id));
    }

    public collectDeviceIdentifiers(adjustId?: string): void {
        this.purchases.collectDeviceIdentifiers();
        if (adjustId) {
            this.purchases.setAdjustID(adjustId);
        }
    }

    public async restoreTransactions(): Promise<void> {
        try {
            const restoredInfo = await this.purchases.restorePurchases();

            this.store.dispatch(
                trackEvent({
                    eventName: 'Restore purchases success',
                    eventProperties: {
                        restoredInfo,
                    },
                }),
            );
        } catch (error: unknown) {
            this.store.dispatch(
                trackEvent({
                    eventName: 'Restore purchases error',
                    eventProperties: {
                        error,
                    },
                }),
            );
        }
    }

    public syncPurchases(): void {
        try {
            this.purchases.syncPurchases();
        } catch (error: unknown) {
            this.store.dispatch(
                trackEvent({
                    eventName: 'Sync Purchases Error',
                    eventProperties: {
                        error,
                    },
                }),
            );
        }
    }

    public presentPromoCodePrompt(): void {
        this.purchases.addShouldPurchasePromoProductListener((deferredPurchase) => {
            this.store.dispatch(trackEvent({ eventName: 'purchases promo-listener' }));
            return deferredPurchase();
        });
        this.purchases.presentCodeRedemptionSheet();
    }
}
