import { Injectable, NgZone, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { App } from '@capacitor/app';
import { capacitorAppRestored, capacitorAppResume, capacitorAppStateChange } from '../store/app-listeners.actions';

@Injectable({
    providedIn: 'root',
})
export class AppListenersService {
    private readonly store = inject(Store);
    private readonly ngZone = inject(NgZone);

    public addAppListeners(): void {
        App.addListener('appStateChange', ({ isActive }) => {
            this.ngZone.run(() => {
                this.store.dispatch(capacitorAppStateChange({ isActive }));
            });
        });

        App.addListener('appRestoredResult', (data) => {
            this.ngZone.run(() => {
                this.store.dispatch(capacitorAppRestored({ data }));
            });
        });

        App.addListener('resume', () => {
            this.ngZone.run(() => {
                this.store.dispatch(capacitorAppResume());
            });
        });
    }
}
