<ion-content [color]="Color.Max" [fullscreen]="true">
    <section class="free-access-modal">
        <img class="free-access-modal__logo" [src]="'assets/images/zigzag/zigzag-logo-white.png'" alt="zigzag logo" />

        <div class="free-access-modal__animation-container">
            <img
                class="free-access-modal__animation"
                [src]="'assets/images/ziggy/ziggy-chasing-tail.gif'"
                alt="animation of a dog chasing his tail"
            />
        </div>

        <div class="free-access-modal__title-container ignore-global-color">
            <h1>Woohoo!<br />Claim your 3 days FREE access on us!</h1>
            <p>No payment details are required</p>
        </div>

        <ion-footer class="free-access-modal-footer page-padding-sides">
            <ion-button
                class="free-access-modal__button"
                appTrackClick
                identifier="free-access-modal-footer-get-access-btn"
                expand="full"
                shape="round"
                data-test="free-access-modal-get-access-btn"
                [color]="Color.Snowy"
                (click)="getFreeAccess(3)"
            >
                Get free access
            </ion-button>
        </ion-footer>
    </section>
</ion-content>
