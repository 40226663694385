import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonButton, IonCard, IonCardContent, IonCardTitle } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { showArrivalModal } from '../../store/modal/modal.actions';
import { selectArrivalTileVm } from './arrival-tile.selectors';

@Component({
    selector: 'app-arrival-tile',
    templateUrl: './arrival-tile.component.html',
    styleUrls: ['./arrival-tile.component.scss'],
    standalone: true,
    imports: [AsyncPipe, TextReplacementPipe, IonCard, IonCardTitle, IonCardContent, IonButton],
})
export class ArrivalTileComponent {
    public readonly store = inject(Store);
    public vm$ = this.store.select(selectArrivalTileVm);

    continueOnboarding(daysUntilArrival: number) {
        this.store.dispatch(showArrivalModal());

        this.store.dispatch(
            trackEvent({
                eventName: 'show-arrival-modal',
                eventProperties: {
                    trigger: 'click',
                    daysUntilArrival,
                },
            }),
        );
    }
}
