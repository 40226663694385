import { PurchaseProduct, subscriptionFeature } from '@frontend/data-access/subscription';
import { selectIsFollowUpDiscountOfferAb } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import {
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    selectIncreasedUkPriceForDiscountPaywallAbOfferingAvailablePackagesSortedByPrice,
    selectIsBasicPlan,
    selectTieredPricingAbBasicOfferingSortedByPrice,
    selectTieredPricingAbPremiumOfferingSortedByPrice,
} from '../store/payment/store/payment.selectors';
import { tieredPricingSelectionFeature } from '../store/payment/store/tiered-pricing-selection.reducer';
import { mapOfferingToPaymentProductCardArray } from './payment-modal.utils';
import { PaymentProductCard } from './payment-modal-product/payment-modal-product.model';

export const selectPaymentProducts = createSelector(
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    selectIncreasedUkPriceForDiscountPaywallAbOfferingAvailablePackagesSortedByPrice,
    selectTieredPricingAbBasicOfferingSortedByPrice,
    selectTieredPricingAbPremiumOfferingSortedByPrice,
    selectIsFollowUpDiscountOfferAb,
    subscriptionFeature.selectHasHistoricalPurchase,
    (
        defaultOffering,
        increasedUkPricePackages,
        basicPackages,
        premiumPackages,
        isFollowUpDiscountOfferAb,
        hasHistoricalPurchase,
    ) => {
        const defaultProducts = isFollowUpDiscountOfferAb
            ? mapOfferingToPaymentProductCardArray(increasedUkPricePackages, !!hasHistoricalPurchase) // UK and US
            : mapOfferingToPaymentProductCardArray(defaultOffering, !!hasHistoricalPurchase); // ROW

        const basicProducts = mapOfferingToPaymentProductCardArray(basicPackages, !!hasHistoricalPurchase);

        const premiumProducts = mapOfferingToPaymentProductCardArray(premiumPackages, !!hasHistoricalPurchase);

        return {
            defaultProducts: defaultProducts,
            premiumProducts: premiumProducts,
            basicProducts,
        };
    },
);

export const selectPaymentModalVm = createSelector(
    selectPaymentProducts,
    subscriptionFeature.selectHasHistoricalPurchase,
    tieredPricingSelectionFeature.selectSelectedProduct,
    tieredPricingSelectionFeature.selectIsPremiumToggleSelected,
    selectIsBasicPlan,
    (
        paymentProducts,
        hasHistoricalPurchase,
        selectedProduct,
        isPremiumToggleSelected,
        isBasicPlan,
    ): {
        defaultProducts: PaymentProductCard[];
        basicProducts: PaymentProductCard[];
        premiumProducts: PaymentProductCard[];
        hasHistoricalPurchase: boolean;
        selectedProduct: PurchaseProduct;
        isPremiumToggleSelected: boolean;
        isBasicPlan: boolean;
    } => {
        return {
            ...paymentProducts,
            hasHistoricalPurchase: !!hasHistoricalPurchase,
            selectedProduct: selectedProduct!,
            isPremiumToggleSelected,
            isBasicPlan,
        };
    },
);
