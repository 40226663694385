<app-onboarding-legacy-question-template
    backButtonId="question-name-back-btn"
    title="What is the name of your pup?"
    subtitle="Let's keep it personal - we'd love to call them by their first name"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <form id="nameForm" class="question-name-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <ion-item class="question-name-form__input-container">
                <ion-input
                    class="zz-input"
                    appTrackClick
                    identifier="question-name-dogname-input"
                    id="name"
                    formControlName="name"
                    autocapitalize="words"
                    placeholder="e.g. Alvin"
                    data-test="dogname-input"
                    type="text"
                    [maxlength]="NAME_MAX_LENGTH"
                    [tabindex]="1"
                />
            </ion-item>
            <app-profile-image
                borderWidth="5px"
                size="72px"
                [isClickable]="!vm().isLoading"
                [type]="ProfileImageType.EDIT"
            />
        </form>
    </ng-container>

    @if (!vm().isInputOpenOnAndroid) {
    <ng-container footer>
        <button
            class="zz-button question-name-no-puppy-button"
            data-test="question-name-unknown-next-btn"
            [disabled]="vm().isLoading"
            (click)="unknownName()"
        >
            I don't know the name yet
        </button>
        <ion-button
            expand="block"
            appTrackClick
            identifier="question-name-next-btn"
            type="submit"
            form="nameForm"
            data-test="question-name-next-btn"
            [color]="Color.Max"
            [disabled]="form.invalid || vm().isLoading"
        >
            @if (vm().isLoading) {
            <ion-spinner name="crescent" />
            } @else { Next }
        </ion-button>
    </ng-container>
    }
</app-onboarding-legacy-question-template>
