<ion-content [fullscreen]="true" color="barney">
    <div class="finalize">
        <div class="page-content ion-text-center">
            <div class="finalize-image-container">
                <img
                    alt="finalize-onboarding-image"
                    class="base-image"
                    src="/assets/images/oliver/oliver-sits-and-looks-at-squiggly-path.svg"
                />
            </div>
            @if (dogName$ | async; as dogName) {
            <div class="finalize-text-container">
                <h1 data-test="finalize-page-title">Hang on {{dogName}}!<br />We're creating your personal program</h1>
                <div class="sub-text"></div>
            </div>
            }

            <div class="loading-components-box">
                <div class="loading-components">
                    <div class="loading-component">
                        <div class="icon-box">
                            @if (stage <= 0) {
                            <ion-spinner color="sophie" name="crescent"></ion-spinner>
                            } @else {
                            <ion-icon color="sophie" name="checkmark-outline"></ion-icon>
                            }
                        </div>
                        <h3>Reviewing your responses</h3>
                    </div>

                    <div class="loading-component">
                        <div class="icon-box">
                            @switch (stage) { @case (0) {
                            <ion-icon color="lola" name="ellipse-outline"></ion-icon>
                            } @case (1) {
                            <ion-spinner color="sophie" name="crescent"></ion-spinner>
                            } @default {
                            <ion-icon color="sophie" name="checkmark-outline"></ion-icon>
                            } }
                        </div>

                        <h3>Building tailored plan</h3>
                    </div>

                    <div class="loading-component">
                        <div class="icon-box">
                            @if (stage === 2) {
                            <ion-spinner color="sophie" name="crescent"></ion-spinner>
                            } @else if (this.stage > 2) {
                            <ion-icon color="sophie" name="checkmark-outline"></ion-icon>
                            } @else if (this.stage < 2) {
                            <ion-icon color="lola" name="ellipse-outline"></ion-icon>
                            }
                        </div>

                        <h3>Preparing your lessons</h3>
                    </div>

                    <div class="finalize-text-container">
                        @if (this.stage <= 1) {
                        <p>Hang on! {{progressBarPercentage}}%..</p>
                        } @else if (this.stage === 2) {
                        <p>Almost there! {{progressBarPercentage}}%..</p>
                        } @else if (this.stage >= 3) {
                        <p>Done! {{progressBarPercentage}}%..</p>
                        }
                    </div>
                    <div class="progress-bar">
                        <ion-progress-bar [value]="progressBarPercentage / 100"></ion-progress-bar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
