import { createSelector } from '@ngrx/store';
import { courseProgressFeature } from './course-progress.reducer';
import { CourseProgressType } from '@shared/user-domain';

export const selectCompletedCourseIds = createSelector(courseProgressFeature.selectProgress, (progress) => {
    return progress
        .filter((entry) => {
            return entry.progressType === CourseProgressType.COMPLETED;
        })
        .map((entry) => entry.courseId);
});
export const selectInProgressCourseIds = createSelector(courseProgressFeature.selectProgress, (progress) => {
    return progress
        .filter((entry) => {
            return entry.progressType === CourseProgressType.IN_PROGRESS;
        })
        .map((entry) => entry.courseId);
});
