import { OnboardingRoutes } from '../../onboarding-routing.model';

export const NUMBER_OF_ONBOARDING_LEGACY_PAGES = 12 as const;

export const NUMBER_OF_ONBOARDING_LEGACY_PAGES_US = 13 as const;

export const ONBOARDING_LEGACY_ROUTES_PROGRESS: Record<string, number> = {
    [OnboardingRoutes.NAME]: 2,
    [OnboardingRoutes.RESCUE]: 3,
    [OnboardingRoutes.AGE]: 4,
    [OnboardingRoutes.HAS_ARRIVED]: 5,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: 6,
    [OnboardingRoutes.ARRIVAL]: 6,
    [OnboardingRoutes.GENDER]: 7,
    [OnboardingRoutes.BREED]: 8,
    [OnboardingRoutes.OWNER_NAME]: 9,
    [OnboardingRoutes.NEEDS]: 10,
    [OnboardingRoutes.NOTIFICATIONS]: 11,
} as const;

export const ONBOARDING_LEGACY_ROUTES_CHECKPOINTS: Record<string, boolean> = {
    [OnboardingRoutes.NAME]: true,
    [OnboardingRoutes.RESCUE]: true,
    [OnboardingRoutes.AGE]: true,
    [OnboardingRoutes.HAS_ARRIVED]: true,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: false,
    [OnboardingRoutes.ARRIVAL]: false,
    [OnboardingRoutes.GENDER]: true,
    [OnboardingRoutes.BREED]: true,
    [OnboardingRoutes.OWNER_NAME]: true,
    [OnboardingRoutes.NEEDS]: false,
    [OnboardingRoutes.NOTIFICATIONS]: false,
} as const;

export const ONBOARDING_LEGACY_ROUTES_PROGRESS_US: Record<string, number> = {
    [OnboardingRoutes.NAME]: 2,
    [OnboardingRoutes.RESCUE]: 3,
    [OnboardingRoutes.AGE]: 4,
    [OnboardingRoutes.HAS_ARRIVED]: 5,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: 6,
    [OnboardingRoutes.ARRIVAL]: 6,
    [OnboardingRoutes.GENDER]: 7,
    [OnboardingRoutes.BREED]: 8,
    [OnboardingRoutes.OWNER_NAME]: 9,
    [OnboardingRoutes.NEEDS]: 10,
    [OnboardingRoutes.NOTIFICATIONS]: 11,
    [OnboardingRoutes.PURINA_OPT_IN]: 12,
} as const;

export const ONBOARDING_LEGACY_ROUTES_CHECKPOINTS_US: Record<string, boolean> = {
    [OnboardingRoutes.NAME]: true,
    [OnboardingRoutes.RESCUE]: true,
    [OnboardingRoutes.AGE]: true,
    [OnboardingRoutes.HAS_ARRIVED]: true,
    [OnboardingRoutes.FIRST_DAY_AT_HOME]: false,
    [OnboardingRoutes.ARRIVAL]: false,
    [OnboardingRoutes.GENDER]: true,
    [OnboardingRoutes.BREED]: true,
    [OnboardingRoutes.OWNER_NAME]: true,
    [OnboardingRoutes.NEEDS]: false,
    [OnboardingRoutes.NOTIFICATIONS]: true,
    [OnboardingRoutes.PURINA_OPT_IN]: true,
} as const;
