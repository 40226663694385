import { mapDateOfBirthToAgeInWeeks, mapDateToIterableDateFormat } from '@shared/utils/typescript';
import { BreedInfo } from '@frontend/data-access/contentful';
import { Profile } from '@frontend/data-access/user/account';
import { ConfigCatFlag } from '@frontend/data-access/user/config-cat';
import { APP_VERSION_NUMBER } from '@shared/constants';
import {
    InternalIterablePostEventCommand,
    InternalIterablePostPushOpenEventCommand,
    InternalIterableUpsertUserCommand,
} from '@shared/user-api-interface';
import { Breed, BreedType, Gender } from '@shared/user-domain';

export function mapUserToInternalIterableUpsertUserCommand(
    profile: Profile,
    profileId: string,
    deviceOs: string,
    featureFlags: string[],
    ipAddress?: string,
    breedInfo?: BreedInfo,
    stepsComplete?: number,
): InternalIterableUpsertUserCommand {
    let dateOfBirthMapped = undefined;
    let dateOfArrivalMapped = undefined;

    if (profile.dateOfBirth) {
        dateOfBirthMapped = mapDateToIterableDateFormat(profile.dateOfBirth);
    }

    if (profile.dateOfArrival) {
        dateOfArrivalMapped = mapDateToIterableDateFormat(profile.dateOfArrival);
    }

    return {
        email: profile.mail,
        dataFields: {
            ownerName: profile.ownerName,
            locale: profile.countryCode ? `en-${profile.countryCode}` : '',
            partners: profile.partners.map((partner) => partner.name),
            stepsComplete: stepsComplete || 0,
            userId: profileId,
            appVersion: APP_VERSION_NUMBER,
            deviceOs: deviceOs,
            ip: ipAddress || '',
            purinaMarketingAccepted: profile.purinaMarketingAccepted || undefined,
            enabledFeatureFlags: featureFlags,
            dogs: [
                {
                    dogName: profile.name,
                    breedGroup: breedInfo?.breedGroup.name || BreedType.RETRIEVERS,
                    breed: breedInfo?.name ?? Breed.MIXED_BREED,
                    ageInWeeks: mapDateOfBirthToAgeInWeeks(profile.dateOfBirth),
                    gender: profile.gender ?? Gender.TBD,
                    dateOfBirth: dateOfBirthMapped,
                    dateOfArrival: dateOfArrivalMapped,
                    isRescuePup: profile.isRescuePup ?? false,
                    hasArrived: profile.hasArrived ?? true,
                    isApproximateDateOfBirth: profile.isApproximateDateOfBirth ?? false,
                },
            ],
        },
    };
}

export function mapTrackEventToInternalIterablePostEventCommand(
    userId: string,
    eventName: string,
    eventProperties: Record<string, unknown> | undefined,
): InternalIterablePostEventCommand {
    return {
        userId,
        eventName,
        ...(eventProperties && { dataFields: eventProperties }),
        createdAt: new Date().getTime(),
    };
}

export function mapNotificationToInternalIterablePostPushOpenEventCommand(
    userId: string,
    campaignId: number,
    templateId: number,
    messageId: string,
): InternalIterablePostPushOpenEventCommand {
    return {
        userId,
        campaignId,
        templateId,
        messageId,
        createdAt: new Date().getTime(),
    };
}

export function mapFeatureFlagsForIterable(featureFlags: ConfigCatFlag[]): string[] {
    const activeFeatureFlags = featureFlags.filter((featureFlag) => featureFlag.flagValue);
    return activeFeatureFlags.map((activeFeatureFlag) => activeFeatureFlag.name);
}
