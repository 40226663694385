import { NgModule } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { provideEffects } from '@ngrx/effects';
import { appInsightsFactory } from './app-insights.factory';
import { APPLICATION_INSIGHTS_CONFIG } from './application-insights-injection-tokens';
import { ApplicationInsightsEffects } from './store/application-insights.effects';

@NgModule({
    providers: [
        provideEffects([ApplicationInsightsEffects]),
        {
            provide: ApplicationInsights,
            useFactory: appInsightsFactory,
            deps: [APPLICATION_INSIGHTS_CONFIG],
        },
    ],
})
export class ZigzagFrontendDataAccessApplicationInsightsModule {}
