<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="question-arrival page-content ion-justify-content-start">
        <section>
            <h1>When will {{ dogName() }} arrive home?</h1>
        </section>

        <form id="arrivalForm" [formGroup]="arrivalForm" (ngSubmit)="onSubmit()">
            <div class="onboarding-datetime">
                <ion-datetime-button
                    datetime="arrivalDatetime"
                    class="datetime-button-new-onboarding"
                    [disabled]="isLoading()"
                />
                <i class="onboarding-datetime__calendar-icon fa-regular fa-calendar"></i>
            </div>

            <ion-modal [keepContentsMounted]="true">
                <ng-template>
                    <ion-datetime
                        id="arrivalDatetime"
                        formControlName="dateOfArrival"
                        presentation="date"
                        [min]="currentDate() | date: 'yyyy-MM-dd' : '-1d'"
                        [showDefaultButtons]="true"
                    />
                </ng-template>
            </ion-modal>
        </form>
    </main>
</ion-content>

<ion-footer class="page-footer ion-text-center">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="question-arrival-next-btn"
        expand="block"
        type="submit"
        form="arrivalForm"
        data-test="question-arrival-next-btn"
        [disabled]="arrivalForm.invalid || isLoading()"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { Next }
    </ion-button>
</ion-footer>
