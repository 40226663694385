<app-onboarding-legacy-question-template
    backButtonId="question-owner-name-back-btn"
    title="What's your name?"
    subtitle="We'd love to refer to the dynamic duo as {{ vm().dogName }} and {{ form.controls.ownerName.value || ' ...' }}"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <form id="ownerNameForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <ion-item class="ion-margin-top">
                <ion-input
                    class="zz-input owner-name-input"
                    id="ownerName"
                    appTrackClick
                    identifier="question-mail-ownername-input"
                    autocapitalize="words"
                    autocomplete="given-name"
                    placeholder="Your name"
                    formControlName="ownerName"
                    type="text"
                    data-test="ownername-input"
                    [tabindex]="1"
                    [maxlength]="OWNER_NAME_MAX_LENGTH"
                    ><i slot="start" class="fa-light fa-user onboarding-icon"></i>
                </ion-input>
            </ion-item>
        </form>
    </ng-container>
    @if (!vm().isInputOpenOnAndroid) {
    <ion-footer footer>
        <ion-button
            appTrackClick
            identifier="question-owner-name-next-btn"
            expand="block"
            type="submit"
            form="ownerNameForm"
            data-test="question-owner-name-next-btn"
            [color]="Color.Max"
            [disabled]="form.invalid || vm().isLoading"
        >
            @if (vm().isLoading) {
            <ion-spinner name="crescent" />
            } @else { Next }
        </ion-button>
    </ion-footer>
    }
</app-onboarding-legacy-question-template>
