import { Injectable, inject } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { from, Observable, of } from 'rxjs';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    private readonly platform = inject(Platform);

    public logout(): Observable<void> {
        if (!this.platform.is('capacitor')) {
            console.info('[Mock Push Notifications] logout()');
            return of(undefined);
        }
        return from(PushNotifications.unregister());
    }

    async pushNotificationsEnabled(): Promise<boolean> {
        if (!this.platform.is('capacitor')) {
            console.info('[Mock Push Notifications] pushNotificationsEnabled()');
            return true;
        }

        const permissions = await PushNotifications.checkPermissions();

        return permissions.receive === 'granted';
    }

    async registerIterablePushNotifications() {
        if (!this.platform.is('capacitor')) {
            console.info('[Mock Push Notifications] registerIterablePushNotifications()');
            return;
        }

        let permissions = await PushNotifications.checkPermissions();

        if (permissions.receive === 'prompt') {
            permissions = await PushNotifications.requestPermissions();
        }

        if (permissions.receive === 'granted') {
            await PushNotifications.register();
        }
    }
}
