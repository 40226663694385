import { LoadingState } from '@frontend/data-access/shared-models';

export interface TodayCourseProgressState {
    todayCourseProgress: TodayCourseProgress[];
    loadingState: LoadingState;
}

export interface TodayCourseProgress {
    id: string;
    courseId: string;
    numberOfStepsCompleted: number;
    dogId: string | undefined;
}
