import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectCourses } from '@frontend/data-access/contentful';
import { getAccountSuccess } from '@frontend/data-access/user/account';
import {
    createCourseProgress,
    createCourseProgressSuccess,
    createManyCourseProgress,
    getCourseProgress,
} from '@frontend/data-access/user/progress';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { CourseProgressType } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { filter, map } from 'rxjs/operators';
import { showCourseCompletionModal } from '../../../courses/course-completion-modal/course-completion-modal.actions';
import { openToast } from '../../toast/toast.actions';
import {
    triggerCreateCourseProgress,
    triggerCreateManyCourseProgress,
    TRIGGERED_FROM_ONBOARDING_CORRELATION_ID,
} from './course-progress.actions';

@Injectable()
export class CourseProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    triggerGetCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(ofType(getAccountSuccess)).pipe(map(() => getCourseProgress()));
    });

    triggerCreateCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerCreateCourseProgress),
            map(({ courseProgress, correlationId }) => {
                return createCourseProgress({
                    courseProgress: {
                        courseId: courseProgress.id,
                        progressType: courseProgress.progressType,
                        timestamp: new Date().getTime(),
                    },
                    correlationId,
                });
            }),
        );
    });

    triggerCreateManyCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerCreateManyCourseProgress),
            map(({ courseProgress, correlationId }) => {
                const timestamp = new Date().getTime();

                const mappedCourseProgress = courseProgress.map((courseProgress) => {
                    return {
                        courseId: courseProgress.id,
                        progressType: courseProgress.progressType,
                        timestamp,
                    };
                });

                return createManyCourseProgress({
                    courseProgress: mappedCourseProgress,
                    correlationId,
                });
            }),
        );
    });

    createCourseProgressSuccessTrackEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCourseProgressSuccess),
            map(({ entry }) => {
                return trackEvent({
                    eventName: '[Course] Course Progress Created',
                    eventProperties: {
                        courseId: entry.courseId,
                        progress: entry.progressType,
                    },
                });
            }),
        );
    });

    createCourseProgressSuccessTriggerToast$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCourseProgressSuccess),
            filter(({ correlationId }) => {
                return correlationId !== TRIGGERED_FROM_ONBOARDING_CORRELATION_ID;
            }),
            filter(({ entry }) => {
                return entry.progressType !== CourseProgressType.COMPLETED;
            }),
            concatLatestFrom(() => this.store.select(selectCourses)),
            map(([{ entry }, courses]) => {
                const course = courses.find((course) => course.id === entry.courseId);

                return openToast({
                    message:
                        entry.progressType === CourseProgressType.IN_PROGRESS
                            ? 'Course added to Today page'
                            : 'Course removed from Today page',
                    color: course?.color ?? Color.Lola,
                });
            }),
        );
    });

    createCourseProgressSuccessTriggerCourseCompletionModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCourseProgressSuccess),
            filter(({ entry }) => entry.progressType === CourseProgressType.COMPLETED),
            map(({ entry }) => {
                return showCourseCompletionModal({ courseId: entry.courseId });
            }),
        );
    });
}
