import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { trackEvent } from '@frontend/data-access/analytics';
import { createUserAppAccessGrantedSuccess } from './user-app-access-granted.actions';

@Injectable()
export class UserAppAccessGrantedTrackingEffects {
    private readonly actions$ = inject(Actions);

    createUserAppAccessGrantedTracking$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserAppAccessGrantedSuccess),
            map(() => trackEvent({ eventName: '[Reverse Paywall] Free Access Granted' })),
        );
    });
}
