@if (vm$ | async; as vm) {
    <div data-test="developmental-windows" class="today-profile-section page-padding-sides">
        <div class="profile-title-image-section">
            <section class="profile-title-section">
                @if (vm.showDevelopmentalWindows) {
                    <h2 data-test="profile-title-section">
                        {{ vm.dogName + '\’s ' }}<span class="ion-text-nowrap">developmental age</span>
                    </h2>
                } @else {
                    <h2 data-test="profile-title-section">Welcome back, {{ vm.dogName }} and {{ vm.ownerName }}!</h2>
                }
            </section>
            <div class="profile-image-section" data-test="today-profile-image-clickable" (click)="routeToProfile()">
                <app-profile-image [size]="'48px'" borderWidth="2px" [type]="ProfileImageType.GO_TO_PROFILE" />
            </div>
        </div>
        @if (vm.showDevelopmentalWindows) {
            <app-developmental-window-timeline />
        }
    </div>
}
