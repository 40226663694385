<app-onboarding-legacy-question-template
    backButtonId="question-notifications-back-btn"
    title="Like to get training reminders?"
    subtitle="Want to receive occasional reminders on the development of your pup? Or your next lesson? Turn on your notifications."
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body></ng-container>
    <ng-container footer>
        <div class="onboarding-radio-button-container">
            <ion-button
                class="light-button-activated-jack"
                fill="outline"
                expand="block"
                appTrackClick
                data-test="question-notifications-yes-btn"
                identifier="question-notifications-yes-btn"
                [disabled]="vm().isLoading"
                (click)="continue(true)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { Yes }
            </ion-button>
            <ion-button
                class="light-button-activated-jack"
                fill="outline"
                expand="block"
                appTrackClick
                data-test="question-notifications-no-btn"
                identifier="question-notifications-no-btn"
                [disabled]="vm().isLoading"
                (click)="continue(false)"
            >
                @if (vm().isLoading) {
                <ion-spinner name="crescent" />
                } @else { No }
            </ion-button>
        </div>
    </ng-container>
</app-onboarding-legacy-question-template>
