import { AsyncPipe, Location } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { routeTo } from '@frontend/data-access/router';
import { loginWithApple, loginWithEmailAndPassword, loginWithGoogle } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { selectLoginPageVm } from '../../data-access/store/authentication.selectors';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss', '../authentication.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonContent,
        IonItem,
        IonInput,
        IonFooter,
        IonButton,
        IonSpinner,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class LoginPage {
    private readonly store = inject(Store);

    // TODO: NBSon - this is public for the sake of testing, but should be private
    public readonly location = inject(Location);

    @ViewChild('passwordInput')
    public input!: IonInput;

    public vm = this.store.selectSignal(selectLoginPageVm);

    public loginForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
    });
    public isPasswordVisible = false;

    protected readonly Color = Color;

    // TODO: NBSon - think about making this a getter? to differentiate between click handlers and other methods
    public showEmailError(): boolean {
        return this.loginForm.controls.email.dirty && this.loginForm.controls.email.invalid;
    }

    public onBackButtonPressed(): void {
        this.location.back();
    }

    public onPasswordVisibilityToggle(): void {
        this.isPasswordVisible = !this.isPasswordVisible;
        this.input.type = this.isPasswordVisible ? 'text' : 'password';
    }

    public onErrorLinkClick(route: string[]): void {
        this.store.dispatch(routeTo({ commands: route, isAnimated: true }));
    }

    public onSignInWithAppleClick(): void {
        this.store.dispatch(loginWithApple());
    }

    public onSignInWithGoogleClick(): void {
        this.store.dispatch(loginWithGoogle());
    }

    public onForgotPasswordClick(): void {
        this.store.dispatch(routeTo({ commands: ['auth', 'reset-password'], isAnimated: true }));
    }

    public onLoginClick(): void {
        this.store.dispatch(
            loginWithEmailAndPassword({
                email: this.loginForm.value.email as string,
                password: this.loginForm.value.password as string,
            }),
        );
    }
}
