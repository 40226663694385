import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { deleteAccount, deleteAccountFailure, deleteAccountSuccess } from './delete-account.actions';

@Injectable()
export class DeleteAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly accountService = inject(AccountService);
    deleteAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteAccount),
            concatMap(() =>
                this.accountService.deleteAccount().pipe(
                    map(() => deleteAccountSuccess()),
                    catchError(({ error }: { error: Error }) => of(deleteAccountFailure({ error }))),
                ),
            ),
        );
    });
}
