import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { AppReadyStatus, changeAppReadyStatus, selectAppComponentVm } from '@frontend/zigzag-main';
import { IonApp, IonRouterOutlet, IonSpinner, Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment/environment';
import { initAnalytics } from '@frontend/data-access/analytics';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [AsyncPipe, IonApp, IonSpinner, IonRouterOutlet],
})
export class AppComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);

    public vm = this.store.selectSignal(selectAppComponentVm);

    ngOnInit(): void {
        // send this event as soon as the app opens for Pierres analytics
        this.store.dispatch(initAnalytics());
        // Attach store to window for Cypress, allows dispatching of actions in tests
        if (environment.attachStoreToWindow && (window as any)['Cypress']) {
            (window as any)['Cypress.store'] = this.store;
        }

        this.store.dispatch(
            changeAppReadyStatus({
                status: this.platform.is('capacitor') ? AppReadyStatus.loading : AppReadyStatus.ready,
            }),
        );
    }
}
