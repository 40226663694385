import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { AdjustService } from '../adjust.service';
import { adjustRequestTrackingConsent, initAdjust, trackAdjustEvent } from './adjust.actions';

@Injectable()
export class AdjustEffects {
    private readonly actions$ = inject(Actions);
    private readonly adjustService = inject(AdjustService);

    adjustInit$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initAdjust),
                tap(() => this.adjustService.initialize()),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    adjustRequestTrackingConsent$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(adjustRequestTrackingConsent),
                tap((): void => void this.adjustService.requestTrackingAuthorization()),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trackAdjustEvent$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(trackAdjustEvent),
                tap((action) => this.adjustService.trackEvent(action.event)),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
