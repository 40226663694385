<ion-content [fullscreen]="true">
    <div
        class="payment-modal-parent ion-align-items-center"
        [ngClass]="!isTieredPricingPaywallAb() ? 'payment-modal-parent__squiggles' : ''"
    >
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="false"
                    [rightButton]="{
                        analyticsIdentifier: 'close-parent-payment-modal-btn',
                        sprite: 'light',
                        iconName: 'xmark',
                        background: 'white',
                        iconColour: 'black',
                    }"
                    (rightButtonPressed)="onCloseClick()"
                />
            </ion-toolbar>
        </ion-header>

        <img class="payment-modal-parent__logo" [src]="'assets/images/zigzag/zigzag-logo.png'" alt="The Zigzag Logo" />

        @if (isTieredPricingPaywallAb()) {
            <app-payment-modal-tiered-pricing />
        } @else {
            @if (showDiscountProducts) {
                <app-payment-modal-discount-products />
            } @else {
                <app-payment-modal-default />
            }
        }
    </div>
</ion-content>
