import { inject, Injectable } from '@angular/core';
import { getGeoLocationSuccess } from '@frontend/data-access/geo-location';
import { accountFeature, getAccountSuccess, updateAccount } from '@frontend/data-access/user/account';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { zip } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { setAnalyticsProfile } from '@frontend/data-access/analytics';
import { selectAnalyticsProfile } from '../../analytics/analytics.selectors';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from './account.constants';

@Injectable()
export class UpdateAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    patchCountryCode$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getAccountSuccess)),
            this.actions$.pipe(ofType(getGeoLocationSuccess)),
        ]).pipe(
            concatLatestFrom(() => {
                return [this.store.select(selectAnalyticsProfile), this.store.select(accountFeature.selectId)];
            }),
            filter(([[{ profile }], , profileId]) => !profile.countryCode && !!profileId),
            switchMap(([[, getGeoLocationSuccess], analyticsProfile, id]) => [
                updateAccount({
                    command: { countryCode: getGeoLocationSuccess.data.countryCode },
                    correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                }),
                setAnalyticsProfile({ profile: analyticsProfile, id: id! }),
            ]),
        );
    });
}
