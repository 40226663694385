import { createSelector } from '@ngrx/store';
import { selectAgeInWeeks, selectOwnerAndDogName } from '@frontend/data-access/user/account';

export const selectTodayDevelopmentalWindowVm = createSelector(
    selectOwnerAndDogName,
    selectAgeInWeeks,
    ({ ownerName, dogName }, ageInWeeks) => {
        return {
            ownerName,
            dogName,
            showDevelopmentalWindows: ageInWeeks !== undefined && ageInWeeks < 104,
        };
    },
);
