import { Component, inject, input, Input } from '@angular/core';
import { IonNav, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';
import { PaymentModalParentComponent } from './payment-modal-parent/payment-modal-parent.component';
import { PaymentModalTrialReminderComponent } from './payment-modal-trial-reminder/payment-modal-trial-reminder.component';

@Component({
    standalone: true,
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    imports: [IonNav, PaymentModalParentComponent, PaymentModalTrialReminderComponent],
})
export class PaymentModalComponent implements ViewWillEnter, ViewWillLeave {
    private readonly platform = inject(Platform);

    @Input()
    public pageComponent: typeof PaymentModalParentComponent | typeof PaymentModalTrialReminderComponent =
        PaymentModalParentComponent;

    @Input()
    public showDiscountProducts = false;

    public trigger = input<string>();

    private backButtonSub = new Subscription();

    public ionViewWillEnter(): void {
        // Prevent the user from closing the modal(s) by pressing the back button
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(100, () => {
            return;
        });
    }

    public ionViewWillLeave() {
        this.backButtonSub.unsubscribe();
    }
}
