import {
    CustomerInfo,
    PACKAGE_TYPE,
    PurchasesEntitlementInfo,
    PurchasesOffering,
    PurchasesStoreProductDiscount,
} from '@awesome-cordova-plugins/purchases/ngx';
import getSymbolFromCurrency from 'currency-symbol-map';
import { RevenueCatPackageDescriptions } from './revenue-cat/revenue-cat.constants';
import {
    BaseSubscriptionOffering,
    Entitlement,
    ProductDiscount,
    SubscriptionPeriodType,
} from './store/models/subscription.model';

export const mapPurchaserInfoToEntitlement = (customerInfo: CustomerInfo): Entitlement[] => {
    const allEntitlements: Record<string, PurchasesEntitlementInfo> = customerInfo.entitlements.all;

    return Object.values(allEntitlements).map(({ isActive, identifier, periodType, expirationDate }) => ({
        isActive,
        identifier,
        periodType: periodType as SubscriptionPeriodType,
        expirationDate: expirationDate ? new Date(expirationDate) : undefined,
    }));
};

export const mapPurchasesOfferingToBaseSubscriptionOffering = (
    purchasesOffering: PurchasesOffering,
): BaseSubscriptionOffering => {
    return {
        identifier: purchasesOffering.identifier,
        availablePackages: purchasesOffering.availablePackages?.map((aPackage) => ({
            identifier: aPackage.identifier,
            packageType: aPackage.packageType,
            description: RevenueCatPackageDescriptions.get(aPackage.packageType),
            product: {
                identifier: aPackage.product.identifier,
                description: aPackage.product.description,
                title: aPackage.product.title.replace(/ *\([^)]*\) */g, ''),
                currencySymbol: getSymbolFromCurrency(aPackage.product.currencyCode) ?? '',
                price: aPackage.product.price,
                priceWithUnit: aPackage.product.priceString,
                pricePerMonth: convertToPricePerMonth(aPackage.product.price, aPackage.packageType),
                introPricePeriod: mapIntroPricePeriodToText(
                    aPackage.product.introPrice?.periodNumberOfUnits ?? 0,
                    aPackage.product.introPrice?.periodUnit ?? '',
                ),
                discount:
                    aPackage.product.discounts && aPackage.product.discounts.length > 0
                        ? mapPurchasesStoreProductDiscountToProductDiscount(
                              aPackage.product.discounts[0],
                              aPackage.packageType,
                              aPackage.product.price,
                          )
                        : undefined,
            },
        })),
    } as BaseSubscriptionOffering;
};

const mapPackageTypeToMonths = (packageType: PACKAGE_TYPE): number | undefined => {
    switch (packageType) {
        case PACKAGE_TYPE.ANNUAL:
            return 12;
        case PACKAGE_TYPE.SIX_MONTH:
            return 6;
        case PACKAGE_TYPE.THREE_MONTH:
            return 3;
        case PACKAGE_TYPE.TWO_MONTH:
            return 2;
        case PACKAGE_TYPE.MONTHLY:
            return 1;
        case PACKAGE_TYPE.WEEKLY:
            return 0.25;
        default:
            return undefined;
    }
};

const mapIntroPricePeriodToText = (numberOfUnits: number, unit: string): string | undefined => {
    switch (unit) {
        case 'DAY':
            return pluralCheck(numberOfUnits, `${+numberOfUnits} day`);
        case 'WEEK':
            return `${+(numberOfUnits * 7)} days`;
        case 'MONTH':
            return pluralCheck(numberOfUnits, `${+numberOfUnits} month`);
        case 'YEAR':
            return pluralCheck(numberOfUnits, `${+numberOfUnits} year`);
        default:
            return undefined;
    }
};

const pluralCheck = (units: number, period: string) => (units > 1 ? `${period}s` : period);

const convertToPricePerMonth = (price: number, packageType: PACKAGE_TYPE): number | undefined => {
    const durationInMonths = mapPackageTypeToMonths(packageType);
    return durationInMonths ? price / durationInMonths : undefined;
};

const mapPurchasesStoreProductDiscountToProductDiscount = (
    discount: PurchasesStoreProductDiscount,
    packageType: PACKAGE_TYPE,
    originalPrice: number,
): ProductDiscount => ({
    identifier: discount.identifier,
    price: discount.price,
    priceWithUnit: discount.priceString,
    pricePerMonth: convertToPricePerMonth(discount.price, packageType),
    discountPercentage: (1 - discount.price / originalPrice) * 100,
});
