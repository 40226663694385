import { inject, Injectable } from '@angular/core';
import { timeFeature } from '@frontend/data-access/time';
import {
    createStepProgress,
    createTodayCourseProgress,
    todayCourseProgressFeature,
    updateTodayCourseProgress,
} from '@frontend/data-access/user/progress';
import { LessonProgressType } from '@shared/user-domain';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { selectNextThreeStepsCardItemForAllCourses } from '../../../today/today-courses/today-courses.selectors';

@Injectable()
export class TodayProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    // TODO: GP: Handle on createStepProgressSuccess instead of createStepProgress
    createTodayCourseProgressWhenStepCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgress),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progressType === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => [
                this.store.select(selectNextThreeStepsCardItemForAllCourses),
                this.store.select(todayCourseProgressFeature.selectTodayCourseProgress),
            ]),
            map(([stepProgress, courseCardItems, todayCourseProgress]) => {
                // TODO: GP: Make possible to handle arrays
                const courseIdsIncludingStep = courseCardItems
                    .filter((cardItem) =>
                        cardItem.cardItemsData.some((item) => item.contentId === stepProgress[0].contentId),
                    )
                    .map((cardItem) => cardItem.courseId);

                return courseIdsIncludingStep.filter(
                    (courseId) => !todayCourseProgress.some((progress) => progress.courseId === courseId),
                );
            }),
            filter((courseIds) => !!courseIds.length),
            concatLatestFrom(() => [this.store.select(timeFeature.selectToday)]),
            mergeMap(([courseIds, today]) => {
                return courseIds.map((courseId) => {
                    return createTodayCourseProgress({
                        command: {
                            courseId,
                            numberOfStepsCompleted: 1,
                            date: today,
                        },
                    });
                });
            }),
        );
    });

    // TODO: GP: Handle on createStepProgressSuccess instead of createStepProgress
    updateTodayCourseProgressWhenStepCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgress),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progressType === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => [
                this.store.select(selectNextThreeStepsCardItemForAllCourses),
                this.store.select(todayCourseProgressFeature.selectTodayCourseProgress),
            ]),
            map(([stepProgress, courseCardItems, todayCourseProgress]) => {
                // TODO: GP: Make possible to handle arrays
                const courseIdsIncludingStep = courseCardItems
                    .filter((cardItem) =>
                        cardItem.cardItemsData.some((item) => item.contentId === stepProgress[0].contentId),
                    )
                    .map((cardItem) => cardItem.courseId);

                return todayCourseProgress.filter((progress) => courseIdsIncludingStep.includes(progress.courseId));
            }),
            filter((todayCourseProgress) => !!todayCourseProgress.length),
            concatLatestFrom(() => [this.store.select(timeFeature.selectToday)]),
            mergeMap(([todayCourseProgress, today]) => {
                return todayCourseProgress.map((todayCourseProgress) => {
                    return updateTodayCourseProgress({
                        id: todayCourseProgress.id,
                        command: {
                            numberOfStepsCompleted: todayCourseProgress.numberOfStepsCompleted + 1,
                            date: today,
                        },
                    });
                });
            }),
        );
    });
}
