import { CallState, LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { FoodPortion, FoodPortionQuery, FoodProduct } from '../food-calculator.model';
import {
    foodPortionQueryUpdated,
    getFoodPortion,
    getFoodPortionFailure,
    getFoodPortionSuccess,
    loadExpectedWeightSuccess,
    loadFoodProducts,
    loadFoodProductsFailure,
    loadFoodProductsSuccess,
} from './food-calculator.actions';
import { getLocalStorageSuccess } from '@frontend/data-access/capacitor';
import { FOOD_CALCULATOR_LOCAL_STORAGE_KEY } from './food-calculator.constants';

export interface FoodCalculatorState {
    foodProducts: FoodProduct[];
    foodProductsLoadingState: CallState;
    foodPortion: FoodPortion | undefined;
    foodPortionLoadingState: CallState;
    foodPortionQuery: Partial<FoodPortionQuery>;
    foodPortionLastCalculated: string | undefined;
}

export const initialState: FoodCalculatorState = {
    foodProducts: [],
    foodProductsLoadingState: LoadingState.INIT,
    foodPortion: undefined,
    foodPortionLoadingState: LoadingState.INIT,
    foodPortionQuery: {
        currentWeight: 4,
    },
    foodPortionLastCalculated: undefined,
};

export const foodCalculatorFeature = createFeature({
    name: 'foodCalculator',
    reducer: createReducer(
        initialState,
        on(getLocalStorageSuccess, (state, { key, data }): FoodCalculatorState => {
            if (key !== FOOD_CALCULATOR_LOCAL_STORAGE_KEY) {
                return state;
            }

            return { ...state, ...(data as any) };
        }),
        on(
            foodPortionQueryUpdated,
            (state, { foodPortionQuery }): FoodCalculatorState => ({
                ...state,
                foodPortionQuery: {
                    ...state.foodPortionQuery,
                    ...foodPortionQuery,
                },
            }),
        ),
        on(
            loadFoodProducts,
            (state): FoodCalculatorState => ({ ...state, foodProductsLoadingState: LoadingState.LOADING }),
        ),
        on(
            loadFoodProductsSuccess,
            (state, { foodProducts }): FoodCalculatorState => ({
                ...state,
                foodProductsLoadingState: LoadingState.LOADED,
                foodProducts,
            }),
        ),
        on(
            loadFoodProductsFailure,
            (state, { error }): FoodCalculatorState => ({
                ...state,
                foodProductsLoadingState: { errorMsg: error.message },
                foodProducts: [],
            }),
        ),
        on(
            getFoodPortion,
            (state, { foodPortionQuery }): FoodCalculatorState => ({
                ...state,
                foodPortionLoadingState: LoadingState.LOADING,

                foodPortionQuery,
            }),
        ),
        on(
            getFoodPortionSuccess,
            (state, { amountPerDay, currentDate }): FoodCalculatorState => ({
                ...state,
                foodPortionLoadingState: LoadingState.LOADED,
                foodPortionLastCalculated: currentDate.toISOString(),
                foodPortion: { amount: amountPerDay },
            }),
        ),
        on(
            getFoodPortionFailure,
            (state, { error }): FoodCalculatorState => ({
                ...state,
                foodPortionLoadingState: { errorMsg: error.message },
                foodPortion: undefined,
            }),
        ),
        on(
            loadExpectedWeightSuccess,
            (state, { expectedWeight }): FoodCalculatorState => ({
                ...state,
                foodPortionQuery: {
                    ...state.foodPortionQuery,
                    expectedWeight,
                },
            }),
        ),
    ),
});
