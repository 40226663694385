<!-- Thumbs Up -->
@if (rating !== ConversationMessageRatingType.POSITIVE) {
    <button
        class="zz-button chat-message-rating-thumb xl"
        data-test="chat-message-rating-positive-btn"
        (click)="onClickRating(ConversationMessageRatingType.POSITIVE)"
    >
        <i class="fa-light fa-thumbs-up chat-message-rating-thumb__icon"></i>
    </button>
}

@if (rating === ConversationMessageRatingType.POSITIVE) {
    <button
        class="zz-button chat-message-rating-thumb xl"
        data-test="chat-message-rating-positive-selected-btn"
        (click)="onClickRating(ConversationMessageRatingType.POSITIVE)"
    >
        <i class="fa-solid fa-thumbs-up chat-message-rating-thumb__icon chat-message-rating-thumb__icon--selected"></i>
    </button>
}

<!-- Thumbs Down -->
@if (rating !== ConversationMessageRatingType.NEGATIVE) {
    <button
        class="zz-button chat-message-rating-thumb xl"
        data-test="chat-message-rating-negative-btn"
        (click)="onClickRating(ConversationMessageRatingType.NEGATIVE)"
    >
        <i class="fa-light fa-thumbs-down chat-message-rating-thumb__icon chat-message-rating-thumb__icon--down"></i>
    </button>
}

@if (rating === ConversationMessageRatingType.NEGATIVE) {
    <button
        class="zz-button chat-message-rating-thumb xl"
        data-test="chat-message-rating-negative-selected-btn"
        (click)="onClickRating(ConversationMessageRatingType.NEGATIVE)"
    >
        <i
            class="fa-solid fa-thumbs-down chat-message-rating-thumb__icon chat-message-rating-thumb__icon--down chat-message-rating-thumb__icon--selected"
        ></i>
    </button>
}
