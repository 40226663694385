import { NgClass, NgStyle } from '@angular/common';
import { Component, inject, input, Input, OnInit } from '@angular/core';
import { BreedInfo } from '@frontend/data-access/contentful';
import { HeaderComponent } from '@frontend/feature/header';
import { SearchBarComponent } from '@frontend/ui';
import { ModalService } from '@frontend/utility/modal';
import { IonChip, IonContent, IonHeader, IonItem, IonLabel, IonList, IonToolbar } from '@ionic/angular/standalone';
import MiniSearch, { SearchResult } from 'minisearch';

@Component({
    selector: 'app-breed-selector-modal',
    templateUrl: './breed-selector-modal.component.html',
    styleUrl: './breed-selector-modal.component.scss',
    standalone: true,
    imports: [
        SearchBarComponent,
        IonChip,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        HeaderComponent,
        IonHeader,
        IonToolbar,
        NgClass,
        NgStyle,
    ],
})
export class BreedSelectorModalComponent implements OnInit {
    private readonly modalService = inject(ModalService);

    @Input({ required: true })
    public set breedOptions(breeds: BreedInfo[]) {
        this.allBreedOptions = breeds;
        this.miniSearch.removeAll();
        this.miniSearch.addAll(breeds);
    }

    public isNewOnboarding = input<boolean>();

    public results: BreedInfo[] = [];

    private allBreedOptions: BreedInfo[] = [];
    private miniSearch: MiniSearch<BreedInfo>;

    constructor() {
        this.miniSearch = new MiniSearch({
            fields: ['id', 'name', 'breedGroup'],
            storeFields: ['id', 'name', 'breedGroup'],
            idField: 'id',
            searchOptions: {
                fuzzy: 0.25,
                prefix: true,
            },
        });
    }

    public ngOnInit(): void {
        this.searchItems('');
    }

    public searchItems(event: string): void {
        if (event === '') {
            this.results = [...this.allBreedOptions];
        } else {
            this.results = this.miniSearch.search(event).map((searchResult: SearchResult) => {
                const { id, name, breedGroup } = searchResult;

                return {
                    id,
                    name,
                    breedGroup,
                };
            });
        }
    }

    public back(): void {
        void this.modalService.dismiss();
    }

    public selectBreed(breedOption: BreedInfo): void {
        void this.modalService.dismiss(breedOption);
    }
}
