import { createFeature, createReducer, on } from '@ngrx/store';
import { aiChatBackButtonClicked, aiChatInputSubmitted, hideAiChatView, showAiChatView } from './today.page.actions';

export interface TodayPageState {
    showAiChatView: boolean;
}

export const initialState: TodayPageState = {
    showAiChatView: false,
};

export const todayPageFeature = createFeature({
    name: 'todayPage',
    reducer: createReducer(
        initialState,
        on(showAiChatView, (state): TodayPageState => ({ ...state, showAiChatView: true })),
        on(
            aiChatBackButtonClicked,
            aiChatInputSubmitted,
            hideAiChatView,
            (state): TodayPageState => ({
                ...state,
                showAiChatView: false,
            }),
        ),
    ),
});
