import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { UserAppAccessGrantedService } from '../services/user-app-access-granted.service';
import { getAccountSuccess } from '@frontend/data-access/user/account';
import {
    createUserAppAccessGranted,
    createUserAppAccessGrantedFailure,
    createUserAppAccessGrantedSuccess,
    getUserAppAccessGrantedFailure,
    getUserAppAccessGrantedSuccess,
    patchUserAppAccessGranted,
    patchUserAppAccessGrantedFailure,
    patchUserAppAccessGrantedSuccess,
} from './user-app-access-granted.actions';

@Injectable()
export class UserAppAccessGrantedEffects {
    private readonly actions$ = inject(Actions);
    private readonly userAppAccessGrantedService = inject(UserAppAccessGrantedService);

    getUserAppAccessGranted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountSuccess),
            concatMap(() =>
                this.userAppAccessGrantedService.getUserAppAccessGranted().pipe(
                    map((appAccessGranted) =>
                        getUserAppAccessGrantedSuccess({ userAppAccessGranted: appAccessGranted }),
                    ),
                    catchError((error: Error) => of(getUserAppAccessGrantedFailure({ error }))),
                ),
            ),
        );
    });

    createUserAppAccessGranted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserAppAccessGranted),
            concatMap(({ postUserAppAccessGrantedCommand }) => {
                return this.userAppAccessGrantedService
                    .createUserAppAccessGranted(postUserAppAccessGrantedCommand)
                    .pipe(
                        map((userAppAccessGranted) => createUserAppAccessGrantedSuccess({ userAppAccessGranted })),
                        catchError((error: Error) => of(createUserAppAccessGrantedFailure({ error }))),
                    );
            }),
        );
    });

    patchUserAppAccessGranted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchUserAppAccessGranted),
            concatMap(({ id, patchUserAppAccessGranted }) => {
                return this.userAppAccessGrantedService
                    .patchUserAppAccessGranted(id, {
                        openedAppAfterExpiry: patchUserAppAccessGranted.openedAppAfterExpiry,
                    })
                    .pipe(
                        map((appAccessGranted) =>
                            patchUserAppAccessGrantedSuccess({ userAppAccessGranted: appAccessGranted }),
                        ),
                        catchError((error: Error) => of(patchUserAppAccessGrantedFailure({ error }))),
                    );
            }),
        );
    });
}
