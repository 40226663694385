import { createFeature, createReducer, on } from '@ngrx/store';
import { capacitorAppStateChange } from './app-listeners.actions';

export interface CapacitorAppState {
    isActive: boolean;
}

export interface CapacitorListenersState {
    app: CapacitorAppState;
}

export const initialState: CapacitorListenersState = {
    app: {
        isActive: false,
    },
};

export const capacitorListenersFeature = createFeature({
    name: 'capacitor-listeners',
    reducer: createReducer(
        initialState,
        on(
            capacitorAppStateChange,
            (state, action): CapacitorListenersState => ({
                ...state,
                app: {
                    ...state.app,
                    isActive: action.isActive,
                },
            }),
        ),
    ),
});
