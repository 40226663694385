import { TabButton } from '@frontend/ui';

export const TabRoute_TODAY = 'today' as const;
export const TabRoute_COURSES = 'courses' as const;
export const TabRoute_LIBRARY = 'library' as const;
export const TabRoute_FOOD_CALCULATOR = 'food-calculator' as const;
export const TabRoute_SUPPORT = 'support' as const;
export const ALL_TAB_ROUTES = [
    TabRoute_TODAY,
    TabRoute_COURSES,
    TabRoute_LIBRARY,
    TabRoute_FOOD_CALCULATOR,
    TabRoute_SUPPORT,
] as const;
export type TabRoutes = (typeof ALL_TAB_ROUTES)[number];

export const tabRoutes = {
    today: 'today',
    courses: 'courses',
    library: 'library',
    foodCalculator: 'food-calculator',
    support: 'support',
};

export const tabButtons: TabButton[] = [
    {
        tab: TabRoute_TODAY,
        label: 'Home',
        iconSrc: 'assets/images/illustrations/tabs/tab-today-icon.svg',
        identifier: 'tab-to-today-btn',
    },
    {
        tab: TabRoute_COURSES,
        label: 'My Journey',
        iconSrc: 'assets/images/illustrations/tabs/tab-journey-icon.svg',
        identifier: 'tab-to-courses-btn',
    },
    {
        tab: TabRoute_LIBRARY,
        label: 'Library',
        iconSrc: 'assets/images/illustrations/tabs/tab-library-icon.svg',
        identifier: 'tab-to-library-btn',
    },
    {
        tab: TabRoute_FOOD_CALCULATOR,
        label: 'Calculator',
        iconSrc: 'assets/images/illustrations/tabs/tab-food-calculator-icon.svg',
        identifier: 'tab-to-food-calculator-btn',
    },
    {
        tab: TabRoute_SUPPORT,
        label: 'My Coach',
        iconSrc: 'assets/images/illustrations/tabs/tab-my-coach-icon.svg',
        identifier: 'tab-to-support-btn',
    },
];
