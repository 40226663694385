import { Component, Input } from '@angular/core';
import { RichContentPipe } from '@frontend/ui';
import { RichTextContent } from 'contentful';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-dynamic-content-block',
    standalone: true,
    imports: [RichContentPipe, TextReplacementPipe],
    templateUrl: './dynamic-content-block.component.html',
})
export class DynamicContentBlockComponent {
    @Input({ required: true }) title!: string;
    @Input({ required: true }) content!: RichTextContent;
}
