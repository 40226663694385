import { selectContentfulTopics, Topic } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { pinnedTopicFeature } from '@frontend/data-access/user/pinned-topics';

export const selectTopics = createSelector(
    selectContentfulTopics,
    pinnedTopicFeature.selectPinnedTopics,
    (topics, pinnedTopics): Topic[] =>
        topics.map((topic) => {
            let isPinned = false;
            if (pinnedTopics) {
                isPinned = pinnedTopics.some((pinnedTopic) => pinnedTopic.topicId === topic.entityId);
            }
            return {
                ...topic,
                pinned: isPinned,
            };
        }),
);
