import { Color } from '@shared/utils/typescript';
import { DevelopmentalWindow } from '@frontend/data-access/contentful';
import { RichTextContent } from 'contentful';

export function getDevelopmentalWindowTimelineProgressPercentage(ageInDays: number | undefined): number {
    let progressPercentage: number;
    if (ageInDays === undefined) {
        progressPercentage = 0;
    } else if (ageInDays < 84) {
        progressPercentage = ((ageInDays / 84) * 100) / 3;
    } else if (ageInDays < 150) {
        const ageInDaysMinusFirstPeriod = ageInDays - 84;
        const secondPeriodLength = 150 - 84;

        progressPercentage = 33.3 + ((ageInDaysMinusFirstPeriod / secondPeriodLength) * 100) / 3;
    } else if (ageInDays < 730) {
        const ageInDaysMinusFirstTwoPeriods = ageInDays - 150;
        const thirdPeriodLength = 730 - 150;

        progressPercentage = 66.6 + ((ageInDaysMinusFirstTwoPeriods / thirdPeriodLength) * 100) / 3;
    } else {
        progressPercentage = 100;
    }
    return Number(progressPercentage.toFixed(2));
}

export function getDevelopmentalWindowSubtitles(
    developmentalWindow: DevelopmentalWindow,
    isFirstWindow: boolean,
    ageInWeeks: number | undefined,
    ageInDays: number | undefined,
    hasArrived: boolean,
    dogName: string,
) {
    if (ageInWeeks === undefined || ageInDays === undefined) {
        return '';
    }
    if (!hasArrived && isFirstWindow && ageInWeeks <= 12) {
        return `${dogName} is ${ageInWeeks} week${ageInWeeks === 1 ? '' : 's'} old`;
    }

    if (ageInDays < developmentalWindow.startAtDays) {
        const daysTillWindowStarts = developmentalWindow.startAtDays - ageInDays;

        if (daysTillWindowStarts === 1) {
            return `Starts <b>tomorrow</b> at ${developmentalWindow.startAtText}`;
        }

        return `Starts in <b>${daysTillWindowStarts}</b> days at ${developmentalWindow.startAtText}`;
    }

    if (ageInDays <= developmentalWindow.endAtDays) {
        const daysTillWindowEnds = developmentalWindow.endAtDays - ageInDays;

        if (daysTillWindowEnds === 0) {
            return 'Ends <b>today</b>';
        }

        if (daysTillWindowEnds === 1) {
            return 'Ends <b>tomorrow</b>';
        }

        return `Ends in <b>${daysTillWindowEnds}</b> days`;
    }
    return '';
}

export function getDevelopmentalWindowModalColors(color: Color): { modalColor: Color; modalSecondaryColor: Color } {
    if (color === Color.Charly) {
        return { modalColor: Color.Charly, modalSecondaryColor: Color.Lola };
    }

    if (color === Color.Sophie) {
        return { modalColor: Color.Sophie, modalSecondaryColor: Color.Bailey };
    }

    if (color === Color.Max) {
        return { modalColor: Color.Luna, modalSecondaryColor: Color.Bella };
    }

    return { modalColor: Color.Charly, modalSecondaryColor: Color.Lola };
}

export function createDevelopmentalWindowInfoAccordions(
    developmentalWindow: DevelopmentalWindow,
): { title: string; content: RichTextContent }[] {
    const accordionArray = [];

    if (developmentalWindow.textImportance) {
        accordionArray.push({ title: 'Why is this important?', content: developmentalWindow.textImportance });
    }

    if (developmentalWindow.textProgramme) {
        accordionArray.push({ title: 'Our programme', content: developmentalWindow.textProgramme });
    }

    if (developmentalWindow.textCourses) {
        accordionArray.push({ title: 'Our courses', content: developmentalWindow.textCourses });
    }

    if (developmentalWindow.textNextCourse) {
        accordionArray.push({ title: "What's next", content: developmentalWindow.textNextCourse });
    }

    return accordionArray;
}
