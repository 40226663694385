import { InternalProgressDto } from '@shared/user-api-interface';
import { TodayCourseProgress } from '../models/today-course-progress.model';

export const mapInternalProgressDtoToCourseTodayProgress = (
    internalProgressDto: InternalProgressDto[],
): TodayCourseProgress[] => {
    return internalProgressDto.map((progress) => ({
        id: progress.id,
        courseId: progress.courseId,
        numberOfStepsCompleted: progress.numberOfStepsCompleted,
        dogId: progress.dogId,
    }));
};
