import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../../data-access/onboarding.selectors';
import { accountFeature } from '@frontend/data-access/user/account';

export const selectQuestionArrivalLegacyPageVm = createSelector(
    selectOnboardingProgress,
    accountFeature.selectDogName,
    timeFeature.selectToday,
    onboardingFeature.selectIsLoading,
    (progress, dogName, currentDate, isLoading) => ({
        progress,
        dogName,
        currentDate,
        isLoading,
    }),
);
