import { LoadingState } from '@frontend/data-access/shared-models';
import { StepProgressType } from '@shared/user-domain';

export interface StepProgressState {
    stepProgress: StepProgress[];
    loadingState: LoadingState;
}

export interface StepProgress {
    id: string;
    stepId: string;
    contentId: number;
    progress: StepProgressType;
    rating?: number;
    isPractice: boolean;
    timestamp: number;
    dogId: string | undefined;
}

export interface StepProgressPayload {
    stepId: string;
    contentId: number;
    progressType: StepProgressType;
    isPractice: boolean;
    rating?: number;
}
