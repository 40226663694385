<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Reset Password"
            titleColour="white"
            [leftButton]="{analyticsIdentifier:'reset-password-check-email-back-btn', background: 'none', iconColour: 'white'}"
            [rightButton]="false"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="authentication-ion-content reset-password-check-email-ion-content" [fullscreen]="true">
    <main class="page-content">
        <section>
            <h1 data-test="check-email-title">Check your email</h1>
            @if(email){
            <p data-test="check-email-explanation">
                We've sent an email to {{ email }}. Be sure to open the email on your phone.
            </p>
            }
        </section>
    </main>
</ion-content>

<ion-footer class="check-email-page-footer authentication-ion-footer ion-text-center page-footer">
    <button
        data-test="no-email-received"
        class="zz-button check-email-page-footer__not-received"
        appTrackClick
        identifier="no-email-received"
        (click)="routeToPrevious()"
    >
        I didn't receive an email
    </button>
    <ion-button
        color="max"
        appTrackClick
        data-test="check-mail-sign-in-btn"
        identifier="check-mail-sign-in-btn"
        expand="block"
        (click)="routeToLogin()"
        >Sign in
    </ion-button>
</ion-footer>
