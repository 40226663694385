import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { stepsFeature } from '@frontend/data-access/contentful';
import { skipWhile } from 'rxjs';
import { LoadingState } from '@frontend/data-access/shared-models';
import { concatLatestFrom } from '@ngrx/operators';
import { map } from 'rxjs/operators';
import { StepType } from '@shared/content-api-interface';
import { selectQueryParams, selectUrlWithoutQueryParams } from '@frontend/data-access/router';

@Injectable({
    providedIn: 'root',
})
export class StepGuard {
    private readonly router: Router = inject(Router);
    private readonly store: Store = inject(Store);

    canActivate(route: ActivatedRouteSnapshot) {
        return this.store.select(stepsFeature.selectLoading).pipe(
            skipWhile((loading) => loading !== LoadingState.LOADED),
            concatLatestFrom(() => [
                this.store.select(stepsFeature.selectSteps),
                this.store.select(selectQueryParams),
                this.store.select(selectUrlWithoutQueryParams),
            ]),
            map(([, steps, params, url]) => {
                const step = steps.find((step) => step.id === route.params['stepId']);

                if (!step) {
                    return this.router.createUrlTree(['main', 'today'], { queryParams: params });
                }

                const stepType = step.content.contentType;

                if (stepType === StepType.ARTICLE) {
                    return this.router.createUrlTree([url, 'article'], { queryParams: params });
                }

                return this.router.createUrlTree([url, 'lesson'], { queryParams: params });
            }),
        );
    }
}
