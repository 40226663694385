import {
    getAccount,
    getAccountFailure,
    getAccountSuccess,
    updateAccount,
    updateAccountFailure,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { createFeature, createReducer, on } from '@ngrx/store';

export interface OnboardingState {
    isLoading: boolean;
}

const initialState: OnboardingState = {
    isLoading: false,
};

export const onboardingFeature = createFeature({
    name: 'onboarding',
    reducer: createReducer(
        initialState,
        on(getAccount, updateAccount, (state): OnboardingState => {
            return {
                ...state,
                isLoading: true,
            };
        }),
        on(
            getAccountSuccess,
            getAccountFailure,
            updateAccountSuccess,
            updateAccountFailure,
            (state): OnboardingState => {
                return {
                    ...state,
                    isLoading: false,
                };
            },
        ),
    ),
});
