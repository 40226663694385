@if (vm$ | async; as vm) {
<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Reset Password"
            titleColour="white"
            [leftButton]="{analyticsIdentifier:'reset-password-enter-email-back-btn', background: 'none', iconColour: 'white'}"
            [rightButton]="false"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="authentication-ion-content reset-password-ion-content" [fullscreen]="true">
    <main class="page-content">
        <section>
            <h1>Enter your email</h1>
            <p>We’ll send you a link to reset your password</p>
        </section>
        <form [formGroup]="requestPasswordResetForm" class="authentication-form authentication-form--alvin">
            <ion-item>
                <ion-input
                    class="zz-input"
                    data-test="email-input"
                    type="email"
                    placeholder="Email"
                    formControlName="email"
                    [tabindex]="1"
                />
            </ion-item>
            @if (showEmailError()) {
            <app-invalid-input-error-message errorMessage="Please fill in a valid email" />
            }
        </form>
    </main>
</ion-content>

@if (!vm.isIonInputFocusedOnAndroid) {
<ion-footer class="ion-text-center page-footer">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="send-email-button"
        data-test="send-email-button"
        expand="block"
        [disabled]="!requestPasswordResetForm.valid"
        (click)="send()"
    >
        Send Email
    </ion-button>
</ion-footer>
} }
