import { createSelector } from '@ngrx/store';
import { platformFeature } from '@frontend/data-access/platform';
import { keyboardFeature } from './keyboard.reducer';

export const selectIsInputOpenOnAndroid = createSelector(
    platformFeature.selectPlatformIsAndroid,
    keyboardFeature.selectIsKeyboardOpen,
    (isAndroid, isKeyboardOpen) => {
        return isAndroid && isKeyboardOpen;
    },
);
