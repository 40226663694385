import { AdjustAttribution } from '@awesome-cordova-plugins/adjust';
import { AdjustEventFailure } from '@awesome-cordova-plugins/adjust/ngx';
import { createAction, props } from '@ngrx/store';
import { AdjustEventType } from './adjust.model';

export const initAdjust = createAction('[Adjust] Init');

export const adjustRequestTrackingConsent = createAction('[Adjust] Request Tracking Consent');

export const adjustRequestTrackingConsentResponse = createAction(
    '[Adjust] Request Tracking Consent Response',
    props<{ consentValue: number; consentText: string }>(),
);

export const adjustEventFailure = createAction('[Adjust] Event Failure', props<{ event: AdjustEventFailure }>());

export const adjustUpdateAttributionData = createAction(
    '[Adjust] Update Attribution Data',
    props<{ attributionData: AdjustAttribution }>(),
);

export const trackAdjustEvent = createAction(
    '[Adjust] Track an event',
    props<{
        event: AdjustEventType;
    }>(),
);
