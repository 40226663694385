import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debouncedValidator } from '@frontend/utility/debounced-validator';

export type CreateAccountForm = FormGroup<{
    email: FormControl<string | null>;
    password: FormControl<string | null>;
}>;

export const signUpForm = new FormGroup({
    email: new FormControl('', [Validators.required], [debouncedValidator(Validators.email)]),
    password: new FormControl('', [Validators.required], [debouncedValidator(Validators.minLength(6))]),
});
