@if (vm$ | async; as vm) {
    <ion-card
        class="sophie text-snowy chat-tile-card"
        appTrackClick
        identifier="support-{{ vm.contentUnlocked ? 'chat' : 'payment-modal' }}-btn"
        (click)="vm.contentUnlocked ? sendChatMessage() : openPaymentModal()"
    >
        <div class="chat-tile-content-container">
            <img
                class="card-item-img"
                src="assets/images/buddy/buddy-talking-with-speech-bubble.svg"
                slot="start"
                alt="dog on the phone"
            />
            <div class="card-item-text">
                <h2>Chat now</h2>
                <p class="s">Our coaches are here to help</p>
            </div>
        </div>
        @if (!vm.contentUnlocked) {
            <div class="overlay">
                <ion-icon name="lock-closed"></ion-icon>
            </div>
        }
    </ion-card>
}
