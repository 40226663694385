import { Directive, HostListener, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { trackEvent } from '@frontend/data-access/analytics';

@Directive({
    selector: '[appTrackInput]',
    standalone: true,
})
export class AnalyticsTrackInputDirective {
    private readonly router = inject(Router);
    private readonly store = inject(Store);
    @Input() identifier = '';

    @HostListener('ionBlur', ['$event']) onIonBlur($event: FocusEvent): void {
        this.store.dispatch(
            trackEvent({
                eventName: 'input',
                eventProperties: {
                    identifier: this.identifier,
                    page: this.router.url,
                    inputValue: ($event.target as HTMLInputElement).value || '',
                },
            }),
        );
    }
}
