import { Routes } from '@angular/router';
import { LandingPage } from './feature-authentication/landing-page/landing.page';
import { LoginPage } from './feature-authentication/login/login.page';
import { ResetPasswordPage } from './feature-authentication/reset-password/reset-password.page';
import { ResetPasswordCheckEmailPage } from './feature-authentication/reset-password-check-email/reset-password-check-email.page';
import { CreateAccountPage } from './feature-authentication/create-account/create-account.page';

export const authenticationRoutes: Routes = [
    {
        path: '',
        component: LandingPage,
    },
    {
        path: 'login',
        component: LoginPage,
    },
    {
        path: 'reset-password',
        component: ResetPasswordPage,
    },
    {
        path: 'reset-password-check-email',
        component: ResetPasswordCheckEmailPage,
    },
    {
        path: 'create-account',
        component: CreateAccountPage,
    },
];
