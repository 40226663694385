<ion-grid class="tiered-pricing-plan-comparison">
    <ion-row class="tiered-pricing-plan-comparison__heading-row">
        <ion-col size="6">
            <h3 class="tiered-pricing-plan-comparison__what-you-get tiered-pricing-plan-comparison__heading">
                What you get:
            </h3>
        </ion-col>
        <ion-col size="3" class="tiered-pricing-plan-comparison__column">
            <h3
                class="tiered-pricing-plan-comparison__heading"
                [ngClass]="
                    !isPremium()
                        ? 'tiered-pricing-plan-comparison__basic-selected'
                        : 'tiered-pricing-plan-comparison__not-selected'
                "
            >
                Basic
            </h3>
        </ion-col>
        <ion-col size="3" class="tiered-pricing-plan-comparison__column">
            <h3
                class="tiered-pricing-plan-comparison__heading"
                [ngClass]="
                    isPremium()
                        ? 'tiered-pricing-plan-comparison__premium-selected'
                        : 'tiered-pricing-plan-comparison__not-selected'
                "
            >
                Premium
            </h3>
        </ion-col>
    </ion-row>

    <ion-row class="tiered-pricing-plan-comparison__features-row">
        @for (feature of featuresList; track $index) {
            <ion-item class="tiered-pricing-plan-comparison__item">
                <ion-col size="6"
                    ><p class="xs tiered-pricing-plan-comparison__text">{{ feature.feature | textReplacement }}</p>
                </ion-col>
                <ion-col size="3" class="tiered-pricing-plan-comparison__column" *appElementRerender="isPremium()">
                    @if (feature.includedInBasic) {
                        <i
                            class="fa-solid fa-circle-check tiered-pricing-plan-comparison__icon"
                            [ngClass]="!isPremium() ? 'tiered-pricing-plan-comparison__basic-selected' : ''"
                        ></i>
                    }
                </ion-col>
                <ion-col size="3" class="tiered-pricing-plan-comparison__column" *appElementRerender="isPremium()">
                    <i
                        class="fa-solid fa-circle-check tiered-pricing-plan-comparison__icon"
                        [ngClass]="isPremium() ? 'tiered-pricing-plan-comparison__premium-selected' : ''"
                    ></i>
                </ion-col>
            </ion-item>
        }
    </ion-row>
</ion-grid>
