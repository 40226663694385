import { PACKAGE_TYPE } from '@awesome-cordova-plugins/purchases/ngx';
import { SubscriptionPackage } from '../subscription.model';
import { SubscriptionProductMock } from './subscription-product.mock';

export class SubscriptionPackageMock {
    private defaultValue: SubscriptionPackage = {
        identifier: '$rc_three_month',
        packageType: PACKAGE_TYPE.THREE_MONTH,
        product: new SubscriptionProductMock().value,
        description: '3 months',
    };

    constructor(overrides?: Partial<SubscriptionPackage>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SubscriptionPackage {
        return this.defaultValue;
    }
}
