import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routeTo } from '@frontend/data-access/router';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonInput, IonItem } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { startWith } from 'rxjs/operators';
import { signUpForm } from '../create-account.model';
import { selectCreateAccountPageVm } from '../create-account.page.selectors';

@Component({
    selector: 'app-create-account-form',
    templateUrl: './create-account-form.component.html',
    styleUrl: '../../authentication.scss',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, InvalidInputErrorMessageComponent, AsyncPipe, IonItem, IonInput],
})
export class CreateAccountFormComponent {
    private readonly store = inject(Store);

    @Output() signUpFormChange = new EventEmitter<FormGroup>();
    @Output() inputFocus = new EventEmitter<boolean>();

    @ViewChild('passwordInput')
    public input!: IonInput;

    public vm$ = this.store.select(selectCreateAccountPageVm);

    public signUpForm = signUpForm;

    public passwordVisible = false;

    constructor() {
        this.signUpForm.valueChanges.pipe(startWith(1), takeUntilDestroyed()).subscribe(() => {
            const emailValue = this.signUpForm.value.email;

            // if statement required to prevent infinite loop
            if (emailValue !== emailValue?.trim()) {
                this.signUpForm.patchValue({ email: emailValue?.trim() });
            }

            void this.signUpFormChange.emit(this.signUpForm);
        });
    }

    public onInputFocus(): void {
        void this.inputFocus.emit(true);
    }

    public onInputBlur(): void {
        void this.inputFocus.emit(false);
    }

    public routeToErrorLinkFromInvalidInput(route: string[] | undefined): void {
        if (route) {
            void this.store.dispatch(routeTo({ commands: route }));
        }
    }

    public showError(control: AbstractControl): boolean {
        return control.dirty && control.invalid;
    }

    public togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
        this.input.type = this.passwordVisible ? 'text' : 'password';
    }
}
