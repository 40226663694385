import { Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { selectBreeds } from '@frontend/data-access/contentful';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { BreedSelectorComponent } from '@frontend/feature/breed-selector';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { onboardingNavigateBack, onboardingQuestionSubmittedBreed } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-breed',
    templateUrl: 'question-breed.page.html',
    styleUrls: ['../onboarding.scss', 'question-breed.page.scss'],
    imports: [
        IonButton,
        IonSpinner,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        ProgressHeaderComponent,
        BreedSelectorComponent,
        FormsModule,
        ReactiveFormsModule,
        AnalyticsTrackClickDirective,
    ],
})
export class OnboardingQuestionBreedPage {
    protected readonly store = inject(Store);
    protected readonly Color = Color;

    public isLoading = this.store.selectSignal(selectIsLoading);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);
    public breeds = this.store.selectSignal(selectBreeds);

    private breedId = this.store.selectSignal(accountFeature.selectBreedId);

    public breedForm = new FormGroup({
        breedId: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });

    constructor() {
        effect(() => {
            if (this.breedId()) {
                this.breedForm.setValue({
                    breedId: this.breedId()!,
                });
            }
        });
    }

    onBackButtonClicked() {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onSubmit(): void {
        if (this.breedForm.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedBreed({ breedId: this.breedForm.controls.breedId.value }));
    }
}
