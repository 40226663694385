import { inject, Injectable } from '@angular/core';
import {
    capacitorPushNotificationOpened,
    capacitorPushNotificationTokenReceived,
} from '@frontend/data-access/capacitor';
import { accountFeature, getAccountSuccess } from '@frontend/data-access/user/account';
import { DEFAULT_APP_PACKAGE_NAME } from '@shared/constants';
import { IterablePlatformType } from '@shared/user-api-interface';
import { Platform } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { combineLatest, concatMap, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { IterableService } from '../iterable.service';
import { PushNotificationService } from '../push-notification.service';
import { mapNotificationToInternalIterablePostPushOpenEventCommand } from '../utils/iterable.utils';
import {
    logoutPushNotifications,
    logoutPushNotificationsFailure,
    logoutPushNotificationsSuccess,
    registerIterableUserForPushNotificationsFailure,
    registerIterableUserForPushNotificationsSuccess,
    trackIterableEvent,
    trackIterableEventFailure,
    trackIterableEventSuccess,
    trackIterablePushOpenEventFailure,
    trackIterablePushOpenEventSuccess,
    upsertIterableUser,
    upsertIterableUserFailure,
    upsertIterableUserSuccess,
} from './push-notification.actions';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class PushNotificationEffects {
    private readonly actions$ = inject(Actions);
    private readonly pushNotificationService = inject(PushNotificationService);
    private readonly iterableService = inject(IterableService);
    private readonly platform = inject(Platform);
    private readonly store = inject(Store);
    logoutPushNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutPushNotifications),
            switchMap(() =>
                this.pushNotificationService.logout().pipe(
                    map(() => logoutPushNotificationsSuccess()),
                    catchError((error: Error) => of(logoutPushNotificationsFailure({ error }))),
                ),
            ),
        );
    });

    upsertIterableUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(upsertIterableUser),
            concatMap(({ user }) =>
                this.iterableService.upsertIterableUser(user).pipe(
                    map(() => upsertIterableUserSuccess()),
                    catchError((error: Error) => of(upsertIterableUserFailure({ error }))),
                ),
            ),
        );
    });

    upsertIterableUserTrack$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(upsertIterableUser),
            map(({ user }) => trackEvent({ eventName: '[Iterable] Iterable Upsert Track', eventProperties: { user } })),
        );
    });

    trackIterableEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackIterableEvent),
            mergeMap(({ command }) => {
                return this.iterableService.trackIterableEvent(command).pipe(
                    map(() => {
                        return trackIterableEventSuccess();
                    }),
                    catchError((error: Error) => of(trackIterableEventFailure({ error }))),
                );
            }),
        );
    });

    trackIterablePushOpenEvent$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(capacitorPushNotificationOpened)),
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
        ]).pipe(
            mergeMap(([{ campaignId, messageId, templateId }, profile]) => {
                const command = mapNotificationToInternalIterablePostPushOpenEventCommand(
                    profile.profileId,
                    campaignId,
                    templateId,
                    messageId,
                );
                return this.iterableService.trackIterablePushOpenEvent(command).pipe(
                    map(() => {
                        return trackIterablePushOpenEventSuccess();
                    }),
                    catchError((error: Error) => of(trackIterablePushOpenEventFailure({ error }))),
                );
            }),
        );
    });

    //todo: add test

    // when testing push notifications on iOS, change the push notification service type to APPLE_PUSH_NOTIFICATION_SERVICE_TEST
    registerIterableUserForPushNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(capacitorPushNotificationTokenReceived),
            concatLatestFrom(() => [this.store.select(accountFeature.selectId)]),
            switchMap(([{ token }, id]) => {
                return this.iterableService
                    .registerIterableUserForPushNotifications({
                        userId: id!,
                        device: {
                            token: token,
                            platform: this.platform.is('android')
                                ? IterablePlatformType.FIREBASE_MESSAGING
                                : IterablePlatformType.APPLE_PUSH_NOTIFICATION_SERVICE,
                            applicationName: DEFAULT_APP_PACKAGE_NAME,
                        },
                    })
                    .pipe(
                        map(() => registerIterableUserForPushNotificationsSuccess()),
                        catchError((error: Error) => of(registerIterableUserForPushNotificationsFailure({ error }))),
                    );
            }),
        );
    });
}
