import { inject, Injectable } from '@angular/core';
import { dismissibleInfoFeature, shouldDisplayModal, ShowModalFrequency } from '@frontend/data-access/dismissible-info';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { backButtonPressed } from '@frontend/data-access/platform';
import {
    aiChatInputClicked,
    aiChatInputSubmitted,
    goToCourseFromTile,
    hideAiChatView,
    showAiChatView,
    suggestedAiChatQuestionClicked,
} from './today.page.actions';
import { todayPageFeature } from './today.page.reducer';
import { routeToAiChat } from '../chat/chat.actions';
import { setCourseSlider } from '../courses/course-slider/course-slider.actions';
import { routeTo } from '@frontend/data-access/router';

@Injectable()
export class TodayPageEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);

    //todo: add test
    trackSuggestedAiChatQuestionClickAndTriggerRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(suggestedAiChatQuestionClicked),
            switchMap(({ optionId, option, card }) => [
                routeToAiChat({ message: optionId }),
                trackEvent({
                    eventName: '[Today] Ai Chat Suggested Question Clicked',
                    eventProperties: { option, card },
                }),
            ]),
        );
    });

    //todo: add test
    trackTodayAiChatBarQuestionAsked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputSubmitted),
            switchMap(({ message }) => [
                trackEvent({
                    eventName: '[Today] Ai Chat Question Asked',
                    eventProperties: { message },
                }),
            ]),
        );
    });

    navigateToAiChatOnTodayAiInputClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputClicked),
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissedInfoItems)),
            filter(([, dismissibleInfoItems]) => {
                return shouldDisplayModal(
                    ShowModalFrequency.ONCE,
                    'ai-chat-intro-modal',
                    dismissibleInfoItems,
                    new Date(),
                );
            }),
            map(() => {
                return routeToAiChat({});
            }),
        );
    });

    showTodayAiChatView$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputClicked),
            concatLatestFrom(() => [
                this.store.select(dismissibleInfoFeature.selectDismissedInfoItems),
                this.store.select(todayPageFeature.selectShowAiChatView),
            ]),
            filter(([, dismissibleInfoItems, showAiChatView]) => {
                return (
                    !shouldDisplayModal(
                        ShowModalFrequency.ONCE,
                        'ai-chat-intro-modal',
                        dismissibleInfoItems,
                        new Date(),
                    ) && !showAiChatView
                );
            }),
            map(() => {
                return showAiChatView();
            }),
        );
    });

    hideTodayAiChatView$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(backButtonPressed),
            concatLatestFrom(() => this.store.select(todayPageFeature.selectShowAiChatView)),
            filter(([, showAiChatView]) => showAiChatView),
            map(() => {
                return hideAiChatView();
            }),
        );
    });

    //todo: add test
    goToCourseFromTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(goToCourseFromTile),
            concatMap(({ courseId }) => {
                return [setCourseSlider({ courseId: courseId }), routeTo({ commands: ['main', 'courses'] })];
            }),
        );
    });
}
