import { AccountErrorObject, FirebaseErrorCode, FirebaseErrorMessage } from '@shared/constants';
import { InternalApiError } from '../models/internal-api-error.model';

export const mapFirebaseError = (error: InternalApiError | Error | undefined): AccountErrorObject | undefined => {
    if (!error) {
        return undefined;
    }

    return mapFirebaseErrorCodeToAccountErrorObject(error.message ?? '');
};

export const mapFirebaseErrorCodeToAccountErrorObject = (errorMessage: string): AccountErrorObject => {
    switch (errorMessage) {
        case FirebaseErrorCode.USER_NOT_FOUND:
            return { message: FirebaseErrorMessage.USER_NOT_FOUND };
        case FirebaseErrorCode.INVALID_PASSWORD:
            return { message: FirebaseErrorMessage.INVALID_PASSWORD };
        case FirebaseErrorCode.INVALID_EMAIL:
            return { message: FirebaseErrorMessage.INVALID_EMAIL };
        case FirebaseErrorCode.TOO_MANY_REQUESTS:
            return { message: FirebaseErrorMessage.TOO_MANY_REQUESTS };
        case FirebaseErrorCode.EMAIL_ALREADY_EXISTS:
            return {
                message: FirebaseErrorMessage.EMAIL_ALREADY_EXISTS,
                link: { text: 'sign in', route: ['auth', 'login'] },
            };
        default:
            return { message: FirebaseErrorMessage.DEFAULT };
    }
};
