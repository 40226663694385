import { inject, Injectable } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, mergeMap, tap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { updateJumpToProgressForSelectedCourse } from '../../../store/progress/step-progress/step-progress.actions';
import { selectSelectedPathCourseId } from '../store/course-path.selectors';
import { showJumpAheadModal, startNextPartClicked } from './jump-ahead-modal.actions';
import { JumpAheadModalComponent } from './jump-ahead-modal.component';

@Injectable()
export class JumpAheadModalEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);
    showJumpAheadModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showJumpAheadModal),
            concatLatestFrom(() => this.store.select(selectSelectedPathCourseId)),
            tap(([{ split }]): void => {
                void this.modalService.showModal({
                    component: JumpAheadModalComponent,
                    componentProps: { split },
                    cssClass: 'modal',
                });
            }),
            map(([{ split }, courseId]) => {
                return trackEvent({
                    eventName: '[Course] Jump Ahead Modal Opened',
                    eventProperties: {
                        courseId,
                        splitNumber: split.splitNumber,
                        completedSteps: split.numberOfCompletedSteps,
                        totalSteps: split.totalSteps,
                    },
                });
            }),
        );
    });

    startNextPart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(startNextPartClicked),
            concatLatestFrom(() => this.store.select(selectSelectedPathCourseId)),
            mergeMap(([{ radioResult, split }, courseId]) => [
                trackEvent({
                    eventName: '[Course] Jump Ahead Modal Next Part Clicked',
                    eventProperties: {
                        courseId,
                        answer: radioResult.answer.value,
                        explanation: radioResult.answer.explanation,
                    },
                }),
                updateJumpToProgressForSelectedCourse({ split }),
            ]),
        );
    });
}
