import { AsyncPipe } from '@angular/common';
import { Component, effect } from '@angular/core';
import { accountFeature } from '@frontend/data-access/user/account';
import { Color } from '@shared/utils/typescript';
import { IonButton, IonFooter, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectQuestionOwnerNameLegacyPageVm } from './question-owner-name-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { onboardingQuestionSubmittedOwnerName } from '../../../data-access/onboarding.actions';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-onboarding-legacy-question-owner-name',
    templateUrl: 'question-owner-name-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonFooter,
        IonInput,
        IonItem,
        OnboardingLegacyQuestionTemplateComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingQuestionOwnerNameLegacyPage extends OnboardingLegacyBaseComponent {
    protected readonly OWNER_NAME_MAX_LENGTH = 32 as const;

    public vm = this.store.selectSignal(selectQuestionOwnerNameLegacyPageVm);

    private ownerName = this.store.selectSignal(accountFeature.selectOwnerName);

    public form = new FormGroup({
        ownerName: new FormControl('', {
            validators: [Validators.required, Validators.maxLength(this.OWNER_NAME_MAX_LENGTH)],
            nonNullable: true,
        }),
    });

    protected readonly Color = Color;

    constructor() {
        super();

        effect(() => {
            if (this.ownerName()) {
                this.form.setValue({
                    ownerName: this.ownerName()!,
                });
            }
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || this.vm().isLoading) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedOwnerName({
                ownerName: this.form.controls.ownerName.value,
            }),
        );
    }
}
