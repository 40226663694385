import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalNotificationService } from '../services/local-notification.service';
import {
    scheduleLocalNotification,
    scheduleLocalNotificationFailure,
    scheduleLocalNotificationSuccess,
} from './local-notification.actions';

@Injectable()
export class LocalNotificationEffects {
    private readonly actions$ = inject(Actions);
    private readonly localNotificationService = inject(LocalNotificationService);

    scheduleLocalNotification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scheduleLocalNotification),
            switchMap(({ notifications }) => {
                return from(this.localNotificationService.scheduleNotification(notifications)).pipe(
                    map((result) => {
                        return scheduleLocalNotificationSuccess({ result });
                    }),
                    catchError((error) => {
                        return of(scheduleLocalNotificationFailure({ error }));
                    }),
                );
            }),
        );
    });
}
