import { createAction, props } from '@ngrx/store';
import { FrontendRescheduledStep } from '../models/rescheduled-step.model';
import { InternalPostRescheduledStepCommand } from '@shared/user-api-interface';

export const getRescheduledSteps = createAction('[Rescheduled Step] Get Rescheduled Steps');

export const getRescheduledStepsSuccess = createAction(
    '[Rescheduled Step] Get Rescheduled Steps Success',
    props<{ rescheduledSteps: FrontendRescheduledStep[] }>(),
);

export const getRescheduledStepsFailure = createAction(
    '[Rescheduled Step] Get Rescheduled Steps Failure',
    props<{ error: Error }>(),
);

export const createRescheduledSteps = createAction(
    '[Rescheduled Step] Create Rescheduled Steps',
    props<{ rescheduledSteps: InternalPostRescheduledStepCommand[] }>(),
);

export const createRescheduledStepsSuccess = createAction(
    '[Rescheduled Step] Create Rescheduled Steps Success',
    props<{ rescheduledSteps: FrontendRescheduledStep[] }>(),
);

export const createRescheduledStepsFailure = createAction(
    '[Rescheduled Step] Create Rescheduled Steps Failure',
    props<{ error: Error }>(),
);

export const setRescheduledStepsAsInactive = createAction(
    '[Rescheduled Step] Set Rescheduled Steps as Inactive',
    props<{ stepId: string }>(),
);

export const setRescheduledStepsAsInactiveSuccess = createAction(
    '[Rescheduled Step] Set Rescheduled Steps as Inactive Success',
    props<{ rescheduledSteps: FrontendRescheduledStep[] }>(),
);

export const setRescheduledStepsAsInactiveFailure = createAction(
    '[Rescheduled Step] Set Rescheduled Steps as Inactive Failure',
    props<{ error: Error }>(),
);
