import { createAction, props } from '@ngrx/store';
import { StreamMessage } from 'stream-chat-angular';

export const userSentMessageInAiChat = createAction(
    '[Ai Chat] User Sent Message In Ai Chat',
    props<{ message: StreamMessage; wasPromptClicked: boolean }>(),
);

export const answerTimeOut = createAction('[Ai Chat] Answer Timeout', props<{ message: StreamMessage }>());

export const sendMessage = createAction('[Ai Chat] Send Message', props<{ message: string }>());

export const sendMessageSuccess = createAction('[Ai Chat] Send Message Success', props<{ message: StreamMessage }>());

export const sendMessageFailure = createAction('[Ai Chat] Send Message Failure');
