<ion-content [fullscreen]="true" [color]="course.color">
    <div class="course-completion-modal-wrapper page-padding-sides" [ngStyle]="getDynamicallyAppliedStyles()">
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="false"
                    [rightButton]="{
                        analyticsIdentifier: 'course-completion-modal-close-btn',
                        sprite: 'light',
                        iconName: 'xmark',
                        background: 'white',
                        iconColour: 'black',
                    }"
                    (rightButtonPressed)="closeModal()"
                />
            </ion-toolbar>
        </ion-header>

        <div class="course-completion-modal-content-container">
            <div class="course-completion-modal-image-container">
                <img
                    alt="dog-running"
                    class="course-completion-modal-image image-no-max-width"
                    src="assets/images/bailey/bailey-runs-towards-you.gif"
                />
            </div>
            <div class="course-completion-modal-text ignore-global-color">
                <h1 data-test="course-completion-modal-title">Course completed!</h1>
                <p data-test="course-completion-modal-text">
                    Well done! You’ve completed the {{ course.title }} course. You and {{ dogName }} deserve a treat for
                    hitting this pawsome milestone.
                </p>
            </div>
        </div>

        <ion-footer class="course-completion-modal-footer page-padding-bottom">
            <ion-button
                color="var(--ion-color-{{ course.color }}-contrast"
                expand="block"
                appTrackClick
                identifier="course-completion-modal-share-btn"
                data-test="course-completion-modal-share-btn"
                (click)="share()"
            >
                Share achievement
                <ion-icon [name]="'share-outline'"></ion-icon>
            </ion-button>
            <ion-button
                color="max"
                expand="full"
                shape="round"
                appTrackClick
                identifier="course-completion-modal-finish-btn"
                data-test="course-completion-modal-finish-btn"
                (click)="closeModal()"
            >
                Finish course
            </ion-button>
        </ion-footer>
    </div>
</ion-content>
