import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { fireHapticFeedback } from '@frontend/utility/native-plugins';
import { IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { delayWhen, map, tap } from 'rxjs/operators';
import { openInfoDialog } from '../../store/info-dialog/info-dialog.actions';
import { FoodPortionQuery } from '../data-access/food-calculator.model';
import { foodCalculatorExpectedWeightInfoProps } from '../data-access/store/food-calculator-info-dialog-props';
import {
    foodCalculatorPageLoaded,
    foodPortionQueryUpdated,
    showFoodCalculatorDisclaimerModal,
} from '../data-access/store/food-calculator.actions';
import { selectFoodCalculatorVm } from '../data-access/store/food-calculator.selectors';
import { FoodCalculatorFormComponent } from './food-calculator-form/food-calculator-form.component';
import { FoodCalculatorInstructionModalContentComponent } from './food-calculator-instruction-modal-content/food-calculator-instruction-modal-content.component';
import { FoodCalculatorResultComponent } from './food-calculator-result/food-calculator-result.component';

@Component({
    selector: 'app-food-calculator',
    templateUrl: './food-calculator.page.html',
    styleUrls: ['./food-calculator.page.scss'],
    standalone: true,
    imports: [
        FoodCalculatorResultComponent,
        FoodCalculatorFormComponent,
        FoodCalculatorInstructionModalContentComponent,
        AsyncPipe,
        IonContent,
    ],
})
export class FoodCalculatorPage implements OnInit {
    private store = inject(Store);

    public vm$ = this.store.select(selectFoodCalculatorVm);

    public foodPortionLoading$ = this.vm$.pipe(
        delayWhen((vm) => timer(vm.foodPortionLoading ? 0 : 700)),
        tap((vm) => {
            if (!vm.foodPortionLoading && vm.foodPortion) {
                this.store.dispatch(fireHapticFeedback({ style: 'success' }));
            }
        }),
        map(({ foodPortionLoading }) => foodPortionLoading),
    );

    public ngOnInit(): void {
        void this.store.dispatch(foodCalculatorPageLoaded());
    }

    public showWeightInfoModal(): void {
        void this.store.dispatch(openInfoDialog(foodCalculatorExpectedWeightInfoProps));
    }

    public openDisclaimerModal(): void {
        void this.store.dispatch(showFoodCalculatorDisclaimerModal());
    }

    public formValueChange(foodPortionQuery: FoodPortionQuery): void {
        this.store.dispatch(
            foodPortionQueryUpdated({
                foodPortionQuery,
            }),
        );
    }
}
