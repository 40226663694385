import { selectBreeds } from '@frontend/data-access/contentful';
import {
    isCountryCodeNorthAmerica,
    selectCurrentIpCountryCode,
    selectCurrentIpLocationLoading,
} from '@frontend/data-access/geo-location';
import { LoadingState } from '@frontend/data-access/shared-models';
import { RevenueCatPackageDescriptionsUserFriendly } from '@frontend/data-access/subscription';
import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { APP_VERSION_NUMBER } from '@shared/constants';
import { mapDateOfBirthToAgeInDays, mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';
import { isSameDay } from 'date-fns';
import { differenceInDays } from 'date-fns/differenceInDays';
import { accountFeature } from './account.reducer';

export const selectPartner = createSelector(accountFeature.selectPartners, (partners) => {
    if (!partners || partners.length === 0) {
        return undefined;
    }

    return {
        partnerName: partners[0].name,
        partnerId: partners[0].id,
        freeAccessLengthInDays: partners[0].freeAccessLengthInDays,
        userFriendlyAccessLength:
            RevenueCatPackageDescriptionsUserFriendly.get(partners[0].freeAccessLengthInDays) || '',
    };
});

export const selectAgeInDays = createSelector(
    accountFeature.selectDateOfBirth,
    timeFeature.selectToday,
    (date, today) => {
        if (!date) {
            return undefined;
        }

        return mapDateOfBirthToAgeInDays(date, today);
    },
);

export const selectAgeInWeeks = createSelector(
    accountFeature.selectDateOfBirth,
    timeFeature.selectToday,
    (date, today) => {
        if (!date) {
            return undefined;
        }

        return mapDateOfBirthToAgeInWeeks(date, today);
    },
);

export const selectAgeInWeeksAtOnboarding = createSelector(
    accountFeature.selectDateOfBirth,
    accountFeature.selectDateOfOnboardingOrToday,
    (dateOfBirth, onboardingDate) => {
        if (!dateOfBirth || !onboardingDate) {
            return 1;
        } else if (onboardingDate < dateOfBirth) {
            return 0;
        }

        return mapDateOfBirthToAgeInWeeks(dateOfBirth, onboardingDate);
    },
);

export const selectOnboardedToday = createSelector(
    timeFeature.selectToday,
    accountFeature.selectDateOfOnboardingOrToday,
    (today, dateOfOnboarding) => {
        if (dateOfOnboarding) {
            return isSameDay(today, dateOfOnboarding);
        }

        return true;
    },
);

export const selectProfileBreedInfo = createSelector(accountFeature.selectBreedId, selectBreeds, (breedId, breeds) =>
    breeds.find((b) => b.id === breedId),
);

export const selectBreedName = createSelector(selectProfileBreedInfo, (breedInfo) => breedInfo?.name);

// Is the pup currently at home
export const selectHasArrived = createSelector(
    accountFeature.selectHasArrivedOrUndefined,
    (hasArrived) => (hasArrived === undefined ? true : hasArrived), // assume puppy has arrived for users with NULL field in database
);

// Was the pup home at the day of onboarding
export const selectHasArrivedAtOnboarding = createSelector(
    accountFeature.selectDateOfArrival,
    accountFeature.selectDateOfOnboardingOrToday,
    (dateOfArrival, dateOfOnboarding) => {
        if (dateOfArrival) {
            return differenceInDays(dateOfOnboarding, dateOfArrival) >= 0;
        }

        return false;
    },
);

// Did the pup arrive at the exact date of onboarding
export const selectHasArrivedOnDateOfOnboarding = createSelector(
    accountFeature.selectDateOfArrival,
    accountFeature.selectDateOfOnboardingOrToday,
    (dateOfArrival, dateOfOnboarding) => {
        if (dateOfArrival) {
            return isSameDay(dateOfArrival, dateOfOnboarding);
        }

        return false;
    },
);

export const selectCountryCode = createSelector(
    accountFeature.selectCountryCode,
    selectCurrentIpCountryCode,
    (profileCountryCode, currentIpCountryCode) => {
        if (profileCountryCode !== undefined && profileCountryCode !== '') {
            return profileCountryCode;
        }

        if (currentIpCountryCode !== undefined && currentIpCountryCode !== '') {
            return currentIpCountryCode;
        }

        return ''; // TODO: NL - think about a default value for country code (or change usages to work with undefined)
    },
);

export const selectIsCountryCodeResolved = createSelector(
    accountFeature.selectCountryCode,
    selectCurrentIpLocationLoading,
    (profileCountryCode, currentIpLocationLoading) => {
        return (profileCountryCode !== '' && profileCountryCode !== undefined) || !currentIpLocationLoading;
    },
);

export const selectIsUsUser = createSelector(selectCountryCode, (countryCode) => countryCode === 'US');

export const selectIsNorthAmericaCountryCode = createSelector(selectCountryCode, (countryCode) => {
    return isCountryCodeNorthAmerica(countryCode);
});

export const selectIsLoading = createSelector(
    accountFeature.selectLoading,
    (loading) => loading === LoadingState.LOADING,
);

export const selectOwnerAndDogName = createSelector(
    accountFeature.selectOwnerName,
    accountFeature.selectDogName,
    (ownerName, dogName) => {
        return { ownerName, dogName };
    },
);

export const selectConfigCatUserProperties = createSelector(
    accountFeature.selectId,
    accountFeature.selectProfile,
    (id, profile) => {
        return {
            identifier: id,
            email: profile?.mail,
            country: profile?.countryCode,
            custom: {
                ...(profile?.name && { dogName: profile?.name }),
                appVersionNumber: APP_VERSION_NUMBER,
                ...(profile?.dateOfOnboarding && { dateOfOnboarding: profile?.dateOfOnboarding.getTime().toString() }),
            },
        };
    },
);
