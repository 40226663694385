import { Platform } from '@ionic/angular/standalone';
import { PlatformConstants } from './platform.constants';

export function getPlatform(platform: Platform): string {
    if (platform.is('ios')) {
        return PlatformConstants.IOS;
    } else if (platform.is('android')) {
        return PlatformConstants.ANDROID;
    }

    return PlatformConstants.WEB;
}
