import { AsyncPipe, Location, NgClass } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { FeedbackComponent, RichContentPipe } from '@frontend/ui';
import { ZzAuthorChipComponent } from '@frontend/zigzag-main/zz-author-chip';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { setStepCompleted } from '../store/progress/step-progress/step-progress.actions';
import { Step } from '../store/step/step.model';
import { selectRouteParamStepId } from '../store/router/router.selectors';
import { selectSteps } from '../store/step/step.selectors';
import { selectContentUnlocked } from '../store/payment/store/payment.selectors';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { Article } from '@frontend/data-access/contentful';
import { StepType } from '@shared/content-api-interface';
import {
    createUserFeedbackContent,
    FeedbackContentTypeIdEnum,
    UserFeedbackContent,
    userFeedbackContentFeature,
} from '@frontend/data-access/feedback';
import { accountFeature } from '@frontend/data-access/user/account';
import { LoadingState } from '@frontend/data-access/shared-models';

@Component({
    selector: 'app-article',
    templateUrl: './article.page.html',
    styleUrls: ['./article.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        HeaderComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        NgClass,
        RichContentPipe,
        TextReplacementPipe,
        ZzAuthorChipComponent,
        FeedbackComponent,
    ],
})
export class ArticlePage implements OnInit {
    @ViewChild('feedbackComponent') feedback!: FeedbackComponent;

    private readonly store = inject(Store);
    private readonly location = inject(Location);

    userId = this.store.selectSignal(accountFeature.selectId);
    stepId = this.store.selectSignal(selectRouteParamStepId);
    steps = this.store.selectSignal(selectSteps);
    unlocked = this.store.selectSignal(selectContentUnlocked);
    hasHistoricalPurchase = this.store.selectSignal(subscriptionFeature.selectHasHistoricalPurchase);
    userFeedbackList = this.store.selectSignal(userFeedbackContentFeature.selectFeedback);
    userFeedbackLoading = this.store.selectSignal(userFeedbackContentFeature.selectLoadingState);

    step: Step<Article> | undefined;
    userFeedback: UserFeedbackContent | undefined;
    loadingState = LoadingState;

    ngOnInit() {
        this.step = this.steps().find(
            (step): step is Step<Article> => step.id === this.stepId() && step.content.contentType === StepType.ARTICLE,
        );

        //  Selects the latest feedback record for the user on the current article
        this.userFeedback = this.userFeedbackList()
            ?.filter((feedback) => feedback.contentfulId === this.stepId() && feedback.userId === this.userId())
            ?.sort((a, b) => +b.timestamp! - +a.timestamp!)
            ?.shift();
    }

    public routeToPreviousPage(): void {
        this.location.back();
    }

    public articleCompleted(step: Step): void {
        this.feedback.onClickDone();

        this.store.dispatch(
            setStepCompleted({
                properties: {
                    title: step.content.title,
                    stepId: step.id,
                    contentType: step.content.contentType,
                    contentId: step.content.id,
                    topicId: step.topic.title,
                },
            }),
        );
        this.routeToPreviousPage();
    }

    public openPaymentModal(): void {
        void this.store.dispatch(showPaymentModal({ trigger: 'article-page' }));
    }

    onFeedbackSubmitted(feedback: UserFeedbackContent) {
        this.store.dispatch(
            createUserFeedbackContent({
                feedback: {
                    contentTypeId: FeedbackContentTypeIdEnum.ARTICLE,
                    contentfulId: feedback.contentfulId,
                    thumbsDown: feedback.thumbsDown,
                    thumbsUp: feedback.thumbsDown === null ? undefined : !feedback.thumbsDown,
                    comment: feedback.comment,
                },
            }),
        );
    }
}
