import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    internalEndpoints,
    InternalPostStepProgressCommand,
    InternalStepProgressDto,
} from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StepProgressService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getStepProgressV2(): Observable<InternalStepProgressDto[]> {
        return this.http.get<InternalStepProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.stepProgressCollection}/v2`,
            httpOptions,
        );
    }

    public createStepProgress(stepProgress: InternalPostStepProgressCommand[]): Observable<InternalStepProgressDto[]> {
        return this.http.post<InternalStepProgressDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.stepProgressCollection}`,
            { stepProgress },
            httpOptions,
        );
    }
}
