import { LoadingState } from '@frontend/data-access/shared-models';
import { InternalApiError } from './internal-api-error.model';
import { Profile } from './profile.model';

export interface AccountState {
    id: string | undefined;
    profile: Profile | undefined;
    loading: LoadingState;
    error: InternalApiError | Error | undefined;
    atLeastOneProfileCalledHasFinished: boolean;
    hasCreateAccountFired: boolean;
    accountFailure: boolean;
}
