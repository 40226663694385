import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectHasArrivedLegacyPageVm } from './question-has-arrived-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { onboardingQuestionSubmittedHasArrived } from '../../../data-access/onboarding.actions';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-onboarding-legacy-question-has-arrived',
    templateUrl: 'question-has-arrived-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss'],
    standalone: true,
    imports: [
        OnboardingLegacyQuestionTemplateComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonSpinner,
    ],
})
export class OnboardingQuestionHasArrivedLegacyPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectHasArrivedLegacyPageVm);

    protected readonly Color = Color;

    public continue(hasArrived: boolean): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedHasArrived({ hasArrived }));
    }
}
