import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonButton, IonFooter, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectQuestionRescueLegacyPageVm } from './question-rescue-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { ReactiveFormsModule } from '@angular/forms';
import { onboardingQuestionSubmittedIsRescue } from '../../../data-access/onboarding.actions';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-onboarding-legacy-question-rescue',
    templateUrl: 'question-rescue-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss', 'question-rescue-legacy.page.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonButton,
        IonFooter,
        IonInput,
        IonItem,
        OnboardingLegacyQuestionTemplateComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingQuestionRescueLegacyPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectQuestionRescueLegacyPageVm);

    protected readonly Color = Color;

    public continue(isRescuePup: boolean): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedIsRescue({ isRescuePup }));
    }
}
