import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, effect } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { formatISO } from 'date-fns';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectQuestionArrivalLegacyPageVm } from './question-arrival-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { onboardingQuestionSubmittedArrivalDate } from '../../../data-access/onboarding.actions';
import { accountFeature } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-onboarding-legacy-question-arrival',
    templateUrl: 'question-arrival-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss'],
    standalone: true,
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        DatePipe,
        InvalidInputErrorMessageComponent,
        IonButton,
        IonInput,
        IonItem,
        OnboardingLegacyQuestionTemplateComponent,
        ReactiveFormsModule,
    ],
})
export class OnboardingLegacyQuestionArrivalPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectQuestionArrivalLegacyPageVm);

    private dateOfArrival = this.store.selectSignal(accountFeature.selectDateOfArrival);

    public form = new FormGroup({
        dateOfArrival: new FormControl(formatISO(new Date(), { representation: 'date' }), {
            validators: [Validators.required],
            nonNullable: true,
        }),
    });

    protected readonly Color = Color;

    constructor() {
        super();

        effect(() => {
            if (this.dateOfArrival()) {
                this.form.setValue({
                    dateOfArrival: formatISO(new Date(this.dateOfArrival()!), { representation: 'date' }),
                });
            }
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || this.vm().isLoading) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedArrivalDate({
                dateOfArrival: new Date(this.form.controls.dateOfArrival.value).toISOString(),
            }),
        );
    }
}
