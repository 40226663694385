import { createAction, props } from '@ngrx/store';
import { CourseWithSteps } from '../models/courses.models';

export const showCourseInformationModal = createAction(
    '[Course Information] Show Modal',
    props<{ course: CourseWithSteps }>(),
);

export const startCourseClicked = createAction(
    '[Course Information] Start Course Clicked',
    props<{
        courseId: string;
    }>(),
);
