import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { TrainingReminderModalComponent } from '../components/training-reminder-modal/training-reminder-modal.component';
import { launchTrainingReminderModal } from './training-reminder-modal.actions';

@Injectable()
export class TrainingReminderModalEffects {
    private actions$ = inject(Actions);
    private modalService = inject(ModalService);

    showTrainingReminderModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchTrainingReminderModal),
            tap(({ dogName }) => {
                return this.modalService.showModal({
                    component: TrainingReminderModalComponent,
                    componentProps: {
                        dogName,
                    },
                    cssClass: ['modal', 'modal-training-reminder'],
                });
            }),
            map(({ trigger }) => {
                return trackEvent({
                    eventName: '[Training Reminder] Show Modal',
                    eventProperties: {
                        trigger,
                    },
                });
            }),
        );
    });
}
