export const PAYMENT_MODAL_ID = 'payment-modal';
export const PAYMENT_MODAL_LIMITED_OFFER_ID = 'payment-modal-limited-offer';

export const defaultPlanDescriptionList = [
    'A training journey that reacts to [NAME]’s progress with specialist courses',
    'Unlimited access to 250+ lessons to overcome any issue',
    '24/7 live chat support with training coaches, plus instant AI answers',
];

export const basicPlanDescriptionList = [
    'A foundational training course that is tailored to [NAME]',
    'Instant AI answers to any questions, trained and reviewed by professional trainers',
];
