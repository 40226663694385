import { AsyncPipe, NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { notEmpty } from '@shared/utils/typescript';
import { IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { CoursePathSplitComponent } from './course-path-split/course-path-split.component';
import { CoursePathStepComponent } from './course-path-step/course-path-step.component';
import { selectCoursePathComponentVm } from './course-path.component.selectors';
import { selectCoursePathSvgSize } from './store/course-path.selectors';
import { CoursePageStep } from './store/models/course-path.model';

@Component({
    selector: 'app-course-path',
    templateUrl: './course-path.component.html',
    styleUrls: ['./course-path.component.scss'],
    standalone: true,
    imports: [IonCard, CoursePathSplitComponent, NgStyle, CoursePathStepComponent, AsyncPipe],
})
export class CoursePathComponent implements AfterViewInit {
    private readonly store = inject(Store);
    @ViewChild('doodle', { read: ElementRef }) public svg!: ElementRef<SVGSVGElement>;

    public vm$ = this.store.select(selectCoursePathComponentVm);

    ngAfterViewInit(): void {
        this.store
            .select(selectCoursePathSvgSize)
            .pipe(
                filter(() => notEmpty(this.svg)),
                tap((svgSize) => {
                    if (svgSize) {
                        this.setViewBox(svgSize.height, svgSize.width);
                    }
                }),
            )
            .subscribe();
    }

    public setViewBox(height: number, width: number) {
        this.svg.nativeElement.setAttribute('height', height.toString());
        this.svg.nativeElement.setAttribute('viewBox', `10 0 ${width} ${height}`);
    }

    trackByFn(_: number, step: CoursePageStep): string {
        return step.id + step.progress.isPractice ? '-practice' : '';
    }
}
