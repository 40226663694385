import { AsyncPipe } from '@angular/common';
import { Component, effect } from '@angular/core';
import { accountFeature } from '@frontend/data-access/user/account';
import { Color } from '@shared/utils/typescript';
import { DEFAULT_PUPPY_NAME } from '@shared/constants';
import { IonButton, IonFooter, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectQuestionNameLegacyPageVm } from './question-name-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { onboardingQuestionSubmittedName } from '../../../data-access/onboarding.actions';
import { AnalyticsTrackClickDirective, AnalyticsTrackInputDirective } from '@frontend/feature/directives';

@Component({
    standalone: true,
    selector: 'app-onboarding-legacy-question-name',
    templateUrl: 'question-name-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss', 'question-name-legacy.page.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        AnalyticsTrackInputDirective,
        AsyncPipe,
        IonButton,
        IonFooter,
        IonInput,
        IonItem,
        OnboardingLegacyQuestionTemplateComponent,
        ProfileImageComponent,
        ReactiveFormsModule,
        IonSpinner,
    ],
})
export class OnboardingQuestionNameLegacyPage extends OnboardingLegacyBaseComponent {
    protected readonly NAME_MAX_LENGTH = 16 as const;

    /**
     * TODO: NBSon - think about dropping vms?
     *
     * - attempted to do private name = signal(this.vm().name) and in test changed the vm value but it did not fire the effect to set the form
     * - duplication of signals
     */
    public vm = this.store.selectSignal(selectQuestionNameLegacyPageVm);

    private name = this.store.selectSignal(accountFeature.selectDogName);

    public form = new FormGroup({
        name: new FormControl<string>('', {
            validators: [Validators.required, Validators.maxLength(this.NAME_MAX_LENGTH)],
            nonNullable: true,
        }),
    });

    protected readonly Color = Color;
    protected readonly ProfileImageType = ProfileImageType;

    constructor() {
        super();

        effect(() => {
            this.form.setValue({
                name: this.name() === DEFAULT_PUPPY_NAME ? '' : this.name(),
            });
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedName({ name: this.form.controls.name.value }));
    }

    public unknownName(): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedName({ name: DEFAULT_PUPPY_NAME }));
        this.form.reset();
    }
}
