<div class="experts">
    <h2 class="experts-title">Meet our training coaches</h2>
    <ion-card class="experts-card">
        @for (expertInfo of expertCards; track expertInfo; let i = $index) {
            <div>
                <app-card-item
                    [content]="expertInfo.card"
                    appTrackClick
                    identifier="support-expert{{ i + 1 }}-btn"
                    (click)="showExpert(expertInfo)"
                >
                </app-card-item>
            </div>
        }
    </ion-card>
</div>
