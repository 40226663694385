import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncourageTrialModalComponent } from '../encourage-trial-modal.component';
import { launchEncourageTrialModal } from './encourage-trial-modal.actions';

@Injectable()
export class EncourageTrialModalEffects {
    private actions$ = inject(Actions);
    private modalService = inject(ModalService);

    showEncourageTrialModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchEncourageTrialModal),
            tap(() => {
                void this.modalService.showModal({
                    component: EncourageTrialModalComponent,
                    cssClass: 'modal',
                });
            }),
            map(() => {
                return trackEvent({ eventName: '[Reverse Paywall] Show Encourage Trial Modal' });
            }),
        );
    });
}
