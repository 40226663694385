import { ChangeDetectorRef, Component, forwardRef, inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonButton } from '@ionic/angular/standalone';

@Component({
    selector: 'app-weight-selector',
    templateUrl: './weight-selector.component.html',
    styleUrls: ['./weight-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => WeightSelectorComponent),
        },
    ],
    standalone: true,
    imports: [IonButton],
})
export class WeightSelectorComponent implements ControlValueAccessor {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    currentWeight = 0;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: (value: number) => void = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: () => void = () => {};

    registerOnChange(fn: (value: number) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(value: number): void {
        this.currentWeight = value;
        Promise.resolve().then(() => {
            this.changeDetectorRef.markForCheck();
        });
    }

    change(number: number) {
        this.onTouched();
        this.currentWeight = Math.max(this.currentWeight + number, 0.5);
        this.onChange(this.currentWeight);
    }
}
