<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackButtonClicked()"
        />
    </ion-toolbar>
</ion-header>

<!-- TODO: NBSon - investigate whether we need this scrollY/why there is overflow -->
<ion-content class="onboarding-ion-content" [fullscreen]="true" [scrollY]="false">
    <main class="question-age page-content ion-justify-content-start">
        <section class="question-age__container">
            <h1>How old is {{ dogName() }}?</h1>
        </section>

        <form id="ageForm" [formGroup]="ageForm" (ngSubmit)="onSubmit()">
            <app-radio-with-logic
                [buttons]="AGE_RADIO_OPTIONS"
                [isDisabled]="isLoading()"
                [isNewOnboarding]="true"
                (changeEvent)="setAnswer($event)"
            />

            @if (radioResult?.complete && radioResult?.selected !== OVER_TWO_YEARS_RADIO_OPTION) {
            <section class="question-age__date-input-container">
                <p class="question-age__date-text">
                    Please specify {{ dogName() }}'s {{ radioResult?.selected === ZERO_TO_FOUR_MONTHS_RADIO_OPTION ?
                    'birthday' : 'birth month' }}, or your closest estimate to help us tailor the program
                </p>

                <div class="onboarding-datetime">
                    <ion-datetime-button
                        datetime="datetime"
                        class="datetime-button-new-onboarding"
                        [disabled]="isLoading()"
                    />
                    <i class="onboarding-datetime__calendar-icon fa-regular fa-calendar"></i>
                </div>

                <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                        <ion-datetime
                            id="datetime"
                            formControlName="dateOfBirth"
                            [presentation]="radioResult?.selected?.domElement?.type"
                            [max]="getMaxValue(radioResult)"
                            [min]="getMinValue(radioResult)"
                            [showDefaultButtons]="true"
                        />
                    </ng-template>
                </ion-modal>
            </section>
            }
        </form>
    </main>
</ion-content>

<ion-footer class="question-age-footer ion-text-center page-footer">
    <ion-button
        class="ion-button-color-max"
        [ngClass]="{'question-age-footer__button--hidden' : !radioResult?.selected}"
        appTrackClick
        identifier="question-age-next-btn"
        expand="block"
        type="submit"
        form="ageForm"
        data-test="question-age-next-btn"
        [disabled]="isLoading() || ageForm.invalid"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { Next }
    </ion-button>
</ion-footer>
