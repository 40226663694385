import { SwiperOptions } from 'swiper/types/swiper-options';
import { ChatIntroModalSlide } from './ai-chat-intro-modal.model';

export const chatIntroModalSliderConfig: SwiperOptions = {
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 8,
    initialSlide: 0,
};

export const chatIntroModalSlides: ChatIntroModalSlide[] = [
    {
        title: 'Need answers now?<br>Meet Ziggy AI',
        description: `Ziggy AI is your personalised canine consultant for all your pup's needs`,
        imageSrc: '/assets/images/ziggy/ziggy-sitting.svg',
        imageAlt: 'Ziggy sitting',
        buttonText: 'Get started',
    },
    {
        title: 'AI-powered,<br>Zigzag-approved',
        description: 'Ziggy AI has been trained by our training coaches to provide advice you can rely on',
        imageSrc: '/assets/images/ziggy/ziggy-reading.svg',
        imageAlt: 'Ziggy reading',
        buttonText: 'Next',
    },
    {
        title: 'Tailored to [NAME_C]',
        description: '[NAME_C]’s age, breed, and needs - Ziggy AI has it covered',
        imageSrc: '/assets/images/ziggy/ziggy-walk.svg',
        imageAlt: 'Ziggy walking',
        buttonText: 'Next',
    },
    {
        title: 'Instant peace of mind',
        description: 'Skip Google. Get Zigzag approved answers, instantly',
        imageSrc: '/assets/images/ziggy/ziggy-phone.svg',
        imageAlt: 'Ziggy with phone in mouth',
        buttonText: 'Get started now',
    },
];
export const aiChatBackgroundTransform = [
    'translate(-100px, -54px) rotate(-90deg)',
    'translate(35px, 50px)',
    'translate(140px, 170px) rotate(90deg)',
    'translate(270px, 300px) rotate(180deg)',
];
