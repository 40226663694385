<div>
    <h2>Are you sure you want to close this lesson?</h2>
</div>
<div>
    <p class="s">
        The lesson will be marked as <ion-icon name="glasses-outline" class="glasses-icon"></ion-icon>&nbsp;<strong
            >Read</strong
        >
        and will appear on your <ion-icon name="home-outline" class="home-icon"></ion-icon>&nbsp;<strong>Today</strong>
        screen until you have completed it.
    </p>
</div>
<div class="button-container">
    <ion-button
        class="close-button"
        identifier="step-notification-modal-close-button"
        color="max"
        (click)="setLessonAsRead()"
        appTrackClick
        >Close lesson</ion-button
    >
    <button class="zz-button" identifier="step-notification-modal-cancel-button" (click)="closeModal()" appTrackClick>
        Cancel
    </button>
</div>
