import { inject, Injectable } from '@angular/core';
import { capacitorAppResume } from '@frontend/data-access/capacitor';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { accountFeature, getAccountSuccess, updateAccount } from '@frontend/data-access/user/account';
import { authenticationFeature } from '@frontend/data-access/user/authentication';
import { getConfigCatFeatureFlagsSuccess, selectReversePaywallAb } from '@frontend/data-access/user/config-cat';
import {
    patchUserAppAccessGranted,
    userAppAccessGrantedFeature,
} from '@frontend/data-access/user/user-app-access-granted';
import { ModalResultEnum, ModalService } from '@frontend/utility/modal';
import { NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { UserAppAccessGrantedType } from '@shared/user-api-interface';
import { differenceInHours } from 'date-fns';
import { combineLatest, skipWhile } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ArrivalModalComponent } from '../../arrival/arrival-modal/arrival-modal.component';
import { EndCourseModalComponent } from '../../courses/end-course-modal/end-course-modal.component';
import { LessonSkipNotificationModalComponent } from '../../lesson/lesson-skip-notification-modal/lesson-skip-notification-modal.component';
import { LESSON_SKIP_NOTIFICATION_MODAL_ID } from '../../lesson/lesson-skip-notification-modal/lesson-skip-notification-modal.constants';
import { launchPupHappinessModal } from '../../pup-happiness-modal/store/pup-happiness-modal.actions';
import { launchEncourageTrialModal } from '../../reverse-paywall/encourage-trial-modal/store/encourage-trial-modal.actions';
import { launchFreeAccessModal } from '../../reverse-paywall/free-access-modal/store/free-access-modal.actions';
import { changeAppReadyStatus } from '../app-component/app-component.actions';
import { AppReadyStatus } from '../app-component/app-ready-status.model';
import { selectSelectedTopic } from '../library/library.selector';
import { UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN } from '../profile/account/account.constants';
import { selectCurrentTab } from '../router/router.selectors';
import {
    closeModal,
    dismissSkipLessonNotificationModal,
    showArrivalModal,
    showEndCourseModal,
    showSkipLessonNotificationModal,
} from './modal.actions';
import { selectShouldLaunchPupHappinessModal } from './modal.selectors';

@Injectable()
export class ModalEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);
    private readonly navController = inject(NavController);

    closeModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeModal),
                tap(() => {
                    void this.modalService.dismiss();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showArrivalModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showArrivalModal),
            switchMap(() => {
                return this.modalService.showModalWithCallback<{ arrived: boolean }>({
                    component: ArrivalModalComponent,
                    cssClass: ['modal', 'modal-small'],
                });
            }),
            filter((data) => !!data?.arrived),
            map(() =>
                updateAccount({
                    command: {
                        hasArrived: true,
                        dateOfArrival: new Date().toISOString(),
                    },
                    correlationId: UPDATE_ACCOUNT_FROM_ZIGZAG_MAIN,
                }),
            ),
        );
    });

    showSkipLessonNotificationModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showSkipLessonNotificationModal),
                tap(({ step }) => {
                    void this.modalService.showModal({
                        id: LESSON_SKIP_NOTIFICATION_MODAL_ID,
                        component: LessonSkipNotificationModalComponent,
                        componentProps: {
                            step,
                        },
                        cssClass: ['modal', 'modal-skip-notification'],
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    dismissSkipLessonNotificationModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(dismissSkipLessonNotificationModal),
                tap(() => {
                    void this.modalService.dismissById(LESSON_SKIP_NOTIFICATION_MODAL_ID);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    navigateToTodayWhenDismissSkipLessonNotificationModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(dismissSkipLessonNotificationModal),
                concatLatestFrom(() => [this.store.select(selectCurrentTab)]),
                filter(
                    ([{ result }, currentTab]) =>
                        result !== ModalResultEnum.CANCELLED_BY_USER && currentTab === 'today',
                ),
                tap(() => {
                    void this.navController.navigateBack(['main', 'today'], {
                        animated: false,
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    navigateToCoursesWhenDismissSkipLessonNotificationModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(dismissSkipLessonNotificationModal),
                concatLatestFrom(() => [this.store.select(selectCurrentTab)]),
                filter(
                    ([{ result }, currentTab]) =>
                        result !== ModalResultEnum.CANCELLED_BY_USER && currentTab === 'courses',
                ),
                tap(() => {
                    void this.navController.navigateBack(['main', 'courses'], {
                        animated: false,
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    navigateToLibraryTopicWhenDismissSkipLessonNotificationModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(dismissSkipLessonNotificationModal),
                concatLatestFrom(() => [this.store.select(selectCurrentTab), this.store.select(selectSelectedTopic)]),
                filter(
                    ([{ result }, currentTab, selectedTopic]) =>
                        result !== ModalResultEnum.CANCELLED_BY_USER && currentTab === 'library' && !!selectedTopic,
                ),
                tap(([, , selectedTopic]) => {
                    void this.navController.navigateBack(['main', 'library', 'topic', selectedTopic!.entityId], {
                        animated: false,
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showEndCourseModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showEndCourseModal),
                tap(({ course }) => {
                    return this.modalService.showModal({
                        component: EndCourseModalComponent,
                        componentProps: { course },
                        cssClass: 'modal',
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    showPupHappinessModalOnResume$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(capacitorAppResume),
            concatLatestFrom(() => [
                this.store.select(authenticationFeature.selectLoggedIn),
                this.store.select(selectShouldLaunchPupHappinessModal),
                this.store.select(accountFeature.selectDogName),
            ]),
            filter(([_, isLoggedIn, shouldLaunchPupHappinessModal]) => isLoggedIn && shouldLaunchPupHappinessModal),
            map(([, , , dogName]) => {
                return launchPupHappinessModal({ dogName });
            }),
        );
    });

    showPupHappinessModalOnceUserLoaded$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
            this.actions$.pipe(ofType(changeAppReadyStatus)),
        ]).pipe(
            skipWhile(([, , { status }]) => status !== AppReadyStatus.ready),
            concatLatestFrom(() => [
                this.store.select(selectShouldLaunchPupHappinessModal),
                this.store.select(accountFeature.selectDogName),
            ]),
            filter(([_, shouldLaunchPupHappinessModal]) => shouldLaunchPupHappinessModal),
            map(([, , dogName]) => {
                return launchPupHappinessModal({ dogName });
            }),
        );
    });

    showFreeAccessModalOnceUserLoaded$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
            this.actions$.pipe(ofType(changeAppReadyStatus)),
        ]).pipe(
            skipWhile(([, , { status }]) => status !== AppReadyStatus.ready),
            concatLatestFrom(() => [
                this.store.select(selectReversePaywallAb),
                this.store.select(subscriptionFeature.selectHasHistoricalPurchase),
                this.store.select(userAppAccessGrantedFeature.selectUserAppAccessGranted),
            ]),
            filter(([[{ profile }], reversePaywallAb, hasHistoricalPurchase, userAppAccessGranted]) => {
                return (
                    reversePaywallAb &&
                    !hasHistoricalPurchase &&
                    differenceInHours(new Date(), profile.dateOfOnboarding!) >= 48 &&
                    !userAppAccessGranted.some(
                        (userAppAccessGranted) =>
                            userAppAccessGranted.accessTypeId === UserAppAccessGrantedType.REVERSE_PAYWALL,
                    )
                );
            }),
            map(() => {
                return launchFreeAccessModal();
            }),
        );
    });

    showTrialEncouragementModalOnceUserLoaded$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(ofType(getAccountSuccess), take(1)),
            this.actions$.pipe(ofType(getConfigCatFeatureFlagsSuccess), take(1)),
            this.actions$.pipe(ofType(changeAppReadyStatus)),
        ]).pipe(
            skipWhile(([, , { status }]) => status !== AppReadyStatus.ready),
            concatLatestFrom(() => [
                this.store.select(selectReversePaywallAb),
                this.store.select(userAppAccessGrantedFeature.selectUserAppAccessGranted),
            ]),
            filter(([, reversePaywallAb, userAppAccessGrantedArray]) => {
                return !!(
                    reversePaywallAb &&
                    userAppAccessGrantedArray.find(
                        (userAppAccessGranted) =>
                            !userAppAccessGranted.openedAppAfterExpiry &&
                            userAppAccessGranted.expiryTimestamp < Date.now(),
                    )
                );
            }),
            switchMap(([, , userAppAccessGrantedArray]) => {
                const userAppAccessGrantedToUpdate = userAppAccessGrantedArray.find(
                    (userAppAccessGranted) =>
                        !userAppAccessGranted.openedAppAfterExpiry &&
                        userAppAccessGranted.accessTypeId === UserAppAccessGrantedType.REVERSE_PAYWALL,
                );
                return [
                    patchUserAppAccessGranted({
                        id: userAppAccessGrantedToUpdate!.id,
                        patchUserAppAccessGranted: {
                            openedAppAfterExpiry: true,
                        },
                    }),
                    launchEncourageTrialModal(),
                ];
            }),
        );
    });
}
