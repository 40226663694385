import { selectShouldDisplayInfoItem, ShowModalFrequency } from '@frontend/data-access/dismissible-info';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { accountFeature } from '@frontend/data-access/user/account';
import { selectIsSelfSetReminders } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { selectQueryParamFromPracticeStep, selectQueryParamRating } from '../../store/router/router.selectors';
import {
    selectSelectedLessonExtras,
    selectSelectedLessonStep,
    selectSelectedStepUnlocked,
} from '../../store/step/step.selectors';
import { TRAINING_REMINDER_TILE_ID } from '../../training-reminder/training-reminder.constant';

const selectRouteParams = createSelector(
    selectQueryParamRating,
    selectQueryParamFromPracticeStep,
    (rating, fromPracticeStep) => {
        return {
            rating,
            fromPracticeStep,
        };
    },
);

export const selectSelectedLessonPagesVm = createSelector(
    selectSelectedLessonStep,
    selectSelectedLessonExtras,
    selectSelectedStepUnlocked,
    accountFeature.selectDogName,
    subscriptionFeature.selectHasHistoricalPurchase,
    selectRouteParams,
    selectIsSelfSetReminders,
    selectShouldDisplayInfoItem(TRAINING_REMINDER_TILE_ID, ShowModalFrequency.ONCE),
    (
        step,
        selectedLessonExtras,
        unlocked,
        dogName,
        hasHistoricalPurchase,
        routeParams,
        isSelfSetReminders,
        shouldDisplayInfoItem,
    ) => {
        return {
            ...selectedLessonExtras,
            step,
            unlocked,
            dogName: dogName ?? 'My pup',
            hasHistoricalPurchase,
            routeParams,
            shouldShowSelfSetReminderTile: isSelfSetReminders && shouldDisplayInfoItem,
        };
    },
);
