import { Routes } from '@angular/router';
import { SupportPage } from './support.page';

const supportRoutes: Routes = [
    {
        path: '',
        component: SupportPage,
        pathMatch: 'full',
    },
    {
        path: 'chat',
        loadChildren: () => import('../chat/chat.routes'),
    },
];

export default supportRoutes;
