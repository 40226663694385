import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { pinnedTopicFeature } from './store/pinned-topics.reducer';
import { PinnedTopicsEffects } from './store/pinned-topics.effects';

@NgModule({
    providers: [provideEffects(PinnedTopicsEffects), provideState(pinnedTopicFeature)],
})
export class ZigzagFrontendDataAccessPinnedTopicsModule {}
