import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { StepProgressService } from '../services/step-progress.service';
import { addIsPracticeToStepProgress, mapInternalStepProgressDtoToStepProgress } from '../utils/step-progress.utils';
import {
    createStepProgress,
    createStepProgressFailure,
    createStepProgressSuccess,
    getStepProgress,
    getStepProgressFailure,
    getStepProgressSuccess,
} from './step-progress.actions';

@Injectable()
export class StepProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly stepProgressService = inject(StepProgressService);
    getStepProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getStepProgress),
            exhaustMap(() => {
                return this.stepProgressService.getStepProgressV2().pipe(
                    map((response) => {
                        const progress = mapInternalStepProgressDtoToStepProgress(response);
                        return getStepProgressSuccess({ progress });
                    }),
                    catchError((error: Error) => [getStepProgressFailure({ error })]),
                );
            }),
        );
    });

    createStepProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgress),
            concatMap((action) => {
                const progressWithoutIsPractice = action.progress.map((item) => {
                    const { isPractice, ...rest } = item;
                    return rest;
                });
                return this.stepProgressService.createStepProgress(progressWithoutIsPractice).pipe(
                    map((response) => {
                        const progress = mapInternalStepProgressDtoToStepProgress(response);
                        const progressWithIsPractice = addIsPracticeToStepProgress(progress, action.progress);
                        return createStepProgressSuccess({ progress: progressWithIsPractice });
                    }),
                    catchError((error: Error) => [createStepProgressFailure({ error })]),
                );
            }),
        );
    });
}
