import { InternalPatchAccountCommand } from '@shared/user-api-interface';
import { createAction, props } from '@ngrx/store';
import { Profile } from '../../models/profile.model';

/**
 * Only pass in changed fields.
 */
export const updateAccount = createAction(
    '[Account] Update Account',
    props<{ command: InternalPatchAccountCommand; correlationId: string }>(),
);

export const updateAccountSuccess = createAction(
    '[Account] Update Account Success',
    props<{ command: InternalPatchAccountCommand; profile: Profile; profileId: string; correlationId: string }>(),
);

export const updateAccountFailure = createAction(
    '[Account] Update Account Failure',
    props<{ error: Error; correlationId: string }>(),
);
