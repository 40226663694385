import { createSelector } from '@ngrx/store';
import { selectContentUnlocked } from '../../../../../store/payment/store/payment.selectors';
import { selectIsCourseLoading } from '../../../../../store/progress/course-progress/course-progress.selectors';

export const selectCourseCardStepsVm = createSelector(
    selectContentUnlocked,
    selectIsCourseLoading,
    //selectIsSubscriptionLoading, // TODO: NBSon/JL - look into flaky loading
    (unlocked, isCourseLoading) => ({
        unlocked,
        // isLoading: isCourseLoading || isSubscriptionLoading,
        isLoading: isCourseLoading,
    }),
);
