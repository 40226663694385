import { createAction, props } from '@ngrx/store';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';

export const openSettingsAndroid = createAction(
    '[Capacitor Open Settings] Android',
    props<{ option: AndroidSettings }>(),
);
export const openSettingsAndroidSuccess = createAction(
    '[Capacitor Open Settings] Android Success',
    props<{ option: AndroidSettings }>(),
);
export const openSettingsAndroidFailure = createAction(
    '[Capacitor Open Settings] Android Failure',
    props<{ option: AndroidSettings; error: Error }>(),
);

export const openSettingsIos = createAction('[Capacitor Open Settings] Ios', props<{ option: IOSSettings }>());
export const openSettingsIosSuccess = createAction(
    '[Capacitor Open Settings] Ios Success',
    props<{ option: IOSSettings }>(),
);
export const openSettingsIosFailure = createAction(
    '[Capacitor Open Settings] Ios Failure',
    props<{ option: IOSSettings; error: Error }>(),
);
