import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';
import { AccountService } from '../../services/account.service';
import { mapUserDtoToProfile } from '../../utils/account.map';
import { updateAccount, updateAccountFailure, updateAccountSuccess } from './update-account.actions';

@Injectable()
export class UpdateAccountEffects {
    private readonly actions$ = inject(Actions);
    private readonly accountService = inject(AccountService);
    updateAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccount),
            concatMap(({ command, correlationId }) =>
                this.accountService.updateAccount(command).pipe(
                    map((userDto) =>
                        updateAccountSuccess({
                            command,
                            profile: mapUserDtoToProfile(userDto),
                            profileId: userDto.id,
                            correlationId,
                        }),
                    ),
                    catchError(({ error }: { error: Error }) => of(updateAccountFailure({ error, correlationId }))),
                ),
            ),
        );
    });
}
