import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { Gender } from '@shared/user-domain';
import { IonButton, IonImg, IonSpinner } from '@ionic/angular/standalone';
import { OnboardingLegacyBaseComponent } from '../../onboarding-legacy-base/onboarding-legacy-base.component';
import { selectGenderLegacyPageVm } from './question-gender-legacy.page.selectors';
import { OnboardingLegacyQuestionTemplateComponent } from '../../onboarding-legacy-question-template/onboarding-legacy-question-template.component';
import { onboardingQuestionSubmittedGender } from '../../../data-access/onboarding.actions';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-onboarding-legacy-question-gender',
    templateUrl: 'question-gender-legacy.page.html',
    styleUrls: ['../../onboarding-legacy.scss', 'question-gender-legacy.page.scss'],
    standalone: true,
    imports: [
        OnboardingLegacyQuestionTemplateComponent,
        AnalyticsTrackClickDirective,
        AsyncPipe,
        IonImg,
        IonButton,
        IonSpinner,
    ],
})
export class OnboardingQuestionGenderLegacyPage extends OnboardingLegacyBaseComponent {
    public vm = this.store.selectSignal(selectGenderLegacyPageVm);

    protected readonly Gender = Gender;
    protected readonly Color = Color;

    public genderAnswered(gender: Gender): void {
        if (this.vm().isLoading) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedGender({ gender }));
    }
}
