import { Routes } from '@angular/router';
import { CoursesPage } from './page/courses.page';
import { stepRoutes } from '../step/step.routes';

const courseRoutes: Routes = [
    {
        path: '',
        component: CoursesPage,
        pathMatch: 'full',
    },
    {
        path: 'step',
        children: stepRoutes,
    },
    {
        path: 'chat',
        loadChildren: () => import('../chat/chat.routes'),
    },
];

export default courseRoutes;
