import { PageTodayContent } from '@frontend/data-access/contentful';
import { CourseType } from '@shared/content-api-interface';
import { CourseWithSteps } from '../../courses/models/courses.models';
import { CourseStatus } from '../../store/course/course.model';
import { Step, StepStatus } from '../../store/step/step.model';

export function getCompliment(
    motivationalMessages: PageTodayContent | undefined,
    completeStepsToday: number,
    ageInDays: number | undefined,
    ageInWeeks: number | undefined,
    courseType: CourseType,
    steps: Step[],
    stepsCompletedOrSkipped: number,
): string {
    let compliment: string;
    const complimentsFirst = motivationalMessages?.complimentsFirst ?? [];
    const complimentsSecond = motivationalMessages?.complimentsSecond ?? [];
    const complimentsOther = motivationalMessages?.complimentsOthers ?? [];

    if (completeStepsToday === 1) {
        compliment = complimentsFirst[getRandomInt(complimentsFirst.length)];
        return compliment;
    }

    if (completeStepsToday === 2) {
        compliment = complimentsSecond[getRandomInt(complimentsSecond.length)];
        return compliment;
    }

    if (completeStepsToday > 3 && completeStepsToday % 3 !== 0) {
        compliment = complimentsOther[getRandomInt(complimentsOther.length)];
        return compliment;
    }

    return getMotivationalMessage(
        motivationalMessages,
        stepsCompletedOrSkipped,
        courseType,
        ageInWeeks,
        ageInDays,
        isOnTrack(ageInDays, steps),
    );
}

export function getMotivationalMessage(
    motivationalMessages: PageTodayContent | undefined,
    stepsCompletedOrSkipped: number,
    courseType: CourseType,
    ageInWeeks: number | undefined,
    ageInDays: number | undefined,
    onTrack: boolean,
): string {
    if (motivationalMessages === undefined || ageInWeeks === undefined || ageInDays === undefined) {
        return '🏆 Daily training will keep you on track';
    }
    if (stepsCompletedOrSkipped <= 0) {
        if (courseType === CourseType.PRE_PUP) {
            return motivationalMessages.newNotAtHomeMessage;
        }
        if (ageInWeeks < 12 && stepsCompletedOrSkipped <= 0) {
            return motivationalMessages.newOnTrackMessage;
        }
        return motivationalMessages.newOver12WeeksMessage;
    } else {
        if (courseType === CourseType.PRE_PUP) {
            return motivationalMessages.startedNotAtHomeMessage;
        }
        if (ageInWeeks >= 26) {
            return motivationalMessages.startedOver6MonthsMessage;
        }
        if (ageInWeeks >= 12) {
            return motivationalMessages.startedOver12WeeksMessage;
        }
        if (ageInWeeks === 11) {
            return motivationalMessages.startedCloseToJuvenile;
        } else if (onTrack) {
            return motivationalMessages.startedOnTrackMessage;
        } else {
            return motivationalMessages.startedNotOnTrackMessage;
        }
    }
}

export function isOnTrack(ageInDays: number | undefined, steps: Step[]) {
    const stepsLeftToComplete = steps.filter((step) => {
        return step.progress.state === StepStatus.READ || step.progress.state === StepStatus.INACTIVE;
    }).length;

    if (ageInDays === undefined) {
        return false;
    }

    const daysLeftUntil12Weeks = 84 - ageInDays;

    return daysLeftUntil12Weeks * 3 >= stepsLeftToComplete;
}

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export const filterCourseForTodayPage = (course: CourseWithSteps): boolean => {
    if (course.status === CourseStatus.COMPLETED) {
        return false;
    }

    if (course.type === CourseType.FOUNDATIONAL) {
        return true;
    }

    return course.status === CourseStatus.IN_PROGRESS;
};

export const sortCoursesForTodayPage = (a: CourseWithSteps, b: CourseWithSteps, hasArrived: boolean): number => {
    if (a.type === b.type) {
        return a.rank - b.rank;
    }

    if (a.type === CourseType.OPTIONAL) {
        return 1;
    }

    if (b.type === CourseType.OPTIONAL) {
        return -1;
    }

    if (a.type === CourseType.FOUNDATIONAL) {
        return hasArrived ? -1 : 1;
    }

    if (b.type === CourseType.FOUNDATIONAL) {
        return hasArrived ? 1 : -1;
    }
    return a.rank - b.rank;
};
