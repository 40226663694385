import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalEndpoints, InternalSubscriptionDto } from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionInternalApiService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getActiveSubscription(): Observable<InternalSubscriptionDto> {
        return this.http.get<InternalSubscriptionDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.subscriptionsCollection}/get-active-subscription`,
            httpOptions,
        );
    }
}
