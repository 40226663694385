import { selectBreeds } from '@frontend/data-access/contentful';
import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../../data-access/onboarding.selectors';
import { accountFeature } from '@frontend/data-access/user/account';

export const selectQuestionBreedLegacyPageVm = createSelector(
    selectOnboardingProgress,
    selectBreeds,
    accountFeature.selectDogName,
    accountFeature.selectBreedId,
    onboardingFeature.selectIsLoading,
    (progress, breeds, dogName, breedId, isLoading) => {
        return {
            progress,
            breeds,
            dogName,
            breedId,
            isLoading,
        };
    },
);
