import { Component } from '@angular/core';
import { IonItem, IonSkeletonText } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    imports: [IonItem, IonSkeletonText],
    selector: 'app-step-card-slim-skeleton',
    templateUrl: 'step-card-slim-skeleton.component.html',
    styleUrls: ['step-card-slim-skeleton.component.scss'],
})
export class StepCardSlimSkeletonComponent {}
