import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../../data-access/onboarding.selectors';
import { accountFeature, selectAgeInWeeks } from '@frontend/data-access/user/account';

export const selectHasArrivedLegacyPageVm = createSelector(
    selectOnboardingProgress,
    accountFeature.selectDogName,
    selectAgeInWeeks,
    onboardingFeature.selectIsLoading,
    (progress, dogName, ageInWeeks, isLoading) => ({
        progress,
        dogName,
        ageInWeeks,
        isLoading,
    }),
);
