import { inject, Injectable, TemplateRef } from '@angular/core';
import { dismissInfo, selectShouldDisplayInfoItem, ShowModalFrequency } from '@frontend/data-access/dismissible-info';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { DismissibleModalComponent } from './dismissible-modal.component';
import { DismissibleModalConfig } from './dismissible-modal.config';

@Injectable({
    providedIn: 'root',
})
export class DismissibleModalService {
    private readonly modalController = inject(ModalController);
    public readonly store = inject(Store);

    async showDismissibleModal(
        modalContent: TemplateRef<unknown>,
        modalConfig: DismissibleModalConfig,
        showModalFrequency: ShowModalFrequency = ShowModalFrequency.ONCE,
        ionContent?: IonContent,
    ): Promise<void> {
        const shouldDisplay = await this.store
            .select(selectShouldDisplayInfoItem(modalConfig.modalId, showModalFrequency))
            .pipe(take(1))
            .toPromise();
        if (shouldDisplay) {
            const modalModel = {
                ...modalConfig,
                useModalTemplate: modalConfig.useModalTemplate ?? true,
                closeable: modalConfig.closeable ?? true,
            };
            const modal = await this.modalController.create({
                component: DismissibleModalComponent,
                cssClass: ['modal', modalConfig.style ?? 'modal-small'],
                backdropDismiss: modalConfig.closeable,
                componentProps: {
                    modalContent,
                    vm: modalModel,
                },
            });
            void modal.onDidDismiss().then(() => {
                if (ionContent) {
                    void ionContent.scrollToBottom(0);
                }
                return this.store.dispatch(dismissInfo({ id: modalConfig.modalId }));
            });
            this.store.dispatch(trackEvent({ eventName: `${modalConfig.modalId}-initiated` }));
            return await modal.present();
        }
    }
}
