import { PurchaseProduct } from '@frontend/data-access/subscription';
import { createAction, props } from '@ngrx/store';

export const showPaymentModal = createAction(
    '[Payment] Show Payment Modal',
    props<{ trigger: string; showDiscountProducts?: boolean }>(),
);

export const showIosDiscountPaymentModal = createAction(
    '[Payment] Show iOS Discount Payment Modal',
    props<{ trigger: string }>(),
);

export const showPaymentModalLimitedOffer = createAction('[Payment] Show Payment Modal Limited Offer');

export const closePaymentModalClicked = createAction('[Payment] Close Payment Modal Clicked');

export const openIosDiscountProducts = createAction('[Payment] Open Ios Discount Products');

export const showPaymentModalPlanComparison = createAction(
    '[Payment] Show Payment Modal Plan Comparison',
    props<{ selectPremium: boolean }>(),
);

export const purchaseProductSelected = createAction(
    '[Payment] Purchase Product Selected',
    props<{ product: PurchaseProduct | undefined }>(),
);

export const premiumToggleSelected = createAction('[Payment] Premium Toggle Selected');

export const basicToggleSelected = createAction('[Payment] Basic Toggle Selected');
