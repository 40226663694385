import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { RescheduledStepService } from '../services/rescheduled-step.service';
import {
    createRescheduledSteps,
    createRescheduledStepsFailure,
    createRescheduledStepsSuccess,
    getRescheduledSteps,
    getRescheduledStepsFailure,
    getRescheduledStepsSuccess,
    setRescheduledStepsAsInactive,
    setRescheduledStepsAsInactiveFailure,
    setRescheduledStepsAsInactiveSuccess,
} from './rescheduled-step.actions';
import { mapFrontendRescheduledStepDtoToRescheduledStep } from '../utils/rescheduled-step.utils';

@Injectable()
export class RescheduledStepEffects {
    private readonly actions$ = inject(Actions);
    private readonly rescheduledStepService = inject(RescheduledStepService);
    getRescheduledSteps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getRescheduledSteps),
            switchMap(() =>
                this.rescheduledStepService.getRescheduledSteps().pipe(
                    map((rescheduledStepsResponse) =>
                        getRescheduledStepsSuccess({
                            rescheduledSteps: rescheduledStepsResponse.map(
                                mapFrontendRescheduledStepDtoToRescheduledStep,
                            ),
                        }),
                    ),
                    catchError((error: Error) => of(getRescheduledStepsFailure({ error }))),
                ),
            ),
        );
    });

    createRescheduledSteps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createRescheduledSteps),
            switchMap(({ rescheduledSteps }) =>
                this.rescheduledStepService.createRescheduledSteps(rescheduledSteps).pipe(
                    map((rescheduledStepsResponse) =>
                        createRescheduledStepsSuccess({
                            rescheduledSteps: rescheduledStepsResponse.map(
                                mapFrontendRescheduledStepDtoToRescheduledStep,
                            ),
                        }),
                    ),
                    catchError((error: Error) => of(createRescheduledStepsFailure({ error }))),
                ),
            ),
        );
    });

    setRescheduledStepsAsInactive$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setRescheduledStepsAsInactive),
            switchMap(({ stepId }) =>
                this.rescheduledStepService.setRescheduledStepsAsInactive(stepId).pipe(
                    map((rescheduledStepsResponse) =>
                        setRescheduledStepsAsInactiveSuccess({
                            rescheduledSteps: rescheduledStepsResponse.map(
                                mapFrontendRescheduledStepDtoToRescheduledStep,
                            ),
                        }),
                    ),
                    catchError((error: Error) => of(setRescheduledStepsAsInactiveFailure({ error }))),
                ),
            ),
        );
    });
}
