import { Injectable } from '@angular/core';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

@Injectable({
    providedIn: 'root',
})
export class OpenSettingsService {
    public openAndroid(option: AndroidSettings) {
        return NativeSettings.openAndroid({
            option,
        });
    }

    public openIos(option: IOSSettings) {
        return NativeSettings.openIOS({
            option,
        });
    }
}
