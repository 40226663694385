<article class="ai-chat-intro">
    <section class="ai-chat-intro__greeting">
        <img class="ai-chat-intro__ziggy-close-up" src="assets/images/ziggy/ziggy-close-up.svg" />
        <h2 class="ai-chat-intro__title">
            @if (isChannelEmpty) {
                <span>Hello {{ user.ownerName }} & {{ user.dogName }}!</span>
            } @else {
                <span>Welcome back!</span>
            }
        </h2>
    </section>
    <section class="ai-chat-intro__content">
        @if (isChannelEmpty) {
            <p class="ai-chat-intro__message">
                You might've seen that I'm powered by some pretty clever tech and have the Zigzag seal of approval 🏅
                from real training coaches. Keep in mind, I'm as human as your pup's favourite chew toy, so I may
                produce inaccurate responses. For those times when you want to dig deeper, our real-life training
                coaches are only a bark away, 24/7.
            </p>
        } @else {
            <p class="ai-chat-intro__message">
                Remember, my answers are AI-driven and whilst I have been trained by Zigzag's skilled training coaches,
                I may produce inaccurate responses. For that extra layer of reassurance, the training coaches are always
                available 24/7 🐶
            </p>
        }
        <app-suggested-ai-chat-questions
            [suggestedQuestionsCards]="suggestedQuestionsCards"
            [selectedOption]="initialSelectedOption()"
            [selectedCard]="initialSelectedCard()"
            (promptClicked)="passMessageToParent($event)"
        />
        @if (!isChannelEmpty) {
            <app-simple-text-card-slim
                title="Continue where you left off"
                [background]="Color.Bailey"
                [hasArrow]="true"
                (click)="close()"
                appTrackClick
                identifier="ai-chat-continue-where-you-left-off"
                data-test="ai-chat-continue-where-you-left-off"
            />
        }
    </section>
</article>
