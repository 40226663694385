import { InternalHouseholdDto } from '@shared/user-api-interface';
import { Household } from '../models/household.model';
import { mapInternalUserDtoToUser } from './user.map';

export function mapInternalHouseholdDtoToHousehold(internalHouseholdDto: InternalHouseholdDto): Household {
    return {
        id: internalHouseholdDto.id,
        name: internalHouseholdDto.name,
        dogs: internalHouseholdDto.dogs,
        users: internalHouseholdDto.users.map((user) => mapInternalUserDtoToUser(user)),
    };
}
