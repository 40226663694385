import { chatFeature } from '@frontend/data-access/chat';
import { selectShouldDisplayInfoItem, ShowModalFrequency } from '@frontend/data-access/dismissible-info';
import { accountFeature, selectOwnerAndDogName } from '@frontend/data-access/user/account';
import { ChatChannelType } from '@shared/constants';
import { createSelector } from '@ngrx/store';
import { isSameDay } from 'date-fns';

export const selectAgentChatChannelParams = createSelector(
    accountFeature.selectId,
    selectOwnerAndDogName,
    (profileId, { ownerName, dogName }) => {
        return {
            type: ChatChannelType.AGENT,
            id: profileId,
            custom: {
                image: '',
                name: `${ownerName} and ${dogName}`,
                ...(profileId && { members: [profileId] }),
            },
        };
    },
);

export const selectAiChatChannelParams = createSelector(accountFeature.selectId, (profileId) => {
    return {
        type: ChatChannelType.AI,
        custom: {
            ...(profileId && { members: [profileId] }),
        },
    };
});

export const selectChannelParams = createSelector(
    selectAgentChatChannelParams,
    selectAiChatChannelParams,
    (agentChannelParams, aiChannelParams) => {
        return {
            agentChannelParams,
            aiChannelParams,
        };
    },
);

export const selectInitChatParams = createSelector(
    accountFeature.selectId,
    accountFeature.selectOwnerName,
    (profileId, ownerName) => {
        return {
            profileId,
            ownerName,
        };
    },
);

export const selectShowChatReviewModal = createSelector(
    chatFeature.selectLastMessageDate,
    selectShouldDisplayInfoItem('chat-review', ShowModalFrequency.MONTHLY),
    (lastMessageDate, shouldDisplayInfoItem) => {
        if (!lastMessageDate) {
            return false;
        }

        return shouldDisplayInfoItem && !isSameDay(new Date(), lastMessageDate);
    },
);
