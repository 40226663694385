import { createFeature, createReducer, on } from '@ngrx/store';
import { keyboardDidHide, keyboardDidShow } from './keyboard.actions';

export interface KeyboardState {
    isKeyboardOpen: boolean;
}

export const initialState: KeyboardState = {
    isKeyboardOpen: false,
};
export const keyboardFeature = createFeature({
    name: 'keyboard',
    reducer: createReducer(
        initialState,
        on(
            keyboardDidShow,
            (state): KeyboardState => ({
                ...state,
                isKeyboardOpen: true,
            }),
        ),
        on(
            keyboardDidHide,
            (state): KeyboardState => ({
                ...state,
                isKeyboardOpen: false,
            }),
        ),
    ),
});
