import { createAction, props } from '@ngrx/store';

export const routeToStep = createAction(
    '[Router] Route to Step',
    props<{ baseUrl: string[]; stepId: string; from: string; fromPracticeStep?: boolean }>(),
);

export const routeToStepUsingContentId = createAction(
    '[Router] Route to Step using Content Id',
    props<{ baseUrl: string[]; contentId: number; from: string; fromPracticeStep?: boolean }>(),
);
