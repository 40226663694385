import { inject, Injectable } from '@angular/core';
import { AdjustEventType, trackAdjustEvent } from '@frontend/data-access/adjust';
import { setUserMobileAppSentry } from '@frontend/data-access/sentry';
import { logInToPurchases } from '@frontend/data-access/subscription';
import { accountFeature, createAccountSuccess } from '@frontend/data-access/user/account';
import { onboardingComplete, OnboardingRoutes, onboardingRouteTo } from '@frontend/feature/onboarding';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class CreateAccountEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    routeToOnboardingWhenAccountCreationSucceeds$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccountSuccess),
            map(() => {
                return onboardingRouteTo({ route: OnboardingRoutes.NAME });
            }),
        );
    });

    setUpSentry$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccountSuccess),
            map(({ profileId, profile }) => {
                return setUserMobileAppSentry({ userId: profileId!, email: profile!.mail });
            }),
        );
    });

    // TODO: JL - add test
    updateUserInRevenueCat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccountSuccess),
            map(({ profileId, profile }) => {
                return logInToPurchases({ profileId: profileId!, email: profile!.mail });
            }),
        );
    });

    fireThirdPartyActionsOnceOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingComplete),
            concatLatestFrom(() => this.store.select(accountFeature.selectDateOfBirth)),
            switchMap(([, dateOfBirth]) => {
                return [
                    ...(mapDateOfBirthToAgeInWeeks(dateOfBirth) < 11
                        ? [trackAdjustEvent({ event: AdjustEventType.YOUNG_PUPPY_INSTALL })]
                        : []),
                    trackAdjustEvent({ event: AdjustEventType.ONBOARDING_COMPLETED }),
                ];
            }),
        );
    });
}
