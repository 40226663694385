import { createAction, props } from '@ngrx/store';

export const courseSliderChanged = createAction(
    '[Course Slider] Course Slider Changed',
    props<{ courseId: string; courseTitle: string }>(),
);

export const setCourseSlider = createAction('[Course Slider] Change Course Slider', props<{ courseId: string }>());
export const setSelectedPathCourseId = createAction(
    '[Course Slider] Set Selected Path Course Id',
    props<{ courseId: string }>(),
);
