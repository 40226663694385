import { Component, computed, inject, model, OnInit, Signal } from '@angular/core';
import { PurchaseProduct, restoreSubscription } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { openIosDiscountProducts } from '../../store/payment/store/payment.actions';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentProductCard } from '../payment-modal-product/payment-product-card.model';
import { PaymentModalTermsComponent } from '../payment-modal-terms/payment-modal-terms.component';
import { selectPaymentModalDiscountProductsVm } from './payment-modal-discount-products.selectors';

@Component({
    standalone: true,
    selector: 'app-payment-modal-discount-products',
    templateUrl: './payment-modal-discount-products.component.html',
    styleUrl: './payment-modal-discount-products.component.scss',
    imports: [
        IonButton,
        PaymentModalProductComponent,
        TextReplacementPipe,
        IonFooter,
        AnalyticsTrackClickDirective,
        PaymentModalTermsComponent,
    ],
})
export class PaymentModalDiscountProductsComponent implements OnInit {
    private readonly store = inject(Store);

    public vm = this.store.selectSignal(selectPaymentModalDiscountProductsVm);

    public initialSelectedProduct: Signal<PurchaseProduct> = computed(() => {
        return {
            identifier: this.vm().discountProducts?.[0]?.identifier,
            priceWithUnit: this.vm().discountProducts?.[0]?.priceWithUnit,
        };
    });

    public selectedProduct = model<PurchaseProduct>();

    ngOnInit() {
        this.selectedProduct.set(this.initialSelectedProduct());
    }

    public onProductClick(product: PaymentProductCard): void {
        this.selectedProduct.set({ identifier: product.identifier, priceWithUnit: product.priceWithUnit });
    }

    public onPurchaseClick(): void {
        this.store.dispatch(openIosDiscountProducts());
    }

    public onRestoreClick(): void {
        this.store.dispatch(restoreSubscription());
    }
}
