import { Component, computed, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { MultiSelectComponent, MultiSelectOption, ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedNeeds } from '../../data-access/onboarding.actions';
import { selectNeedsByCountry } from '../../data-access/onboarding.selectors';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-needs',
    templateUrl: 'question-needs.page.html',
    styleUrls: ['question-needs.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        MultiSelectComponent,
        ProgressHeaderComponent,
        ReactiveFormsModule,
    ],
})
export class OnboardingQuestionNeedsPage extends OnboardingQuestionBasePage {
    public options = this.store.selectSignal(selectNeedsByCountry);

    public actualOptions = signal<MultiSelectOption[]>([]);

    public needs = computed(() => {
        const needs: Record<string, boolean> = {};

        (this.actualOptions().length > 0 ? this.actualOptions() : this.options()).forEach(
            (option: MultiSelectOption) => {
                needs[option.value] = option.selected;
            },
        );

        return needs;
    });
    public nextButtonText = computed(() => {
        const noTilesSelected = !this.actualOptions().some((option) => option.selected);

        return noTilesSelected ? 'Skip' : 'Next';
    });

    public onNeedsChanged(options: MultiSelectOption[]): void {
        this.actualOptions.set([...options]);
    }

    public onNextClick() {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedNeeds({
                needs: this.needs(),
            }),
        );
    }
}
