import { Photo } from '@capacitor/camera';
import { createFeature, createReducer, on } from '@ngrx/store';
import { removeProfileImage, setProfileImage } from './profile-image.actions';
import { getLocalStorageSuccess } from '@frontend/data-access/capacitor';
import { PROFILE_IMAGE_LOCAL_STORAGE_KEY } from './profile-image.constants';

export interface ProfileImageState {
    image: Photo | undefined;
}

export const initialState: ProfileImageState = { image: undefined };

export const profileImageFeature = createFeature({
    name: 'profileImage',
    reducer: createReducer(
        initialState,
        on(getLocalStorageSuccess, (state, { key, data }): ProfileImageState => {
            if (key !== PROFILE_IMAGE_LOCAL_STORAGE_KEY) {
                return state;
            }

            return { ...state, ...(data as any) };
        }),
        on(setProfileImage, (state, { image }): ProfileImageState => ({ ...state, image })),
        on(removeProfileImage, (state): ProfileImageState => ({ ...state, image: undefined })),
    ),
});
