import { inject, Injectable } from '@angular/core';
import { adjustRequestTrackingConsent } from '@frontend/data-access/adjust';
import { getCoursesSuccess, selectCourses } from '@frontend/data-access/contentful';
import { routeTo } from '@frontend/data-access/router';
import { selectIsHomeRecommendedContentEnabled } from '@frontend/data-access/user/config-cat';
import { finalisePageFinishedWaiting, onboardingComplete } from '@frontend/feature/onboarding';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { FoundationCourseId } from '@shared/constants';
import { CourseProgressType } from '@shared/user-domain';
import { removeDuplicates } from '@shared/utils/typescript';
import { zip } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { selectCourseToSetInProgressAfterOnboardingForNonHomeRecommendedContentUsers } from '../../store/content/content.selectors';
import {
    triggerCreateCourseProgress,
    triggerCreateManyCourseProgress,
    TRIGGERED_FROM_ONBOARDING_CORRELATION_ID,
} from '../../store/progress/course-progress/course-progress.actions';

@Injectable()
export class OnboardingEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    finalizeOnboarding$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(finalisePageFinishedWaiting),
            switchMap(() => [routeTo({ commands: ['course-preview'] }), adjustRequestTrackingConsent()]),
        );
    });

    setInProgressCourseAfterOnboardingForNonHomeRecommendedContentUsers$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getCoursesSuccess)),
            this.actions$.pipe(ofType(onboardingComplete)),
        ]).pipe(
            concatLatestFrom(() => {
                return this.store.select(selectIsHomeRecommendedContentEnabled);
            }),
            filter(([, isHomeRecommendedContentEnabled]) => !isHomeRecommendedContentEnabled),
            concatLatestFrom(() => {
                return this.store.select(selectCourseToSetInProgressAfterOnboardingForNonHomeRecommendedContentUsers);
            }),
            map(([_, foundationIdToSetInProgress]) => {
                return triggerCreateCourseProgress({
                    courseProgress: {
                        id: foundationIdToSetInProgress,
                        progressType: CourseProgressType.IN_PROGRESS,
                    },
                    correlationId: TRIGGERED_FROM_ONBOARDING_CORRELATION_ID,
                });
            }),
        );
    });

    setInProgressCourseAfterOnboardingForHomeRecommendedContentUsers$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(getCoursesSuccess)),
            this.actions$.pipe(ofType(onboardingComplete)),
        ]).pipe(
            concatLatestFrom(() => {
                return this.store.select(selectIsHomeRecommendedContentEnabled);
            }),
            filter(([, isHomeRecommendedContentEnabled]) => !!isHomeRecommendedContentEnabled),
            concatLatestFrom(() => {
                return this.store.select(selectCourses);
            }),
            map(([_, courses]) => {
                const createCourseProgressEntries = courses.map((course) => {
                    return {
                        id: course.id,
                        progressType: CourseProgressType.IN_PROGRESS,
                    };
                });

                // JL - for setting all foundation courses to in progress after onboarding
                for (const key in FoundationCourseId) {
                    createCourseProgressEntries.push({
                        id: FoundationCourseId[key as keyof typeof FoundationCourseId],
                        progressType: CourseProgressType.IN_PROGRESS,
                    });
                }

                const tidiedCourseProgressEntries = removeDuplicates(
                    createCourseProgressEntries,
                    (a, b) => a.id === b.id,
                );

                return triggerCreateManyCourseProgress({
                    courseProgress: tidiedCourseProgressEntries,
                    correlationId: TRIGGERED_FROM_ONBOARDING_CORRELATION_ID,
                });
            }),
        );
    });
}
