import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { PlatformEffects } from './platform.effects';
import { platformFeature } from './platform.reducer';

@NgModule({
    providers: [provideEffects([PlatformEffects]), provideState(platformFeature)],
})
export class ZigzagFrontendDataAccessPlatformModule {}
