import { chatFeature } from '@frontend/data-access/chat';
import { selectIsUsUser } from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { selectContentUnlocked, selectIsBasicPlan } from '../store/payment/store/payment.selectors';

export const selectSupportPageVm = createSelector(
    selectContentUnlocked,
    selectIsUsUser,
    chatFeature.selectIsDestroying,
    selectIsBasicPlan,
    (isContentUnlocked, isUsUser, isStreamChatBusyCleaningUp, isBasicPlan) => {
        const aiChat = {
            imageUrl: '/assets/images/ziggy/ziggy-close-up.svg',
            title: 'Ask Ziggy',
            backgroundColor: Color.Sophie,
            cardName: 'ai-chat',
            isContentUnlocked: true,
        };

        const expertSupport = {
            imageUrl: '/assets/images/trainers/coach-petrina-red-circle.png',
            title: 'Chat with a training coach',
            backgroundColor: Color.Harley,
            cardName: 'expertsupport',
            isContentUnlocked: isContentUnlocked && !isBasicPlan,
        };

        const tiles = [aiChat, expertSupport];
        return {
            tiles,
            isUsUser,
            isStreamChatBusyCleaningUp,
            isBasicPlan,
        };
    },
);
