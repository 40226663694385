@if (vm$ | async; as vm) {
<ion-content class="course-preview-page" [fullscreen]="true">
    <div class="page-content-padding-top page-padding-sides course-preview-container page-padding-bottom">
        <div class="course-preview-header-section">
            <img class="course-preview-logo" [src]="'assets/images/zigzag/zigzag-logo.png'" alt="zigzag-logo" />
            @if (vm.partner) {
            <div class="course-preview-header-partner">
                <h1 class="course-preview-title">Your puppyhood journey begins here</h1>
                <p class="course-preview-partner-subheading" data-test="course-preview-subheading">
                    As a {{ vm.partner.partnerName }} customer you've unlocked access to Zigzag for {{
                    vm.partner.userFriendlyAccessLength }}. 🎉👏
                </p>
            </div>
            } @else {
            <h1 class="course-preview-title" data-test="course-preview-title">
                {{ 'Start [NAME]’s tailored journey for' | textReplacement }} <span class="font-max">FREE</span>
            </h1>
            }
        </div>
        <div
            class="course-preview-wrapper"
            data-test="course-preview-wrapper"
            [ngClass]="{showSquiggleBackground: vm.ageInWeeks! < 12}"
        >
            <div class="course-preview-profile">
                <app-profile-image size="58px" borderWidth="2px" [type]="ProfileImageType.VIEW_ONLY" />
                <div class="profile-info">
                    <div class="profile-info-row">
                        <p class="profile-info-item s">
                            <i class="fa-light fa-dog profile-info-icon"></i>
                            <span data-test="course-preview-breed-name">{{ vm.profileInfo.breedName }}</span>
                        </p>
                    </div>
                    <div class="profile-info-row">
                        <p class="profile-info-item s">
                            <i class="fa-light fa-cake-candles profile-info-icon"></i>
                            <span data-test="course-preview-age">{{ vm.profileInfo.dateOfBirth | age }} old</span>
                        </p>
                        <p class="profile-info-item s">
                            @if (vm.profileInfo.gender === 'Boy') {
                            <span>
                                <i
                                    data-test="course-preview-gender-icon-male"
                                    class="fa-light fa-mars profile-info-icon"
                                ></i>
                            </span>
                            } @else {
                            <span>
                                <i
                                    data-test="course-preview-gender-icon-female"
                                    class="fa-light fa-venus profile-info-icon"
                                ></i>
                            </span>
                            }
                            <span data-test="course-preview-gender">{{ vm.profileInfo.gender }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="course-preview-content ignore-global-color"
                [ngStyle]="{'background-color': 'var(--ion-color-' + vm.courseInfo.color + ')', 'color': 'var(--ion-color-' + vm.courseInfo.color + '-contrast' + ')'}"
            >
                <h2>{{ vm.courseInfo.title | textReplacement }}</h2>
                @if (vm.courseInfo.subtitle) {
                <p class="s">{{ vm.courseInfo.subtitle | textReplacement }}</p>
                }
                <div class="course-preview-targets">
                    @for (target of vm.courseInfo.targets; track target.title) {
                    <div class="course-preview-target-item no-margin">
                        <img [ngSrc]="target.link" width="60" height="60" class="course-preview-image" />
                        <h3>{{ target.title | textReplacement }}</h3>
                    </div>
                    }
                </div>
            </div>
            @if(vm.ageInWeeks! < 12) {
            <div class="additional-courses-wrapper">
                @for (nextCourse of vm.nextCourses; track nextCourse.title) {
                <div class="course-preview-additional-courses">
                    <div
                        [ngStyle]="{'background-color': 'var(--ion-color-' + nextCourse.color + ')', 'color': 'var(--ion-color-' + nextCourse.color + '-contrast' + ')', 'left': nextCourse.xOffset + 'px'}"
                        class="course-preview-additional-courses-item ignore-global-color"
                    >
                        <img class="additional-course-img" [src]="nextCourse.image" [alt]="nextCourse.alt" />
                        <h3 class="s ignore-screen-based-font-scaling">{{ nextCourse.title | textReplacement }}</h3>
                        <p class="xxs">{{ nextCourse.subtitle | textReplacement }}</p>
                    </div>
                </div>
                }
            </div>
            }
        </div>
        <div class="course-preview-endorsements" data-test="course-preview-endorsements">
            <h3>
                Endorsed by leading dog training & {{ vm.isUSUser ? 'behaviorist organizations' : 'behaviourist
                organisations' }}.
            </h3>
            <app-endorsements />
        </div>
    </div>
    <div class="ion-text-center gradient-footer" slot="fixed" data-test="course-preview-footer">
        <ion-button
            color="max"
            expand="full"
            shape="round"
            data-test="course-preview-start-journey-btn"
            appTrackClick
            identifier="course-preview-start-journey-btn"
            (click)="next(vm.hasPartner)"
        >
            Start my journey!
        </ion-button>
    </div>
</ion-content>
}
