import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { ShowModalFrequency } from '../models/dismissible-info.model';
import { dismissibleInfoFeature } from './dismissible-info.reducer';
import { shouldDisplayModal } from '../utils/dismissible-info.utils';

export const selectShouldDisplayInfoItem = (itemId: string, showModalFrequency: ShowModalFrequency) =>
    createSelector(dismissibleInfoFeature.selectDismissedInfoItems, timeFeature.selectToday, (dismissedItems, today) =>
        shouldDisplayModal(showModalFrequency, itemId, dismissedItems, today),
    );
