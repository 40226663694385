import { createAction, props } from '@ngrx/store';
import { CreateCourseProgressEntry } from './course-progress.model';

export const TRIGGERED_FROM_ONBOARDING_CORRELATION_ID = 'Triggered from onboarding';

export const triggerCreateCourseProgress = createAction(
    '[Course Progress] Trigger Create Course Progress',
    props<{ courseProgress: CreateCourseProgressEntry; correlationId?: string }>(),
);

export const triggerCreateManyCourseProgress = createAction(
    '[Course Progress] Trigger Create Many Course Progress',
    props<{ courseProgress: CreateCourseProgressEntry[]; correlationId?: string }>(),
);
