import { Injectable, inject } from '@angular/core';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import { HapticImpactStyle, HapticNotificationStyle, HapticStyle } from './haptic.style';
import { Platform } from '@ionic/angular/standalone';

const impactMap: { [key in HapticImpactStyle]: ImpactStyle } = {
    light: ImpactStyle.Light,
    medium: ImpactStyle.Medium,
    heavy: ImpactStyle.Heavy,
};

const notificationMap: { [key in HapticNotificationStyle]: NotificationType } = {
    success: NotificationType.Success,
    warning: NotificationType.Warning,
    error: NotificationType.Error,
};

@Injectable({
    providedIn: 'root',
})
export class HapticFeedbackService {
    private readonly platform = inject(Platform);

    async fire(style: HapticStyle) {
        if (!this.platform.is('capacitor')) {
            console.info('[Mock Haptic Feedback Service] fire()', { style });
            return;
        }

        if (style in impactMap) {
            await Haptics.impact({ style: impactMap[style as HapticImpactStyle] });
        }
        if (style in notificationMap) {
            await Haptics.notification({ type: notificationMap[style as HapticNotificationStyle] });
        }
        if (style === 'selection') {
            await Haptics.selectionStart();
            await Haptics.selectionChanged();
        }
    }
}
