import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { FoodPortion } from '../../data-access/food-calculator.model';

@Component({
    selector: 'app-food-calculator-result',
    templateUrl: './food-calculator-result.component.html',
    styleUrls: ['./food-calculator-result.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TextReplacementPipe],
})
export class FoodCalculatorResultComponent {
    @Input()
    public foodPortion?: FoodPortion;
    @Input()
    public loading = false;

    @Output()
    public howIsThisCalculated = new EventEmitter<void>();
}
