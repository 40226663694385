<app-onboarding-legacy-question-template
    backButtonId="question-arrival-back-btn"
    title="When will {{ vm().dogName }} arrive home?"
    [progress]="vm().progress"
    [isLoading]="vm().isLoading"
    (backButtonPressed)="routeToPrevious()"
>
    <ng-container body>
        <form id="arrivalForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <ion-item class="question-arrival-margin-top">
                <ion-input
                    class="zz-input"
                    id="dateOfArrival"
                    formControlName="dateOfArrival"
                    type="date"
                    data-test="date-of-arrival-input"
                    [min]="vm().currentDate | date: 'yyyy-MM-dd' : '-1d'"
                    [tabindex]="1"
                />
            </ion-item>
            @if (form.invalid) {
            <app-invalid-input-error-message
                errorMessage="Please enter a date in the near future"
                data-test="date-validation"
            />
            }
        </form>
    </ng-container>
    <ng-container footer>
        <ion-button
            appTrackClick
            identifier="question-arrival-next-btn"
            expand="block"
            type="submit"
            form="arrivalForm"
            data-test="question-arrival-next-btn"
            [disabled]="form.invalid || vm().isLoading"
            [color]="Color.Max"
            >Next
        </ion-button>
    </ng-container>
</app-onboarding-legacy-question-template>
