<ion-header class="ai-chat-header">
    <ion-toolbar color="app-outline" class="ai-chat-header__toolbar" />
    <app-ai-chat-header (backClicked)="routeToPrevious()" />
</ion-header>

@if (vm()) {
<ion-content
    class="ai-chat"
    [ngClass]="{
            'ai-chat--intro': shouldShowChatIntro,
            'page-ion-content--safe-area': vm().platformInfo.isIos && !vm().platformInfo.isKeyboardOpen && !messageInputLocked(),
        }"
    [fullscreen]="true"
>
    @if (vm().chat.channelInitialized) { @if (shouldShowChatIntro) {
    <app-ai-chat-intro
        data-test="ai-chat-page-intro"
        [user]="{
                        ownerName: vm().user.ownerName,
                        dogName: vm().user.dogName,
                    }"
        [suggestedQuestionsCards]="vm().suggestedQuestionsCards"
        [isChannelEmpty]="vm().chat.channelEmpty"
        [optionId]="vm().oneFreeQuestionInfo.initialOptionId"
        (messageToPrefill)="placePromptMessageInInput($event)"
        (hide)="introTriggeredHide()"
    />
    }

    <stream-channel>
        @if (!shouldShowChatIntro) {
        <stream-message-list data-test="ai-chat-page-message-list" />
        } @if (shouldShowFreeQuestionChip()) {
        <app-generic-chip
            class="ai-chat__chip"
            data-test="ai-chat-page-free-question-chip"
            [text]=" vm().oneFreeQuestionInfo.questionsRemaining + ' question remaining today'"
        />
        }

        <!-- We want to NOT use @if as we need to input to be rendered to paste in the message from the Today page -->
        <stream-message-input
            #streamMessageInput
            data-test="ai-chat-page-message-input"
            [hidden]="messageInputLocked()"
            [autoFocus]="false"
            [isFileUploadEnabled]="false"
            (focusin)="onFocusInStreamChatInput()"
            (messageUpdate)="messageSentOrUpdated($event)"
        />

        @if (messageInputLocked()) {
        <ion-footer class="ai-chat-footer page-footer" data-test="ai-chat-page-locked-content-footer">
            <h3 class="ai-chat-footer__more-questions-text">Want to ask more questions today?</h3>
            <ion-button
                color="max"
                expand="full"
                shape="round"
                class="ai-chat-footer__show-paywall-button"
                appTrackClick
                identifier="ai-chat-ask-more-questions-btn"
                (click)="openPaymentModal()"
            >
                <i class="fa-regular fa-lock-keyhole ion-margin-end icon-large ai-chat__padlock-icon"></i>
                {{ vm().hasHistoricalPurchase ? 'See plans' : 'Start my free trial' }}
            </ion-button>
        </ion-footer>
        }
    </stream-channel>
    } @else {
    <app-loading-screen />
    }
</ion-content>
}

<ng-template #messageTemplate let-message="message" let-isLastSentMessage="isLastSentMessage">
    <stream-message [message]="message | appStreamChatHtmlMessage" [isLastSentMessage]="isLastSentMessage" />

    @if (message.user.id === ZIGGY_AI_ID) {
    <app-chat-message-rating
        data-test="ai-chat-message-rating"
        [rating]="message.zigzagRating"
        (ratingChanged)="onRatingChanged($event, message.id)"
    />
    } @if (message.id === lastNegativeRatedMessageId) {
    <app-feedback
        [aiChat]="true"
        (optionSelected)="checkIfShouldScrollToRatingFeedback()"
        (feedbackSubmitted)="onFeedbackSubmitted($event, message.id)"
    />
    } @if (vm() && isLastSentMessage && vm().didAnswerTimeOut) {
    <app-chat-error-message
        [backgroundColor]="vm().errorMessageProps.backgroundColor"
        [textColor]="vm().errorMessageProps.textColor"
    />
    }
</ng-template>
