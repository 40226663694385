import { inject, Injectable } from '@angular/core';
import {
    getLocalStorage,
    getLocalStorageSuccess,
    setLocalStorage,
    syncLegacyLocalStorageDataFailure,
    syncLegacyLocalStorageDataSuccess,
} from '@frontend/data-access/capacitor';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ofSourceType } from '@shared/utils/typescript';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PROFILE_IMAGE_ACTION_SOURCE, PROFILE_IMAGE_LOCAL_STORAGE_KEY } from './profile-image.constants';
import { profileImageFeature } from './profile-image.reducer';

@Injectable()
export class ProfileImageEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    readProfileImageStateFromLocalStorageOnStartup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(syncLegacyLocalStorageDataSuccess, syncLegacyLocalStorageDataFailure),
            map(() => getLocalStorage({ key: PROFILE_IMAGE_LOCAL_STORAGE_KEY })),
        );
    });

    syncProfileImageStateWithLocalStorage$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                // State needs to be read first, before we want to start saving.
                ofType(getLocalStorageSuccess),
                filter(({ key }) => key === PROFILE_IMAGE_LOCAL_STORAGE_KEY),
                take(1),
            ),
            this.actions$.pipe(
                // Save on every action
                ofSourceType(PROFILE_IMAGE_ACTION_SOURCE),
            ),
        ]).pipe(
            concatLatestFrom(() => this.store.select(profileImageFeature.selectProfileImageState)),
            map(([, state]) => setLocalStorage({ key: PROFILE_IMAGE_LOCAL_STORAGE_KEY, data: state })),
        );
    });
}
