import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppRatingService } from '../app-rating.service';
import { launchRatingPrompt } from './app-rating.actions';
import { selectShouldLaunchRatingPrompt } from './app-rating.selectors';

@Injectable()
export class AppRatingEffects {
    private readonly actions$ = inject(Actions);
    private readonly appRatingService = inject(AppRatingService);
    private readonly store = inject(Store);

    launchRatingPrompt$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(launchRatingPrompt),
                concatLatestFrom(() => this.store.select(selectShouldLaunchRatingPrompt)),
                filter(([_, shouldLaunchRatingPrompt]) => shouldLaunchRatingPrompt),
                tap(() => {
                    this.appRatingService.launch();
                }),
            );
        },
        { dispatch: false },
    );
}
