import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectQueryParamFrom } from '../../router/router.selectors';
import { selectIsFirstCompletedStep } from '@frontend/data-access/user/progress';
import { setStepCompleted, setStepRead, setStepSkipped, trackStepUpdate } from './step-progress.actions';
import { StepUpdateEventName } from './step-progress.model';
import { createUserFeedbackContent } from '@frontend/data-access/feedback';

@Injectable()
export class StepProgressAnalyticsEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    trackStepCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepCompleted),
            concatLatestFrom(() => this.store.select(selectIsFirstCompletedStep)),
            map(([action, isFirstCompletedStep]) =>
                trackStepUpdate({
                    eventName: StepUpdateEventName.TASK_COMPLETED,
                    eventProperties: {
                        ...action.properties,
                        isFirstCompletedStep,
                    },
                }),
            ),
        );
    });

    trackStepRead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepRead),
            map((action) =>
                trackStepUpdate({
                    eventName: StepUpdateEventName.TASK_READ,
                    eventProperties: action.properties,
                }),
            ),
        );
    });

    trackStepSkipped$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepSkipped),
            map((action) =>
                trackStepUpdate({
                    eventName: StepUpdateEventName.TASK_SKIPPED,
                    eventProperties: action.properties,
                }),
            ),
        );
    });

    trackStepUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackStepUpdate),
            concatLatestFrom(() => this.store.select(selectQueryParamFrom)),
            map(([{ eventName, eventProperties }, primaryRoute]) =>
                trackEvent({
                    eventName,
                    eventProperties: {
                        ...eventProperties,
                        primaryRoute,
                    },
                }),
            ),
        );
    });

    userLeftArticleFeedback$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserFeedbackContent),
            map(({ feedback }) => {
                return trackEvent({
                    eventName: '[Article] User Left Feedback',
                    eventProperties: {
                        comment: feedback.comment,
                        thumbsDown: feedback.thumbsDown,
                        thumbsUp: feedback.thumbsUp,
                        contentfulId: feedback.contentfulId,
                        contentTypeId: feedback.contentTypeId,
                    },
                });
            }),
        );
    });
}
