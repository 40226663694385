<ion-content>
    <button
        class="xl tiered-pricing-comparison-modal__close-button"
        (click)="closeModalClicked()"
        appTrackClick
        identifier="tiered-pricing-comparison-modal-close-btn"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <section class="tiered-pricing-comparison-modal">
        <div class="tiered-pricing-comparison-modal__title-container">
            <h1>Plan comparison</h1>
            <p class="s tiered-pricing-comparison-modal__subtitle">
                @if (vm().isBasicPlan) {
                    Go Premium <i class="fa-regular fa-crown"></i> now
                } @else {
                    Choose the best plan for you and {{ '[NAME]' | textReplacement }}
                }
            </p>
            @if (!vm().isBasicPlan) {
                <app-tiered-pricing-comparison-toggle />
            }
        </div>

        <section class="tiered-pricing-comparison-modal__products">
            @if (this.vm().isPremiumToggleSelected) {
                @for (premiumProduct of vm().premiumProducts; track premiumProduct.identifier) {
                    <app-payment-modal-product
                        [isSelected]="premiumProduct.identifier === vm().selectedProduct.identifier"
                        [product]="premiumProduct"
                    />
                }
            } @else {
                @for (basicProduct of vm().basicProducts; track basicProduct.identifier) {
                    <app-payment-modal-product
                        [isBasicSelected]="basicProduct.identifier === vm().selectedProduct.identifier"
                        [product]="basicProduct"
                    />
                }
            }
        </section>

        <app-tiered-pricing-plan-comparison [isPremium]="this.vm().isPremiumToggleSelected" />
        <app-payment-modal-terms class="tiered-pricing-comparison-modal__terms" />
    </section>

    <ion-footer class="tiered-pricing-comparison-modal-footer gradient-footer">
        <div class="tiered-pricing-comparison-modal-footer__button-group">
            <ion-button
                appTrackClick
                [identifier]="'tiered-pricing-comparison-modal-payment-purchase-' + vm().selectedProduct.identifier"
                color="max"
                expand="full"
                shape="round"
                [disabled]="!vm().selectedProduct"
                (click)="onPurchaseClick(vm().selectedProduct)"
            >
                @if (vm().isBasicPlan) {
                    Upgrade to Premium
                    <i class="fa-regular fa-crown tiered-pricing-comparison-modal-footer__premium-icon"></i>
                } @else {
                    {{ vm().hasHistoricalPurchase ? 'Start my subscription' : 'Start my free trial' }}
                }
            </ion-button>
        </div>
    </ion-footer>
</ion-content>
