import { createAction, props } from '@ngrx/store';
import { UserAppAccessGranted } from '../models/user-app-access-granted.model';
import {
    InternalPatchUserAppAccessGrantedCommand,
    InternalPostUserAppAccessGrantedCommand,
} from '@shared/user-api-interface';

export const getUserAppAccessGrantedSuccess = createAction(
    '[User App Access Granted] Get User App Access Granted Success',
    props<{ userAppAccessGranted: UserAppAccessGranted[] }>(),
);

export const getUserAppAccessGrantedFailure = createAction(
    '[User App Access Granted] Get User App Access Granted Failure',
    props<{ error: Error }>(),
);

export const createUserAppAccessGranted = createAction(
    '[User App Access Granted] Create User App Access Granted',
    props<{ postUserAppAccessGrantedCommand: InternalPostUserAppAccessGrantedCommand }>(),
);

export const createUserAppAccessGrantedSuccess = createAction(
    '[User App Access Granted] Create User App Access Granted Success',
    props<{ userAppAccessGranted: UserAppAccessGranted }>(),
);

export const createUserAppAccessGrantedFailure = createAction(
    '[User App Access Granted] Create User App Access Granted Failure',
    props<{ error: Error }>(),
);

export const patchUserAppAccessGranted = createAction(
    '[User App Access Granted] Patch User App Access Granted',
    props<{ id: string; patchUserAppAccessGranted: InternalPatchUserAppAccessGrantedCommand }>(),
);

export const patchUserAppAccessGrantedSuccess = createAction(
    '[User App Access Granted] Patch User App Access Granted Success',
    props<{ userAppAccessGranted: UserAppAccessGranted }>(),
);

export const patchUserAppAccessGrantedFailure = createAction(
    '[User App Access Granted] Patch User App Access Granted Failure',
    props<{ error: Error }>(),
);
