<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            [leftButton]="false"
            [rightButton]="{
                analyticsIdentifier: 'close-course-information-modal-btn',
                sprite: 'light',
                iconName: 'xmark',
                background: 'none',
                iconColour: 'black',
            }"
            (rightButtonPressed)="closeModal()"
        />
    </ion-toolbar>
</ion-header>

@if (course) {
    <ion-content
        class="course-information-modal"
        data-test="course-information-content-container"
        [fullscreen]="true"
        [color]="course.color"
    >
        <article class="course-information-modal__content" [ngStyle]="getDynamicallyAppliedStyles()">
            <section class="ion-align-self-center">
                <img class="course-information-modal__image" [src]="course.imageUrl" alt="course image" />
            </section>
            <section>
                <h1 class="ion-text-center" data-test="course-information-modal-title">
                    {{ course.title }}
                </h1>
                @if (course.subTitle) {
                    <p
                        class="course-information-modal__subtitle"
                        [ngStyle]="{ color: 'var(--ion-color-' + course.color + '-contrast)' }"
                    >
                        {{ course.subTitle }}
                    </p>
                }
            </section>
            @if (course.explanation && course.explanation.content?.length > 0) {
                <section
                    class="ignore-global-color"
                    [innerHTML]="course.explanation | richContent | textReplacement"
                    [ngStyle]="{ color: 'var(--ion-color-' + course.color + '-contrast)' }"
                ></section>
            }
        </article>
        @if (vm$ | async; as vm) {
            @if (vm.selectedPathCourseId !== course.id || course.status === courseStatus.INACTIVE) {
                <ion-footer
                    class="course-information-modal__footer"
                    [ngStyle]="{
                        background:
                            'linear-gradient(to top, rgba(var(--ion-color-' +
                            course.color +
                            '-rgb), 1) 0%, rgba(var(--ion-color-' +
                            course.color +
                            '-rgb), 1) 65%, rgba(var(--ion-color-' +
                            course.color +
                            '-rgb), 0) 100% )',
                        '--gradient-color': course.color,
                    }"
                >
                    <section class="ion-text-center">
                        <ion-button
                            color="max"
                            expand="full"
                            shape="round"
                            appTrackClick
                            [identifier]="'start-course-information-modal-btn'"
                            data-test="start-course-information-modal-btn"
                            (click)="
                                vm.unlocked ? goToCourse(course.status === courseStatus.INACTIVE) : openPaymentModal()
                            "
                        >
                            @if (vm.isBasicPlan && course.type === CourseType.OPTIONAL) {
                                Upgrade to Premium
                                <i class="fa-regular fa-crown course-information-modal__premium-icon"></i>
                            } @else if (course.status === courseStatus.INACTIVE) {
                                Start course
                            } @else {
                                Go to course
                            }
                        </ion-button>
                        <button
                            [ngStyle]="{ color: 'var(--ion-color-' + course.color + '-contrast)' }"
                            (click)="closeModal()"
                            class="zz-button course-information-modal__cancel-button"
                            data-test="cancel-course-information-modal-btn"
                            appTrackClick
                            identifier="cancel-course-information-modal-btn"
                        >
                            Cancel
                        </button>
                    </section>
                </ion-footer>
            }
        }
    </ion-content>
}
