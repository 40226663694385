import { inject, Injectable } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { signInClicked } from './landing.page.actions';

@Injectable()
export class LandingPageEffects {
    private readonly actions$ = inject(Actions);

    routeToLoginAfterSignInClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signInClicked),
            map(() => routeTo({ commands: ['auth', 'login'], isAnimated: true })),
        );
    });
}
