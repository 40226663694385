import { RequestState } from '@frontend/utility/request-reducer';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { InternalSubscriptionDto } from '@shared/user-api-interface';
import { Entitlement, PurchaseProduct, SubscriptionOffering } from './models/subscription.model';
import {
    getActiveSubscription,
    getActiveSubscriptionFailure,
    getActiveSubscriptionSuccess,
    getEntitlements,
    getEntitlementsFailure,
    getEntitlementsSuccess,
    getOfferings,
    getOfferingsFailure,
    getOfferingsSuccess,
    logInToPurchasesSuccess,
    purchaseProduct,
    purchaseProductCancelled,
    purchaseProductFailure,
    purchaseProductSuccess,
} from './subscription.actions';

export interface SubscriptionState {
    nonNativeSubscriptionInfo: RequestState<{
        value: InternalSubscriptionDto;
    }>;
    entitlements: RequestState<{
        value: Entitlement[];
        hasHistoricalPurchase?: boolean;
    }>;
    offering: RequestState<{
        value: SubscriptionOffering[];
    }>;
    purchase: RequestState<PurchaseProduct>;
}

export const initialState: SubscriptionState = {
    nonNativeSubscriptionInfo: {
        loading: false,
        data: { value: { isActive: false } },
    },
    entitlements: {
        loading: false,
        data: {
            value: [],
            hasHistoricalPurchase: false,
        },
    },
    offering: { loading: false, data: { value: [] } },
    purchase: {
        data: { identifier: '', priceWithUnit: '' },
        loading: false,
    },
};

export const subscriptionFeature = createFeature({
    name: 'subscription',
    reducer: createReducer(
        initialState,
        on(
            getActiveSubscription,
            (state): SubscriptionState => ({
                ...state,
                nonNativeSubscriptionInfo: { ...state.nonNativeSubscriptionInfo, loading: true },
            }),
        ),
        on(
            getActiveSubscriptionSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                nonNativeSubscriptionInfo: {
                    loading: false,
                    data: { value: { isActive: payload.data.value.isActive, provider: payload.data.value.provider } },
                },
            }),
        ),
        on(
            getActiveSubscriptionFailure,
            (state): SubscriptionState => ({
                ...state,
                nonNativeSubscriptionInfo: { ...state.nonNativeSubscriptionInfo, loading: false },
            }),
        ),
        on(
            getEntitlements,
            (state): SubscriptionState => ({
                ...state,
                entitlements: { ...state.entitlements, loading: true },
            }),
        ),
        on(
            getEntitlementsSuccess,
            logInToPurchasesSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                entitlements: { loading: false, data: payload.data },
            }),
        ),
        on(
            getEntitlementsFailure,
            (state): SubscriptionState => ({
                ...state,
                entitlements: { ...state.entitlements, loading: false },
            }),
        ),
        on(
            getOfferings,
            (state): SubscriptionState => ({
                ...state,
                offering: { ...state.offering, loading: true },
            }),
        ),
        on(
            getOfferingsSuccess,
            (state, payload): SubscriptionState => ({
                ...state,
                offering: {
                    loading: false,
                    data: payload.data,
                },
            }),
        ),
        on(
            getOfferingsFailure,
            (state): SubscriptionState => ({
                ...state,
                offering: { ...state.offering, loading: false },
            }),
        ),
        on(
            purchaseProduct,
            (state): SubscriptionState => ({
                ...state,
                purchase: { ...state.purchase, loading: true },
            }),
        ),
        on(
            purchaseProductSuccess,
            purchaseProductCancelled,
            (state, payload): SubscriptionState => ({
                ...state,
                purchase: {
                    loading: false,
                    data: { identifier: payload?.data?.identifier, priceWithUnit: payload?.data?.priceWithUnit },
                },
            }),
        ),
        on(
            purchaseProductFailure,
            (state): SubscriptionState => ({
                ...state,
                purchase: { ...state.purchase, loading: false },
            }),
        ),
    ),
    extraSelectors: ({ selectSubscriptionState }) => ({
        selectEntitlements: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.entitlements?.data?.value || [],
        ),
        selectAreEntitlementsLoading: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.entitlements?.loading || false,
        ),
        selectDefaultOffering: createSelector(
            selectSubscriptionState,
            (subscriptionState) =>
                subscriptionState.offering?.data?.value?.find((offering) => offering.isDefault) || undefined,
        ),
        selectOfferings: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.offering?.data?.value || [],
        ),
        selectNonNativeSubscriptionInfo: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.nonNativeSubscriptionInfo,
        ),
        selectHasHistoricalPurchase: createSelector(
            selectSubscriptionState,
            (subscriptionState) => subscriptionState.entitlements?.data?.hasHistoricalPurchase,
        ),
    }),
});
