<div [ngStyle]="dynamicallyAppliedStyles()" class="progress-streak-modal" data-test="progress-streak-modal">
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-header
                [leftButton]="false"
                [rightButton]="{
                    analyticsIdentifier: 'progress-streak-modal-close-btn',
                    sprite: 'light',
                    iconName: 'xmark',
                    iconColour: headerButtonIconColor(),
                    background: headerButtonBackgroundColor(),
                }"
                (rightButtonPressed)="closeModal()"
            />
        </ion-toolbar>
    </ion-header>

    <section class="progress-streak-modal__content">
        <div class="progress-streak-modal__title-container">
            <h1 class="progress-streak-modal__streak-length xxxl">{{ courseStreak().length }}</h1>
            <h1 class="progress-streak-modal__streak-text">day streak</h1>
        </div>

        <div class="progress-streak-modal__bottom-content-container ignore-global-color">
            <h1 class="ion-text-center progress-streak-modal__subtitle">
                {{ handIcon() }} - paw for completing 3 lessons of {{ courseStreak().title }}!
            </h1>

            <ul class="progress-streak-modal__week-container">
                @for (day of DAYS_IN_WEEK; track DAYS_IN_WEEK.length; let i = $index) {
                    <li class="progress-streak-modal__paw-and-day">
                        <p class="ion-text-center">{{ courseStreak().dateStarted | weekday: i }}</p>
                        <i class="fa-paw fa-xl" [ngClass]="i < courseStreak().length ? 'fa-solid' : 'fa-light'"></i>
                    </li>
                }
            </ul>

            <p class="ion-text-center">{{ getComplimentString() | textReplacement }}</p>
        </div>
    </section>

    <ion-footer class="page-padding-bottom">
        <ion-button
            class="primary-btn ion-padding-horizontal"
            expand="block"
            appTrackClick
            identifier="progress-streak-modal-got-it-btn"
            data-test="progress-streak-modal-got-it-btn"
            [color]="buttonColor()"
            (click)="closeModal()"
            >Continue
        </ion-button>
    </ion-footer>
</div>
