import { Entitlement, SubscriptionPeriodType } from '../subscription.model';

export class EntitlementMock {
    private defaultValue: Entitlement = {
        identifier: '',
        isActive: false,
        periodType: SubscriptionPeriodType.NORMAL,
    };

    constructor(overrides?: Partial<Entitlement>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Entitlement {
        return this.defaultValue;
    }
}
