import { createAction, props } from '@ngrx/store';

export const addCapacitorPushNotificationListeners = createAction(
    '[Capacitor Push Notifications] Add Push Notification Listeners',
);

export const capacitorPushNotificationTokenReceived = createAction(
    '[Capacitor Push Notifications] Push Notification Token Received',
    props<{ token: string }>(),
);

export const capacitorPushNotificationReceived = createAction(
    '[Capacitor Push Notifications] Push Notification Received',
    props<{ id: string | undefined }>(),
);

export const capacitorPushNotificationOpened = createAction(
    '[Capacitor Push Notifications] Push Notification Opened',
    props<{ campaignId: number; messageId: string; templateId: number; urlToOpen: string }>(),
);
