@if (vm()) {
    <section
        appTrackClick
        identifier="open daily bite modal {{ vm().dailyBite?.id }}"
        data-test="today-daily-bites"
        (click)="openDailyBiteModal(vm().dailyBite)"
    >
        <h2 class="no-margin-bottom" data-test="today-daily-bites-title">Daily bites</h2>
        <p class="s no-margin-top" data-test="today-daily-bites-subtitle">
            {{ 'Wish you could read [NAME]’s mind? Now you can!' | textReplacement }}
        </p>
        <app-daily-bite-card data-test="today-daily-bite-tile" [content]="vm().dailyBite" />
    </section>
}
