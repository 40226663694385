import { Component, computed, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { accountFeature, selectIsLoading, selectIsUsUser } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { MultiSelectComponent, MultiSelectOption, ProgressHeaderComponent } from '@frontend/ui';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { onboardingNavigateBack, onboardingQuestionSubmittedNeeds } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';
import { specialNeedOptions, usSpecialNeedOptions } from '../../legacy/constants/question-special-needs.constants';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-needs',
    templateUrl: 'question-needs.page.html',
    styleUrls: ['question-needs.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        MultiSelectComponent,
        ProgressHeaderComponent,
        ReactiveFormsModule,
    ],
})
export class OnboardingQuestionNeedsPage {
    private readonly store = inject(Store);

    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public isLoading = this.store.selectSignal(selectIsLoading);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);
    public isUSUser = this.store.selectSignal(selectIsUsUser);

    public actualOptions = signal<MultiSelectOption[]>([]);

    public options = computed(() => {
        return this.isUSUser() ? usSpecialNeedOptions : specialNeedOptions;
    });
    public needs = computed(() => {
        const needs: Record<string, boolean> = {};

        (this.actualOptions().length > 0 ? this.actualOptions() : this.options()).forEach(
            (option: MultiSelectOption) => {
                needs[option.value] = option.selected;
            },
        );

        return needs;
    });
    public nextButtonText = computed(() => {
        const noTilesSelected = !this.actualOptions().some((option) => option.selected);

        return noTilesSelected ? 'Skip' : 'Next';
    });

    public onBackButtonClicked(): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onNeedsChanged(options: MultiSelectOption[]): void {
        this.actualOptions.set([...options]);
    }

    public onNextClick() {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedNeeds({
                needs: this.needs(),
            }),
        );
    }
}
