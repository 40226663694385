import { AsyncPipe, NgClass, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { AgePipe, EndorsementsComponent } from '@frontend/ui';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { SHOW_PAYMENT_MODAL_TRIGGERS } from '../../../store/payment/constants/payment.constants';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { selectCoursePreviewPageVm } from './course-preview.page.selectors';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    standalone: true,
    selector: 'app-onboarding-course-preview',
    templateUrl: 'course-preview.page.html',
    styleUrls: ['course-preview.page.scss'],
    imports: [
        IonContent,
        IonButton,
        AsyncPipe,
        ProfileImageComponent,
        NgOptimizedImage,
        EndorsementsComponent,
        AnalyticsTrackClickDirective,
        AgePipe,
        TextReplacementPipe,
        NgStyle,
        NgClass,
    ],
})
export class OnboardingCoursePreviewPage {
    public readonly store = inject(Store);
    public vm$ = this.store.select(selectCoursePreviewPageVm);
    protected readonly ProfileImageType = ProfileImageType;

    next(hasPartner: boolean) {
        if (!hasPartner) {
            this.store.dispatch(showPaymentModal({ trigger: SHOW_PAYMENT_MODAL_TRIGGERS.COURSE_PREVIEW_PAGE }));
        }
        this.store.dispatch(routeTo({ commands: ['main', 'today'] }));
    }
}
