import { NgClass } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { accountFeature, selectIsLoading } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui';
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader,
    IonSpinner,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Gender } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { onboardingNavigateBack, onboardingQuestionSubmittedGender } from '../../data-access/onboarding.actions';
import { selectOnboardingPreviousPage, selectOnboardingProgress } from '../../data-access/onboarding.selectors';

@Component({
    standalone: true,
    selector: 'app-onboarding-question-gender',
    templateUrl: 'question-gender.page.html',
    styleUrls: ['../onboarding.scss', 'question-gender.page.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonCard,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        NgClass,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionGenderPage {
    private readonly store = inject(Store);

    protected readonly Color = Color;
    protected readonly Gender = Gender;

    public isLoading = this.store.selectSignal(selectIsLoading);
    public dogName = this.store.selectSignal(accountFeature.selectDogName);
    public progress = this.store.selectSignal(selectOnboardingProgress);
    public previousOnboardingPage = this.store.selectSignal(selectOnboardingPreviousPage);
    public gender = this.store.selectSignal(accountFeature.selectGender);

    public selectedGender!: Gender;

    constructor() {
        effect(() => {
            this.selectedGender = this.gender()!;
        });
    }

    public onBackButtonClicked() {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingNavigateBack());
    }

    public onGenderAnswered(gender: Gender): void {
        if (this.isLoading()) {
            return;
        }

        this.selectedGender = gender;

        this.store.dispatch(onboardingQuestionSubmittedGender({ gender }));
    }
}
