import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { GenericCardSlimComponent } from '@frontend/ui';
import { IonContent, ViewWillEnter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { LibraryDailyBiteTileComponent } from './daily-bites/library-daily-bite-tile/library-daily-bite-tile.component';
import { selectLibraryPageVm } from './library-page.selectors';
import { LibrarySearchBarComponent } from './library-search-bar/library-search-bar.component';
import { LibraryTopicOverviewComponent } from './library-topic-overview/library-topic-overview.component';
import { routeToAiChat } from '../chat/chat.actions';
import { routeTo } from '@frontend/data-access/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-library',
    templateUrl: './library.page.html',
    styleUrls: ['./library.scss'],
    standalone: true,
    imports: [
        NgClass,
        LibrarySearchBarComponent,
        GenericCardSlimComponent,
        AnalyticsTrackClickDirective,
        LibraryTopicOverviewComponent,
        LibraryDailyBiteTileComponent,
        AsyncPipe,
        IonContent,
    ],
})
export class LibraryPage implements ViewWillEnter {
    private readonly store = inject(Store);
    vm$ = this.store.select(selectLibraryPageVm);
    arriveOnPage$ = new BehaviorSubject<boolean>(false);
    isSearching = false;

    protected readonly Color = Color;

    ionViewWillEnter() {
        this.arriveOnPage$.next(true);
    }

    cardClicked() {
        void this.store.dispatch(routeTo({ commands: ['main', 'library', 'faq'] }));
    }

    openZiggyChat(shouldAllowClick: boolean) {
        if (shouldAllowClick) {
            void this.store.dispatch(routeToAiChat({}));
        }
    }

    openPaymentModal(): void {
        void this.store.dispatch(showPaymentModal({ trigger: 'library-page-ziggy-ai-tile-locked' }));
    }
}
