import { createAction, props } from '@ngrx/store';
import { DialogProps, SupportProps } from './info-dialog.model';

export const openInfoDialog = createAction(
    '[InfoDialog] Open Info Dialog',
    props<{
        dialogProps: DialogProps;
        supportProps?: SupportProps;
    }>(),
);
