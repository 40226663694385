import { NgStyle } from '@angular/common';
import {
    Component,
    computed,
    CUSTOM_ELEMENTS_SCHEMA,
    effect,
    ElementRef,
    EventEmitter,
    inject,
    input,
    NgZone,
    Output,
    ViewChild,
} from '@angular/core';
import { SwiperDirective } from '@frontend/ui';
import { Store } from '@ngrx/store';
import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/swiper-element';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { selectSelectedPathCourseId } from '../course-path/store/course-path.selectors';
import { CourseSliderTileComponent } from '../course-slider-tile/course-slider-tile.component';
import { CourseWithSteps } from '../models/courses.models';
import { courseSliderChanged } from './course-slider.actions';

@Component({
    selector: 'app-course-slider',
    templateUrl: './course-slider.component.html',
    styleUrls: ['./course-slider.component.scss'],
    standalone: true,
    imports: [CourseSliderTileComponent, SwiperDirective, NgStyle],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CourseSliderComponent {
    @Output() slideChange: EventEmitter<void> = new EventEmitter<void>();

    public courses = input<CourseWithSteps[]>([]);

    @ViewChild('swiper')
    public swiperContainerRef!: ElementRef<SwiperContainer>;

    private readonly store = inject(Store);
    private readonly ngZone = inject(NgZone);

    private selectedCourseId = this.store.selectSignal(selectSelectedPathCourseId);

    private selectedCourseIndex = computed(() => {
        return this.courses().findIndex((course) => course.id === this.selectedCourseId()) ?? 0;
    });

    public config: SwiperOptions = {
        centeredSlides: true,
        slidesPerView: 1.1,
        spaceBetween: 8,
        initialSlide: 0,
    };

    constructor() {
        effect(() => {
            if (this.swiperContainerRef?.nativeElement?.swiper) {
                this.swiperContainerRef.nativeElement.swiper.slideTo(this.selectedCourseIndex());
            }
        });

        // This is a workaround for the issue where the swiper update when the number of slides/order changes
        effect(() => {
            this.courses();

            if (this.swiperContainerRef?.nativeElement?.swiper) {
                this.swiperContainerRef.nativeElement.swiper.update();
            }
        });
    }

    public onSlideChange({ detail }: { detail: [swiper: Swiper] }): void {
        const courseId = this.courses()[detail[0].activeIndex].id;
        const courseTitle = this.courses()[detail[0].activeIndex].title;
        this.slideChange.emit();

        // Swiper events occur outside the Angular zone / change detection
        // In order for the events to correctly and consistently trigger actions on mobile devices we need to place this back inside ngZone
        this.ngZone.run(() => {
            this.store.dispatch(courseSliderChanged({ courseId, courseTitle }));
        });
    }
}
