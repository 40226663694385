import { createAction, props } from '@ngrx/store';
import { PinnedTopic } from '../models/pinned-topic';

export const getPinnedTopicsSuccess = createAction(
    '[Pinned Topics] Get Pinned Topics Success',
    props<{ pinnedTopics: PinnedTopic[] }>(),
);

export const getPinnedTopicsFailure = createAction(
    '[Pinned Topics] Get Pinned Topics Success',
    props<{ error: Error }>(),
);

export const addPinnedTopic = createAction('[Pinned Topics] Add Pinned Topic', props<{ id: string }>());

export const addPinnedTopicSuccess = createAction(
    '[Pinned Topics] Add Pinned Topic Success',
    props<{ pinnedTopic: PinnedTopic }>(),
);

export const addPinnedTopicFailure = createAction(
    '[Pinned Topics] Add Pinned Topic Failure',
    props<{ error: Error }>(),
);

export const removePinnedTopic = createAction(
    '[Pinned Topics] Remove Pinned Topic',
    props<{ pinnedTopic: PinnedTopic }>(),
);

export const removePinnedTopicSuccess = createAction(
    '[Pinned Topics] Remove Pinned Topic Success',
    props<{ pinnedTopic: PinnedTopic }>(),
);

export const removePinnedTopicFailure = createAction(
    '[Pinned Topics] Remove Pinned Topic Failure',
    props<{ error: Error }>(),
);
