import { AsyncPipe, NgClass, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Color, getTemplateThemeColor } from '@shared/utils/typescript';
import { StepType } from '@shared/content-api-interface';
import { IonIcon } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { checkmarkOutline, glassesOutline, lockClosed, playForward, star } from 'ionicons/icons';
import { trackEvent } from '@frontend/data-access/analytics';
import { routeToStep } from '../../../store/router/router.actions';
import { StepStatus } from '../../../store/step/step.model';
import { CoursePageStep } from '../store/models/course-path.model';
import { selectCoursePathStepComponentVm } from './course-path-step.selectors';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-course-path-step',
    templateUrl: './course-path-step.component.html',
    styleUrls: ['./course-path-step.component.scss'],
    standalone: true,
    imports: [IonIcon, NgStyle, NgClass, NgOptimizedImage, AsyncPipe, TextReplacementPipe],
})
export class CoursePathStepComponent {
    private readonly store = inject(Store);
    @Input({ required: true }) public step!: CoursePageStep;

    public vm$ = this.store.select(selectCoursePathStepComponentVm);

    public iconType: Record<string, string> = {
        [StepStatus.READ]: 'glasses-outline',
        [StepStatus.SKIPPED]: 'play-forward',
        [StepStatus.COMPLETED]: 'checkmark-outline',
    };

    public readonly StepState = StepStatus;

    public readonly StepType = StepType;

    constructor() {
        addIcons({ star, glassesOutline, playForward, checkmarkOutline, lockClosed });
    }

    public setBorderAnimationColor(color: Color) {
        return {
            border: `4px solid ${getTemplateThemeColor(color)}`,
        };
    }

    public goToStep(step: CoursePageStep, unlocked: boolean): void {
        this.store.dispatch(
            routeToStep({
                baseUrl: ['main', 'courses'],
                stepId: step.id,
                from: 'courses',
                fromPracticeStep: step.progress.isPractice,
            }),
        );
        this.store.dispatch(
            trackEvent({
                eventName: 'click',
                eventProperties: {
                    identifier: '[Step] To Step From Course Path',
                    stepId: step.id,
                    contentId: step.content.id,
                    stepType: step.content.contentType,
                    title: step.content.title,
                    topic: step.topic.title,
                    progressState: step.progress.state,
                    progressRating: step.progress.rating,
                    unlocked,
                },
            }),
        );
    }

    public mapStepTypeToImageClass(type: StepType): string {
        switch (type) {
            case StepType.LESSON:
                return 'step-image';
            case StepType.ARTICLE:
                return 'article-image';
        }
    }
}
