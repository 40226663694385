import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { filter, map, take, timestamp } from 'rxjs/operators';
import { dismissInfo, dismissInfoWithTimestamp } from './dismissible-info.actions';
import {
    getLocalStorage,
    getLocalStorageSuccess,
    setLocalStorage,
    syncLegacyLocalStorageDataFailure,
    syncLegacyLocalStorageDataSuccess,
} from '@frontend/data-access/capacitor';
import { combineLatest } from 'rxjs';
import { ofSourceType } from '@shared/utils/typescript';
import { DISMISSIBLE_INFO_ACTION_SOURCE, DISMISSIBLE_INFO_LOCAL_STORAGE_KEY } from './dismissible-info.constants';
import { dismissibleInfoFeature } from './dismissible-info.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class DismissibleInfoEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    dismissInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dismissInfo),
            timestamp(),
            map(({ value: { id, dontShowAgain }, timestamp: time }) => {
                return dismissInfoWithTimestamp({ id, dontShowAgain, timestamp: new Date(time).toISOString() });
            }),
        );
    });

    readDismissibleInfoStateFromLocalStorageOnStartup$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(syncLegacyLocalStorageDataSuccess, syncLegacyLocalStorageDataFailure),
            map(() => getLocalStorage({ key: DISMISSIBLE_INFO_LOCAL_STORAGE_KEY })),
        );
    });

    // todo: add test
    syncDismissibleInfoStateWithLocalStorage$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                // State needs to be read first, before we want to start saving.
                ofType(getLocalStorageSuccess),
                filter(({ key }) => key === DISMISSIBLE_INFO_LOCAL_STORAGE_KEY),
                take(1),
            ),
            this.actions$.pipe(
                // Save on every action
                ofSourceType(DISMISSIBLE_INFO_ACTION_SOURCE),
            ),
        ]).pipe(
            concatLatestFrom(() => this.store.select(dismissibleInfoFeature.selectDismissibleInfoState)),
            map(([, state]) => setLocalStorage({ key: DISMISSIBLE_INFO_LOCAL_STORAGE_KEY, data: state })),
        );
    });
}
