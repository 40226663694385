<ion-item
    class="product-item"
    lines="full"
    button
    detail="false"
    appFoodProductSelector
    data-test="search-product-bar"
    [products]="foodProducts$ | async"
    (foodProductChange)="selectFoodProduct($event)"
>
    <ion-label position="stacked">Product</ion-label>
    @if (selectedFoodProduct) {
        <h3 class="food-product" data-test="food-product">
            {{ selectedFoodProduct.brandName }} {{ selectedFoodProduct.name }}
        </h3>
    } @else {
        <h3 class="placeholder">Search brand & product</h3>
    }
    <ion-icon name="chevron-forward" color="max" size="small" slot="end"></ion-icon>
</ion-item>
