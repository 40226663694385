import { Component, computed, inject, input, model } from '@angular/core';
import { purchaseProduct } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { IonButton, IonChip, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { closeModal } from '../../store/modal/modal.actions';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentProductCard } from '../payment-modal-product/payment-modal-product.model';
import { TieredPricingComparisonToggleComponent } from '../payment-modal-tiered-pricing/tiered-pricing-comparison-toggle/tiered-pricing-comparison-toggle.component';
import { selectIsTieredPricingPaywallAb } from '@frontend/data-access/user/config-cat';
import { showPaymentModalPlanComparison } from '../../store/payment/store/payment.actions';
import { PaymentModalLimitedOfferProductComponent } from './payment-modal-limited-offer-product/payment-modal-limited-offer-product.component';

@Component({
    selector: 'app-payment-modal-limited-offer',
    templateUrl: './payment-modal-limited-offer.component.html',
    styleUrls: ['./payment-modal-limited-offer.component.scss'],
    standalone: true,
    imports: [
        IonContent,
        IonToolbar,
        IonHeader,
        HeaderComponent,
        TextReplacementPipe,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        PaymentModalLimitedOfferProductComponent,
        PaymentModalProductComponent,
        TieredPricingComparisonToggleComponent,
        IonChip,
    ],
})
export class PaymentModalLimitedOfferComponent {
    private readonly store = inject(Store);

    public products = input<PaymentProductCard[]>([]);

    public selectedProductId = model<string>('');

    public discount = computed(() => {
        const discountedProduct = this.products().find((product) => !!product.discount);

        if (!discountedProduct) {
            return undefined;
        }

        return discountedProduct.discount?.discountPercentage;
    });
    public selectedPurchaseProduct = computed(() => {
        const selectedProduct = this.products().find((product) => product.identifier === this.selectedProductId());

        if (!selectedProduct) {
            return undefined;
        }

        return {
            identifier: selectedProduct.identifier,
            priceWithUnit: selectedProduct.priceWithUnit,
        };
    });

    public selectIsTieredPricingPaywallAb = this.store.selectSignal(selectIsTieredPricingPaywallAb);

    protected readonly Color = Color;

    public onCloseModalClick(): void {
        this.store.dispatch(closeModal());
    }

    public onProductClick(id: string): void {
        this.selectedProductId.set(id);
    }

    public onStartTrialClick(): void {
        if (!this.selectedPurchaseProduct()) {
            return;
        }

        this.store.dispatch(
            purchaseProduct({
                data: this.selectedPurchaseProduct()!,
            }),
        );
    }

    public launchComparisonModal() {
        this.store.dispatch(showPaymentModalPlanComparison({ selectPremium: true }));
    }
}
