import { chatFeature } from '@frontend/data-access/chat';
import { accountFeature, selectIsUsUser } from '@frontend/data-access/user/account';
import { selectIsHomeRecommendedContentEnabled } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { selectAiChatSuggestedQuestionsCardData } from '../chat/ai-chat/chat-intro/store/ai-chat-intro.selectors';
import { selectContentUnlocked, selectIsBasicPlan } from '../store/payment/store/payment.selectors';
import { todayPageFeature } from './today.page.reducer';

const selectTodayPageUserInfo = createSelector(accountFeature.selectDogName, selectIsUsUser, (dogName, isUsUser) => ({
    dogName,
    isUsUser,
}));

// TODO: NBSon - look into moving this into signals
export const selectTodayPageVm = createSelector(
    selectTodayPageUserInfo,
    selectContentUnlocked,
    chatFeature.selectIsDestroying,
    todayPageFeature.selectShowAiChatView,
    selectAiChatSuggestedQuestionsCardData,
    selectIsHomeRecommendedContentEnabled,
    selectIsBasicPlan,
    (
        userInfo,
        contentUnlocked,
        isStreamChatBusyCleaningUp,
        showAiChatView,
        suggestedQuestions,
        isHomeRecommendedContentEnabled,
        isBasicPlan,
    ) => {
        const expertSupport = {
            imageUrl: '/assets/images/trainers/coach-petrina-red-circle.png',
            title: 'Training coach',
            description: 'Replies within 1 hour',
            backgroundColor: Color.Harley,
            cardName: 'puppy-coach',
            contentUnlocked: contentUnlocked && !isBasicPlan,
            isBasicPlan,
        };

        return {
            userInfo,
            contentUnlocked,
            isStreamChatBusyCleaningUp,
            showAiChatView,
            suggestedQuestions,
            tiles: [expertSupport],
            isHomeRecommendedContentEnabled,
            isBasicPlan,
        };
    },
);
