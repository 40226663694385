import { NgClass, NgStyle } from '@angular/common';
import { Component, computed, effect, ElementRef, inject, viewChild } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { Store } from '@ngrx/store';
import { svgPathProperties } from 'svg-path-properties';
import { trackEvent } from '@frontend/data-access/analytics';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { ProfileImageComponent } from '@frontend/feature/profile-picture';
import { AppReadyStatus } from '../../../store/app-component/app-ready-status.model';
import { DevelopmentalWindowModalComponent } from '../developmental-window-modal/developmental-window-modal.component';
import { DevelopmentalWindowTimelineModel } from './developmental-window-timeline.model';
import { selectDevelopmentalWindowTimelineVm } from './developmental-window-timeline.selectors';
import { appStatusFeature } from '../../../store/app-component/app-component.reducer';

@Component({
    selector: 'app-developmental-window-timeline',
    templateUrl: './developmental-window-timeline.component.html',
    styleUrl: './developmental-window-timeline.component.scss',
    standalone: true,
    imports: [NgClass, NgStyle, TextReplacementPipe, ProfileImageComponent],
})
export class DevelopmentalWindowTimelineComponent {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    public DEVELOPMENTAL_WINDOW_LENGTH = 349 as const;
    public TIMELINE_LENGTH = 1135 as const; // svg.nativeElement.getTotalLength()

    public LABEL_12_WEEKS_X_COORDINATE = 351 as const; // 1/3
    public LABEL_5_MONTHS_X_COORDINATE = 696 as const; // 2/3
    public END_OF_TIMELINE_X_COORDINATE = 1041 as const; // 3/3

    public svgPath = new svgPathProperties(
        'M5 16.4987C21.5 16.4987 25.417 6.08322 39.5 5.99868C59.2675 5.88002 59.2426 25.3571 79 25.9987C100.382 26.693 108.615 8.59446 130 7.99868C153.03 7.35709 159.573 28.2653 182.5 25.9987C200.579 24.2113 206.387 7.40058 224.5 5.99868C246.193 4.31969 259.787 27.3853 281.5 25.9987C300.159 24.8071 311.341 7.1903 330 5.99868C351.713 4.61203 360.334 27.9872 382 25.9987C399.566 24.3865 404.861 5.82227 422.5 5.99868C439.789 6.1716 444.934 23.2218 462 25.9987C487.478 30.1443 499.22 7.69958 525 8.99868C547.277 10.1212 556.248 27.5339 578.5 25.9987C597.33 24.6996 603.682 8.46673 622.5 6.99868C645.286 5.22108 644.645 25.9325 667.5 25.9987C690.538 26.0654 709.978 6.1209 733 6.99868C753.834 7.79305 761.654 23.381 782.5 22.9987C802.444 22.6329 809.561 5.42771 829.5 5.99868C848.19 6.53387 854.308 25.5358 873 25.9987C892.762 26.4881 889.838 11.0371 909.5 8.99868C935.546 6.29842 938.264 32.9852 963.5 25.9987C977.385 22.1547 979.41 9.00407 993.5 5.99868C1010.36 2.4022 1013 18 1041.5 15.9987',
    ) as unknown as SVGPathElement;

    public vm = this.store.selectSignal(selectDevelopmentalWindowTimelineVm);
    public appReady = this.store.selectSignal(appStatusFeature.selectAppStatus);

    public developmentalWindowOuter = viewChild('developmentalWindowOuter', { read: ElementRef });

    public distance = computed(() => {
        return (this.vm().progressPercentage / 100) * this.TIMELINE_LENGTH;
    });
    public profileImagePoint = computed(() => {
        return this.svgPath.getPointAtLength(this.distance());
    });
    public ageInDays = computed(() => {
        return this.vm().ageInDays ?? 0;
    });
    public scrollDistance = computed(() => {
        if (this.ageInDays() >= 84 && this.ageInDays() < 150) {
            return this.DEVELOPMENTAL_WINDOW_LENGTH;
        } else if (this.ageInDays() >= 150) {
            return this.DEVELOPMENTAL_WINDOW_LENGTH * 2;
        }
        return 0;
    });

    constructor() {
        effect(() => {
            if (this.appReady() === AppReadyStatus.ready) {
                setTimeout(() => {
                    this.developmentalWindowOuter()?.nativeElement.scrollTo({ left: this.scrollDistance() });
                }, 0);
            }
        });
    }

    public showInformationModal(developmentalWindow: DevelopmentalWindowTimelineModel): void {
        this.store.dispatch(
            trackEvent({
                eventName: 'click',
                eventProperties: {
                    identifier: 'developmental-window-modal-opened',
                    developmentalWindow: developmentalWindow.title,
                },
            }),
        );

        void this.modalService.showModal({
            component: DevelopmentalWindowModalComponent,
            componentProps: {
                developmentalWindow,
            },
        });
    }
}
