import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { differenceInDays } from 'date-fns/differenceInDays';
import { accountFeature } from '@frontend/data-access/user/account';
import { dailyBitesFeature } from '@frontend/data-access/contentful';

export const selectAccountAgeInDays = createSelector(
    accountFeature.selectDateOfOnboardingOrToday,
    timeFeature.selectToday,
    (onboarded, today) => differenceInDays(today, onboarded),
);

export const selectDailyBiteForToday = createSelector(
    dailyBitesFeature.selectDailyBitesState,
    selectAccountAgeInDays,
    ({ dailyBites }, daysOnApp) => {
        if (daysOnApp === undefined || daysOnApp <= 0) {
            return dailyBites[0];
        }
        if (dailyBites.length > daysOnApp) {
            return dailyBites[daysOnApp];
        }
        return dailyBites[dailyBites.length - 1];
    },
);

export const selectSeenDailyBites = createSelector(
    dailyBitesFeature.selectDailyBitesState,
    selectAccountAgeInDays,
    ({ dailyBites }, daysOnApp) => {
        if (daysOnApp !== undefined && dailyBites.length > daysOnApp) {
            return dailyBites.slice(0, daysOnApp + 1);
        }
        return dailyBites;
    },
);
