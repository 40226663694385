import { MultiSelectOption } from '@frontend/ui';
import { Color } from '@shared/utils/typescript';

const baseSpecialNeedOptions: MultiSelectOption[] = [
    {
        title: 'Kids',
        value: 'kids',
        image: 'assets/images/charly/charly-gives-paw.svg',
        alt: 'Charly giving you his paw',
        backgroundColor: Color.Bailey,
        selected: false,
    },
    {
        title: 'Other dogs',
        value: 'otherDogs',
        image: 'assets/images/charly/charly-copies-older-dog.svg',
        alt: 'Charly copying an older dog on a walk',
        backgroundColor: Color.Sophie,
        selected: false,
    },
    {
        title: 'Cats',
        value: 'aCat',
        image: 'assets/images/charly/charly-chases-a-cat.svg',
        alt: 'Charly escaping his lead and chasing a cat',
        backgroundColor: Color.Max,
        selected: false,
    },
];

export const specialNeedOptions: MultiSelectOption[] = [
    ...baseSpecialNeedOptions,
    {
        title: 'No garden',
        value: 'noGarden',
        image: 'assets/images/charly/charly-digs-a-hole.svg',
        alt: 'Charly digging a hole in the garden',
        backgroundColor: Color.Lola,
        selected: false,
    },
];

export const usSpecialNeedOptions: MultiSelectOption[] = [
    ...baseSpecialNeedOptions,
    {
        title: 'No yard',
        value: 'noGarden',
        image: 'assets/images/charly/charly-digs-a-hole.svg',
        alt: 'Charly digging a hole in the yard',
        backgroundColor: Color.Lola,
        selected: false,
    },
];
