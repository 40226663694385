import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { showDailyBiteModal } from './daily-bite-modal.actions';
import { ModalService } from '@frontend/utility/modal';
import { DailyBiteModalComponent } from './daily-bite-modal.component';
import { filter } from 'rxjs';

@Injectable()
export class DailyBiteModalEffects {
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);
    showDailyBiteModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showDailyBiteModal),
                tap(({ dailyBite }) => {
                    void this.modalService.showModal({
                        component: DailyBiteModalComponent,
                        componentProps: { dailyBite },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
