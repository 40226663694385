<ion-item (click)="toggleAccordion()" class="no-padding" [lines]="open ? 'none' : 'inset'" data-test="accordion-btn">
    <ion-label class="ion-text-wrap" style="font-weight: bold">{{ title }}</ion-label>
    @if (open) {
        <ion-icon name="chevron-up-outline" slot="end"></ion-icon>
    } @else {
        <ion-icon name="chevron-down-outline" slot="end"></ion-icon>
    }
</ion-item>
@if (open) {
    <ion-item>
        <p style="white-space: pre-wrap" [innerHtml]="content"></p>
    </ion-item>
}
