import { Component, Input, inject } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { IonButton } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { DialogProps, SupportProps } from '../../store/info-dialog/info-dialog.model';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { routeToCoachChat } from '../../chat/chat.actions';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    standalone: true,
    imports: [TextReplacementPipe, IonButton],
})
export class InfoDialogComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @Input({ required: true }) dialogProps!: DialogProps;
    @Input({ required: true }) supportProps!: SupportProps;
    @Input({ required: true }) contentUnlocked!: boolean;

    public sendChat(): void {
        this.store.dispatch(routeToCoachChat());
        void this.modalService.dismiss();
    }

    public openPaymentModal(): void {
        void this.store.dispatch(showPaymentModal({ trigger: 'info-dialog-chat-button' }));
    }

    public close(): void {
        void this.modalService.dismiss();
    }
}
