import { Color } from '@shared/utils/typescript';
import { Breed } from '@shared/user-domain';
import { CoursePreviewContent } from './course-preview.model';

export const createCoursePreviewContent = (
    hasArrived: boolean,
    ageInWeeks: number | undefined,
    breedName: string | undefined,
    isUSUser: boolean,
    isRescuePupValuePropAb: boolean,
    isRescue: boolean | undefined,
): CoursePreviewContent => {
    const breed = breedName ?? Breed.MIXED_BREED;
    if (!hasArrived) {
        return coursePreviewContentPupNotHome(breed);
    } else if (ageInWeeks === undefined || ageInWeeks < 12) {
        return coursePreviewContentLessThan12Weeks(breed, isUSUser);
    } else {
        return coursePreviewContentGeneral(breed, ageInWeeks, isRescuePupValuePropAb, isRescue);
    }
};

const coursePreviewContentPupNotHome = (breedName: string): CoursePreviewContent => {
    return {
        title: `${breedName} Pre Pup`,
        subtitle: `Get ready for [NAME]'s arrival and understand how a ${breedName} develops before they get home`,
        color: Color.Harley,
        targets: [
            {
                title: 'Learn about pup development week-by-week',
                link: 'assets/images/charly/charly-sits-patiently.svg',
            },
            {
                title: 'Plan your puppy essentials checklist',
                link: 'assets/images/illustrations/course-preview/checklist.svg',
            },
            {
                title: 'Peace of mind with 24/7 training coach access',
                link: 'assets/images/charly/charly-gives-paw.svg',
            },
        ],
    };
};

const coursePreviewContentLessThan12Weeks = (breedName: string, isUSUser: boolean): CoursePreviewContent => {
    return {
        title: `${breedName} Pup Starter`,
        subtitle: 'Designed to support you during the most critical development period',
        color: Color.Charly,
        targets: [
            {
                title: `Develop ${isUSUser ? 'potty' : 'toilet'} and sleep training routines`,
                link: 'assets/images/charly/charly-looks-at-poo-medium.svg',
            },
            {
                title: 'Build confidence in new environments',
                link: 'assets/images/charly/charly-sniffs-the-ground-medium.svg',
            },
            {
                title: 'Peace of mind with 24/7 training coach access',
                link: 'assets/images/charly/charly-gives-paw.svg',
            },
        ],
    };
};

const coursePreviewContentGeneral = (
    breedName: string,
    ageInWeeks: number,
    isRescuePupValuePropAb: boolean,
    isRescue: boolean | undefined,
): CoursePreviewContent => {
    let title: string;
    let firstTargetTitle: string;

    if (isRescuePupValuePropAb && isRescue && ageInWeeks >= 26) {
        title = `${breedName} Rescue Foundations`;
    } else if (ageInWeeks >= 104) {
        title = `${breedName} Foundations`;
    } else if (ageInWeeks >= 26) {
        title = `Tackle the Terrible Teens`;
    } else {
        title = `${breedName} Pup Starter`;
    }

    if (isRescuePupValuePropAb && isRescue && ageInWeeks >= 26) {
        firstTargetTitle = 'Settle [NAME] at home with their training journey';
    } else if (ageInWeeks <= 104) {
        firstTargetTitle = 'Prevent problems with [NAME]’s training journey';
    } else {
        firstTargetTitle = 'Target problems with [NAME]’s training journey';
    }

    return {
        title,
        color: Color.Bailey,
        targets: [
            { title: firstTargetTitle, link: 'assets/images/charly/charly-jumps.svg' },
            {
                title: 'Solve any pup issue with a library of 250+ lessons',
                link: 'assets/images/charly/charly-bites-toy-bone-large.svg',
            },
            {
                title: 'Peace of mind with 24/7 training coach access',
                link: 'assets/images/charly/charly-gives-paw.svg',
            },
        ],
    };
};

export const createNextCoursesContent = (hasArrived: boolean) => {
    const offsets = [-70, 30, -40, 0];
    const nextCourses = [
        {
            title: 'Time to take [NAME] out and about',
            subtitle: 'Build on the basics & grow their confidence',
            color: Color.Sophie,
            image: 'assets/images/bailey/bailey-walks-nicely-on-leash-large.svg',
            alt: 'dog-walking-with-harness',
        },
        {
            title: 'Tackle terrible teens',
            subtitle: 'Growing up is not scary',
            color: Color.Oliver,
            image: 'assets/images/oliver/oliver-destroys-chair.svg',
            alt: 'dog-sitting-on-chewed-up-chair',
        },
        {
            title: 'Build bond through play',
            subtitle: 'Let those tails wag',
            color: Color.Luna,
            image: 'assets/images/charly/charly-runs-with-snake-toy.svg',
            alt: 'dog-carrying-snake-toy',
        },
    ];

    if (!hasArrived) {
        nextCourses.unshift({
            title: 'Start training on the right paw',
            subtitle: 'Core skills and build confidence',
            color: Color.Charly,
            image: 'assets/images/charly/charly-looks-at-poo-medium.svg',
            alt: 'dog-sitting-next-to-poop',
        });
    }

    return nextCourses.map((course, index) => {
        return { ...course, xOffset: offsets[index] };
    });
};
