@if (vm$ | async; as vm) {
    @if (vm.step) {
        <ion-content class="page-ion-content">
            <ion-header class="page-header ion-no-border">
                <ion-toolbar>
                    <app-header
                        title="Steps"
                        [leftButton]="{ analyticsIdentifier: 'step-prev-btn', background: 'white' }"
                        [rightButton]="{
                            analyticsIdentifier: 'step-close-and-read-btn',
                            sprite: 'light',
                            iconName: 'xmark',
                            background: 'white',
                            iconColour: 'black',
                        }"
                        (leftButtonPressed)="previousPage()"
                        (rightButtonPressed)="navigateOrShowNotificationModal(vm.step)"
                    />
                </ion-toolbar>
            </ion-header>

            <section class="lesson-column">
                @if (videoUrl) {
                    <div class="lesson-instruction-small-video-box">
                        <iframe
                            class="lesson-instruction-top-video"
                            allow="autoplay; fullscreen; picture-in-picture"
                            [src]="videoUrl"
                        ></iframe>
                    </div>
                } @else {
                    <div class="lesson-instruction-small-image-box {{ vm.step.topic.color }}">
                        <img
                            class="lesson-instruction-top-image"
                            [src]="vm.step.topic.imageUrl"
                            [alt]="vm.step.topic.title + ' image'"
                        />
                    </div>
                }

                @if (vm.step.content.audio) {
                    <div class="lesson-instruction-small-audio-box">
                        <app-audio-player class="lesson-instruction-top-audio" [audio]="vm.step.content.audio" />
                    </div>
                }

                @for (line of vm.step.content.steps | filter: emptyString; track line; let i = $index) {
                    <div class="lesson-instruction-text-block">
                        <h2 class="numbering">{{ i + 1 }}</h2>
                        <p>{{ line | textReplacement }}</p>
                    </div>
                }

                @for (dynamicContent of vm.step.content.stepsDynamicContent; track dynamicContent.title) {
                    <app-dynamic-content-block [title]="dynamicContent.title" [content]="dynamicContent.content" />
                }

                <h2>Tips</h2>
                @for (line of vm.step.content.tips | filter: emptyString; track line) {
                    <div class="lesson-instruction-text-block">
                        <div class="checkmark">
                            <ion-icon name="checkmark-sharp" />
                        </div>
                        <p>{{ line | textReplacement }}</p>
                    </div>
                }

                @for (dynamicContent of vm.step.content.tipsDynamicContent; track dynamicContent.title) {
                    <app-dynamic-content-block [title]="dynamicContent.title" [content]="dynamicContent.content" />
                }

                @if (vm.shouldShowSelfSetReminderTile) {
                    <h2>Want to train later?</h2>
                    <app-generic-card-slim
                        data-test="lesson-instruction-training-reminder-card"
                        [background]="Color.Luna"
                        [imageUrl]="'assets/images/charly/charly-gets-notification.svg'"
                        [title]="'Set training reminder'"
                        [unlocked]="true"
                        (click)="setTrainingReminder(vm.dogName)"
                    />
                }

                <h2>Need help?</h2>
                <app-chat-tile />
            </section>

            <ion-footer class="gradient-footer" slot="fixed">
                <ion-button
                    appTrackClick
                    identifier="step-page-view-rating"
                    color="max"
                    expand="block"
                    data-test="lesson-instruction-next-btn"
                    (click)="nextPage()"
                >
                    Rate Lesson
                </ion-button>
            </ion-footer>
        </ion-content>
    }
}
