import { UserAppAccessGranted } from '../user-app-access-granted.model';

export class UserAppAccessGrantedMock {
    private defaultValue: UserAppAccessGranted = {
        accessTypeId: '1',
        expiryTimestamp: 0,
        id: '123',
        numberOfDaysAccessGranted: 0,
        openedAppAfterExpiry: false,
        userId: '321',
    };

    constructor(overrides?: Partial<UserAppAccessGranted>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): UserAppAccessGranted {
        return this.defaultValue;
    }
}
