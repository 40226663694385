<ion-chip class="zz-author-chip">
    @if (authorImageUrl()) {
        <img
            class="zz-author-chip__image"
            data-test="zz-author-chip-image"
            [src]="authorImageUrl()"
            [alt]="'Photo of ' + author()"
            loading="eager"
        />
    }
    <p class="zz-author-chip__text xxs" data-test="zz-author-chip-text">By {{ author() }}, Zigzag training coach</p>
</ion-chip>
