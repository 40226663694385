import { timeFeature } from '@frontend/data-access/time';
import {
    accountFeature,
    selectAgeInWeeks,
    selectBreedName,
    selectHasArrived,
} from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { GenderToGenderFaIcon, GenderToYouthTitle } from '@shared/user-domain';
import { mapDateOfBirthToAgeString } from '@shared/utils/typescript';
import { isBefore, isSameDay } from 'date-fns';
import { selectNeedsFromSelectedNeedIds } from '../data-access/onboarding.selectors';
import { Card } from './creating-plan-cards.model';

export const selectDateOfBirthString = createSelector(
    accountFeature.selectDateOfBirth,
    timeFeature.selectToday,
    (dateOfBirth, today) => {
        if (!dateOfBirth) {
            return '';
        }

        if (isSameDay(dateOfBirth, today)) {
            return 'Happy Birthday!';
        }

        return `${mapDateOfBirthToAgeString(dateOfBirth)} old`;
    },
);

export const selectDateOfArrivalString = createSelector(
    accountFeature.selectDateOfArrival,
    selectHasArrived,
    timeFeature.selectToday,
    selectAgeInWeeks,
    (dateOfArrival, hasArrived, today, ageInWeeks) => {
        if (!dateOfArrival && hasArrived && ageInWeeks && ageInWeeks > 9) {
            return 'Is at home';
        }

        if (!dateOfArrival) {
            return '';
        }

        if (!hasArrived && isSameDay(today, dateOfArrival!)) {
            return 'Arriving today';
        }

        // This case handles has arrived and is the first day at home, is not the first day at home, OR if somehow the date of arrival is in the past
        if (isBefore(dateOfArrival!, today) || (hasArrived && isSameDay(today, dateOfArrival!))) {
            return 'Is at home';
        }

        return 'Arriving in ' + mapDateOfBirthToAgeString(today, dateOfArrival!);
    },
);

export const selectNeedsString = createSelector(selectNeedsFromSelectedNeedIds, (needs) => {
    return needs.map((need) => need.title).join(', ');
});

export const selectCreatingPlanCards = createSelector(
    accountFeature.selectDogName,
    selectBreedName,
    accountFeature.selectGender,
    selectDateOfBirthString,
    accountFeature.selectIsRescuePup,
    selectDateOfArrivalString,
    selectNeedsString,
    (dogName, breedName, gender, dateOfBirthString, isRescue, dateOfArrivalString, needsString) => {
        const cards: Card[] = [
            {
                title: {
                    text: 'Pup info',
                },
                firstRow: {
                    items: [
                        {
                            text: dogName,
                            icon: 'fa-signature',
                        },
                        {
                            text: breedName ? breedName : '',
                            icon: 'fa-dog',
                        },
                    ],
                },
                secondRow: {
                    items: [
                        {
                            text: gender ? GenderToYouthTitle[gender] : '',
                            icon: gender ? GenderToGenderFaIcon[gender] : '',
                        },
                        {
                            text: dateOfBirthString,
                            icon: 'fa-cake-candles',
                        },
                        ...(isRescue ? [{ text: 'Rescue', icon: 'fa-box' }] : []),
                    ],
                },
            },
            {
                title: {
                    text: 'Building a plan',
                },
                firstRow: {
                    items: [
                        {
                            text: dateOfArrivalString,
                            icon: 'fa-house',
                        },
                    ],
                },
                ...(needsString
                    ? {
                          secondRow: {
                              items: [
                                  {
                                      text: needsString,
                                      icon: 'fa-list',
                                  },
                              ],
                          },
                      }
                    : {}),
            },
            {
                title: {
                    text: 'Preparing your journey',
                },
            },
        ];

        return cards;
    },
);
