import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { dismissInfo } from '@frontend/data-access/dismissible-info';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DEFAULT_PUPPY_NAME } from '@shared/constants';
import { tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PupHappinessModalComponent } from '../pup-happiness-modal.component';
import { PUP_HAPPINESS_MODAL_ID } from '../pup-happiness-modal.constant';
import { launchPupHappinessModal } from './pup-happiness-modal.actions';

@Injectable()
export class PupHappinessModalEffects {
    private actions$ = inject(Actions);
    private modalService = inject(ModalService);

    showPupHappinessModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchPupHappinessModal),
            tap(({ dogName }) => {
                void this.modalService.showModal({
                    component: PupHappinessModalComponent,
                    componentProps: {
                        dogName: dogName || DEFAULT_PUPPY_NAME,
                    },
                    cssClass: 'modal-center',
                });
            }),
            switchMap(() => [
                dismissInfo({ id: PUP_HAPPINESS_MODAL_ID }),
                trackEvent({ eventName: '[Pup Happiness] Show Modal' }),
            ]),
        );
    });
}
