import { Course } from '@frontend/data-access/contentful';

export const calculateCourseProgress = (course: Course, completedOrSkippedIds: string[]): number => {
    if (course.stepIds.length === 0) {
        return 0;
    }

    const completedOrSkippedWithinCourse = course.stepIds.filter((stepId) =>
        completedOrSkippedIds.includes(stepId),
    ).length;

    return completedOrSkippedWithinCourse / course.stepIds.length;
};
