import { Gender } from '@shared/user-domain';
import { Profile } from '../models/profile.model';

export class ProfileMock {
    private defaultValue: Profile = {
        name: 'Oliver',
        dateOfBirth: new Date(),
        breedId: 'MIXED_BREED',
        gender: Gender.MALE,
        ownerName: 'Mattijs',
        mail: 'mattijs@test.zigzag.dog',
        notifications: false,
        dateOfOnboarding: new Date(),
        privacyPolicyAccepted: true,
        dateOfArrival: undefined,
        hasArrived: true,
        userToken: '',
        partners: [],
        countryCode: '',
        firstDayAtHomeWhenOnboarding: undefined,
        isRescuePup: false,
        purinaMarketingAccepted: undefined,
        isApproximateDateOfBirth: undefined,
    };

    private empty: Profile = {
        name: '',
        dateOfBirth: undefined,
        breedId: undefined,
        gender: undefined,
        ownerName: '',
        mail: '',
        notifications: false,
        dateOfOnboarding: undefined,
        privacyPolicyAccepted: true,
        dateOfArrival: undefined,
        hasArrived: undefined,
        userToken: '',
        partners: [],
        countryCode: '',
        firstDayAtHomeWhenOnboarding: undefined,
        isRescuePup: undefined,
        purinaMarketingAccepted: undefined,
        isApproximateDateOfBirth: undefined,
    };

    constructor(overrides?: Partial<Profile>) {
        Object.assign(this.defaultValue, overrides);
        Object.assign(this.empty, overrides);
    }

    public get value(): Profile {
        return this.defaultValue;
    }

    public get emptyValue(): Profile {
        return this.empty;
    }
}
