import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { authenticationFeature, mapAuthenticationError } from '@frontend/data-access/user/authentication';
import { createSelector } from '@ngrx/store';
import { platformFeature } from '@frontend/data-access/platform';

export const selectLoginPageVm = createSelector(
    authenticationFeature.selectLoginError,
    authenticationFeature.selectLoginLoading,
    selectIsInputOpenOnAndroid,
    platformFeature.selectPlatformIsIos,
    (loginError, loginLoading: boolean, isInputFocusedOnAndroid, isIos) => ({
        error: mapAuthenticationError(loginError?.code),
        isLoading: loginLoading,
        isInputFocusedOnAndroid,
        isIos,
    }),
);
