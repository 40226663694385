import { AsyncPipe, NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    inject,
    viewChild,
    ViewChild,
} from '@angular/core';
import { coursesFeature } from '@frontend/data-access/contentful';
import { accountFeature, selectAgeInWeeks, selectHasArrived } from '@frontend/data-access/user/account';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { collapseAnimation, fadeInAnimation, GenericCardSquareComponent, SliderComponent } from '@frontend/ui';
import { IonContent, IonHeader, IonToolbar, ViewWillEnter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { ArrivalTileComponent } from '../arrival/arrival-tile/arrival-tile.component';
import { AiChatHeaderComponent } from '../chat/ai-chat/header/ai-chat-header.component';
import { SuggestedAiChatQuestionsComponent } from '../chat/ai-chat/suggested-questions-prompt/suggested-ai-chat-questions.component';
import { SuggestedQuestionsPromptClicked } from '../chat/ai-chat/suggested-questions-prompt/suggested-ai-chat-questions.model';
import { routeToAiChat, routeToCoachChat } from '../chat/chat.actions';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import {
    aiChatBackButtonClicked,
    aiChatInputClicked,
    aiChatInputSubmitted,
    suggestedAiChatQuestionClicked,
} from './data-access/today.page.actions';
import { todayPageFeature } from './data-access/today.page.reducer';
import { TodayAiChatInputComponent } from './today-ai-chat-input/today-ai-chat-input.component';
import { CourseTileComponent } from './today-courses/course-tile/course-tile.component';
import { TodayDailyBitesComponent } from './today-daily-bites/today-daily-bites.component';
import { TodayDevelopmentalWindowComponent } from './today-developmental-window/today-developmental-window.component';
import { TodayPinnedTopicsComponent } from './today-pinned-topics/today-pinned-topics.component';
import { selectTodayPageVm } from './today.page.selectors';
import { filterCoursesForTodayPage } from './today.page.utils';

@Component({
    selector: 'app-today',
    templateUrl: './today.page.html',
    styleUrl: './today.page.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        IonContent,
        IonHeader,
        IonToolbar,
        AiChatHeaderComponent,
        AnalyticsTrackClickDirective,
        ArrivalTileComponent,
        CourseTileComponent,
        GenericCardSquareComponent,
        SliderComponent,
        SuggestedAiChatQuestionsComponent,
        TodayAiChatInputComponent,
        TodayDailyBitesComponent,
        TodayDevelopmentalWindowComponent,
        TodayPinnedTopicsComponent,
    ],
    animations: [fadeInAnimation(), collapseAnimation()],
})
export class TodayPage implements ViewWillEnter {
    @ViewChild('suggestedQuestionsPrompt')
    public suggestedQuestions!: SuggestedAiChatQuestionsComponent;

    public aiChatInputRef = viewChild<TodayAiChatInputComponent>('aiChatInput');

    private readonly store = inject(Store);
    private readonly cdr = inject(ChangeDetectorRef);

    public vm = this.store.selectSignal(selectTodayPageVm);
    public showAiChatView = this.store.selectSignal(todayPageFeature.selectShowAiChatView);

    private courses = this.store.selectSignal(coursesFeature.selectCourses);
    private hasArrived = this.store.selectSignal(selectHasArrived);
    private ageInWeeks = this.store.selectSignal(selectAgeInWeeks);
    private isRescue = this.store.selectSignal(accountFeature.selectIsRescuePup);

    public filteredCourses = computed(() => {
        return filterCoursesForTodayPage(this.courses(), this.hasArrived(), this.ageInWeeks(), this.isRescue());
    });

    protected readonly Color = Color;

    constructor() {
        // TODO: NBSon - look into testing effects
        // Clear the input when the view is hidden
        effect(() => {
            if (this.aiChatInputRef() && this.showAiChatView() === false) {
                this.aiChatInputRef()!.clear();
            }
        });
    }

    public ionViewWillEnter(): void {
        this.cdr.markForCheck();
    }

    public onAiChatClicked(showAiChatView: boolean): void {
        if (!showAiChatView) {
            this.store.dispatch(aiChatInputClicked());
        }
    }

    public onAiChatBackClicked(): void {
        this.store.dispatch(aiChatBackButtonClicked());
    }

    public onAiChatSubmitted(message: string): void {
        this.store.dispatch(
            aiChatInputSubmitted({
                message,
            }),
        );
    }

    public onAiChatPromptClicked({ optionId, option, card }: SuggestedQuestionsPromptClicked): void {
        if (optionId && option && card) {
            this.store.dispatch(suggestedAiChatQuestionClicked({ optionId, option, card }));

            this.suggestedQuestions.clearSelection();
        }
    }

    public onCardClick(unlocked: boolean, cardName: string, shouldAllowClick: boolean): void {
        if (!shouldAllowClick) {
            return;
        }

        if (unlocked) {
            switch (cardName) {
                case 'puppy-coach': {
                    this.store.dispatch(routeToCoachChat());
                    break;
                }
                case 'ziggy-ai': {
                    this.store.dispatch(routeToAiChat({}));
                    break;
                }
                default: {
                    break;
                }
            }
        } else {
            this.store.dispatch(showPaymentModal({ trigger: cardName }));
        }
    }
}
