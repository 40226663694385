import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs/operators';
import { ApplicationInsightsService } from '../application-insights.service';
import { logEvent } from './application-insights.actions';

@Injectable()
export class ApplicationInsightsEffects {
    private readonly actions$ = inject(Actions);
    private readonly applicationInsights = inject(ApplicationInsightsService);
    logEvent$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(logEvent),
                tap(({ name, properties }) => {
                    this.applicationInsights.logEvent(name, properties);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
