import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { backButtonPressed, platformReady, setPlatforms, storePlatforms } from './platform.actions';

@Injectable()
export class PlatformEffects {
    private actions$ = inject(Actions);
    private platform = inject(Platform);

    platformReady$ = createEffect(() => {
        return from(this.platform.ready()).pipe(
            map(() => {
                return platformReady();
            }),
        );
    });

    setPlatforms$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setPlatforms),
            map(() => {
                const platforms = this.platform.platforms();
                return storePlatforms({ platforms });
            }),
        );
    });

    backButtonPressed$ = createEffect(() => {
        return from(this.platform.backButton).pipe(
            map(() => {
                return backButtonPressed();
            }),
        );
    });
}
