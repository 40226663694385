import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    internalEndpoints,
    InternalPatchUserAppAccessGrantedCommand,
    InternalPostUserAppAccessGrantedCommand,
    InternalUserAppAccessGrantedDto,
} from '@shared/user-api-interface';
import { Observable } from 'rxjs';
import { UserAppAccessGranted } from '../models/user-app-access-granted.model';

@Injectable({
    providedIn: 'root',
})
export class UserAppAccessGrantedService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getUserAppAccessGranted(): Observable<UserAppAccessGranted[]> {
        return this.http.get<InternalUserAppAccessGrantedDto[]>(
            `${this.environment.internalApiUrl}${internalEndpoints.userAppAccessGrantedCollection}`,
            httpOptions,
        );
    }
    public createUserAppAccessGranted(
        command: InternalPostUserAppAccessGrantedCommand,
    ): Observable<UserAppAccessGranted> {
        return this.http.post<InternalUserAppAccessGrantedDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.userAppAccessGrantedCollection}`,
            command,
            httpOptions,
        );
    }

    public patchUserAppAccessGranted(
        id: string,
        command: InternalPatchUserAppAccessGrantedCommand,
    ): Observable<UserAppAccessGranted> {
        return this.http.patch<InternalUserAppAccessGrantedDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.userAppAccessGrantedCollection}/${id}`,
            command,
            httpOptions,
        );
    }
}
