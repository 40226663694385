import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { DevelopmentalWindowTimelineComponent } from './developmental-window-timeline/developmental-window-timeline.component';
import { selectTodayDevelopmentalWindowVm } from './today-developmental-window.component.selectors';
import { routeTo } from '@frontend/data-access/router';

@Component({
    selector: 'app-today-developmental-window',
    templateUrl: './today-developmental-window.component.html',
    styleUrls: ['./today-developmental-window.component.scss'],
    standalone: true,
    imports: [AsyncPipe, DevelopmentalWindowTimelineComponent, ProfileImageComponent, NgClass],
})
export class TodayDevelopmentalWindowComponent {
    private readonly store = inject(Store);
    vm$ = this.store.select(selectTodayDevelopmentalWindowVm);
    ProfileImageType = ProfileImageType;

    routeToProfile() {
        void this.store.dispatch(routeTo({ commands: ['main/today/profile'] }));
    }
}
