<div class="lesson-rating-component-container page-padding-sides page-content-padding-top">
    <div class="lesson-rating-content-container">
        <p class="text-snowy">Good work!</p>
        <h1 class="text-snowy">
            {{ 'How well did [NAME] do this lesson?' | textReplacement }}
        </h1>
        <img src="assets/images/buddy/buddy-gets-head-pats-large.png" alt="someone patting a dog on the head" />
        <app-star-rating-component
            [maxNumberOfStars]="maxRating"
            [starValueSelected]="rating"
            (ratingChanged)="onRatingChange($event)"
        ></app-star-rating-component>
        <h2 class="text-snowy">{{ ratingText | textReplacement }}</h2>
        <p class="text-snowy">
            {{ currentRating <= 3 && ratedStep ? 'We’ll add a practice lesson to your journey' : '' }}
        </p>
    </div>
</div>
