import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { storePlatforms } from './platform.actions';

export interface PlatformState {
    platforms: string[];
}

export const initialState: PlatformState = {
    platforms: [],
};
export const platformFeature = createFeature({
    name: 'platform',
    reducer: createReducer(
        initialState,
        on(
            storePlatforms,
            (state, action): PlatformState => ({
                ...state,
                platforms: action.platforms,
            }),
        ),
    ),
    // NOTE: if you are using the device toolbar in the browser, the user agent string will be different, and so the platform will be different
    extraSelectors: ({ selectPlatforms }) => ({
        selectPlatformIsIos: createSelector(selectPlatforms, (platforms) => platforms.includes('ios')),
        selectPlatformIsAndroid: createSelector(selectPlatforms, (platforms) => platforms.includes('android')),
        selectPlatformIsCapacitor: createSelector(selectPlatforms, (platforms) => platforms.includes('capacitor')),
    }),
});
