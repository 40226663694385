import { inject, Injectable } from '@angular/core';
import {
    getBreedGroups,
    getChatTileTopics,
    getCourses,
    getDailyBites,
    getDevelopmentalWindows,
    getFaqs,
    getPageToday,
    getSteps,
    getTopics,
} from '@frontend/data-access/contentful';
import {
    accountFeature,
    createAccountSuccess,
    getAccountSuccess,
    updateAccountSuccess,
} from '@frontend/data-access/user/account';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap, merge, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectGetContentInfo } from './content.selectors';
import { ContentFetchingConditions } from './content-effects.constants';
import { containsValueForAllConditions, containsValueForAtLeastOneCondition } from './content.utils';
import { getContentfulLocaleFromAlpha2CountryCode } from '@shared/constants';

@Injectable()
export class ContentEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);

    getContentWhen(accountHasValueFor: ContentFetchingConditions[], accountValueChangedFor = accountHasValueFor) {
        return merge(
            this.actions$.pipe(
                ofType(getAccountSuccess),
                filter(({ profile }) => containsValueForAllConditions(profile, accountHasValueFor)),
            ),
            this.actions$.pipe(
                ofType(updateAccountSuccess),
                concatLatestFrom(() => this.store.select(accountFeature.selectProfile)),
                filter(([, profile]) => !!profile && containsValueForAllConditions(profile, accountHasValueFor)),
                filter(([{ command }]) => containsValueForAtLeastOneCondition(command, accountValueChangedFor)),
            ),
        ).pipe(
            concatLatestFrom(() => this.store.select(selectGetContentInfo)),
            map(([_, contentInfo]) => ({
                ...contentInfo,
            })),
        );
    }

    getOnboardingContentWhenAccountCreated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccountSuccess),
            switchMap(({ profile }) => [
                getBreedGroups({ params: { locale: getContentfulLocaleFromAlpha2CountryCode(profile.countryCode) } }),
                getTopics({ params: { locale: getContentfulLocaleFromAlpha2CountryCode(profile.countryCode) } }),
                getFaqs({
                    params: { locale: getContentfulLocaleFromAlpha2CountryCode(profile.countryCode) },
                }),
                getPageToday({
                    params: { locale: getContentfulLocaleFromAlpha2CountryCode(profile.countryCode) },
                }),
                getDevelopmentalWindows({
                    params: { locale: getContentfulLocaleFromAlpha2CountryCode(profile.countryCode) },
                }),
            ]),
        );
    });

    // TODO - JL - figure out if this is required following country code in onboarding changes
    getContentAffectedByCountryCodeOnly$ = createEffect(() => {
        return this.getContentWhen([ContentFetchingConditions.countryCode]).pipe(
            concatMap(({ locale }) => [
                getBreedGroups({
                    params: {
                        locale,
                    },
                }),
                getTopics({
                    params: {
                        locale,
                    },
                }),
                getFaqs({
                    params: { locale },
                }),
                getPageToday({
                    params: { locale },
                }),
                getDevelopmentalWindows({
                    params: { locale },
                }),
            ]),
        );
    });

    getLocaleAndBreedIdBasedContent$ = createEffect(() => {
        return this.getContentWhen([ContentFetchingConditions.countryCode, ContentFetchingConditions.breedId]).pipe(
            concatMap(({ locale, breedId }) => [
                getDailyBites({
                    params: { locale, breedId: breedId! },
                }),
            ]),
        );
    });

    getLocaleBreedIdAndAgeBasedContent$ = createEffect(() => {
        return this.getContentWhen([
            ContentFetchingConditions.countryCode,
            ContentFetchingConditions.breedId,
            ContentFetchingConditions.dateOfBirth,
            ContentFetchingConditions.isRescuePup,
        ]).pipe(
            concatMap(({ locale, breedId, ageInWeeks, isRescue }) => [
                getSteps({
                    params: { locale, breedId: breedId!, ageInWeeks, isRescue },
                }),
            ]),
        );
    });

    getContentThatDependsOnALot$ = createEffect(() => {
        const requiredConditions = [
            ContentFetchingConditions.countryCode,
            ContentFetchingConditions.breedId,
            ContentFetchingConditions.dateOfBirth,
        ];
        const updateConditions = [
            ...requiredConditions,
            ContentFetchingConditions.hasArrived,
            ContentFetchingConditions.isRescuePup,
        ];
        return this.getContentWhen(requiredConditions, updateConditions).pipe(
            concatMap(({ locale, ageInWeeks, hasArrivedOnDateOfOnboarding, isRescue, breedId, hasArrived }) => [
                getCourses({
                    params: {
                        locale,
                        breedId: breedId!,
                        ageInWeeks,
                        hasArrived,
                        hasArrivedOnDateOfOnboarding,
                        isRescue: isRescue ?? false,
                    },
                }),
            ]),
        );
    });

    getChatTileTopicsContent$ = createEffect(() => {
        const requiredConditions = [
            ContentFetchingConditions.dateOfBirth,
            ContentFetchingConditions.hasArrived,
            ContentFetchingConditions.isRescuePup,
        ];
        return this.getContentWhen(requiredConditions).pipe(
            concatMap(({ locale, ageInWeeks, hasArrived, isRescue }) => [
                getChatTileTopics({
                    params: { locale, ageInWeeks, hasArrived, isRescue },
                }),
            ]),
        );
    });
}
