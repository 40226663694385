import { Component, inject, OnInit } from '@angular/core';
import { PurchaseProduct, purchaseProduct } from '@frontend/data-access/subscription';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonChip, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { premiumToggleSelected, showPaymentModalPlanComparison } from '../../store/payment/store/payment.actions';
import { PaymentModalPlanFeaturesListComponent } from '../payment-modal-plan-features-list/payment-modal-plan-features-list.component';
import { PaymentModalProductComponent } from '../payment-modal-product/payment-modal-product.component';
import { PaymentModalTermsComponent } from '../payment-modal-terms/payment-modal-terms.component';
import { basicPlanDescriptionList, defaultPlanDescriptionList } from '../payment-modal.constant';
import { selectPaymentModalVm } from '../payment-modal.selectors';
import { TieredPricingComparisonModalComponent } from './tiered-pricing-comparison-modal/tiered-pricing-comparison-modal.component';
import { TieredPricingComparisonToggleComponent } from './tiered-pricing-comparison-toggle/tiered-pricing-comparison-toggle.component';

@Component({
    standalone: true,
    selector: 'app-payment-modal-tiered-pricing',
    templateUrl: './payment-modal-tiered-pricing.component.html',
    styleUrl: './payment-modal-tiered-pricing.component.scss',
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        PaymentModalTermsComponent,
        PaymentModalPlanFeaturesListComponent,
        TieredPricingComparisonToggleComponent,
        IonChip,
        TieredPricingComparisonModalComponent,
        PaymentModalProductComponent,
    ],
})
export class PaymentModalTieredPricingComponent implements OnInit {
    private readonly store = inject(Store);

    protected readonly basicFeaturesList = basicPlanDescriptionList;
    protected readonly defaultFeaturesList = defaultPlanDescriptionList;
    protected readonly Color = Color;

    public vm = this.store.selectSignal(selectPaymentModalVm);

    ngOnInit() {
        this.store.dispatch(premiumToggleSelected());
    }

    public onPurchaseClick(product: PurchaseProduct): void {
        this.store.dispatch(purchaseProduct({ data: product }));
    }

    public launchComparisonModal() {
        this.store.dispatch(showPaymentModalPlanComparison({ selectPremium: false }));
    }
}
