import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { StepProgressEffects } from './store/step-progress.effects';
import { stepProgressFeature } from './store/step-progress.reducer';

@NgModule({
    providers: [provideState(stepProgressFeature), provideEffects([StepProgressEffects])],
})
export class ZigzagFrontendDataAccessUserStepProgressModule {}
