<p class="tiered-pricing-comparison__most-popular">Most Popular</p>

<section class="tiered-pricing-comparison__chip-container">
    <ion-chip
        class="tiered-pricing-comparison__chip"
        [ngClass]="{ 'tiered-pricing-comparison--basic-selected': !isPremiumSelected() }"
        (click)="basicClicked()"
        ><h3 class="tiered-pricing-comparison__chip-text">Basic</h3></ion-chip
    >
    <ion-chip
        class="tiered-pricing-comparison__chip"
        [ngClass]="{ 'tiered-pricing-comparison--premium-selected': isPremiumSelected() }"
        (click)="premiumClicked()"
        ><h3 class="tiered-pricing-comparison__chip-text">Premium <i class="fa-regular fa-crown"></i></h3>
    </ion-chip>
</section>
