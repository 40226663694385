import { Injectable, inject } from '@angular/core';
import { Share } from '@capacitor/share';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, tap } from 'rxjs/operators';
import { socialShare } from './social-share.actions';
import { Platform } from '@ionic/angular/standalone';
import { EMPTY } from 'rxjs';

@Injectable()
export class SocialShareEffects {
    private readonly actions$ = inject(Actions);
    private readonly platform = inject(Platform);
    socialShare$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(socialShare),
                filter(() => {
                    return this.platform.is('capacitor');
                }),
                tap(({ message }) => Share.share({ text: message })),
                catchError((e) => {
                    // errors will be thrown when users close the share after opening it, and it will be sent to sentry if not handled
                    console.warn(e);
                    return EMPTY;
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
