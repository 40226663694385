import { accountFeature } from '@frontend/data-access/user/account';
import { createSelector } from '@ngrx/store';
import { onboardingFeature } from '../../../data-access/onboarding.reducer';
import { selectOnboardingProgress } from '../../../data-access/onboarding.selectors';

export const selectQuestionPurinaOptInLegacyPageVm = createSelector(
    selectOnboardingProgress,
    accountFeature.selectDogName,
    onboardingFeature.selectIsLoading,
    (progress, dogName, isLoading) => {
        return {
            progress,
            dogName,
            isLoading,
        };
    },
);
