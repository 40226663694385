import { PACKAGE_TYPE } from '@awesome-cordova-plugins/purchases/ngx';

export enum RevenueCatPurchaseErrorCodes {
    PURCHASE_CANCELLED = 'PurchaseCancelledError',
}

export const RevenueCatPackageDescriptions = new Map<PACKAGE_TYPE, string>([
    [PACKAGE_TYPE.MONTHLY, 'monthly'],
    [PACKAGE_TYPE.ANNUAL, 'yearly'],
    [PACKAGE_TYPE.SIX_MONTH, 'every six months'],
    [PACKAGE_TYPE.THREE_MONTH, 'quarterly'],
    [PACKAGE_TYPE.TWO_MONTH, 'every two months'],
    [PACKAGE_TYPE.WEEKLY, 'weekly'],
    [PACKAGE_TYPE.LIFETIME, 'lifetime'],
    [PACKAGE_TYPE.UNKNOWN, ''],
]);

export const RevenueCatPackageDescriptionsUserFriendly = new Map<number, string>([
    [365, '1 year'],
    [180, '6 months'],
    [90, '3 months'],
    [60, '2 months'],
    [30, '1 month'],
    [14, '2 weeks'],
    [10, '10 days'],
    [7, '1 week'],
]);
