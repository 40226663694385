<ion-header
    class="page-header ion-no-border"
    [ngClass]="{ 'breed-selector-modal-onboarding-header': isNewOnboarding() }"
>
    <ion-toolbar>
        <app-header
            title="Search breed"
            [leftButton]="{
                analyticsIdentifier: 'breed-selector-modal-back-btn',
                background: 'none',
                iconColour: isNewOnboarding() ? 'white' : 'black',
            }"
            [rightButton]="false"
            (leftButtonPressed)="back()"
            [titleColour]="isNewOnboarding() ? 'var(--ion-color-snowy)' : ''"
        />
    </ion-toolbar>
</ion-header>

<app-search-bar
    [ngClass]="{ 'breed-selector-modal-onboarding__searchbar': isNewOnboarding() }"
    data-test="breed-selector-modal-search-bar"
    [placeholder]="'Search breed'"
    [resultListTemplate]="resultListTemplate"
    [noResultTemplate]="noResults"
    [instructionTemplate]="resultListTemplate"
    [initialFocus]="true"
    [hasResults]="results.length > 0"
    (search)="searchItems($event)"
/>

<ng-template #resultListTemplate>
    <ion-chip [ngClass]="isNewOnboarding() ? 'breed-selector-modal-onboarding__chip' : 'breed-selector-modal__chip'">
        If your pup is a mixed breed, select the most prominent breed.
    </ion-chip>
    <ion-content
        class="breed-selector-modal__content"
        [ngClass]="{ 'breed-selector-modal-onboarding__content': isNewOnboarding() }"
    >
        <ion-list>
            @for (result of results; track result.id) {
                <ion-item
                    [ngClass]="
                        isNewOnboarding() ? 'breed-selector-modal-onboarding__item' : 'breed-selector-modal__item'
                    "
                    (click)="selectBreed(result)"
                    data-test="breed-search-result"
                >
                    <ion-label class="ion-text-wrap">
                        <h3 [ngClass]="{ 'breed-selector-modal-onboarding__label-text': isNewOnboarding() }">
                            {{ result.name }}
                        </h3>
                    </ion-label>
                </ion-item>
            }
        </ion-list>
    </ion-content>
</ng-template>

<ng-template #noResults let-searchValue="searchValue">
    <section class="center" [ngClass]="{ 'breed-selector-modal-onboarding-no-results': isNewOnboarding() }">
        <div class="breed-selector-modal__no-results-image">
            <img src="assets/images/oliver/oliver-looks-confused.png" />
        </div>
        <h3>No results for "{{ searchValue }}"</h3>
        <p [ngClass]="{ 'breed-selector-modal-onboarding-no-results__text': isNewOnboarding() }">
            Try again using different spelling or keywords.
        </p>
    </section>
</ng-template>
