import { LoadingState } from '@frontend/data-access/shared-models';
import {
    courseProgressFeature,
    stepProgressFeature,
    todayCourseProgressFeature,
} from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';

export const selectIsCourseLoading = createSelector(
    courseProgressFeature.selectLoadingState,
    stepProgressFeature.selectLoadingState,
    todayCourseProgressFeature.selectLoadingState,
    (courseProgressLoadingState, stepProgressLoadingState, todayCourseProgress) =>
        courseProgressLoadingState === LoadingState.LOADING ||
        stepProgressLoadingState === LoadingState.LOADING ||
        todayCourseProgress === LoadingState.LOADING,
);
