import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { StepProgressState } from '../models/step-progress.model';
import {
    createStepProgress,
    createStepProgressFailure,
    createStepProgressSuccess,
    getStepProgress,
    getStepProgressFailure,
    getStepProgressSuccess,
} from './step-progress.actions';

export const stepProgressInitialState: StepProgressState = {
    stepProgress: [],
    loadingState: LoadingState.INIT,
};

export const stepProgressFeature = createFeature({
    name: 'stepProgress',
    reducer: createReducer(
        stepProgressInitialState,
        on(
            getStepProgress,
            createStepProgress,
            (state): StepProgressState => ({
                ...state,
                loadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getStepProgressFailure,
            createStepProgressFailure,
            (state): StepProgressState => ({
                ...state,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            getStepProgressSuccess,
            (state, { progress }): StepProgressState => ({
                ...state,
                stepProgress: progress,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            createStepProgressSuccess,
            (state, { progress }): StepProgressState => ({
                ...state,
                stepProgress: [...state.stepProgress, ...progress],
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
});
