import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
    adjustEventFailure,
    adjustRequestTrackingConsent,
    adjustRequestTrackingConsentResponse,
    adjustUpdateAttributionData,
} from '@frontend/data-access/adjust';
import { trackEvent, updateAttributionData } from '@frontend/data-access/analytics';

@Injectable()
export class ConnectingAnalyticsEffects {
    private readonly actions$ = inject(Actions);
    public readonly platform = inject(Platform);

    adjustRequestTrackingConsentLogging$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adjustRequestTrackingConsent),
            map(() => trackEvent({ eventName: 'show tracking consent' })),
        );
    });

    adjustRequestTrackingConsentResponse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adjustRequestTrackingConsentResponse),
            map(({ consentText, consentValue }) =>
                trackEvent({
                    eventName: 'answered tracking consent',
                    eventProperties: { consentText, consentValue },
                }),
            ),
        );
    });

    adjustEventFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adjustEventFailure),
            map(({ event }) =>
                trackEvent({
                    eventName: 'AdjustEventFailure',
                    eventProperties: { ...event, platforms: this.platform.platforms() },
                }),
            ),
        );
    });

    adjustUpdateAttributionData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(adjustUpdateAttributionData),
            map(({ attributionData }) => updateAttributionData({ attributionData })),
        );
    });
}
