import { RadioWithLogicItem } from '@frontend/ui';

export const ZERO_TO_FOUR_MONTHS_RADIO_OPTION: RadioWithLogicItem = {
    value: '0 - 4 months',
    domElement: {
        name: 'datetime',
        type: 'date',
    },
};

export const FIVE_TO_ELEVEN_MONTHS_RADIO_OPTION: RadioWithLogicItem = {
    value: '5 - 11 months',
    domElement: {
        name: 'datetime',
        type: 'month-year',
    },
};

export const ONE_TO_TWO_YEARS_RADIO_OPTION: RadioWithLogicItem = {
    value: '1 - 2 years',
    domElement: {
        name: 'datetime',
        type: 'month-year',
    },
};

export const OVER_TWO_YEARS_RADIO_OPTION: RadioWithLogicItem = {
    value: '2+ years',
};

export const AGE_RADIO_OPTIONS = [
    ZERO_TO_FOUR_MONTHS_RADIO_OPTION,
    FIVE_TO_ELEVEN_MONTHS_RADIO_OPTION,
    ONE_TO_TWO_YEARS_RADIO_OPTION,
    OVER_TWO_YEARS_RADIO_OPTION,
];
