import { getAccountSuccess, Profile, updateAccountSuccess } from '@frontend/data-access/user/account';
import { Action } from '@ngrx/store';
import { format, formatISO } from 'date-fns';
import { createFormGroupState, formStateReducer, reset, setValue, updateGroup, validate } from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';
import { resetProfileEditForm } from '../../../profile/pages/profile-edit/store/profile-edit.page.actions';
import { EditProfileForm, EditProfileFormValues } from './edit-profile.form.model';
import { maxDate } from '@shared/utils/typescript';

export const FORM_ID = 'editProfileForm';

export const initialEditProfileFormValues: EditProfileFormValues = {
    name: '',
    breedId: '',
    gender: undefined,
    ownerName: '',
    mail: '',
    dateOfBirth: formatISO(new Date(), { representation: 'date' }),
};

export const initialEditProfileFormState: EditProfileForm = createFormGroupState<EditProfileFormValues>(
    FORM_ID,
    initialEditProfileFormValues,
);

const validateForm = (form: EditProfileForm): EditProfileForm =>
    updateGroup<EditProfileFormValues>({
        name: validate([required, maxLength(16)]),
        breedId: validate([required]),
        gender: validate([required]),
        ownerName: validate([required, maxLength(32)]),
        dateOfBirth: validate([required, maxDate(new Date())]),
    })(form);

export const editProfileFormReducer = (form: EditProfileForm, action: Action): EditProfileForm => {
    let result = form;
    // todo look at changing this to make it more modern
    result = formStateReducer(result, action);
    result = validateForm(result);

    switch (action.type) {
        case updateAccountSuccess.type: {
            const profile = (action as unknown as { profile: Profile }).profile;
            result = setEditProfileState(result, profile);
            break;
        }
        case getAccountSuccess.type: {
            const profile = (action as unknown as { profile: Profile }).profile;
            result = setEditProfileState(result, profile);
            break;
        }
        case resetProfileEditForm.type: {
            const profile = (action as unknown as { profile: Profile }).profile;
            result = setEditProfileState(result, profile);
            break;
        }
    }
    return result;
};

const setEditProfileState = (form: EditProfileForm, profile: Profile): EditProfileForm => {
    let result = form;

    result = reset(result);
    result = setValue(result, {
        name: profile.name,
        ownerName: profile.ownerName,
        mail: profile.mail,
        gender: profile.gender,
        breedId: profile.breedId,
        dateOfBirth: format(profile.dateOfBirth ?? new Date(), 'yyy-MM-dd'),
    });
    return result;
};
