import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TopicCardComponent } from '../../components/topic-card/topic-card.component';
import { selectLibraryTopicOverviewVm } from './library-topic-overview.selectors';

@Component({
    selector: 'app-library-topic-overview',
    templateUrl: './library-topic-overview.component.html',
    styleUrls: ['library-topic-overview.component.scss'],
    standalone: true,
    imports: [TopicCardComponent, AsyncPipe],
})
export class LibraryTopicOverviewComponent {
    private readonly store = inject(Store);

    public vm$ = this.store.select(selectLibraryTopicOverviewVm);
}
