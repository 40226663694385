import { createSelector } from '@ngrx/store';
import { selectPinnedTopicsWithProgress } from '../../store/topic/topic-with-progress.selectors';
import { LoadingState } from '@frontend/data-access/shared-models';
import { pinnedTopicFeature } from '@frontend/data-access/user/pinned-topics';

export const selectPinnedTopicOverviewVm = createSelector(
    selectPinnedTopicsWithProgress,
    pinnedTopicFeature.selectLoadingState,
    (topics, loading) => {
        return { topics, arePinnedTopicsLoading: loading === LoadingState.LOADING };
    },
);
