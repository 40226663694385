import { destroyChat, getLatestMessageDateSuccess, setMessageData } from '@frontend/data-access/chat';
import { ChatChannelType, ZIGGY_AI_ID } from '@shared/constants';
import { createFeature, createReducer, on } from '@ngrx/store';
import { isSameDay } from 'date-fns';
import { answerTimeOut, sendMessage, sendMessageSuccess, userSentMessageInAiChat } from './ai-chat.actions';
import { aiChatBackButtonClicked, aiChatInputSubmitted } from '../../../today/today.page.actions';

export interface AiChatState {
    isAnswerLoading: boolean;
    didAnswerTimeOut: boolean;
    questionsRemaining: number;
    initialMessage: string;
}

export const initialState: AiChatState = {
    isAnswerLoading: false,
    didAnswerTimeOut: false,
    questionsRemaining: 0,
    initialMessage: '',
};

export const aiChatFeature = createFeature({
    name: 'aiChat',
    reducer: createReducer(
        initialState,
        on(
            userSentMessageInAiChat,
            sendMessageSuccess,
            (state): AiChatState => ({
                ...state,
                isAnswerLoading: true,
                didAnswerTimeOut: false,
            }),
        ),
        on(setMessageData, (state, { lastMessageSender, channelType }): AiChatState => {
            if (channelType !== ChatChannelType.AI) {
                return state;
            }

            return {
                ...state,
                isAnswerLoading: lastMessageSender !== ZIGGY_AI_ID,
                didAnswerTimeOut: false,
            };
        }),
        on(
            answerTimeOut,
            (state): AiChatState => ({
                ...state,
                isAnswerLoading: false,
                didAnswerTimeOut: true,
            }),
        ),
        on(getLatestMessageDateSuccess, (state, { date, chatType }): AiChatState => {
            if (chatType !== ChatChannelType.AI) {
                return state;
            }
            return {
                ...state,
                questionsRemaining: isSameDay(new Date(), date) ? 0 : 1,
            };
        }),
        on(
            aiChatBackButtonClicked,
            destroyChat,
            sendMessage,
            (state): AiChatState => ({
                ...state,
                initialMessage: '',
            }),
        ),
        on(
            aiChatInputSubmitted,
            (state, { message }): AiChatState => ({
                ...state,
                initialMessage: message,
            }),
        ),
    ),
});
