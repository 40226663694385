import { createAction, props } from '@ngrx/store';
import { Household } from '../models/household.model';

export const getHousehold = createAction('[Household] Get Household');

export const getHouseholdSuccess = createAction('[Household] Get Household Success', props<{ household: Household }>());

export const getHouseholdFailure = createAction('[Household] Get Household Failure', props<{ error: Error }>());

export const createHousehold = createAction(
    '[Household] Create Household',
    props<{ id?: string; email: string; accountId: string; countryCode: string }>(),
);

export const createHouseholdSuccess = createAction(
    '[Household] Create Household Success',
    props<{ household: Household }>(),
);

export const createHouseholdFailure = createAction('[Household] Create Household Failure', props<{ error: Error }>());
