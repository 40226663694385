@if (vm$ | async; as vm) {
<ion-content class="food-calculator-bg" [fullscreen]="true">
    <section class="food-calculator-new ion-text-center">
        <app-food-calculator-result
            [foodPortion]="vm.foodPortion"
            [loading]="foodPortionLoading$ | async"
            (howIsThisCalculated)="openDisclaimerModal()"
        />
        <app-food-calculator-form
            [foodPortionQuery]="vm.foodPortionQuery"
            [foodPortionLastCalculated]="vm.foodPortionLastCalculated"
            (formValueChange)="formValueChange($event)"
            (showWeightInfoModal)="showWeightInfoModal()"
        />
    </section>
</ion-content>
}
<app-food-calculator-instruction-modal-content />
