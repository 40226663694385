import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OpenSettingsService } from '../services/open-settings.service';
import { concatMap, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
    openSettingsAndroid,
    openSettingsAndroidFailure,
    openSettingsAndroidSuccess,
    openSettingsIos,
    openSettingsIosFailure,
    openSettingsIosSuccess,
} from './open-settings.actions';

@Injectable()
export class OpenSettingsEffects {
    private readonly actions$ = inject(Actions);
    private readonly openSettingsService = inject(OpenSettingsService);
    openSettingsAndroid$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(openSettingsAndroid),
            concatMap(({ option }) =>
                from(this.openSettingsService.openAndroid(option)).pipe(
                    map(() => {
                        return openSettingsAndroidSuccess({ option });
                    }),
                    catchError((error) => of(openSettingsAndroidFailure({ option, error }))),
                ),
            ),
        );
    });

    openSettingsIos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(openSettingsIos),
            concatMap(({ option }) =>
                from(this.openSettingsService.openIos(option)).pipe(
                    map(() => {
                        return openSettingsIosSuccess({ option });
                    }),
                    catchError((error) => of(openSettingsIosFailure({ option, error }))),
                ),
            ),
        );
    });
}
