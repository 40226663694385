import { mixpanelConfigTest } from '@shared/config/mixpanel-config';
import { firebaseConfigDev } from '../firebase.config';
import { Environment } from './environment.model';
import { ProjectEnvironment } from '@frontend/configuration';

export const environment: Environment = {
    baseEnvironmentConfig: {
        production: false,
        environment: ProjectEnvironment.ACCEPTANCE,
        internalApiUrl: 'https://zigzag-internal-traffic-manager-test.trafficmanager.net/',
        internalAuthSecret: 'NIDct6kA0I',
        applicationToInternalHashedApiKey:
            '$argon2id$v=19$m=4096,t=3,p=1$UGp0YUxkKjIweEFTNTFnZmEjUmVGaCZLQDZ4WkRuaSo$n2ojwADy07KyxvceM1C/KMUftcL9+STRUt9MWRmSstQ',
    },
    firebaseConfig: firebaseConfigDev,
    useSubscriptionMock: false,
    revenueCatKey: 'FkcuhfqFHCUvosCPapJXilfzyXCjzOnd',
    adjustAppToken: 'zlhl6pv8y70g',
    streamChatConfig: {
        apiKey: 'zatrqnzwk4pd',
        useServiceMock: false,
    },
    configCatConfig: {
        apiKey: 'configcat-sdk-1/w6LaCCdY1kiHQRICXSOQjA/2NGnYV9DAkm7WydcfVnf1w',
        useServiceMock: false,
    },
    applicationInsights: {
        active: true,
        instrumentationKey: '00080a59-38b5-432e-bad6-a07d49694555',
    },
    mixpanelConfig: {
        platform: 'browser',
        projectToken: mixpanelConfigTest.projectToken,
    },
    attachStoreToWindow: false,
};
