import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StarRatingComponent } from '@frontend/ui';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-lesson-rating',
    templateUrl: './lesson-rating.component.html',
    styleUrls: ['./lesson-rating.component.scss'],
    standalone: true,
    imports: [StarRatingComponent, AsyncPipe, TextReplacementPipe],
})
export class LessonRatingComponent {
    @Input() public rating = 0;
    @Input() public maxRating = 0;
    @Output() public ratingChangedEvent = new EventEmitter<{ ratedStep: boolean; currentRating: number }>();

    public ratedStep = false;
    public ratingText = 'Rate how well [NAME] did this lesson';
    public currentRating = 0;

    onRatingChange(updatedRating: number): void {
        this.ratedStep = true;
        switch (updatedRating) {
            case 1:
                this.ratingText = 'Not quite there yet ...';
                break;
            case 2:
                this.ratingText = '[NAME] needs more training';
                break;
            case 3:
                this.ratingText = '[NAME] is almost there!';
                break;
            case 4:
                this.ratingText = '[NAME] did great!';
                break;
            case 5:
                this.ratingText = 'Huge success!';
                break;
        }
        this.currentRating = updatedRating;
        this.ratingChangedEvent.emit({ ratedStep: this.ratedStep, currentRating: this.currentRating });
    }
}
