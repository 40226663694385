@for (i of [0, 1, 2]; track $index) {
    <ion-item class="step-card-slim-skeleton" lines="none" detail="false">
        <div class="step-card-slim-skeleton__image-container" [attr.data-test]="'skeleton-loader-image-' + $index">
            <ion-skeleton-text class="step-card-slim-skeleton__image" [animated]="true"></ion-skeleton-text>
        </div>
        <div class="step-card-slim-skeleton__text-container">
            <ion-skeleton-text class="step-card-slim-skeleton__title" [animated]="true"></ion-skeleton-text>
            <ion-skeleton-text class="step-card-slim-skeleton__subtitle" [animated]="true"></ion-skeleton-text>
        </div>
    </ion-item>
}
