<div
    [ngStyle]="{ transform: 'translate(' + step.coordinates.x + 'px,' + step.coordinates.y + 'px)' }"
    class="course-path-step-container"
    [attr.data-test]="'course-path-step-' + step.id"
>
    <div class="course-path-step-inner-container">
        @if (step.topic && step.progress) {
            <div
                [ngStyle]="step.isFirstInactiveStep ? setBorderAnimationColor(vm().selectedCourseColor) : null"
                [ngClass]="['course-path-step', step.topic.color, vm().unlocked ? step.progress.state : 'locked']"
                (click)="goToStep(step, vm().unlocked)"
                data-test="course-path-step-icon"
            >
                @if (!step.progress.isPractice) {
                    <div class="rating-container">
                        @if (step.progress.rating && step.progress.state !== StepState.INACTIVE) {
                            <div class="circle-container">
                                @for (number of [1, 2, 3, 4, 5]; track number) {
                                    <i
                                        class="fa-solid fa-star"
                                        [ngClass]="number <= step.progress.rating ? 'star' : 'empty-star'"
                                    ></i>
                                }
                            </div>
                        }
                    </div>
                } @else {
                    <img
                        [src]="'/assets/images/illustrations/step/step-practice-text.svg'"
                        alt="This is a practice lesson"
                        class="step-practice-image image-no-max-width"
                    />
                }
                <div class="step-icon">
                    <img
                        [ngSrc]="step.thumbnailUrl"
                        fill="true"
                        loading="eager"
                        alt="step-image"
                        class="{{ mapStepTypeToImageClass(step.content.contentType) }}"
                    />
                    @if (vm().unlocked) {
                        @if (!step.progress.isPractice) {
                            @if (step.progress.state === StepStatus.READ) {
                                <div class="state-icon state-icon--read">
                                    <i
                                        [attr.data-test]="'icon-' + step.progress.state"
                                        class="fa-solid fa-glasses state-icon__icon"
                                    ></i>
                                </div>
                            }
                            @if (step.progress.state === StepStatus.SKIPPED) {
                                <div class="state-icon state-icon--skipped">
                                    <i
                                        [attr.data-test]="'icon-' + step.progress.state"
                                        class="fa-solid fa-forward state-icon__icon"
                                    ></i>
                                </div>
                            }
                            @if (step.progress.state === StepStatus.COMPLETED) {
                                <div class="state-icon state-icon--completed">
                                    <i
                                        [attr.data-test]="'icon-' + step.progress.state"
                                        class="fa-solid fa-check state-icon__icon"
                                    ></i>
                                </div>
                            }
                        }
                    } @else {
                        <div class="state-icon state-icon--locked">
                            <i class="fa-solid fa-lock state-icon__icon"></i>
                        </div>
                    }
                </div>
            </div>
        }
        <p class="path-step-title ignore-screen-based-font-scaling xs">
            {{ step.content.title | textReplacement }}
        </p>
    </div>
</div>
