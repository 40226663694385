export interface DismissibleModalConfig {
    modalId: string;
    useModalTemplate?: boolean;
    closeable?: boolean;
    mainButtonText?: string;
    mainButtonPath?: string;
    mainButtonAction?: () => void;
    backgroundColor?: string;
    style?: string;
}
