import { selectCourseLoadingState, selectTodayPageState, stepsFeature } from '@frontend/data-access/contentful';
import { LoadingState } from '@frontend/data-access/shared-models';
import {
    courseProgressFeature,
    selectCompletedOrSkippedStepProgressIds,
    stepProgressFeature,
    todayCourseProgressFeature,
} from '@frontend/data-access/user/progress';
import { CourseType } from '@shared/content-api-interface';
import { createSelector } from '@ngrx/store';
import { isSameDay } from 'date-fns';
import { selectCoursesWithStepsAndSplits } from '../../courses/course-steps-and-splits.selectors';
import { CourseWithSteps } from '../../courses/models/courses.models';
import { StepCardSlimModel } from '../../step/step-card-slim/models/step-card-slim.model';
import { selectInactiveCourses } from '../../store/course/course-with-progress.selectors';
import { CourseStatus } from '../../store/course/course.model';
import { selectContentUnlocked } from '../../store/payment/store/payment.selectors';
import { selectAgeInDays, selectAgeInWeeks, selectHasArrived } from '@frontend/data-access/user/account';
import { StepStatus } from '../../store/step/step.model';
import { mapStepToStepCardSlim } from '../../store/step/step.utils';
import { CourseCard } from './course-card/course-card.model';
import { filterCourseForTodayPage, getCompliment, sortCoursesForTodayPage } from './today-courses.utils';

export const selectIncompleteStepsAtStartOfDayForAllCourses = createSelector(
    selectCoursesWithStepsAndSplits,
    (courses) => {
        return courses.map(
            (course): CourseWithSteps => ({
                ...course,
                steps: course.steps.filter((step) => {
                    if (step.progress.state === StepStatus.INACTIVE || step.progress.state === StepStatus.READ) {
                        return true;
                    }

                    return step.progress.date && isSameDay(step.progress.date, new Date());
                }),
            }),
        );
    },
);

export const selectNextThreeStepsCardItemForAllCourses = createSelector(
    selectIncompleteStepsAtStartOfDayForAllCourses,
    selectContentUnlocked,
    todayCourseProgressFeature.selectTodayCourseProgress,
    (courseIdsWithNextSteps, unlocked, todayCourseProgress) => {
        return courseIdsWithNextSteps.map((courseIdWithNextSteps) => {
            const completedStepsToday =
                todayCourseProgress.find((courseProgress) => courseProgress.courseId === courseIdWithNextSteps.id)
                    ?.numberOfStepsCompleted ?? 0;

            const numberOfStepsToReturn = completedStepsToday % 3 === 0 ? 3 : 3 - (completedStepsToday % 3);

            return {
                courseId: courseIdWithNextSteps.id,
                cardItemsData: courseIdWithNextSteps.steps
                    .filter(
                        (step) =>
                            !(
                                step.progress.state === StepStatus.COMPLETED ||
                                step.progress.state === StepStatus.SKIPPED
                            ),
                    )
                    .slice(0, numberOfStepsToReturn)
                    .map((step): StepCardSlimModel => {
                        return mapStepToStepCardSlim(step, !unlocked);
                    }),
                completedStepsToday,
            };
        });
    },
);

export const selectCoursesForTodayCoursesVm = createSelector(
    selectCoursesWithStepsAndSplits,
    selectNextThreeStepsCardItemForAllCourses,
    selectTodayPageState,
    selectHasArrived,
    selectCompletedOrSkippedStepProgressIds,
    selectAgeInDays,
    selectAgeInWeeks,
    (courses, courseCardItems, motivationalMessages, hasArrived, stepsCompletedOrSkipped, ageInDays, ageInWeeks) => {
        return {
            courses: courses
                .map((course): CourseCard => {
                    const completedStepsToday =
                        courseCardItems.find((cardItemWithCourseId) => cardItemWithCourseId.courseId === course.id)
                            ?.completedStepsToday ?? 0;
                    return {
                        ...course,
                        locked: course.type === CourseType.FOUNDATIONAL && course.status === CourseStatus.INACTIVE,
                        cardItems:
                            courseCardItems.find((cardItemWithCourseId) => cardItemWithCourseId.courseId === course.id)
                                ?.cardItemsData ?? [],
                        completedStepsToday,
                        courseCompliment: getCompliment(
                            motivationalMessages,
                            completedStepsToday,
                            ageInDays,
                            ageInWeeks,
                            course.type,
                            course.steps,
                            stepsCompletedOrSkipped.length,
                        ),
                    };
                })
                .filter((course) => filterCourseForTodayPage(course))
                .sort((a, b) => sortCoursesForTodayPage(a, b, hasArrived)),
        };
    },
);

export const selectTodayCoursesVm = createSelector(
    selectInactiveCourses,
    selectCourseLoadingState,
    courseProgressFeature.selectLoadingState,
    stepsFeature.selectLoading,
    stepProgressFeature.selectLoadingState,
    selectCoursesForTodayCoursesVm,
    (
        inactiveCourses,
        courseLoading,
        courseProgressLoading,
        stepsLoading,
        stepProgressLoading,
        coursesForTodayCourses,
    ) => {
        return {
            showAddButton: inactiveCourses.length !== 0 && courseProgressLoading === LoadingState.LOADED,
            courseProgressLoading:
                courseProgressLoading !== LoadingState.LOADED || courseLoading !== LoadingState.LOADED,
            stepProgressLoading: stepProgressLoading === LoadingState.LOADING || stepsLoading !== LoadingState.LOADED,
            ...coursesForTodayCourses,
        };
    },
);
