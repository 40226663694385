import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, on } from '@ngrx/store';
import { upsertArrayItem } from '@shared/utils/typescript';
import { CourseProgressState } from '../models/course-progress.model';
import {
    createCourseProgress,
    createCourseProgressFailure,
    createCourseProgressSuccess,
    createManyCourseProgress,
    createManyCourseProgressFailure,
    createManyCourseProgressSuccess,
    getCourseProgress,
    getCourseProgressFailure,
    getCourseProgressSuccess,
} from './course-progress.actions';

export const courseProgressInitialState: CourseProgressState = {
    progress: [],
    loadingState: LoadingState.INIT,
};

export const courseProgressFeature = createFeature({
    name: 'courseProgress',
    reducer: createReducer(
        courseProgressInitialState,
        on(
            getCourseProgress,
            createCourseProgress,
            createManyCourseProgress,
            (state): CourseProgressState => ({
                ...state,
                loadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getCourseProgressSuccess,
            (state, { progress }): CourseProgressState => ({
                ...state,
                progress,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(createCourseProgressSuccess, (state, { entry }): CourseProgressState => {
            const progress = [...state.progress];
            const index = progress.findIndex((existingEntry) => existingEntry.courseId === entry.courseId);

            if (index === -1) {
                progress.push(entry);
            } else {
                progress[index] = entry;
            }

            return {
                ...state,
                progress,
                loadingState: LoadingState.LOADED,
            };
        }),
        on(createManyCourseProgressSuccess, (state, { entries }): CourseProgressState => {
            const updatedEntries = upsertArrayItem(
                [...state.progress],
                entries,
                (existingEntry, newEntry) => existingEntry.courseId === newEntry.courseId,
            );

            return {
                ...state,
                progress: updatedEntries,
                loadingState: LoadingState.LOADED,
            };
        }),
        on(
            getCourseProgressFailure,
            createCourseProgressFailure,
            createManyCourseProgressFailure,
            (state): CourseProgressState => ({
                ...state,
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
});
