import { NgStyle } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { isColorContrastLight } from '@shared/utils/typescript';
import { Course } from '@frontend/data-access/contentful';
import { ModalService } from '@frontend/utility/modal';
import { DEFAULT_PUPPY_NAME } from '@shared/constants';
import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { addIcons } from 'ionicons';
import { shareOutline } from 'ionicons/icons';
import { socialShare } from '@frontend/data-access/capacitor';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';

@Component({
    selector: 'app-course-completion-modal',
    templateUrl: './course-completion-modal.component.html',
    styleUrls: ['./course-completion-modal.component.scss'],
    standalone: true,
    imports: [
        IonContent,
        NgStyle,
        AnalyticsTrackClickDirective,
        IonFooter,
        IonButton,
        IonIcon,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
})
export class CourseCompletionModalComponent {
    private readonly modalService = inject(ModalService);
    private readonly store = inject(Store);
    @Input() public course!: Course;
    @Input() public dogName = DEFAULT_PUPPY_NAME;

    public readonly squiggleDarkSvg = 'url(/assets/images/squiggles/course-completion-squiggle-dark.svg) no-repeat';
    public readonly squiggleLightSvg = 'url(/assets/images/squiggles/course-completion-squiggle-light.svg) no-repeat';

    constructor() {
        addIcons({ shareOutline });
    }

    public closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    public share(): void {
        void this.store.dispatch(
            socialShare({
                message: `Woohoo! ${this.dogName} just completed ${this.course.title} successfully on the Zigzag app!
         Join me on https://zigzag.dog`,
            }),
        );
    }

    public getDynamicallyAppliedStyles() {
        return {
            background: isColorContrastLight(this.course.color) ? this.squiggleLightSvg : this.squiggleDarkSvg,
            'background-size': 'contain',
        };
    }
}
