import { SubscriptionProduct } from '../subscription.model';

export class SubscriptionProductMock {
    private defaultValue: SubscriptionProduct = {
        identifier: 'zigzag_quarterly_plan:zigzag-quarterly',
        description: 'Tailored training plan and puppy coach chat.',
        title: 'Pup Training: Quarterly Plan',
        price: 19.99,
        priceWithUnit: '£19.99',
        pricePerMonth: 6.663333333333333,
        currencySymbol: '£',
        introPricePeriod: '7 days',
        discount: { identifier: '', price: 0, priceWithUnit: '', pricePerMonth: 0, discountPercentage: 0 },
    };

    constructor(overrides?: Partial<SubscriptionProduct>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SubscriptionProduct {
        return this.defaultValue;
    }
}
