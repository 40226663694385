import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationInsightsConfig } from './application-insights-injection-tokens';

export const appInsightsFactory = (appInsightsConfig: ApplicationInsightsConfig) => {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: appInsightsConfig.instrumentationKey,
        },
    });

    appInsights.loadAppInsights();

    return appInsights;
};
