import { selectQueryParams, selectRouteParams, selectUrl, selectUrlLastChild } from '@frontend/data-access/router';
import { createSelector } from '@ngrx/store';
import { ALL_TAB_ROUTES } from '../../tabs/tabs.constants';

export const tabRoutes = ['today', 'courses', 'library', 'food-calculator', 'support'];

export const selectIsRouteChildRoute = createSelector(selectUrlLastChild, (lastChild) => {
    return lastChild && !tabRoutes.includes(lastChild);
});

export const selectCurrentTab = createSelector(selectUrl, (url) => {
    const paths = url.split('/');
    // 'domain', 'main', [tab route], [sub route] (query params still on)

    const pathsWithoutQueryParams = paths.map((path) => {
        if (path.includes('?')) {
            return path.split('?')[0];
        }
        return path;
    });

    if (pathsWithoutQueryParams.length < 3) {
        return undefined;
    }

    return ALL_TAB_ROUTES.find((tab) => tab === pathsWithoutQueryParams[2]);
});

export const selectRouteParamStepId = createSelector(selectRouteParams, (routeParams) => routeParams['stepId']);

export const selectRouteParamTopic = createSelector(selectRouteParams, (routeParams) => routeParams['topic']);

export const selectQueryParamOptionId = createSelector(selectQueryParams, (queryParams) => queryParams['optionId']);

export const selectQueryParamFrom = createSelector(selectQueryParams, (queryParams) => queryParams['from']);

export const selectQueryParamRating = createSelector(selectQueryParams, (queryParams) => {
    const rating = queryParams['rating'];
    return rating !== undefined ? Number.parseInt(rating) : -1;
});

export const selectQueryParamFromPracticeStep = createSelector(selectQueryParams, (queryParams) => {
    const fromPracticeStep = queryParams['fromPracticeStep'];

    return fromPracticeStep === 'true';
});
