@if (profileImage$ | async; as profileImageSrc) {
    <div class="profile-image-container">
        <img
            data-test="profile-image"
            [class.image-border]="type !== ProfileImageType.EDIT"
            [src]="profileImageSrc"
            (click)="isClickable && selectImageSource()"
            alt="profile-image"
        />
    </div>
}
