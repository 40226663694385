@if (dialogProps) {
    <h2>{{ dialogProps.title | textReplacement }}</h2>
    <p>{{ dialogProps.content | textReplacement }}</p>
}
<div>
    @if (supportProps) {
        <ion-button
            (click)="contentUnlocked ? sendChat() : openPaymentModal()"
            fill="outline"
            color="dark"
            expand="block"
            data-test="info-dialog-chat-button"
        >
            {{ supportProps.buttonText | textReplacement }}
        </ion-button>
    }

    @if (dialogProps) {
        <ion-button (click)="close()" expand="block" color="max" data-test="info-dialog-close-button">{{
            dialogProps.buttonText | textReplacement
        }}</ion-button>
    }
</div>
