import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { UserAppAccessGranted } from '../models/user-app-access-granted.model';
import { LoadingState } from '@frontend/data-access/shared-models';
import {
    createUserAppAccessGranted,
    createUserAppAccessGrantedSuccess,
    getUserAppAccessGrantedSuccess,
    patchUserAppAccessGranted,
    patchUserAppAccessGrantedSuccess,
} from './user-app-access-granted.actions';

export interface UserAppAccessGrantedState {
    userAppAccessGranted: UserAppAccessGranted[];
    loadingState: LoadingState;
}

export const initialState: UserAppAccessGrantedState = {
    userAppAccessGranted: [],
    loadingState: LoadingState.INIT,
};

export const userAppAccessGrantedFeature = createFeature({
    name: 'userAppAccessGranted',
    reducer: createReducer(
        initialState,
        on(
            createUserAppAccessGranted,
            patchUserAppAccessGranted,
            (state): UserAppAccessGrantedState => ({
                ...state,
                loadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getUserAppAccessGrantedSuccess,
            (state, action): UserAppAccessGrantedState => ({
                ...state,
                userAppAccessGranted: action.userAppAccessGranted,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            createUserAppAccessGrantedSuccess,
            (state, action): UserAppAccessGrantedState => ({
                ...state,
                userAppAccessGranted: [...state.userAppAccessGranted, action.userAppAccessGranted],
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            patchUserAppAccessGrantedSuccess,
            (state, action): UserAppAccessGrantedState => ({
                ...state,
                userAppAccessGranted: state.userAppAccessGranted.map((appAccess) =>
                    appAccess.id === action.userAppAccessGranted.id
                        ? { ...appAccess, ...action.userAppAccessGranted }
                        : appAccess,
                ),
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
    extraSelectors: ({ selectUserAppAccessGranted }) => ({
        selectUserHasFreeAccess: createSelector(selectUserAppAccessGranted, (userAppAccess) => {
            return userAppAccess.some((userAppAccessGranted) => userAppAccessGranted.expiryTimestamp > Date.now());
        }),
    }),
});
