import { timeFeature } from '@frontend/data-access/time';
import { createSelector } from '@ngrx/store';
import { differenceInDays } from 'date-fns';
import { accountFeature } from '@frontend/data-access/user/account';

export const selectArrivalTileVm = createSelector(
    accountFeature.selectDateOfArrival,
    timeFeature.selectToday,
    accountFeature.selectHasArrivedOrUndefined,
    (profileDateOfArrival, today, hasArrived) => {
        const dateOfArrival = profileDateOfArrival ?? today;
        return {
            showTile: !hasArrived && hasArrived !== undefined,
            daysUntilArrival: differenceInDays(dateOfArrival, today),
        };
    },
);
