import { createFeature, createReducer, on } from '@ngrx/store';
import { upsertArrayItem } from '@shared/utils/typescript';
import { DismissibleInfoItem } from '../models/dismissible-info.model';
import { dismissInfoWithTimestamp } from './dismissible-info.actions';
import { getLocalStorageSuccess } from '@frontend/data-access/capacitor';
import { DISMISSIBLE_INFO_LOCAL_STORAGE_KEY } from './dismissible-info.constants';

export const dismissibleInfoFeatureKey = 'dismissibleInfo';

export interface DismissibleInfoState {
    dismissedInfoItems: DismissibleInfoItem[];
}

export const initialState: DismissibleInfoState = {
    dismissedInfoItems: [],
};
export const dismissibleInfoFeature = createFeature({
    name: dismissibleInfoFeatureKey,
    reducer: createReducer(
        initialState,
        on(getLocalStorageSuccess, (state, { key, data }): DismissibleInfoState => {
            if (key !== DISMISSIBLE_INFO_LOCAL_STORAGE_KEY) {
                return state;
            }

            return { ...state, ...(data as any) };
        }),
        on(
            dismissInfoWithTimestamp,
            (state: DismissibleInfoState, { id, timestamp, dontShowAgain }): DismissibleInfoState => {
                const { dismissedInfoItems: dismissedInfoItemsFromState } = state;

                const newDismissedInfoItems: DismissibleInfoItem[] = [{ id, timestamp, dontShowAgain }];
                const updatedDismissedInfoItems = upsertArrayItem(
                    dismissedInfoItemsFromState,
                    newDismissedInfoItems,
                    (item) => item.id === id,
                );

                return {
                    ...state,
                    dismissedInfoItems: updatedDismissedInfoItems,
                };
            },
        ),
    ),
});
