import { Article } from '@frontend/data-access/contentful';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { createSelector } from '@ngrx/store';
import { StepType } from '@shared/content-api-interface';
import { selectContentUnlocked } from '../store/payment/store/payment.selectors';
import { selectRouteParamStepId } from '../store/router/router.selectors';
import { Step } from '../store/step/step.model';
import { selectSteps } from '../store/step/step.selectors';

export const selectArticlePageVm = createSelector(
    selectRouteParamStepId,
    selectSteps,
    selectContentUnlocked,
    subscriptionFeature.selectHasHistoricalPurchase,
    (stepId, steps, contentUnlocked, hasHistoricalPurchase) => {
        const step = steps.find(
            (step): step is Step<Article> => step.id === stepId && step.content.contentType === StepType.ARTICLE,
        );

        return { step, unlocked: contentUnlocked, hasHistoricalPurchase };
    },
);
