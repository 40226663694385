import { Step, StepStatus } from '../../store/step/step.model';

export const placeRescheduledStepsInCourseSteps = (
    steps: Step[],
    rescheduledSteps: { stepId: string; count: number }[],
): Step[] => {
    let result = steps;

    // sort so we will start with the earliest practice steps to place
    rescheduledSteps.sort((a, b) => {
        const indexA = result.findIndex((step) => step.id === a.stepId) + a.count * 4;
        const indexB = result.findIndex((step) => step.id === b.stepId) + b.count * 4;
        return indexA - indexB;
    });

    rescheduledSteps.forEach((item) => {
        const step = result.find((step) => step.id === item.stepId && step.progress && !step.progress.isPractice);

        if (step) {
            const index = result.indexOf(step) + item.count * 4;

            result = [
                ...result.slice(0, index),
                {
                    ...step,
                    progress: {
                        state: StepStatus.INACTIVE,
                        isPractice: true,
                    },
                },
                ...result.slice(index),
            ];
        }
    });

    return result;
};
