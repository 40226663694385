import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable, skipWhile } from 'rxjs';
import { accountFeature } from '@frontend/data-access/user/account';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable({
    providedIn: 'root',
})
export class OnboardingCompleteGuard {
    private readonly store = inject(Store);
    private readonly router = inject(Router);

    public canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(accountFeature.selectAtLeastOneProfileCalledHasFinished).pipe(
            skipWhile((ready) => !ready),
            concatLatestFrom(() => [this.store.select(accountFeature.selectDateOfOnboarding)]),
            map(([, dateOfOnboarding]) => {
                if (dateOfOnboarding) {
                    return this.router.createUrlTree(['main', 'today']);
                } else {
                    return true;
                }
            }),
        );
    }
}
