import { Course } from '@frontend/data-access/contentful';
import { OptionalCourseId } from '@shared/constants';
import { CourseType } from '@shared/content-api-interface';

export const filterCoursesForTodayPage = (
    courses: Course[],
    hasArrived: boolean,
    ageInWeeks: number | undefined,
    isRescue: boolean | undefined,
) => {
    const result: Course[] = [];

    if (!courses.length || hasArrived === undefined || ageInWeeks === undefined || isRescue === undefined) {
        return result;
    }

    // Pre-pup
    if (!hasArrived) {
        const prePupCourse = courses.filter((course) => course.type === CourseType.PRE_PUP);
        result.push(...prePupCourse);
    }

    // Foundational
    const foundationalCourses = courses.filter((course) => course.type === CourseType.FOUNDATIONAL);
    result.push(...foundationalCourses);

    if (!hasArrived) {
        return result;
    }

    // Pup Master
    if (ageInWeeks >= 0 && ageInWeeks <= 25) {
        const pupMasterCourse = courses.filter((course) => course.id === OptionalCourseId.PUP_MASTER);
        result.push(...pupMasterCourse);
    }

    // Terrible Teens
    if (ageInWeeks >= 12 && ageInWeeks <= 51 && !isRescue) {
        const terribleTeensCourse = courses.filter((course) => course.id === OptionalCourseId.TERRIBLE_TEENS);
        result.push(...terribleTeensCourse);
    }

    // Separation Foundations
    if (ageInWeeks >= 12) {
        const separationFoundationsCourse = courses.filter(
            (course) => course.id === OptionalCourseId.SEPARATION_FOUNDATIONS,
        );
        result.push(...separationFoundationsCourse);
    }

    // Trick Starter
    if (ageInWeeks >= 26) {
        const trickStarterCourse = courses.filter((course) => course.id === OptionalCourseId.TRICK_STARTER);
        result.push(...trickStarterCourse);

        // Move Separation Foundation to the end
        const separationFoundationsIndex = result.findIndex(
            (course) => course.id === OptionalCourseId.SEPARATION_FOUNDATIONS,
        );
        if (separationFoundationsIndex !== -1) {
            result.push(result.splice(separationFoundationsIndex, 1)[0]);
        }
    }

    return result;
};
