<div class="jump-ahead-modal">
    <div class="jump-ahead-modal__background"></div>
    <!--div necessary for flexbox spread-->
    <div>
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="false"
                    [rightButton]="{
                        analyticsIdentifier: 'jump-ahead-close-btn',
                        sprite: 'light',
                        iconName: 'xmark',
                        background: 'white',
                        iconColour: 'black',
                    }"
                    (rightButtonPressed)="closeModal()"
                />
            </ion-toolbar>
        </ion-header>

        <section class="jump-ahead-modal__content-container page-content-padding-top page-padding-sides">
            <div class="jump-ahead-modal__image-container">
                <img [src]="'/assets/images/charly/charly-wants-belly-rubs.png'" alt="pup laying on back" />
            </div>
            <h3 class="ion-text-center ion-padding-top">Why do you want to jump to the next part?</h3>
            <app-radio-with-explanation
                class="jump-ahead-modal__radio"
                [buttons]="buttons"
                [color]="Color.Harley"
                (changeEvent)="setAnswer($event)"
            ></app-radio-with-explanation>
        </section>
    </div>
    <ion-footer class="jump-ahead-modal__footer page-padding-sides page-padding-bottom">
        <ion-button
            (click)="startNextPart()"
            color="max"
            expand="block"
            appTrackClick
            identifier="jump-ahead-start-next-part-btn"
            data-test="jump-ahead-start-next-part-btn"
            class="disabled-max-no-opacity"
            [disabled]="!radioResult?.complete"
            >Start next part
        </ion-button>
        <button
            (click)="closeModal()"
            appTrackClick
            identifier="jump-ahead-cancel-btn"
            data-test="jump-ahead-cancel-btn"
            class="zz-button ion-text-center"
        >
            Cancel
        </button>
    </ion-footer>
</div>
