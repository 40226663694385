<section class="payment-modal-discount-products-title">
    <h1>
        {{ vm().ownerName }}, here is an additional
        <span class="payment-modal-discount-products-title__highlight">{{
            vm().discountProducts[0]?.discount?.discountPercentage
        }}</span>
        off for you
    </h1>
    <p class="s">
        {{ 'Limited time only! Continue [NAME]’s journey to pup happiness.' | textReplacement }}
    </p>
</section>

@for (product of vm().discountProducts; track product.identifier) {
    <app-payment-modal-product
        [isSelected]="product.identifier === selectedProduct()?.identifier"
        [product]="product"
        (productClicked)="onProductClick($event)"
    />
}

<ion-footer class="payment-modal-discount-products-footer">
    <ion-button
        appTrackClick
        [identifier]="'discount-paywall-payment-purchase-' + selectedProduct()?.identifier"
        color="max"
        expand="full"
        shape="round"
        [disabled]="!selectedProduct() || vm().isPurchaseInProgress"
        (click)="onPurchaseClick()"
    >
        Get offer
    </ion-button>
    <button
        class="zz-button"
        appTrackClick
        identifier="discount-products-paywall-payment-restore-button"
        (click)="onRestoreClick()"
    >
        Restore purchases
    </button>
    <app-payment-modal-terms />
</ion-footer>
