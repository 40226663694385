import { PurchaseProduct } from '../subscription.model';

export class PurchaseProductMock {
    private defaultValue: PurchaseProduct = {
        identifier: '',
        priceWithUnit: '',
    };

    constructor(overrides?: Partial<PurchaseProduct>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): PurchaseProduct {
        return this.defaultValue;
    }
}
